import CoreApiService from "../../system/api/coreApiService";
import { MovementRequest } from "../../libs/core-api-requests/documents/movementRequest";
import { DocumentDataProviderV2 } from "./Common/DocumentDataProviderV2";
import {  IMovementCreateDTO, IMovementGetDTO, IMovementUpdateDTO, IMovementViewDTO } from "../../libs/coreapi-dto/documents/movement";
import { IMovementItemGetDTO, IMovementItemViewDTO } from "../../libs/coreapi-dto/documents/movementItem";


export class MovementDataProvider extends DocumentDataProviderV2<IMovementViewDTO, IMovementGetDTO, IMovementCreateDTO, IMovementUpdateDTO, IMovementItemViewDTO, IMovementItemGetDTO>
{
    private _coreApiService: CoreApiService;

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<MovementRequest> {
        return this._coreApiService.movementRequest;
    }

    getParamView(body: IViewBody, callback?: (data: IMovementViewDTO[], totalCount: number) => void) {
        this.getDataRequest().then((r) =>
            r.getParamView(body, (e) => {
                if (e.respType === "isCompleted") callback?.(e.data.entities, e.data.totalCount);
                else throw new Error(e.message.detail);
            })
        );
    }

}