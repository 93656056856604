const AnalysisLotGridPluginSettings: IPluginSettings = {
    name: 'Анализ товарных остатков',
    mnemocode: 'analysis_lot_plugin',
    permission: 'Lot',
    columns: [{
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'IdGlobal',
        visibility: false,
        width: 150
    }, {
        order: 1,
        propertyName: 'idContractorGlobal',
        propertyType: 'string',
        displayName: 'idContractorGlobal',
        visibility: false,
        width: 150
    }, {
        order: 2,
        propertyName: 'idGoodsGlobal',
        propertyType: 'string',
        displayName: 'idGoodsGlobal',
        visibility: false,
        width: 150
    }, {
        order: 3,
        propertyName: 'idProducerGlobal',
        propertyType: 'string',
        displayName: 'idProducerGlobal',
        visibility: false,
        width: 150
    }, {
        order: 4,
        propertyName: 'idStoreGlobal',
        propertyType: 'string',
        displayName: 'idStoreGlobal',
        visibility: false,
        width: 150
    }, {
        order: 5,
        propertyName: 'goodsName',
        propertyType: 'string',
        displayName: 'Товар',
        visibility: true,
        width: 150
    }, {
        order: 6,
        propertyName: 'goodsNameShort',
        propertyType: 'string',
        displayName: 'Короткое наименование',
        visibility: false,
        width: 150
    }, {
        order: 7,
        propertyName: 'goodsCode',
        propertyType: 'string',
        displayName: 'Код товара',
        visibility: true,
        width: 150
    }, {
        order: 8,
        propertyName: 'countryName',
        propertyType: 'string',
        displayName: 'Страна',
        visibility: true,
        width: 150
    }, {
        order: 9,
        propertyName: 'internalBarcode',
        propertyType: 'string',
        displayName: 'Внутренний штрихкод',
        visibility: true,
        width: 150
    }, {
        order: 10,
        propertyName: 'producerName',
        propertyType: 'string',
        displayName: 'Производитель',
        visibility: true,
        width: 150
    }, {
        order: 11,
        propertyName: 'scalingRatioDisplayName',
        propertyType: 'string',
        displayName: 'Упаковка',
        visibility: true,
        width: 150
    }, {
        order: 12,
        propertyName: 'storeName',
        propertyType: 'string',
        displayName: 'Склад',
        visibility: true,
        width: 150
    }, {
        order: 13,
        propertyName: 'tradeNameRus',
        propertyType: 'string',
        displayName: 'ТН (рус)',
        visibility: true,
        width: 150
    }, {
        order: 14,
        propertyName: 'tradeNameLat',
        propertyType: 'string',
        displayName: 'ТН (лат)',
        visibility: true,
        width: 150
    }, {
        order: 15,
        propertyName: 'isGnvls',
        propertyType: 'boolean',
        displayName: 'ЖНВЛС',
        visibility: true,
        width: 150
    }, {
        order: 16,
        propertyName: 'isKiz',
        propertyType: 'boolean',
        displayName: 'Киз',
        visibility: true,
        width: 150
    }, {
        order: 17,
        propertyName: 'isKizBox',
        propertyType: 'boolean',
        displayName: 'Короб',
        visibility: true,
        width: 150
    }, 
    /*{
        order: 18,
        propertyName: 'isKizFlag',
        propertyType: 'boolean',
        displayName: 'КИЗ',
        visibility: true,
        width: 150
    },*/
    {
        order: 19,
        propertyName: 'quantityAdd',
        propertyType: 'number',
        displayName: 'Кол-во (Добавлено)',
        visibility: true,
        width: 150
    }, {
        order: 20,
        propertyName: 'quantitySub',
        propertyType: 'number',
        displayName: 'Кол-во (Проведено)',
        visibility: true,
        width: 150
    }, {
        order: 21,
        propertyName: 'quantityRem',
        propertyType: 'number',
        displayName: 'Кол-во (Остаток)',
        visibility: true,
        width: 150
    }, {
        order: 22,
        propertyName: 'quantityRes',
        propertyType: 'number',
        displayName: 'Кол-во (Зарезервировано)',
        visibility: true,
        width: 150
    }, {
        order: 23,
        propertyName: 'productMargin',
        propertyType: 'number',
        displayName: 'Наценка',
        visibility: true,
        width: 150
    }, {
        order: 24,
        propertyName: 'retailPrice',
        propertyType: 'number',
        displayName: 'Цена розн.',
        visibility: true,
        width: 150
    }, {
        order: 25,
        propertyName: 'retailPriceIncVat',
        propertyType: 'number',
        displayName: 'Цена розн. c НДС',
        visibility: true,
        width: 150
    }, {
        order: 26,
        propertyName: 'retailSum',
        propertyType: 'number',
        displayName: 'Сумма розн.',
        visibility: true,
        width: 150
    }, {
        order: 27,
        propertyName: 'retailSumIncVat',
        propertyType: 'number',
        displayName: 'Сумма розн. с НДС',
        visibility: true,
        width: 150
    }, {
        order: 28,
        propertyName: 'retailVat',
        propertyType: 'number',
        displayName: 'НДС розн.',
        visibility: true,
        width: 150
    }, {
        order: 29,
        propertyName: 'retailVatSum',
        propertyType: 'number',
        displayName: 'Сумма НДС розн.',
        visibility: true,
        width: 150
    }, {
        order: 30,
        propertyName: 'supplierPrice',
        propertyType: 'number',
        displayName: 'Цена поставщика',
        visibility: true,
        width: 150
    }, {
        order: 31,
        propertyName: 'supplierPriceIncVat',
        propertyType: 'number',
        displayName: 'Цена поставщика с НДС',
        visibility: true,
        width: 150
    }, {
        order: 32,
        propertyName: 'supplierSum',
        propertyType: 'number',
        displayName: 'Сумма поставщика',
        visibility: true,
        width: 150
    }, {
        order: 33,
        propertyName: 'supplierSumIncVat',
        propertyType: 'number',
        displayName: 'Сумма поставщика c НДС',
        visibility: true,
        width: 150
    }, {
        order: 34,
        propertyName: 'supplierVat',
        propertyType: 'number',
        displayName: 'НДС поставщика',
        visibility: true,
        width: 150
    }, {
        order: 35,
        propertyName: 'supplierVatSum',
        propertyType: 'number',
        displayName: 'Сумма НДС поставщика',
        visibility: true,
        width: 150
    }, {
        order: 36,
        propertyName: 'dateModified',
        propertyType: 'datetime',
        displayName: 'Дата изменения',
        visibility: true,
        width: 150
    }, 
    {
        order: 37,
        propertyName: 'deleted',
        propertyType: 'boolean',
        displayName: 'Удален',
        visibility: true,
        width: 150
    },
    {
        order: 38,
        propertyName: 'dateDeleted',
        propertyType: 'datetime',
        displayName: 'Дата удаления',
        visibility: true,
        width: 150
    },
    {
        order: 39,
        propertyName: 'supplier',
        propertyType: 'string',
        displayName: 'Поставщик',
        visibility: true,
        width: 150
    },
    {
        order: 40,
        propertyName: 'seriesBestBefore',
        propertyType: 'datetime',
        displayName: 'Срок годности',
        visibility: true,
        width: 150
    },
    {
        order: 41,
        propertyName: 'seriesNumber',
        propertyType: 'string',
        displayName: 'Серия',
        visibility: true,
        width: 150
    },
    {
        order: 42,
        propertyName: 'gtdNumber',
        propertyType: 'string',
        displayName: 'ГТД',
        visibility: true,
        width: 150
    },
    {
        order: 43,
        propertyName: 'contractorName',
        propertyType: 'string',
        displayName: 'Аптека',
        visibility: true,
        width: 150
    },
    {
        order: 44,
        propertyName: 'taskProgramName',
        propertyType: 'string',
        displayName: 'ЦП ГК',
        visibility: true,
        width: 150
    },
    {
        order: 45,
        propertyName: 'gosContractName',
        propertyType: 'string',
        displayName: 'ГК',
        visibility: true,
        width: 150
    },
    {
        order: 46,
        propertyName: 'mnn',
        propertyType: 'string',
        displayName: 'МНН',
        visibility: true,
        width: 150
    },
    {
        order: 47,
        propertyName: 'dateCreated',
        propertyType: 'datetime',
        displayName: 'Дата создания',
        visibility: true,
        width: 150
    },
    {
        order: 48,
        propertyName: 'sales',
        propertyType: 'number',
        displayName: 'Кол-во продаж',
        visibility: true,
        width: 150
    },
    {
        order: 49,
        propertyName: 'percentRemain',
        propertyType: 'number',
        displayName: 'Уровень текущего остатка партии %',
        visibility: true,
        width: 150
    },
    {
        order: 50,
        propertyName: 'lifeLot',
        propertyType: 'number',
        displayName: 'Время жизни поставки (дн.)',
        visibility: true,
        width: 150
    },
    {
        order: 51,
        propertyName: 'isExcessStock',
        propertyType: 'boolean',
        displayName: 'Избыточный запас',
        visibility: true,
        width: 150
    },
    {
        order: 52,
        propertyName: 'bestBeforeMonth',
        propertyType: 'number',
        displayName: 'Срок годности (мес.)',
        visibility: true,
        width: 150
    },
    {
        order: 53,
        propertyName: 'percentBestBefore',
        propertyType: 'number',
        displayName: 'Срок годности %',
        visibility: true,
        width: 150
    },
    {
        order: 54,
        propertyName: 'salesSpeed',
        propertyType: 'number',
        displayName: 'Средняя Скорость продажи (дн.)',
        visibility: true,
        width: 150
    },
    {
        order: 55,
        propertyName: 'turnover',
        propertyType: 'number',
        displayName: 'Оборачиваемость (дн.)',
        visibility: true,
        width: 150
    },
    ]
}
export default AnalysisLotGridPluginSettings