import { IAccessPointDTO, IAccessPointViewDTO } from "../../../libs/coreapi-dto/dirs/accessPoint";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";


interface IAccessPointCreate extends IAccessPointDTO {
}
interface IAccessPointUpdate extends IAccessPointDTO {
}

class AccessPointRequest extends BaseDirRequest<IAccessPointViewDTO, IAccessPointDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "accessPoint", token, eventHandler)
    }
    
    create(body: IAccessPointCreate, callback: (e:CoreApiResponse<string>) => void) {
        super.create(body, callback)
    }

    update(idGlobal: string, body: IAccessPointUpdate, callback: (e:CoreApiResponse<null>) => void) {
        super.update(idGlobal, body, callback)
    }

    getDirectories(idGlobal: string, callback?: (e) => void) {
        this.get(`${this._baseUrl}/${this._routePrefix}/${idGlobal}/Directories`, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }
}
export default AccessPointRequest