import ContractorSelectorInput from "../../../components/controls/inputs/ContractorSelectorInput";
import GoodsSelectorInput from "../../../components/controls/inputs/GoodsSelectorInput";
import ScalingRatioSelectorInput from "../../../components/controls/inputs/ScalingRatioSelectorInput";
import BaseDocumentCreator from "../../../components/documents/baseDocumentCreator";
import { IInventoryVedItemEditDTO, IInventoryVedItemViewDTO } from "../../../libs/coreapi-dto/documents/invoice/inventory/inventoryVedItem";
import { CreateVariant } from "../../MasterTabsProvider";
import { InventoryVedItemFormDataType } from "./inventoryVedItemFormTab";
import { IButton } from "../../../components/modalWindows/BaseModalWindow";
import { ICostInfoDTO, IEntitySimpleDTO } from "../../../libs/coreapi-dto/@types/common";
import { NumberInput, TextInput } from "../../../components/controls/inputs";
import GridWrapper from "../../../components/controls/GridWrapper";
import TaxTypeSelectorInput from "../../../components/controls/inputs/TaxTypeSelectorInput";
import { FC, useEffect, useState } from "react";
import Link from "../../../components/controls/link";
import FlexRow from "../../../components/controls/FlexRow";
import styles from "./styles/index.module.scss";
import { useTabsContext } from "../../../system/providers/tabsProvider";
import { ContractorDataProvider } from "../../../Services/DataProviders/ContractorDataProvider";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { TaxTypeDataProvider } from "../../../Services/DataProviders/TaxTypeDataProvider";
import { GoodsDataProvider } from "../../../Services/DataProviders/GoodsDataProvider";
import { Accordion } from "../../../components/controls/accordion";
import { ScalingRatioDataProvider } from "../../../Services/DataProviders/ScalingRatioDataProvider";
import { MoneyFormatComponent } from "../../../components/controls/inputs/BaseInput";

interface IInventoryVedItemFormProps {
    idGlobal: string;
    variant: CreateVariant;
    data: InventoryVedItemFormDataType;
    onSubmit?: (editItem: IInventoryVedItemEditDTO, viewItem: IInventoryVedItemViewDTO) => void;
}

const defaultCostInfo: ICostInfoDTO = { vat: 0, price: 0, vatPrice: 0, priceIncVat: 0, sum: 0, vatSum: 0, sumIncVat: 0, adprice: 0 };

const InventoryVedItemForm: FC<IInventoryVedItemFormProps> = (props) => {
    const appContext = useAppContext();
    const tabContext = useTabsContext();

    const contractorDP = new ContractorDataProvider(appContext.coreApiService);
    const taxTypeDP = new TaxTypeDataProvider(appContext.coreApiService);
    const goodsDP = new GoodsDataProvider(appContext.coreApiService);
    const scalingRatioDP = new ScalingRatioDataProvider(appContext.coreApiService);

    const [disabled, setDisabled] = useState<boolean>(false);
    const [entity, setEntity] = useState<IInventoryVedItemEditDTO>({
        idGlobal: props.idGlobal,
    } as IInventoryVedItemEditDTO);
    const [lot, setLot] = useState<IEntitySimpleDTO | null>(null);
    const [supplier, setSupplier] = useState<IEntitySimpleDTO | null>(null);
    const [goods, setGoods] = useState<IEntitySimpleDTO | null>(null);
    const [scalingRatio, setScalingRatio] = useState<IEntitySimpleDTO | null>(null);
    const [supplierTaxType, setSupplierTaxType] = useState<IEntitySimpleDTO | null>(null);
    const [retailTaxType, setRetailTaxType] = useState<IEntitySimpleDTO | null>(null);
    const [supplierCostInfo, setSupplierCostInfo] = useState<ICostInfoDTO>(defaultCostInfo);
    const [retailCostInfo, setRetailCostInfo] = useState<ICostInfoDTO>(defaultCostInfo);

    useEffect(() => {
        switch (props.data.type) {
            case "lot":
                const lot = props.data.lot;

                let entityObj = {
                    ...entity,
                    hasLot: true,
                    quantity: lot.quantityRem,
                    quantityRem: lot.quantityRem,
                    isKiz: lot.isKiz ?? false,
                    isKizBox: lot.isKizBox ?? false,
                    idLotGlobal: lot.idGlobal,
                    kizOsuDto: lot.kizOsuDto?.barcode !== undefined ? {...lot.kizOsuDto, idDocumentItem: entity.idGlobal } : undefined,
                    isOsu: lot.kizOsuDto?.barcode !== undefined
                };

                setDisabled(true);
                setLot({ idGlobal: lot.idGlobal, displayName: lot.displayName });
                setGoods({ ...lot.goods });
                setScalingRatio({ ...lot.scalingRatio });
                setEntity(entityObj);

                // Если поиск пот КИЗ/Коробу и неделенный товар
                // Сразу вернуть
                if (props.data.searchQuery && (lot.isKiz || lot.isKizBox) && !(lot.numerator < lot.denominator)) {
                    tabContext.closeCurrent();

                    entityObj.quantity = 1;
                    const item = {
                        entityObj,
                        lot
                    }

                    const callback = (dtoItems: any) => {
                        props.onSubmit?.(dtoItems?.editItem as IInventoryVedItemEditDTO, dtoItems?.viewItem as IInventoryVedItemViewDTO);
                    }

                    makeInventoryVedDtoItems(item, callback);

                } else {

                    // Штатно запросить поставщика
                    contractorDP.getById(lot.idSupplierGlobal, (d) => setSupplier({ idGlobal: d.idGlobal, displayName: d.name }));
                    taxTypeDP.getByRate(lot.supplierCostInfo?.vat ?? 0, (value) => {
                        setSupplierCostInfo({ ...lot.supplierCostInfo, vat: value.taxRate });
                        setSupplierTaxType({ idGlobal: value.idGlobal, displayName: value.displayName });
                    });
                    taxTypeDP.getByRate(lot.retailCostInfo?.vat ?? 0, (value) => {
                        setRetailCostInfo({ ...lot.retailCostInfo, vat: value.taxRate });
                        setRetailTaxType({ idGlobal: value.idGlobal, displayName: value.displayName });
                    });
                }
                break;

            case "goods":
                const goods = props?.data?.goods;
                if (goods?.idGlobal) {
                    goodsDP.getByIdAndScalingRatio(goods.idGlobal, "00000000-0000-0000-0000-000000000000", (entity) => {
                        if (goods?.idGlobal && entity?.scalingRatio.idGlobal) {
                            scalingRatioDP.overrideGetById(goods?.idGlobal, entity?.scalingRatio.idGlobal, (e) => {
                                setScalingRatio({ displayName: e.displayName, idGlobal: e.idGlobal });
                            });
                        }
                    });
                }
                setDisabled(false);
                setGoods({ ...props.data.goods });
                setEntity({ ...entity, hasLot: false });
                break;

            case "entity":
                const ent = props.data.entity;
                setDisabled(ent.hasLot);
                contractorDP.getById(ent.idSupplierGlobal, (d) => setSupplier({ idGlobal: d.idGlobal, displayName: d.name }));
                goodsDP.getById(ent.idGoodsGlobal, (d) => setGoods({ idGlobal: ent.idGoodsGlobal, displayName: d.goodsName.name }));
                scalingRatioDP.overrideGetById(ent.idGoodsGlobal, ent.idScalingRatioGlobal, (d) => setScalingRatio({ idGlobal: d.idGlobal, displayName: d.displayName }));
                setEntity({ ...ent });
                taxTypeDP.getByRate(ent.supplierCostInfo?.vat ?? 0, (value) => {
                    setSupplierCostInfo({ ...ent.supplierCostInfo, vat: value.taxRate });
                    setSupplierTaxType({ idGlobal: value.idGlobal, displayName: value.displayName });
                });
                taxTypeDP.getByRate(ent.retailCostInfo?.vat ?? 0, (value) => {
                    setRetailCostInfo({ ...ent.retailCostInfo, vat: value.taxRate });
                    setRetailTaxType({ idGlobal: value.idGlobal, displayName: value.displayName });
                });
                break;
        }
    }, [props.data]);

    const makeInventoryVedDtoItems = (data: any = null, callback: (dtoItems: any) => void = () => {}) => {

        let entityDto : IInventoryVedItemEditDTO = data ? data.entityObj : entity;
        let goodsDto : IEntitySimpleDTO = data ? { ...data.lot.goods } : goods;
        let scalingRatioDto = data ? { ...data.lot.scalingRatio } : scalingRatio;
        let supplierDto : IEntitySimpleDTO | null = supplier;
        let supplierCostInfoDto = supplierCostInfo;
        let retailCostInfoDto = retailCostInfo;
        let lotDto : IEntitySimpleDTO = data ? data.lot : lot;

        const act = () => {
            if (!goodsDto || !scalingRatioDto || !supplierDto) {
                return;
            }
    
            const editItem: IInventoryVedItemEditDTO = {
                ...entityDto,
                idGoodsGlobal: goodsDto.idGlobal,
                idScalingRatioGlobal: scalingRatioDto.idGlobal,
                idSupplierGlobal: supplierDto.idGlobal,
                supplierCostInfo: supplierCostInfoDto,
                retailCostInfo: retailCostInfoDto,
                kizs: entityDto.kizs ?? [],
                kizBoxes: entityDto.kizBoxes ?? [],
            };
    
            const viewItem: IInventoryVedItemViewDTO = {
                internalBarcode: entityDto.internalBarcode ?? "",
                displayName: goodsDto.displayName,
                idDocumentGlobal: "",
                retailVat: retailCostInfoDto.vat,
                retailPrice: retailCostInfoDto.price,
                retailVatPrice: retailCostInfoDto.vatPrice,
                retailPriceIncVat: retailCostInfoDto.priceIncVat,
                retailSum: retailCostInfoDto.sum,
                retailVatSum: retailCostInfoDto.vatSum,
                retailSumIncVat: retailCostInfoDto.sumIncVat,
                retailAdprice: retailCostInfoDto.adprice,
                supplierVat: supplierCostInfoDto.vat,
                supplierPrice: supplierCostInfoDto.price,
                supplierVatPrice: supplierCostInfoDto.vatPrice,
                supplierPriceIncVat: supplierCostInfoDto.priceIncVat,
                supplierSum: supplierCostInfoDto.sum,
                supplierVatSum: supplierCostInfoDto.vatSum,
                supplierSumIncVat: supplierCostInfoDto.sumIncVat,
                supplierAdprice: supplierCostInfoDto.adprice,
                lotName: lotDto?.displayName ?? "",
                goodsName: goodsDto.displayName,
                scalingRatioName: scalingRatioDto.displayName,
                supplierName: supplierDto.displayName,
                seriesName: "",
                regCertName: "",
                gosContractGoodsName: "",
                idGlobal: entityDto.idGlobal,
                quantity: entityDto.quantity,
                quantityRem: entityDto.quantityRem,
                hasLot: entityDto.hasLot,
                supplierDocNumber: entityDto.supplierDocNumber ?? null,
                supplierBillDocNumber: entityDto.supplierBillDocNumber ?? null,
                supplierDocDate: entityDto.supplierDocDate,
                supplierBillDocDate: entityDto.supplierBillDocDate,
                invoiceNum: entityDto.invoiceNum,
                invoiceDate: entityDto.invoiceDate,
                gtdNumber: entityDto.gtdNumber ?? "",
                registerPrice: entityDto.registerPrice ?? 0,
                isKiz: entityDto.isKiz,
                isKizBox: entityDto.isKizBox,
                kizCount: entityDto.kizs?.length ?? 0,
                kizBoxCount: entityDto.kizBoxes?.length ?? 0,
                kizScannedCount: entityDto.isKiz || entityDto.isKizBox ? ((entityDto.kizs?.length ?? 0) + (entityDto.kizBoxes?.length ?? 0)) + ` из ${entityDto.quantityRem}` : '-',
                isOsu: entityDto?.isOsu
            };
    
            return { editItem, viewItem };
        }

        if (data) {
            contractorDP.getById(data.lot.idSupplierGlobal, (d) => {
                supplierDto = { idGlobal: d.idGlobal, displayName: d.name };

                taxTypeDP.getByRate(data.lot.supplierCostInfo?.vat ?? 0, (value) => {
                    supplierCostInfoDto = { ...data.lot.supplierCostInfo, vat: value.taxRate };

                    taxTypeDP.getByRate(data.lot.retailCostInfo?.vat ?? 0, (value) => {
                        retailCostInfoDto = { ...data.lot.retailCostInfo, vat: value.taxRate };

                        const items = act();
                        if (callback) {
                            callback(items);
                        }
                    });
                });
            });
        } else {
            return act();
        }
    }

    const okButton: IButton = {
        onClick() {
            const items = makeInventoryVedDtoItems();

            props.onSubmit?.(items?.editItem as IInventoryVedItemEditDTO, items?.viewItem as IInventoryVedItemViewDTO);
            tabContext.closeCurrent();
        },
    };

    const cancelButton: IButton = {
        onClick() {
            tabContext.closeCurrent();
        },
    };

    useEffect(() => {
        if (goods) {
            goodsDP.getById(goods.idGlobal, (value) => {
                setSupplierTaxType({ ...value.taxType });
                setRetailTaxType({ ...value.taxType });
            });
        }
    }, [goods]);

    useEffect(() => {
        if (supplierTaxType) {
            taxTypeDP.getById(supplierTaxType.idGlobal, (value) => setSupplierCostInfo({ ...supplierCostInfo, vat: value.taxRate }));
        } else {
            setSupplierCostInfo({ ...supplierCostInfo, vat: 0 });
        }
    }, [supplierTaxType]);

    useEffect(() => {
        if (retailTaxType) {
            taxTypeDP.getById(retailTaxType.idGlobal, (value) => setRetailCostInfo({ ...retailCostInfo, vat: value.taxRate }));
        } else {
            setRetailCostInfo({ ...retailCostInfo, vat: 0 });
        }
    }, [retailTaxType]);

    const round = (num: number) => {
        const x = Math.pow(10, 2);
        return Math.round(num * x) / x;
    };

    const onQuantityChange = (quantity: number) => {
        setSupplierCostInfo({
            ...supplierCostInfo,
            sum: round(supplierCostInfo.price * quantity),
            vatSum: round(supplierCostInfo.vatPrice * quantity),
            sumIncVat: round(supplierCostInfo.priceIncVat * quantity),
        });
        setRetailCostInfo({
            ...retailCostInfo,
            sum: round(retailCostInfo.price * quantity),
            vatSum: round(retailCostInfo.vatPrice * quantity),
            sumIncVat: round(retailCostInfo.priceIncVat * quantity),
        });
        setEntity({ ...entity, quantity: quantity });
    };

    return (
        
        <>{ !(props.data.searchQuery && props.data.type === "lot" && (props.data.lot.isKiz || props.data.lot.isKizBox) && !(props.data.lot.numerator < props.data.lot.denominator)) && 
            <BaseDocumentCreator ok={okButton} cancel={cancelButton}>
                <GridWrapper cols={3}>
                    <ContractorSelectorInput label="Поставщик" selectedEntity={supplier} required onSelect={(value) => setSupplier({ ...value })} disabled={disabled} />
                    <GoodsSelectorInput label="Номенклатура" selectedEntity={goods} required onSelect={(value) => setGoods({ ...value })} disabled={disabled} />
                    <ScalingRatioSelectorInput
                        label="Единица измерения"
                        selectedEntity={scalingRatio}
                        required
                        masterIdGlobal={goods?.idGlobal}
                        onSelect={(value) => setScalingRatio({ ...value })}
                        disabled={disabled}
                    />
                </GridWrapper>
                <GridWrapper cols={8}>
                    <NumberInput label="Кол-во" value={entity.quantity} onChange={onQuantityChange} />
                    <NumberInput label="Док. остаток" value={entity.quantityRem} disabled />
                    <TextInput label="GTD" value={entity.gtdNumber ?? ""} onChange={(value) => setEntity({ ...entity, gtdNumber: value })} disabled={disabled} />
                </GridWrapper>
                <Accordion opened={true} caption="Расчет&nbsp;отпускной&nbsp;цены" id="CalculationOfTheSellingPriceInventoryVedItem">
                    <FlexRow wrap>
                        <TaxTypeSelectorInput
                            label="Ставка НДС, пост."
                            selectedEntity={supplierTaxType}
                            onSelect={(value) => setSupplierTaxType({ ...value })}
                            onClear={() => setSupplierTaxType(null)}
                            disabled={disabled}
                        />
                        <MoneyFormatComponent label="Наценка, пост." value={supplierCostInfo.adprice} disabled={disabled} />
                        <MoneyFormatComponent label="НДС, пост." value={supplierCostInfo.vatPrice} disabled={disabled} />
                        <MoneyFormatComponent label="Цена, пост." value={supplierCostInfo.price} disabled={disabled} />
                        <MoneyFormatComponent label="Сумма, пост." value={supplierCostInfo.sum} disabled={disabled} />
                        <MoneyFormatComponent label="Сумма НДС, пост." value={supplierCostInfo.vatSum} disabled={disabled} />
                        <MoneyFormatComponent label="Цена с/НДС, пост." value={supplierCostInfo.priceIncVat} disabled={disabled} />
                        <MoneyFormatComponent label="Сумма с/НДС, пост." value={supplierCostInfo.sumIncVat} disabled={disabled} />
                    </FlexRow>
                    <FlexRow wrap>
                        <TaxTypeSelectorInput
                            label="Ставка НДС, розн."
                            selectedEntity={retailTaxType}
                            onSelect={(value) => setRetailTaxType({ ...value })}
                            onClear={() => setRetailTaxType(null)}
                            disabled={disabled}
                        />
                        <MoneyFormatComponent label="Наценка, розн." value={retailCostInfo.adprice} disabled={disabled} />
                        <MoneyFormatComponent label="НДС, розн." value={retailCostInfo.vatPrice} disabled={disabled} />
                        <MoneyFormatComponent label="Цена, розн." value={retailCostInfo.price} disabled={disabled} />
                        <MoneyFormatComponent label="Сумма, розн." value={retailCostInfo.sum} disabled={disabled} />
                        <MoneyFormatComponent label="Сумма НДС, розн." value={retailCostInfo.vatSum} disabled={disabled} />
                        <MoneyFormatComponent label="Цена с/НДС, розн." value={retailCostInfo.priceIncVat} disabled={disabled} labelVariant="green" variant="green" />
                        <MoneyFormatComponent label="Сумма с/НДС, розн." value={retailCostInfo.sumIncVat} disabled={disabled} labelVariant="green" variant="green" />
                    </FlexRow>
                    <FlexRow>
                        <Link>Серии и сертификаты</Link>
                        <Link className={styles.margin20}>Региональные сертификаты</Link>
                        <Link>История поставок</Link>
                    </FlexRow>
                </Accordion>
            </BaseDocumentCreator>
            }
        </>
    );
};

export default InventoryVedItemForm;
