import classNames from "classnames"
import { FC, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { GoodsCreatorModal } from "."
import { DocumentStatusType, DocumentType } from "../../../@types/enumsGlobal"
import DefaultTreeViewCommandsPanel from "../../../components/commandsPanels/DefaultTreeViewCommandsPanel"
import { ContextMenu } from "../../../components/contextMenu/contextMenu"
import { DefaultGrid } from "../../../components/grids/default/defaultGrid"
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins"
import { Spinner } from "../../../components/spiner/Spinner"
import { TabsPanel } from "../../../components/tabs"
import { TreeViewPanel } from "../../../components/treeViewPanel"
import { IGoodsConvolution, IGoodsConvolutionInfo, IGoodsViewDTO } from "../../../libs/coreapi-dto/dirs/goods"
import tabsStyles from '../../../pages/styles/homePage.module.scss'
import { GoodsDataProvider } from "../../../Services/DataProviders/GoodsDataProvider"
import { GoodsGroupDataProvider } from "../../../Services/DataProviders/GoodsGroupDataProvider"
import { } from '../../../Services/Extensions/Boolean'
import useGridFilter, { CreateSelectorGridFilter } from "../../../system/hooks/useGridFilter"
import { useDetailsTabsPanel } from "../../../system/hooks/useTabsPanel"
import { useAppContext } from "../../../system/providers/appContextProvider"
import { usePluginContext } from "../../../system/providers/plugin"
import { useUserContext } from "../../../system/providers/userContextProvider"
import { LotsByGoodsFilterButtons } from "../../Accounting/LotsByGoods/FilteringPanel/LotsByGoodsFilterButtons"
import { checkAccessStatus, checkActiveCommands } from "../../CommonHelperFunctions"
import styles from '../../styles/index.module.scss'
import GoodsGroupCreatorModal from "../GoodsGroup/GoodsGroupCreatorModal"
import GoodsGroupSelectorModal from "../GoodsGroup/GoodsGroupSelectorModal"
import { FiltersPanel } from "./components/FiltersPanel"
import goodsStyles from './styles/GoodsPluginView.module.scss'
import GridWrapper from "../../../components/controls/GridWrapper"
import * as MainIcons from "../../../libs/corporate/icons/outlined/action/MainCollection"
import { GoodsConvolutionModalWindow } from "./GoodsConvolutionModal"
import renderGlobalAlert from "../../../system/hooks/useGlobalAlert"
import { ProgressActionSpinner } from "../../../components/progressActionSpinner/ProgressActionSpinner"

type GridRowViewState = "view" | "return" | "attachToGroup" | "moveToGroup" | "detachFromGroup"

const GoodsPluginView: FC<IGridProps> = (props) => {
    const appContext = useAppContext();
    const pluginContext = usePluginContext();
    const userContext = useUserContext();

    const goodsDataProvider = new GoodsDataProvider(appContext.coreApiService)
    const goodsGroupDataProvider = new GoodsGroupDataProvider(appContext.coreApiService)

    const [goodsViewState, setGoodsViewState] = useState<GridStateType>("view")
    const [goodsGroupViewState, setGoodsGroupViewState] = useState<TreeViewStateType>("view")
    const [contextMenuViewState, setContextMenuViewState] = useState<GridRowViewState>("view")

    const [goodsGroupGridFilter] = useGridFilter(CreateSelectorGridFilter)

    const [entities, setEntities] = useState<IGoodsViewDTO[]>([])
    const [totalCount, setTotalCount] = useState(0)
    const [gridFilter, dispatchGridFilter] = useGridFilter(CreateSelectorGridFilter)
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    // selected items in invoice for multiselect
    const [selectedItems, setSelectedItems] = useState<IGridRow[]>([]);
    const [selectedItemView, setSelectedItemView] = useState<IGoodsViewDTO>();
    const [multipleSelect, setMultipleSelect] = useState<boolean>(false);
    const [selectedDocumentState, setSelectedDocumentState] = useState<DocumentStatusType | undefined>();

    const [goodsGroupFolders, setGoodsGroupFolders] = useState<ITreeViewFolder[]>([])
    const [detailsTabsPanel, dispatchDetailsTabsPanel] = useDetailsTabsPanel(props.plugin.mnemocode)

    const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);

    const [clientWidth, setClientWidth] = useState<number | null>(null);
    const xDividerPos = useRef<number>(0);
    const treeViewPanelRef = useRef<HTMLDivElement>(null);

    const [searchGroup, setSearchGroup] = useState<string>('');
    const [loaderThree, setLoaderThree] = useState<boolean>(true);

    // Состояние модального окна
    const [modalWindow, setModalWindow] = useState(<></>);
    
    const [isOpenConvolution, setIsOpenConvolution] = useState<boolean>(false)
    const [goodsConvolution, setGoodsConvolution] = useState<[IGoodsConvolutionInfo, IGoodsConvolutionInfo]>();

    const convolutionLocked = () => {
        const activeCommands = checkActiveCommands("GoodsConvolution", userContext.userPermission);
        return !activeCommands.convoluteGoods
    };

    const convolutionLockFromPermission = convolutionLocked();

    const { t } = useTranslation();

    const updateTree = (callBack?: () => void) => {
        goodsGroupDataProvider.getView(goodsGroupGridFilter, (entities) => {
            let tmpList: ITreeViewFolder[] = []
            entities.forEach(item => {
                tmpList.push({ folderId: item.idGlobal, displayName: item.name, childGroups: item.childGroups })
            });
            setGoodsGroupFolders(tmpList)
            setGoodsGroupViewState('view')
            callBack?.()
        })
    }

    useEffect(() => {
        if (!pluginContext.treeView.visiblePanel && !pluginContext.treeView.selectedFolder) {
            setLoaderThree(true);
        }

        if (pluginContext.treeView.visiblePanel) {
            if (!pluginContext.treeView.selectedFolder) {
                updateTree(() => setLoaderThree(false));
            }
            else if (goodsGroupViewState === 'return') {
                updateTree();
            }
        }
    }, [pluginContext.treeView.visiblePanel, goodsGroupViewState, pluginContext.treeView.selectedFolder])

    const onMouseHoldDown = (e) => {
        xDividerPos.current = e.clientX;
    }

    const onMouseHoldUp = () => {
        xDividerPos.current = 0;
    }

    const onMouseHoldMove = (e) => {
        if (!xDividerPos.current) {
            return;
        }
        setClientWidth(clientWidth + e.clientX - xDividerPos.current);
        xDividerPos.current = e.clientX;
    }

    useEffect(() => {
        setClientWidth(treeViewPanelRef.current?.clientWidth as number);
    }, [])

    useEffect(() => {
        document.addEventListener("mouseup", onMouseHoldUp);
        document.addEventListener("mousemove", onMouseHoldMove);
        return () => {
            document.removeEventListener("mouseup", onMouseHoldUp);
            document.removeEventListener("mousemove", onMouseHoldMove);
        }
    })

    useEffect(() => {
        if (!clientWidth) {
            setClientWidth(treeViewPanelRef.current?.clientWidth as number);
            return;
        }
        if (treeViewPanelRef.current) {
            treeViewPanelRef.current.style.minWidth = clientWidth + "px";
            treeViewPanelRef.current.style.maxWidth = clientWidth + "px";
        }
    }, [clientWidth, treeViewPanelRef.current])

    useEffect(() => {
        const abortController = new AbortController()
        const signal = abortController.signal
        setIsSubmitting(true)
        goodsDataProvider.getViewByName(gridFilter, (entities, totalCount) => {
            setEntities(entities)
            setTotalCount(totalCount)
            setIsSubmitting(false)
        }, signal)
        return () => abortController.abort('abort');
    }, [gridFilter])

    useEffect(() => {
        if (goodsViewState === 'refresh') {
            setIsSubmitting(true)
            goodsDataProvider.getViewByName(gridFilter, (entities, totalCount) => {
                setEntities(entities)
                setTotalCount(totalCount)
                setIsSubmitting(false)
                setGoodsViewState('view')
            })
        }
    }, [goodsViewState])

    useEffect(() => {
        setGoodsViewState(goodsViewState)
    }, [goodsViewState])

    useEffect(() => {
        if (!multipleSelect) {
            setSelectedItems([]);
        } else {
            setSelectedItem(undefined);
        }
        if (selectedItem) {
            const documentState = selectedItem.cells.find((c) => c.propertyName === "documentState")?.value as DocumentStatusType;
            setSelectedDocumentState(documentState ?? undefined);
            setSelectedItemView(entities.find((x) => x.idGlobal === selectedItem.idGlobal)); // ? может не работать при пагинации
        } else {
            setSelectedItemView(undefined);
        }
    }, [multipleSelect, selectedItem]);

    useEffect(() => {
        if(multipleSelect) {
            setSelectedItems([]);
        }
        setSelectedItem(undefined);
        pluginContext.masterGrid.onUnselectEvent()
    }, [gridFilter]);

    const showConvolution = () => {

        // Сворачиваем 2 товара
        if (selectedItems?.length === 2) {
            const expression = `"IdGlobal" in ('${selectedItems[0].idGlobal}', '${selectedItems[1].idGlobal}')`;
            const selectedGoodsFilter = { ...gridFilter, fieldOrderBy: "IsEs", orderDesc: false, expression: expression } as IGridCustomWhereExpressionFilter

            // Получение сводки по goods
            goodsDataProvider.getViewCustom(selectedGoodsFilter, (goods, totalCount) => {
                
                if (totalCount === 2) {

                    // Проверка на ЕС
                    if (goods.every(x => x.isEs as boolean)) {
                        renderGlobalAlert({ variant: "danger", statusCode: 0, title: t("directory.goods.esChangeErr") })
                    } else {
                        setGoodsConvolution([
                            {
                                idGlobal: goods[0].idGlobal,
                                goodsName: goods[0].name,
                                important: goods[0].important,
                                isEs: goods[0].isEs,
                                taxValue: goods[0].taxTypeName,
                                manufacturer: goods[0].producerName
                            } as IGoodsConvolutionInfo,
                            {
                                idGlobal: goods[1].idGlobal,
                                goodsName: goods[1].name,
                                important: goods[1].important,
                                isEs: goods[1].isEs,
                                taxValue: goods[1].taxTypeName,
                                manufacturer: goods[1].producerName
                            } as IGoodsConvolutionInfo
                        ]);
                        setIsOpenConvolution(true);
                    }
                }
            })
        }
    };

    const renderProgressView = (actionMessage: string) => {
        setModalWindow(
            <ProgressActionSpinner
                loaderText={`${actionMessage}`}
            />
        );
    };

    return <PluginWrapper>
        {
            checkAccessStatus(props.plugin.permission as IPermission, userContext.userPermission) && <ToolbarWrapper>
                <GridWrapper cols={2}>
                    <DefaultTreeViewCommandsPanel
                        selectedItems={[selectedItems, setSelectedItems]}
                        selectedDocumentStatusType={selectedDocumentState}
                        multipleSelect={[multipleSelect, setMultipleSelect]}
                        selectedItem={[selectedItem, setSelectedItem]}
                        gridFilter={[gridFilter, dispatchGridFilter]}
                        dataProvider={goodsDataProvider}
                        groupDataProvider={goodsGroupDataProvider}
                        creatorModalJsx={GoodsCreatorModal}
                        creatorGroupModalJsx={GoodsGroupCreatorModal}
                        selectorGroupModalJsx={GoodsGroupSelectorModal}
                        pluginSettings={props.plugin}
                        setViewState={(vs) => {
                            setIsOpenFilter(false)
                            setGoodsViewState(vs)
                        }}
                        setGroupViewState={(vs) => {
                            setGoodsGroupViewState(vs)
                        }}
                        contextMenuViewState={[contextMenuViewState, setContextMenuViewState]}
                    />
                    <div>
                        <button
                            className={classNames(styles.convolutionButton)}
                            onClick={() => showConvolution()}
                            disabled={convolutionLockFromPermission || selectedItems.length !== 2}
                        >
                            <MainIcons.SwapIcon/>
                            <div className={styles.contentWrapper}>
                                {t("directory.goods.convolution")}
                            </div>
                        </button>
                    </div>
                </GridWrapper>

                <LotsByGoodsFilterButtons
                    isOpen={isOpenFilter}
                    onOpenChange={() => { setIsOpenFilter(!isOpenFilter) }}
                />
            </ToolbarWrapper>
        }
        {
            isOpenFilter && <FiltersPanel gridFilter={gridFilter} onEnter={(value) => dispatchGridFilter(value)} />
        }
        {
            
                <div className={styles.pluginWorkspace}>
                    {
                        <>
                            <div style={{display: pluginContext.treeView.visiblePanel ? 'block' : 'none'}} className={styles.treeView} ref={treeViewPanelRef}>
                                <TreeViewPanel
                                    loaderThree={loaderThree}
                                    folders={goodsGroupFolders}
                                    selectedFolder={pluginContext.treeView.selectedFolder}
                                    onHideClick={() => pluginContext.treeView.onVisiblePanelEvent(false)}
                                    onSelectFolder={(folder) => pluginContext.treeView.onSelectEvent(folder)}
                                    onExpandFolder={(folder, callback) => {
                                        goodsGroupDataProvider.getViewById(folder.folderId, goodsGroupGridFilter,
                                            (entities) => {
                                                let tmpList: ITreeViewFolder[] = []
                                                entities.forEach(item => {
                                                    tmpList.push({ folderId: item.idGlobal, displayName: item.name, childGroups: item.childGroups })
                                                });
                                                callback(tmpList)
                                            })
                                    }}
                                    setSearchGroup = {(value) => setSearchGroup(value)}
                                    searchGroup= {searchGroup}
                                    groupDataProvider={goodsGroupDataProvider}
                                    setGroupViewState={(value) => {
                                        setGoodsGroupViewState(value)
                                        setGoodsViewState('refresh')
                                    }}
                                    gridFilter={[gridFilter,dispatchGridFilter]}
                                    pluginSettings={props.plugin}
                                />
                            </div>
                            <div onMouseDown={onMouseHoldDown} style={{cursor: 'col-resize', display: pluginContext.treeView.visiblePanel ? 'block' : 'none'}} className={classNames(styles.separator, styles.separator_vertical)}></div>
                        </>
                    }
                    {
                    isSubmitting ? <div className={goodsStyles.loading}><Spinner /></div>  :
                    <div className={styles.gridWrapper}>
                        <div className={styles.masterGrid}>
                            <DefaultGrid
                                gridId={props.gridId}
                                data={entities}
                                totalCount={totalCount}
                                filter={gridFilter}
                                plugin={props.plugin}
                                separator
                                selectedItem={selectedItem}
                                multipleSelect={multipleSelect}
                                selectedItems={selectedItems}
                                dataProvider={goodsDataProvider}
                                getView={(gridFilter,callback) => {
                                    goodsDataProvider.getViewByName(gridFilter, (entities) => {
                                        callback(entities)
                                        setSelectedItem(undefined);
                                        pluginContext.masterGrid.onUnselectEvent()
                                    })
                                }}
                                hiddenPagination={{ hiddenCountRow: false, hiddenNumberPage: false }}
                                onSelect={(row) => {
                                    setSelectedItem(row? row : undefined);
                                    row
                                        ? pluginContext.masterGrid.onSelectEvent(row, DocumentType.goods)
                                        : pluginContext.masterGrid.onUnselectEvent()
                                }}
                                onMultipleSelect={(rows) => setSelectedItems(rows) }
                                onSort={(i) => { dispatchGridFilter({ type: "sort", payload: i.propertyName }) }}
                                onFilterDelete={(i) => { dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName }) }}
                                onPageNumberChange={(n) => { dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } }) }}
                                onNumberPerPageChange={(n) => { dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } }) }}
                                onGridRowContextMenuMouseClick={(selectedRow, position) => {
                                    const checkGroupSelected = pluginContext.treeView.selectedFolder !== undefined;
                                    const multiPosition = selectedItems.find(el => el.idGlobal === selectedRow.idGlobal) ? false : true;
                                    const onePosition = (selectedRow.idGlobal === selectedItem?.idGlobal) ? false : true;
                                    const checkGroup =  checkGroupSelected ? (multipleSelect ? multiPosition : onePosition) : true;
                                    
                                    return <ContextMenu 
                                    id={'ctxMenu_row_' + props.gridId} xPos={position.x - 300} yPos={position.y - 140}
                                        items={[{
                                            name: t('general.addToGroup'),
                                            onClick: () => setContextMenuViewState("attachToGroup"),
                                            disabled: multipleSelect ? multiPosition : onePosition,
                                        }, {
                                            name: t('general.moveToGroup'),
                                            disabled: checkGroup,
                                            onClick: () => setContextMenuViewState("moveToGroup")
                                        }, {
                                            name: t('general.removeFromGroup'),
                                            disabled: checkGroup,
                                            onClick: () => setContextMenuViewState("detachFromGroup")
                                        }
                                        ]}>
                                    </ContextMenu>
                                }} />

                        </div>

                        <div className={styles.detailsTabView}>
                            {
                                detailsTabsPanel.tabs.length > 0 &&
                                <div>
                                    <div className={tabsStyles.tabsPanelWrapper}>
                                        <div id="detailsTabsPanelWrapper" style={{ position: "relative", overflow: 'hidden', width: '100%' }}>
                                            <TabsPanel id="detailsTabsPanel" activeId={detailsTabsPanel.currentTab?.id} tabs={detailsTabsPanel.tabs}
                                                onActive={(tab) => { dispatchDetailsTabsPanel({ type: "activate", payload: tab }) }}>
                                            </TabsPanel>
                                        </div>
                                    </div>
                                    <div className={tabsStyles.contentWrapper}>
                                        {detailsTabsPanel.tabs.map(item => {
                                            return <div key={item.id} className={classNames(item.id === detailsTabsPanel.currentTab?.id ? tabsStyles.contentVisible : tabsStyles.contentHidden)}>
                                                {item.view.content}
                                            </div>
                                        })}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    }
                </div>
        }
        {
            isOpenConvolution && 
            <GoodsConvolutionModalWindow
                goodsSource={ goodsConvolution?.[0] as IGoodsConvolutionInfo}
                goodsTarget={ goodsConvolution?.[1] as IGoodsConvolutionInfo}
                convolute={(convolutionDto: IGoodsConvolution) => {
                    if (convolutionDto.sourceIsEs) {
                        renderGlobalAlert({ variant: "danger", statusCode: 0, title: t("directory.goods.esChangeErr") })
                    } else {
                        setIsOpenConvolution(false);
                        renderProgressView(t("directory.goods.convolution"));
                        goodsDataProvider.convolute(convolutionDto, (e) => {

                            setMultipleSelect(false);
                            setGoodsViewState("refresh");
                            setModalWindow(<></>);
                        }, (e) => {
                            setModalWindow(<></>);
                        })
                    }
                }}
                cancel={() => {
                    setIsOpenConvolution(false);
                }}
            />
        }
        {modalWindow}

    </PluginWrapper>
}

export default GoodsPluginView