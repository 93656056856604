export const AccessPointDetailPluginSettings: IPluginSettings = {
    name: 'Поставщики',
    mnemocode: 'access_point_detail_plugin',
    permission: 'AccessPointDetail',
    columns: [{
        order: 0,
        propertyName: 'supplierName',
        propertyType: 'string',
        displayName: 'Наименование',
        visibility: true,
        width: 220
    },
    {
        order: 1,
        propertyName: 'connectionString',
        propertyType: 'string',
        displayName: 'Строка подключения',
        visibility: true,
        width: 400
    },
    ],
    detailsPlugins: []
}
