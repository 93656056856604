import { IAccessPoint2SupplierDTO, IAccessPoint2SupplierViewDTO } from "../../libs/coreapi-dto/dirs/accessPoint2Supplier";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class AccessPoint2SupplierDataProvider extends DictionaryDataProvider<IAccessPoint2SupplierViewDTO, IAccessPoint2SupplierDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IAccessPoint2SupplierViewDTO, IAccessPoint2SupplierDTO>> {
        return this._coreApiService.accessPoint2SupplierRequest
    }    

    getViewById(idGlobal: string, body: IViewBody, callback?: (data: IAccessPoint2SupplierViewDTO[], totalCount: number) => void) {
        this._coreApiService.accessPoint2SupplierRequest
            .then(r => r
                .getViewById(idGlobal, body, (e) => {
                    if (e.respType === 'isCompleted') {
                        callback?.(e.data.entities, e.data.totalCount);
                    }
                    else
                        throw new Error(e.message.title);
                })
            );
    }    

    deleteById(id: string, callback: () => void) {
        this._coreApiService.accessPoint2SupplierRequest
            .then(r => r
                .deleteById(id, () => callback()))
    }
}