import { BasicSettingsPluginSettings } from "./BasicSettingsPluginSettings";
import { ProductGroupsGISMTPluginSettings } from "./ProductGroupsGISMTPluginSettings";
import { RegKeysListPluginSettings } from "./RegKeysListPluginSettings";
import { SettingTimePluginSettings } from "./SettingTimePluginSettings";

export const CodeControlPluginSettings: IPluginSettings = {
  name: 'CodeControlPlugin',
  mnemocode: 'code_control_plugin',
  permission: 'CodeControl',
  columns: [],
  detailsPlugins: [BasicSettingsPluginSettings, SettingTimePluginSettings, ProductGroupsGISMTPluginSettings, RegKeysListPluginSettings]
}