import i18n from "../i18n";

const t = (value: string) => i18n.t("sidePanel." + value);
const groupNames = (value: string) => i18n.t("menuGroupNames." + value);
const DefaultUserInterface: UserInterface.IUserInterface = {
    key: "default-interface",
    name: "Интерфейс (полный)",
    mainMenuItems: [
        {
            key: "menuItem_#1",
            displayName: t("documents.title"),
            submenu: [
                {
                    key: "menuItem_#1_submenu_#1",
                    displayName: groupNames("document.invoice"),
                    groups: [
                        {
                            key: "menuItem_#1_submenugroup_#1",
                            items: [
                                {
                                    key: "menuItem_#1_submenuItem_#1",
                                    displayName: t("documents.submenu.invoices"),
                                    commandType: "IPlugin",
                                    command: "invoice_plugin",
                                    name: "Invoice",
                                },
                                {
                                    key: "menuItem_#1_submenuItem_#2",
                                    displayName: t("documents.submenu.importRemains"),
                                    commandType: "IPlugin",
                                    command: "import_remains_plugin",
                                    name: "ImportRemains",
                                },
                            ],
                        },
                    ],
                },
                {
                    key: "menuItem_#1_submenu_#2",
                    displayName: groupNames("document.invoiceOut"),
                    groups: [
                        {
                            key: "menuItem_#1_submenugroup_#2",
                            items: [
                                {
                                    key: "menuItem_#1_submenuItem_#3",
                                    displayName: t("documents.submenu.invoiceOut"),
                                    commandType: "IPlugin",
                                    command: "invoice_out_plugin",
                                    name: "InvoiceOut",
                                },
                                {
                                    key: "menuItem_#1_submenuItem_#4",
                                    displayName: t("documents.submenu.actDeduction"),
                                    commandType: "IPlugin",
                                    command: "act_deduction_plugin",
                                    name: "ActDeduction",
                                },
                                {
                                    key: "menuItem_#2_submenuItem_#5",
                                    displayName: t("documents.submenu.actReturnToContractor"),
                                    commandType: "IPlugin",
                                    command: "act_return_to_contractor_plugin",
                                    name: "ActReturnToContractor",
                                },
                            ],
                        },
                    ],
                },
                {
                    key: "menuItem_#1_submenu_#3",
                    displayName: groupNames("document.other"),
                    groups: [
                        {
                            key: "menuItem_#1_submenugroup_#3",
                            items: [
                                {
                                    key: "menuItem_#1_submenuItem_#5",
                                    displayName: t("documents.submenu.movements"),
                                    commandType: "IPlugin",
                                    command: "movement_plugin",
                                    name: "Movement",
                                },
                                {
                                    key: "menuItem_#1_submenuItem_#6",
                                    displayName: t("documents.submenu.actRevaluation"),
                                    commandType: "IPlugin",
                                    command: "act_revaluation_plugin",
                                    name: "ActRevaluation",
                                },
                                {
                                    key: "menuItem_#2_submenuItem_#3",
                                    displayName: t("documents.submenu.actDisassembling"),
                                    commandType: "IPlugin",
                                    command: "act_disassembling_plugin",
                                    name: "ActDisassembling",
                                },
                                {
                                    key: "menuItem_#2_submenuItem_#4",
                                    displayName: t("documents.submenu.actDisaggregation"),
                                    commandType: "IPlugin",
                                    command: "act_disagregation_plugin",
                                    name: "ActDisagregation",
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            key: "menuItem_#2",
            displayName: t("directory.title"),
            submenu: [
                {
                    key: "menuItem_#2_submenu_#1",
                    displayName: groupNames("directory.main"),
                    groups: [
                        {
                            key: "menuItem_#3_submenugroup_#1",
                            items: [
                                {
                                    key: "menuItem_#3_submenuItem_#1",
                                    displayName: t("directory.submenu.nomenclature"),
                                    commandType: "IPlugin",
                                    command: "goods_plugin",
                                    name: "Goods",
                                },
                                {
                                    key: "menuItem_#3_submenuItem_#2",
                                    displayName: t("directory.submenu.country"),
                                    commandType: "IPlugin",
                                    command: "country_plugin",
                                    name: "Country",
                                },
                                {
                                    key: "menuItem_#3_submenuItem_#3",
                                    displayName: t("directory.submenu.manufacturers"),
                                    commandType: "IPlugin",
                                    command: "producer_plugin",
                                    name: "Producer",
                                },
                                {
                                    key: "menuItem_#3_submenuItem_#4",
                                    displayName: t("directory.submenu.mnn"),
                                    commandType: "IPlugin",
                                    command: "substance_plugin",
                                    name: "Substance",
                                },
                                {
                                    key: "menuItem_#3_submenuItem_#6",
                                    displayName: t("directory.submenu.dosageForms"),
                                    commandType: "IPlugin",
                                    command: "cure_form_plugin",
                                    name: "CureForm",
                                },
                                {
                                    key: "menuItem_#3_submenuItem_#7",
                                    displayName: t("directory.submenu.gosContract"),
                                    commandType: "IPlugin",
                                    command: "gos_contract_plugin",
                                    name: "GosContract",
                                },
                                {
                                    key: "menuItem_#3_submenuItem_#8",
                                    displayName: t("directory.submenu.tradeName"),
                                    commandType: "IPlugin",
                                    command: "trade_name_plugin",
                                    name: "TradeName",
                                },
                                {
                                    key: "menuItem_#3_submenuItem_#15",
                                    displayName: t("directory.submenu.taskProgram"),
                                    commandType: "IPlugin",
                                    command: "task_program_plugin",
                                    name: "TaskProgram",
                                },
                                {
                                    key: "menuItem_#3_submenuItem_#16",
                                    displayName: t("directory.submenu.goodsKind"),
                                    commandType: "IPlugin",
                                    command: "goods_kind_plugin",
                                    name: "GoodsKind",
                                },
                                {
                                    key: "menuItem_#3_submenuItem_#17",
                                    displayName: t("directory.submenu.gosContractFundingSource"),
                                    commandType: "IPlugin",
                                    command: "gos_contract_funding_source_plugin",
                                    name: "GosContract",
                                },
                            ],
                        },
                    ],
                },
                {
                    key: "menuItem_#2_submenu_#2",
                    displayName: groupNames("directory.additionally"),
                    groups: [
                        {
                            key: "menuItem_#3_submenugroup_#2",
                            items: [
                                {
                                    key: "menuItem_#3_submenuItem_#16",
                                    displayName: t("directory.submenu.legalEntities"),
                                    commandType: "IPlugin",
                                    command: "legal_entity_plugin",
                                    name: "LegalEntity",
                                },
                                {
                                    key: "menuItem_#3_submenuItem_#17",
                                    displayName: t("directory.submenu.contractors"),
                                    commandType: "IPlugin",
                                    command: "contractor_plugin",
                                    name: "Contractor",
                                },
                                {
                                    key: "menuItem_#3_submenuItem_#6",
                                    displayName: t("directory.submenu.stores"),
                                    commandType: "IPlugin",
                                    command: "store_plugin",
                                    name: "Store",
                                },
                                {
                                    key: "menuItem_#3_submenuItem_#7",
                                    displayName: t("directory.submenu.typeofStores"),
                                    commandType: "IPlugin",
                                    command: "store_type_plugin",
                                    name: "StoreType",
                                },
                                {
                                    key: "menuItem_#3_submenuItem_#9",
                                    displayName: t("directory.submenu.vatRates"),
                                    commandType: "IPlugin",
                                    command: "tax_type_plugin",
                                    name: "TaxType",
                                },
                                {
                                    key: "menuItem_#3_submenuItem_#18",
                                    displayName: t("directory.submenu.supplierCodes"),
                                    commandType: "IPlugin",
                                    command: "goods_code_plugin",
                                    name: "GoodsCode",
                                },
                                {
                                    key: "menuItem_#3_submenuItem_#19",
                                    displayName: t("directory.submenu.pricingModel"),
                                    commandType: "IPlugin",
                                    command: "pricing_model_plugin",
                                    name: "Pricing",
                                },
                                {
                                    key: "menuItem_#3_submenuItem_#21",
                                    displayName: "Формула модели ценообразования",
                                    commandType: "IPlugin",
                                    command: "pricing_formula_plugin",
                                    name: "Pricing",
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            key: "menuItem_#3",
            displayName: t("accounting.title"),
            submenu: [
                {
                    key: "menuItem_#3_submenu_#1",
                    displayName: t("accounting.title"),
                    groups: [
                        {
                            key: "menuItem_#4_submenugroup_#1",
                            items: [
                                {
                                    key: "menuItem_#4_submenuItem_#1",
                                    displayName: t("accounting.submenu.lotsByGoods"),
                                    commandType: "IPlugin",
                                    command: "lots_by_goods_plugin",
                                    name: "Lot",
                                },
                                {
                                    key: "menuItem_#4_submenuItem_#2",
                                    displayName: t("accounting.submenu.cheque"),
                                    commandType: "IPlugin",
                                    command: "cheque_view_plugin",
                                    name: "Cheque",
                                },
                                {
                                    key: "menuItem_#4_submenuItem_#3",
                                    displayName: t("accounting.submenu.cashSession"),
                                    commandType: "IPlugin",
                                    command: "cash_session_plugin",
                                    name: "CashSession",
                                },
                                {
                                    key: "menuItem_#4_submenuItem_#4",
                                    displayName: t("accounting.submenu.kizExchange"),
                                    commandType: "IPlugin",
                                    command: "kiz_exchange_plugin",
                                    name: "KizExchange",
                                },
                                {
                                    key: "menuItem_#4_submenuItem_#5",
                                    displayName: t("accounting.submenu.defectura"),
                                    commandType: "IPlugin",
                                    command: "defectura_plugin",
                                    name: "Defectura",
                                },
                                {
                                    key: "menuItem_#4_submenuItem_#6",
                                    displayName: t("accounting.submenu.analysisLot"),
                                    commandType: "IPlugin",
                                    command: "analysis_lot_plugin",
                                    name: "Lot",
                                },
                            ],
                        },
                    ],
                },
                {
                    key: "menuItem_#3_submenu_#2",
                    displayName: t("discount.title"),
                    groups: [
                        {
                            key: "menuItem_#4_submenugroup_#2",
                            items: [
                                {
                                    key: "menuItem_#4_submenuItem_#2.1",
                                    displayName: t("discount.submenu.discountProgram"),
                                    commandType: "IPlugin",
                                    command: "discount_plugin",
                                    name: "DiscountProgram",
                                },
                                {
                                    key: "menuItem_#4_submenuItem_#2.2",
                                    displayName: t("discount.submenu.discountCardType"),
                                    commandType: "IPlugin",
                                    command: "discount_card_type_plugin",
                                    name: "DiscountCardType",
                                },
                                {
                                    key: "menuItem_#4_submenuItem_#2.3",
                                    displayName: t("discount.submenu.discountCard"),
                                    commandType: "IPlugin",
                                    command: "discount_card_plugin",
                                    name: "DiscountCard",
                                },
                                {
                                    key: "menuItem_#4_submenuItem_#2.4",
                                    displayName: t("discount.submenu.discountMember"),
                                    commandType: "IPlugin",
                                    command: "discount_member_plugin",
                                    name: "DiscountMember",
                                },
                            ],
                        },
                    ],
                },
                {
                    key: "menuItem_#3_submenu_#3",
                    displayName: t("inventory.title"),
                    groups: [
                        {
                            key: "menuItem_#4_submenugroup_#3",
                            items: [
                                {
                                    key: "menuItem_#4_submenuItem_#3.1",
                                    displayName: t("inventory.submenu.inventoryVed"),
                                    commandType: "IPlugin",
                                    command: "inventory_ved_plugin",
                                    name: "InventoryVed",
                                },
                                {
                                    key: "menuItem_#4_submenuItem_#3.2",
                                    displayName: t("inventory.submenu.inventorySved"),
                                    commandType: "IPlugin",
                                    command: "inventory_sved_plugin",
                                    name: "InventorySved",
                                },
                                {
                                    key: "menuItem_#4_submenuItem_#3.3",
                                    displayName: t("inventory.submenu.kizInventory"),
                                    commandType: "IPlugin",
                                    command: "kiz_inventory_plugin",
                                    name: "KizInventory",
                                },
                            ]
                        }
                    ]
                },
                {
                    key: "menuItem_#3_submenu_#4",
                    displayName: t("internetOrder.title"),
                    groups: [
                        {
                            key: "menuItem_#4_submenugroup_#4",
                            items: [
                                {
                                    key: "menuItem_#4_submenuItem_#4.1",
                                    displayName: t("internetOrder.submenu.internetOrders"),
                                    commandType: "IPlugin",
                                    command: "internet_order_plugin",
                                    name: "InternetOrder",
                                }
                            ]
                        }
                    ]
                }
            ],
        },
        {
            key: "menuItem_#4",
            displayName: t("reports.title"),
            submenu: [
                {
                    key: "menuItem_#4_submenu_#1",
                    displayName: t("reports.title"),
                    groups: [
                        {
                            key: "menuItem_#5_submenugroup_#1",
                            items: [
                                {
                                    key: "menuItem_#5_submenuItem_#1",
                                    displayName: t("reports.submenu.reportsMaterial"),
                                    commandType: "IPlugin",
                                    command: "reports_material_plugin",
                                    name: "Reports",
                                },
                                {
                                    key: "menuItem_#5_submenuItem_#2",
                                    displayName: t("reports.submenu.reportsProduct"),
                                    commandType: "IPlugin",
                                    command: "reports_product_plugin",
                                    name: "Reports",
                                },
                                {
                                    key: "menuItem_#5_submenuItem_#3",
                                    displayName: t("reports.submenu.reportsWithoutGroup"),
                                    commandType: "IPlugin",
                                    command: "reports_withoutGroup_plugin",
                                    name: "Reports",
                                },
                                {
                                    key: "menuItem_#5_submenuItem_#4",
                                    displayName: t("reports.submenu.reportsCash"),
                                    commandType: "IPlugin",
                                    command: "reports_cash_plugin",
                                    name: "Reports",
                                },
                                {
                                    key: "menuItem_#5_submenuItem_#5",
                                    displayName: t("reports.submenu.AccountingReports"),
                                    commandType: "IPlugin",
                                    command: "reports_accounting_plugin",
                                    name: "Reports",
                                },
                                {
                                    key: "menuItem_#5_submenuItem_#6",
                                    displayName: t("reports.submenu.analisys"),
                                    commandType: "IPlugin",
                                    command: "reports_analisys_plugin",
                                    name: "Reports",
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            key: "menuItem_#5",
            displayName: "Файл",
            submenu: [
                {
                    key: "menuItem_#5_submenu_#1",
                    displayName: "Файл",
                    groups: [
                        {
                            key: "menuItem_#5_submenugroup_#1",
                            items: [
                                {
                                    key: "menuItem_#5_submenuItem_#1",
                                    displayName: t("files.submenu.importInvoices"),
                                    commandType: "IPlugin",
                                    command: "import_invoices_plugin",
                                    name: "ImportInvoice",
                                },
                                {
                                    key: "additionalMenuItem_#5_submenuItem_#2",
                                    displayName: t("files.submenu.importPricingModel"),
                                    commandType: "IPlugin",
                                    command: "import_pricing_model_plugin",
                                    name: "ImportPricing",
                                },
                                {
                                    key: "additionalMenuItem_#5_submenuItem_#3",
                                    displayName: t("files.submenu.importRemains"),
                                    commandType: "IPlugin",
                                    command: "import_remain_plugin",
                                    name: "ImportedRemains",
                                },
                                {
                                    key: "additionalMenuItem_#5_submenuItem_#4",
                                    displayName: t("files.submenu.importExternalReport"),
                                    commandType: "IPlugin",
                                    command: "import_external_report_plugin",
                                    name: "Reports",
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],

    additionalMenuItems: [
        {
            key: "additionalMenuItem_#1",
            displayName: t("service.title"),
            submenu: [
                {
                    key: "additionalMenuItem_#1_submenu_#1",
                    displayName: t("service.title"),
                    groups: [
                        {
                            key: "additionalMenuItem_#1_submenugroup_#1",
                            items: [
                                {
                                    key: "additionalMenuItem_#1_submenuItem_#1",
                                    displayName: t("service.submenu.cashRegisters"),
                                    commandType: "IPlugin",
                                    command: "cash_registers_plugin",
                                    name: "CashRegister",
                                },
                                {
                                    key: "additionalMenuItem_#1_submenuItem_#2",
                                    displayName: t("service.submenu.disposalRegistrar"),
                                    commandType: "IPlugin",
                                    command: "disposal_registrar_plugin",
                                    name: "CashRegister", // нейминг модуля todo
                                },
                                {
                                    key: "additionalMenuItem_#1_submenuItem_#3",
                                    displayName: t("service.submenu.users"),
                                    commandType: "IPlugin",
                                    command: "users_plugin",
                                    name: "User",
                                },
                                {
                                    key: "additionalMenuItem_#1_submenuItem_#4",
                                    displayName: t("service.submenu.roles"),
                                    commandType: "IPlugin",
                                    command: "role_plugin",
                                    name: "Role",
                                },
                                {
                                    key: "additionalMenuItem_#1_submenuItem_#5",
                                    displayName: t("service.submenu.companies"),
                                    commandType: "IPlugin",
                                    command: "companies_plugin",
                                    name: "Company",
                                },
                                {
                                    key: "additionalMenuItem_#1_submenuItem_#6",
                                    displayName: t("service.submenu.discountConditionType"),
                                    commandType: "IPlugin",
                                    command: "discount_condition_type_plugin",
                                    name: "DiscountConditionType",
                                },
                                {
                                    key: "additionalMenuItem_#1_submenuItem_#7",
                                    displayName: t("service.submenu.systemSpeedTest"),
                                    commandType: "IPlugin",
                                    command: "system_test_plugin",
                                    name: "Invoice",
                                },
                                {
                                    key: "additionalMenuItem_#1_submenuItem_#8",
                                    displayName: t("service.submenu.labelDesigner"),
                                    commandType: "IPlugin",
                                    command: "label_designer_plugin",
                                    name: "LabelDesigner",
                                },
                                {
                                    key: "additionalMenuItem_#1_submenuItem_#10",
                                    displayName: t("service.submenu.testPricingModel"),
                                    commandType: "IPlugin",
                                    command: "test_pricing_model_plugin",
                                    name: "Pricing",
                                },
                                {
                                    key: "additionalMenuItem_#1_submenuItem_#11",
                                    displayName: t("service.submenu.userActionLog"),
                                    commandType: "IPlugin",
                                    command: "user_action_log_plugin",
                                    name: "Logger",
                                },
                                {
                                    key: "additionalMenuItem_#1_submenuItem_#12",
                                    displayName: t("service.submenu.constructor"),
                                    commandType: "IPlugin",
                                    command: "constructor_schemes_plugin",
                                    name: "KizConstructor",
                                },
                                {
                                    key: "additionalMenuItem_#1_submenuItem_#13",
                                    displayName: t("service.submenu.kizFiles"),
                                    commandType: "IPlugin",
                                    command: "kiz_files_plugin",
                                    name: "Kiz",
                                },
                                {
                                    key: "additionalMenuItem_#1_submenuItem_#14",
                                    displayName: t("service.submenu.accessPoint"),
                                    commandType: "IPlugin",
                                    command: "access_point_plugin",
                                    name: "AccessPoint",
                                },
                                {
                                    key: "additionalMenuItem_#1_submenuItem_#15",
                                    displayName: t("service.submenu.esCode"),
                                    commandType: "IPlugin",
                                    command: "es_code_plugin",
                                    name: "EsCode",
                                },
                                {
                                    key: "additionalMenuItem_#1_submenuItem_#16",
                                    displayName: t("service.submenu.sysOptions"),
                                    commandType: "IPlugin",
                                    command: "sys_options_plugin",
                                    name: "SysOptions",
                                },
                                {
                                    key: "additionalMenuItem_#1_submenuItem_#17",
                                    displayName: t("service.submenu.companyActiveSessions"),
                                    commandType: "IPlugin",
                                    command: "company_active_sessions_plugin",
                                    name: "CompanyActiveSessions",
                                },
                                {
                                    key: "additionalMenuItem_#1_submenuItem_#18",
                                    displayName: "Настройка соединения F3ЛЛО",
                                    commandType: "IPlugin",
                                    command: "connection_settings_f3llo_plugin",
                                    name: "Goods",
                                },
                            ],
                        },
                    ],
                },
                {
                    key: "additionalMenuItem_#1_submenu_#2",
                    displayName: t("about.title"),
                    groups: [
                        {
                            key: "additionalMenuItem_#2_submenugroup_#1",
                            items: [
                                {
                                    key: "additionalMenuItem_#2_submenuItem_#1",
                                    displayName: t("about.submenu.version"),
                                    commandType: "IPlugin",
                                    command: "about_plugin",
                                    name: "About",
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            key: "additionalMenuItems_#2",
            displayName: t("certificate.title"),
        },
        {
            key: "additionalMenuItem_#3",
            displayName: t("usersLocal.title"),
            submenu: [
            {
                key: "additionalMenuItem_#3_submenu_#1",
                displayName: t("usersLocal.title"),
                groups: [
                {
                    key: "additionalMenuItem_#3_submenugroup_#1",
                    items: [
                        {
                            key: "additionalMenuItem_#3_submenuItem_#1",
                            displayName: t("usersLocal.submenu.UserLocal"),
                            commandType: "IPlugin",
                            command: "user_local_plugin",
                            name: "UserLocal",
                        },
                        {
                            key: "additionalMenuItem_#3_submenuItem_#2",
                            displayName: t("usersLocal.submenu.RoleLocal"),
                            commandType: "IPlugin",
                            command: "role_local_plugin",
                            name: "RoleLocal",
                        },
                        {
                            key: "additionalMenuItem_#3_submenuItem_#3",
                            displayName: t("usersLocal.submenu.LockingDocuments"),
                            commandType: "IPlugin",
                            command: "locking_documents_plugin",
                            name: "LockingDocumentsAdmin",
                        },
                    ]
                }
                ]
            }
            ]
        },
    ],
};

export { DefaultUserInterface };
