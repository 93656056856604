import {IOption} from '../components/selects/select';

export enum TypeExport {
    xlsx = "xlsx",
    xml = "xml"
}

export enum GridRowHeightEnum {
    normal = 36,
    sparse = 44,
    compressed = 30,
}

export enum LoadingStatus {
    NoData,
    InProcess,
    Completed,
    Failed,
    NoAccess,
    None,
    Exporting,
}

export enum DocumentStateType {
    new = "new",
    draft = 'draft',
    cancel = "cancel",
    proc = 'proc',
    del = 'del',
    done = "done",
    sent = "sent",
    deposited = "deposited",
    internetOrder = "internetOrder",
    open = 'Open',
    closed = 'Closed',
    mdlp = 'mdlp',
}

export enum DocumentStatusType {
    New = 'new',
    Draft = 'draft',
    Del = 'del',
    Cancel = 'cancel',
    Save = 'save',
    Prov = 'proc',
    Done = 'done',
    Sent = 'sent',
}

export enum KizStateType {
    save = 'Save',
    hold = 'Hold',
    proc = 'Proc',
    part = 'Part',
    exit = 'Exit',
    stop = 'Stop',
    del = 'Del',
}

export enum EncodingType {
    base64 = 'base64',
    ascii = 'ascii',
}

export enum DocumentType {
    cheque = 'Cheque',
    invoice = 'Invoice',
    invoiceOut = 'InvoiceOut',
    movement = 'Movement',
    revaluation = 'Revaluation',
    disassembling = 'Disassembling',
    disaggregation = 'Disaggregation',
    importRemains = 'ImportRemains',
    importInvoice = 'ImportInvoice',
    gosContract = 'GosContract',
    goods = 'Goods',
    lots = 'Lots',
    lotsByGoods = 'LotsByGoods',
    cashSession = 'CashSession',
    actDeduction = 'ActDeduction',
    actReturnToContractor = 'ActReturnToContractor',
    inventoryVed = "InventoryVed",
    inventorySved = "InventorySved",
    accessPoint = 'AccessPoint',
    sysOptions = 'SysOptions',
    contractor = 'Contractor',
    internetOrder = 'InternetOrder',
    kizExchange = 'KizExchange',
    kizInventory = "KizInventory",
    pricingAdpriceScaleType = 'PricingAdpriceScaleType',
    pricingRoundSchema = 'PricingRoundSchema',
    pricingExtremeAdpriceSchemaType = 'PricingExtremeAdpriceSchemaType',
    pricingMinAdpriceSchemaType = 'PricingMinAdpriceSchemaType',
    pricingPriceCalcSchemaType = 'PricingPriceCalcSchemaType'
}

export enum DocumentTypeRu {
    invoice = 'Приходная накладная',
    movement = 'Перемещение',
    actRevaluation = 'Акт переоценки',
    actDisassembling = 'Акт разукомплектации',
    actDisagregation = 'Акт разагрегации'
}

export enum DocumentState {
    new = "Новый",
    draft = 'Черновик',
    cancel = "Отменён",
    proc = 'Отработан',
    del = 'Удален',
    done = "Завершён",
    sent = "Отправлен",
    deposited = "Отложенный",
    internetOrder = "Интернет заказ",
    Open = 'Открыта',
    Closed = 'Закрыта',
    mdlp = 'Завершён',
}

export enum KizState {
    Save = 'Сохранен',
    Proc = 'Отработан',
    Exit = 'Закончен',
    Stop = 'Остановлен',
    Hold = 'Удержан',
    Error = 'Ошибка',
    Del = 'Удален',
}

export enum FieldValidation {
    Quantity = 'quantity',
}

export enum defaultColumn {
    mnemocode = 'Номер',
    supplierName = 'Поставщик',
    storeName = 'Склад',
    documentDate = 'Дата',
    documentState = 'Статус',
    dateModified = 'Дата изменения',
    dateDeleted = 'Дата удаления',
    isKizBox = 'Короб',
    idStoreGlobal = 'Склад',
    deleted = 'Удален',
    quantityRem = 'Остаток',
}

export enum SearchOption {
    kiz = 'KIZ',
    name = 'name',
    goodsName = 'goodsName',
    kizBox = 'KizBox',
    kizOrKizBox = 'kizOrKizBox',
    barcode = 'barcode',
    goodsCode = 'goodsCode',
    goodsCodeBylot = 'goodsCodeBylot',
    internalBarcode = 'internalBarcode',
    kizOrKizBoxInvoice = 'kizOrKizBoxInvoice',
    gtin = 'gtin',
    gtinSgtin = 'gtinSgtin'
}

export enum SearchOptionDocument {
    mnemocode = 'mnemocode',
    supplierDocNumber = 'supplierDocNumber'
}

export enum defaultOperator {
    Like = 'Содержит',
    Eq = 'Равно',
    NotEq = 'Не равно',
    More = 'Больше',
    MoreOrEq = 'Больше либо равно',
    Less = 'Меньше',
    LessOrEq = 'Меньше либо равно',
}

export enum ViewExtension {
    dbf = 'dbf',
    sst = 'sst',
    sstProtek = 'sst.protek',
}

export enum Extensions {
    dbf = 'dbf',
    sst = 'sst',
}

export const AdmissibleExtensions: IOption[] = [
    {value: 'dbf', displayName: 'dbf'},
    {value: 'sst', displayName: 'sst'},
    {value: 'sst.protek', displayName: 'sst-протек'},
];

export const GridRowHeightEnumOption = [
    {displayName: 'Обычный', value: GridRowHeightEnum.normal},
    {displayName: 'Разреженный', value: GridRowHeightEnum.sparse},
    {displayName: 'Сжатый ', value: GridRowHeightEnum.compressed}
] as IOption[];

export const Encoding: IOption[] = [
    {value: '1251', displayName: 'WIN'},
    {value: '866', displayName: 'DOS'},
];

export enum responseErrorType {
    exception = 'Исключение',
    error = 'Ошибка',
    warning = 'Предупреждение'
}

export enum SysOptionName {
    SmartSearch = 'SmartSearch',
    AllowUnProccesDocuments = 'AllowUnProccesDocuments',
    StateNotice = 'StateNotice',
    SaveTab = 'SaveTab',
    EsExchangeSettings = 'EsExchangeSettings',
    AllowAcquiringReturnWithDisconnectedPinpad = 'AllowAcquiringReturnWithDisconnectedPinpad',
    SourceTypeKizInventory = 'SourceTypeKizInventory',
    PermissionRegimeMdlpSetting = 'PermissionRegimeMdlpSetting',
    KizSourceForDisposalRegistrar = 'KizSourceForDisposalRegistrar',
    SettingHeightSizeOfComponents = 'SettingHeightSizeOfComponents',
    SnoEnabled = 'SnoEnabled'
}

export const FirstCountRows = 20

export enum IdTableVariant {

    /// <summary>
    /// 2 - Приходная накладная
    /// </summary>
    Invoice = 2,

    /// <summary>
    /// 2 - Приходная накладная
    /// </summary>
    InvoiceOut = 222,

    /// <summary>
    /// 3 - Акт возврата поставщику
    /// </summary>
    ActReturnToContractor = 3,

    /// <summary>
    /// 6 - Акт разукомплектации
    /// </summary>
    ActDisassembling = 6,

    /// <summary>
    /// 7 - Кассовый чек
    /// </summary>
    Cheque = 7,

    /// <summary>
    /// 8 - Перемещение товара
    /// </summary>
    Movement = 8,

    /// <summary>
    /// 12 - Акт возврата от покупателя
    /// </summary>
    ActReturnToBuyer = 12,

    /// <summary>
    /// 13 - Акт переоценки
    /// </summary>
    ActRevaluation2 = 13,

    /// <summary>
    /// 22 - Счет
    /// </summary>
    Bill = 22,

    /// <summary>
    /// 45 - Интернет заказ
    /// </summary>
    InternetOrder = 45,

    /// <summary>
    /// 59 - Заказ интернет-магазина
    /// </summary>
    EShopOrder = 59,

    /// <summary>
    /// 66 - Акт разагрегации
    /// </summary>
    ActDisagregation = 66,

    /// <summary>
    /// 143 - Оперативные заявки
    /// </summary>
    RequestOperational = 143,

    /// <summary>
    /// 30 - Ввод остатков
    /// </summary>
    ImportRemains = 30,

    /// <summary>
    /// 250 - Акт списания
    /// </summary>
    ActDeduction = 250,

    /// <summary>
    /// 23 - Инвентаризационная ведомость
    /// </summary>
    InventoryVed = 23,

    /// <summary>
    /// 24 - Сводная инвентаризационная ведомость
    /// </summary>
    InventorySved = 24,

    /// <summary>
    /// 251 - Товарные остатки
    /// </summary>
    LotsByGoods = 251,

     /// <summary>
    /// 252 - Товарные остатки по партиям
    /// </summary>
    LotsByLots = 252,

    /// <summary>
    /// 260 - Киз инвентаризация
    /// </summary>
    KizInventory = 260,
}