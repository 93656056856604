import classNames from "classnames";
import { DateTime } from "luxon";
import { FC, useEffect, useState } from "react";
import { DocumentGridStateType } from "../../../@types/documents";
import { IDatePeriod } from "../../../components/datePeriodPicker/datePeriodPicker";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins";
import { DatePeriodPanel } from "../../../hoc/DatePeriodPanel/DatePeriodPanel";
import { ICashSessionViewDTO } from "../../../libs/coreapi-dto/accounting/cashSession";
import { CashSessionDataProvider } from "../../../Services/DataProviders/CashSessionDataProvider";
import useGridFilter from "../../../system/hooks/useGridFilter";
import { useDetailsTabsPanel } from "../../../system/hooks/useTabsPanel";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { usePluginContext } from "../../../system/providers/plugin";
import styles from "../../styles/index.module.scss";
import tabsStyles from "../../../pages/styles/homePage.module.scss";
import { TabsPanel } from "../../../components/tabs";
import { CashSessionPanel } from "./Components/CashSessionPanel";
import { Spinner } from "../../../components/spiner/Spinner";

const DefaultGridFilter = (datePeriod) => {
    return {
        numberPerPage: 10,
        pageNumber: 1,
        columnFilters: [
            ...(datePeriod?.startDate
                ? [
                    {
                        name: "dateOpen",
                        value: datePeriod.startDate.toFormat("yyyyMMdd"),
                        operator: "MoreOrEq",
                        invisible: true
                    } as IGridColumnFilter,
                ]
                : []),
            ...(datePeriod?.endDate
                ? [
                    {
                        name: "dateOpen",
                        value: datePeriod.endDate.toFormat("yyyyMMdd"),
                        operator: "LessOrEq",
                        invisible: true
                    } as IGridColumnFilter,
                ]
                : []),
        ],
    };
};

export const CashSessionGridView: FC<IGridProps> = (props) => {
    const appContext = useAppContext();
    const cdp = new CashSessionDataProvider(appContext.coreApiService);
    const DefaultDatePeriod = { startDate: DateTime.now().plus({ days: -7 }), endDate: DateTime.now() } as IDatePeriod;
    const pluginContext = usePluginContext();

    const [gridFilter, dispatchGridFilter] = useGridFilter(DefaultGridFilter(DefaultDatePeriod));
    const [viewState, setViewState] = useState<DocumentGridStateType>("view");
    const [detailsTabsPanel, dispatchDetailsTabsPanel] = useDetailsTabsPanel(props.plugin.mnemocode);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);

    const [datePeriod, setDatePeriod] = useState<IDatePeriod>(DefaultDatePeriod);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [data, setData] = useState<ICashSessionViewDTO[]>([]);

    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [selectedItems, setSelectedItems] = useState<IGridRow[]>(props.data ?? []);

    useEffect(() => {
        setIsSubmitting(true)
        cdp.getCashSession(gridFilter, (entities, totalCount) => {
            setTotalCount(totalCount);
            setData(entities);
            setIsSubmitting(false)
            setViewState("view");
        });
    }, [viewState, gridFilter]);

    return (
        <>
            <PluginWrapper>
                <ToolbarWrapper>
                    <CashSessionPanel
                        closeSession={{
                            onClick: () => {
                                if (selectedItem?.cells.find((c) => c.propertyName === "documentState")?.value !== "Closed") {
                                    cdp.closeSession(selectedItem?.idGlobal as string, data.find((c) => c.idGlobal === selectedItem?.idGlobal) as ICashSessionViewDTO, () => {
                                        setViewState("refresh");
                                        setSelectedItem(undefined);
                                    });
                                }
                            },
                            disabled: selectedItem ? false : true,
                        }}
                        refresh={{
                            onClick: () => {
                                setViewState("refresh");
                                setSelectedItem(undefined);
                            },
                        }}
                        permission={props.plugin.permission}
                    />
                    <DatePeriodPanel
                        datePeriod={datePeriod}
                        onDatePeriodChange={(date: IDatePeriod) => {
                            setDatePeriod(date);
                            dispatchGridFilter({
                                type: "sortByFilters",
                                payload: {
                                    gridColumnFilter: [
                                        ...(date?.startDate
                                            ? [
                                                {
                                                    name: "dateOpen",
                                                    value: date.startDate.toFormat("yyyyMMdd"),
                                                    operator: "MoreOrEq",
                                                } as IGridColumnFilter,
                                            ]
                                            : []),
                                        ...(date?.endDate
                                            ? [
                                                {
                                                    name: "dateOpen",
                                                    value: date.endDate.toFormat("yyyyMMdd"),
                                                    operator: "LessOrEq",
                                                } as IGridColumnFilter,
                                            ]
                                            : []),
                                    ],
                                },
                            });
                        }}
                    />
                </ToolbarWrapper>

                <div className={styles.gridWrapper}>
                    <div className={styles.masterGrid}>
                        {isSubmitting ? (
                            <Spinner />
                        ) :
                            <DefaultGrid
                                gridId={props.gridId}
                                data={data}
                                filter={gridFilter}
                                totalCount={totalCount}
                                separator
                                dataProvider={cdp}
                                documentStatus
                                plugin={props.plugin}
                                selectedItem={selectedItem}
                                selectedItems={selectedItems}
                                multipleSelect={props.multipleSelect}
                                onMultipleSelect={(rows) => {
                                    setSelectedItems(rows ? rows : []);
                                    props?.onMultipleSelect?.(rows ? rows : []);
                                }}
                                onSelect={(row) => {
                                    if (row) {
                                        setSelectedItem(row)
                                        props?.onSelect?.(row, data.find(x => x.idGlobal === row?.idGlobal))
                                        pluginContext.masterGrid.onSelectDetailEvent(row);
                                    } else {
                                        setSelectedItem(undefined)
                                        props?.onSelect?.(undefined)
                                        pluginContext.masterGrid.onUnselectDetailEvent()
                                    }
                                }}
                                onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                                onFilterDelete={(i) => {
                                    i.propertyName === "documentDate" && setDatePeriod({ startDate: null, endDate: null });
                                    dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName });
                                }}
                                onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                                onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                            />}
                    </div>
                    {props.isDetail &&
                        <>
                            <div className={classNames(styles.separator, styles.separator_horizontal)}></div>
                            <div className={styles.detailsTabView}>
                                {selectedItem && detailsTabsPanel.tabs.length > 0 &&
                                    <>
                                        <div className={tabsStyles.tabsPanelWrapper}>
                                            <div id="detailsTabsPanelWrapper" style={{ position: "relative", overflow: "hidden", width: "100%" }}>
                                                <TabsPanel
                                                    id="detailsTabsPanel"
                                                    activeId={detailsTabsPanel.currentTab?.id}
                                                    tabs={detailsTabsPanel.tabs}
                                                    onActive={(id) => {
                                                        dispatchDetailsTabsPanel({ type: "activate", payload: id });
                                                    }}
                                                ></TabsPanel>
                                            </div>
                                        </div>
                                        <div className={tabsStyles.contentWrapper}>
                                            {detailsTabsPanel.tabs.map((item) => {
                                                return (
                                                    <div key={item.id} className={classNames(item.id === detailsTabsPanel.currentTab?.id ? tabsStyles.contentVisible : tabsStyles.contentHidden)}>
                                                        {item.view.content}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </>
                                }
                            </div>
                        </>
                    }
                </div>
            </PluginWrapper>
        </>
    );
};