import { v4 as uuidv4 } from 'uuid';
import i18n from '../../../../i18n';
import { AccessPointDetailPluginSettings } from './AccessPointDetailPluginSettings';
import { AccessPointDetailPluginView } from './AccessPointDetailPluginView';

export const AccessPointDetailTab = (): ITab => {
    return {
        id: uuidv4(),
        title: "Поставщики",
        view: {
            content: AccessPointDetailPluginSettings
                ? <AccessPointDetailPluginView gridId={uuidv4()} plugin={AccessPointDetailPluginSettings} />
                : <p> {i18n.t("general.directoryNotConfigured")} </p>
        }
    }
}

export default AccessPointDetailTab