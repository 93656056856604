import { FC, SetStateAction, useContext, useEffect, useState } from "react";
import { StoreCreatorModal } from ".";
import { LoadingStatus } from "../../../@types/enumsGlobal";
import DefaultDictionaryCommandsPanel from "../../../components/commandsPanels/DefaultDictionaryCommandsPanel";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins";
import { Spinner } from "../../../components/spiner/Spinner";
import { IStoreViewDTO } from "../../../libs/coreapi-dto/dirs/store";
import { StoreDataProvider } from "../../../Services/DataProviders/StoreDataProvider";
import useGridFilter, { CreateSelectorGridFilter, CreateSelectorGridFilterParam } from "../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { useUserContext } from "../../../system/providers/userContextProvider";
import { checkAccessStatus } from "../../CommonHelperFunctions";
import { OptionsSno } from "./OptionsSno";
import GridWrapper from "../../../components/controls/GridWrapper";
import { TextInput } from "../../../components/controls/inputs";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';
import { Checkbox } from "../../../components/checkboxes/checkbox";
import { usePluginContext } from "../../../system/providers/plugin";

interface IStore extends IGridProps {
    isDetail?: boolean
}
const StoreGrid: FC<IStore> = (props) => {
    const appCtx = useAppContext();
    const pluginContext = usePluginContext()
    const userContext = useUserContext();
    const storeDataProvider = new StoreDataProvider(appCtx.coreApiService)

    const [data, setData] = useState<IStoreViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [totalCount, setTotalCount] = useState(0);
    const [viewState, setViewState] = useState<GridStateType>("view");
    const getGridFilter = () :IGridFilter => {
        if (props.idTable) {
            let idTableGridFilter :IGridFilter = CreateSelectorGridFilterParam(props.idTable, props.isEnabledTo);

            if (props.baseGridFilter && props.baseGridFilter.columnFilters?.length as number > 0) {
                const baseGridColumns = props.baseGridFilter.columnFilters;
                idTableGridFilter.columnFilters = baseGridColumns.concat(idTableGridFilter.columnFilters);                
            }

            return idTableGridFilter;
        } else {
            return props.baseGridFilter ?? CreateSelectorGridFilter
        }
    }
    const [gridFilter, dispatchGridFilter] = useGridFilter(getGridFilter());
    const [selectedItem, setSelectedItem] = useState<IGridRow | undefined>(undefined);
    const [selectedItems, setSelectedItems] = useState<IGridRow[]>(props.data ?? []);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);
    const [multipleSelect, setMultipleSelect] = useState<boolean>(props.multipleSelect ?? false);

    const [onlyUsers, setOnlyUsers] = useState<boolean>(false);

    const [searchValue, setSearchValue] = useState<string>('');

    const { t } = useTranslation();

    useEffect(() => {
        if (onlyUsers) {
            dispatchGridFilter({
                type: 'paramSimpleFilter',
                payload: { gridParamFilter: {...gridFilter.paramFilter, idUserGlobal: userContext.idGlobal}, gridColumnFilter: gridFilter.columnFilters }
            });
        } else {
            dispatchGridFilter({ type: 'deleteParamFilter' })
        }
    }, [onlyUsers]);

    useEffect(() => {
        if (pluginContext.masterGrid.selectedItem?.idGlobal && props.isDetail) getView()
    },[pluginContext.masterGrid.selectedItem?.idGlobal])

    useEffect(() => {
        if (props.idTable || onlyUsers) getViewParam()
        else getView()
    }, [gridFilter])

    useEffect(() => {
        if (viewState === 'refresh') {
            if (props.idTable || onlyUsers) getViewParam()
            else getView()
        }
    }, [viewState])

    const getView = () => {
        setIsSubmitting(true)

        let expression = ``

        if (pluginContext.masterGrid.selectedItem?.idGlobal && props.isDetail) {
            expression = `"IdContractorGlobal" = '${pluginContext.masterGrid.selectedItem?.idGlobal}'`
        }

        storeDataProvider.getView({...gridFilter,expression: expression}, (data, totalCount) => {
            setData(data.map((x) => {
                return {
                    ...x,
                    snoName: OptionsSno().find(c => c.value === x.sno)?.displayName ?? 'Не указано'
                } as IStoreViewDTO
            }))
            setTotalCount(totalCount)
            setViewState('view')
            data.length > 0 ? setLoadingStatus(LoadingStatus.Completed) : setLoadingStatus(LoadingStatus.NoData)
            setIsSubmitting(false)
        })
    }

    const getViewParam = () => {
        setIsSubmitting(true)
        storeDataProvider.getViewParam(gridFilter, (data, totalCount) => {
            setData(data.map((x) => {
                return {
                    ...x,
                    snoName: OptionsSno().find(c => c.value === x.sno)?.displayName ?? 'Не указано'
                } as IStoreViewDTO
            }))
            setTotalCount(totalCount)
            setViewState('view')
            data.length > 0 ? setLoadingStatus(LoadingStatus.Completed) : setLoadingStatus(LoadingStatus.NoData)
            setIsSubmitting(false)
        })
    }

    const clearSelectedItems = () => {
        setSelectedItems([]);
        setSelectedItem(undefined);
        props?.onMultipleSelect?.([]);
        props?.onSelect?.(undefined);
    }

    function toggleMultipleSelect(toggleAction: SetStateAction<boolean>) {
        clearSelectedItems();
        setMultipleSelect(toggleAction);
    }

    const search = (value: string) => {

        // Вхождение с 3 символов актуально только для живого поиска
        if (value.length > 0) {
            // pgsql-выражение (блока условия, финальный and (expr)):
            // соединение через ИЛИ
            const expression = `lower("Name") like lower('%${value}%') or lower("ContractorName") like lower('%${value}%') or lower("LegalEntityName") like lower('%${value}%')`;

            dispatchGridFilter({ type: "addCustomWhereExpressionFilter", payload: expression });
        } else {
            dispatchGridFilter({ type: "deleteCustomWhereExpressionFilter" });
        }
    };

    return (
        <PluginWrapper>
            { !props.isDetail &&
                checkAccessStatus(props.plugin.permission as IPermission, userContext.userPermission) && <ToolbarWrapper>
                    <GridWrapper cols={props.isModal ? 3 : 8}>
                        <DefaultDictionaryCommandsPanel
                            selectedItem={[selectedItem, setSelectedItem]}
                            selectedItems={[selectedItems, setSelectedItems]}
                            multipleSelect={[multipleSelect, toggleMultipleSelect]}
                            gridFilter={[gridFilter, dispatchGridFilter]}
                            dataProvider={storeDataProvider}
                            creatorModalJsx={StoreCreatorModal}
                            pluginSettings={props.plugin}
                            setViewState={(vs) => setViewState(vs)}
                            showGroupProcessing={props.showGroupProcessing}
                        />
                        <div style={{ display: "flex", alignItems: "center", padding: 6, minWidth: 190 }}>
                            <Checkbox
                                name={`userStores-${uuidv4()}`}
                                label={t("directory.stores.onlyUsers")}//'Собственные склады'
                                onChange={(checked: boolean) => {
                                    setOnlyUsers(checked);
                                }}
                            />
                        </div>
                    </GridWrapper>
                </ToolbarWrapper>
                
            }
            {!props.isDetail &&
                <div style={{ marginLeft: -13 }}>
                    <GridWrapper cols={8}>
                        <TextInput
                            value={searchValue}
                            label=''
                            searchButtonShow
                            inline
                            style={{ width: props.isModal ? 360 : 360 }}
                            placeholder={t("directory.stores.search")}
                            onKeyDown={(e) => {
                                switch (e.key) {
                                    case "Enter": {
                                        search(e.currentTarget?.value);
                                    }
                                }
                            }}
                            onChange={(value: string) => {
                                setSearchValue(value);
                            }}
                            searchButtonOnClick={() => {
                                search(searchValue);
                            }}
                            onFocus={() => {
                                props.isModal && props.onFocusChanged?.(true);
                            }}
                            onEndChange={() => {
                                props.isModal && props.onFocusChanged?.(false);
                            }}
                        >
                        </TextInput>
                    </GridWrapper>
                </div>
            }
            {
                isSubmitting ? <Spinner /> : <DefaultGrid
                    gridId={props.gridId}
                    data={data}
                    loadingStatus={loadingStatus}
                    setLoadingStatus={setLoadingStatus}
                    //dataProvider={storeDataProvider}
                    getView={(gridFilter, callback) => {
                        if (props.idTable || onlyUsers) {
                            storeDataProvider.getViewParam(gridFilter, (data, totalCount) => {
                                callback(data);
                            });
                        } else {
                            storeDataProvider.getView(gridFilter, (data, totalCount) => {
                                callback(data);
                            });
                        }
                    }}
                    totalCount={totalCount}
                    filter={gridFilter}
                    separator
                    singleDirectory
                    plugin={props.plugin}
                    selectedItem={selectedItem}
                    selectedItems={selectedItems}
                    multipleSelect={multipleSelect}
                    searching
                    isServerFilter
                    onMultipleSelect={(rows) => {
                        setSelectedItems(rows ? rows : []);
                        props?.onMultipleSelect?.(rows ? rows : []);
                    }}
                    onSelect={(row) => {
                        if (row) {
                            setSelectedItem(row)
                            props?.onSelect?.(row, data.find(x => x.idGlobal === row?.idGlobal))
                            pluginContext.masterGrid.onSelectDetailEvent(row);
                        } else {
                            setSelectedItem(undefined)
                            props?.onSelect?.(undefined)
                            pluginContext.masterGrid.onUnselectDetailEvent()
                        }
                    }}
                    onDoubleClick={(i) => props.onDoubleClick?.(i, data.find(x => x.idGlobal === i.idGlobal))}
                    onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                    onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                    onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                    onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                    onEnter={() => setViewState("edit")}
                />
            }
        </PluginWrapper>
    )
}

export default StoreGrid
