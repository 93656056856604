import { IAccessPoint2SupplierDTO, IAccessPoint2SupplierViewDTO } from "../../../libs/coreapi-dto/dirs/accessPoint2Supplier";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";
import { ContentType } from "../../fetchApiDistributor/contentTypes";


interface IAccessPoint2SupplierCreate extends IAccessPoint2SupplierDTO {
}
interface IAccessPoint2SupplierUpdate extends IAccessPoint2SupplierDTO {
}

class AccessPoint2SupplierRequest extends BaseDirRequest<IAccessPoint2SupplierViewDTO, IAccessPoint2SupplierDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "accessPoint2Supplier", token, eventHandler)
    }
    
    create(body: IAccessPoint2SupplierCreate, callback: (e:CoreApiResponse<string>) => void) {
        super.create(body, callback)
    }

    update(idGlobal: string, body: IAccessPoint2SupplierUpdate, callback: (e:CoreApiResponse<null>) => void) {
        super.update(idGlobal, body, callback)
    }

    getViewById(idGlobal: string, body: IViewBody, callback?: (e: CoreApiResponse<IViewWrapper<IAccessPoint2SupplierViewDTO>>) => void) {
        this.post<IViewWrapper<IAccessPoint2SupplierViewDTO>>(`${this._baseUrl}/${this._routePrefix}/${idGlobal}/View`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
           if(this._eventHandler){
               if(e.respType==="isFailed") {
                   this._eventHandler.onGetResponse(e);
               }
            }
            callback?.(e);
        })
    }    

    deleteById(id: string, callback?: () => void) {
        this.delete(`${this._baseUrl}/${this._routePrefix}/${id}`, {}, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), e => {
            callback?.()
        })
    }
}
export default AccessPoint2SupplierRequest