import { FC, useEffect, useState } from "react";
import { Accordion } from "../../../components/controls/accordion"
import { v4 as uuidv4} from 'uuid';
import { DatePeriodPanel } from "../../../hoc/DatePeriodPanel/DatePeriodPanel";
import { IDatePeriod } from "../../../components/datePeriodPicker/datePeriodPicker";
import { DefaultDatePeriod } from "../../../system/hooks/useGridFilter";
import FlexColumn from "../../../components/controls/FlexColumn";
import { NumberInput } from "../../../components/controls/inputs/BaseInput";
import styles from "../AnalysisLot/Styles/AnalysisFilterLot.module.scss"
import Checkbox from "../../../components/checkboxes/checkbox";
import { useTranslation } from "react-i18next";
import { IOption, Select } from "../../../components/selects/select";
import FlexRow from "../../../components/controls/FlexRow";

interface Props {
    onOpenAccorion: (name: TypeAccordionName) => void
    setFilters:(filters: IAnalysisFilters) => void
}

export type TypeAccordionName = 'expiringDate' | 'lliquidLots' | 'salesSpeed' | 'none'

interface IlliquidLots {
    datePriod: IDatePeriod,
    sales: number,
    percentRemain: number,
    lifeLot: number
}

interface ISalesSpeed {
    datePriod: IDatePeriod,
    isExcessStock: boolean
}

interface IExpiringDate {
    bestBeforeMonth: number,
    compareBestBeforeMonth: IOption
    percentBestBefore: number
    comparePercentBestBefore: IOption
}

export interface IAnalysisFilters {
    lliquidLots: IlliquidLots
    salesSpeed: ISalesSpeed
    expiringDate: IExpiringDate
}

export const defaultAnalysisFilters = {
    expiringDate: {
        bestBeforeMonth: 0,
        percentBestBefore: 0,
        compareBestBeforeMonth: {displayName: '>=', value: 'MoreOrEq' as OperatorType},
        comparePercentBestBefore: {displayName: '>=', value: 'MoreOrEq' as OperatorType},
    },
    lliquidLots: {
        datePriod: DefaultDatePeriod,
        lifeLot: 0,
        sales: 0,
        percentRemain: 0
    },
    salesSpeed: {
        datePriod: DefaultDatePeriod,
        isExcessStock: false
    }
} as IAnalysisFilters

export const AnalyticalFilters: FC<Props> = (props) => {
    const [isIlliquidLots, setIlliquidLots] = useState<boolean>(false);
    const [isSalesSpeed, setSalesSpeed] = useState<boolean>(false);
    const [isExpiringDate, setExpiringDate] = useState<boolean>(false);

    const defaultSearchOptions = [
        {displayName: '=', value: 'Eq' as OperatorType},
        {displayName: '>', value: 'More' as OperatorType},
        {displayName: '>=', value: 'MoreOrEq' as OperatorType},
        {displayName: '<', value: 'Less' as OperatorType},
        {displayName: '<=', value: 'LessOrEq' as OperatorType},
    ] as IOption[];

    const [analysisFilters, setAnalysisFilters] = useState<IAnalysisFilters>(defaultAnalysisFilters)
    const { t } = useTranslation();
    const baseT = (value: string) => t('analysisLot.' + value)

    useEffect(() => {
        setTimeout(() => {
            props.setFilters(analysisFilters)
        }, 200);        
    },[analysisFilters])

    const getTypeAccordion = (value: TypeAccordionName, isOpen: boolean): TypeAccordionName => {
        return isOpen == true ? value: 'none'
    }

    return <>
        <h1>{baseT('analysisFilter')}</h1>
        <Accordion 
            opened={isIlliquidLots}
            caption={baseT('isIlliquidLots')} 
            id={`${uuidv4()}`}
            onOpen={(isOpen) => {
                setIlliquidLots(isOpen)
                setSalesSpeed(false)
                setExpiringDate(false)
                props.onOpenAccorion(getTypeAccordion("lliquidLots",isOpen))
            }}
        >
             <FlexColumn className={styles.flexColumn}>
                <DatePeriodPanel
                    datePeriod={analysisFilters.lliquidLots.datePriod}
                    label={baseT("lliquidLotsDatePriod")}
                    onDatePeriodChange={(date: IDatePeriod) => {
                        setAnalysisFilters({...analysisFilters, lliquidLots: {...analysisFilters.lliquidLots, datePriod: date} })
                    }}
                />

                <NumberInput className={styles.numberInput} label={baseT("lliquidLotsSales")} value={analysisFilters.lliquidLots.sales} 
                    onChange={(value) => setAnalysisFilters({...analysisFilters, lliquidLots: {...analysisFilters.lliquidLots, sales: value} })} 
                />
                    
                <NumberInput className={styles.numberInput}label={baseT("lliquidLotsPercentRemain")} value={analysisFilters.lliquidLots.percentRemain} 
                    onChange={(value) => setAnalysisFilters({...analysisFilters, lliquidLots: {...analysisFilters.lliquidLots, percentRemain: value} })}   
                    max={100}
                    min={0}
                />
                <NumberInput className={styles.numberInput} label={baseT("lliquidLotsLifeLot")} value={analysisFilters.lliquidLots.lifeLot} 
                    onChange={(value) => setAnalysisFilters({...analysisFilters, lliquidLots: {...analysisFilters.lliquidLots, lifeLot: value} })}
                />
                    
            </FlexColumn>
        </Accordion>

        <Accordion 
            opened={isSalesSpeed} 
            caption={baseT('salesSpeed')} 
            id={`${uuidv4()}`}
            onOpen={(isOpen) => {
                setIlliquidLots(false)
                setSalesSpeed(isOpen)
                setExpiringDate(false)
                props.onOpenAccorion(getTypeAccordion("salesSpeed",isOpen))
            }}
        
        >
         <FlexColumn className={styles.flexColumn}>
            <DatePeriodPanel
                    datePeriod={analysisFilters.salesSpeed.datePriod}
                    label={baseT('lliquidLotsDatePriod')}
                    onDatePeriodChange={(date: IDatePeriod) => {
                        setAnalysisFilters({...analysisFilters, salesSpeed: {...analysisFilters.salesSpeed, datePriod: date} })
                    }}
            />
            <div>
                <Checkbox
                    name='isExcessStock'
                    label={baseT('isExcessStock')}
                    checked={analysisFilters.salesSpeed.isExcessStock}
                    onChange={(checked) => {
                        setAnalysisFilters({...analysisFilters, salesSpeed: {...analysisFilters.salesSpeed, isExcessStock: checked} })
                    }}
                    disabled={false} 
                />
            </div>
         </FlexColumn>
        </Accordion>

        <Accordion 
            opened={isExpiringDate} 
            caption={baseT('isExpiringDate')} 
            id={`${uuidv4()}`}
            onOpen={(isOpen) => {
                setIlliquidLots(false)
                setSalesSpeed(false)
                setExpiringDate(isOpen)
                props.onOpenAccorion(getTypeAccordion("expiringDate",isOpen))
            }}
        
        >
            <FlexColumn className={styles.flexColumn}>
                <FlexRow>
                    <NumberInput className={styles.numberInput} label={baseT('expiringDateBestBeforeMonth')} value={analysisFilters.expiringDate.bestBeforeMonth} 
                        onChange={(value) => setAnalysisFilters({...analysisFilters, expiringDate: {...analysisFilters.expiringDate, bestBeforeMonth: value} })}                    
                    />
                    <Select 
                        label={baseT('compare')} 
                        defaultOption={defaultSearchOptions[2]}
                        options={defaultSearchOptions}
                        value={analysisFilters.expiringDate.compareBestBeforeMonth}
                        onSelect={(option) => setAnalysisFilters({...analysisFilters, expiringDate: {...analysisFilters.expiringDate, compareBestBeforeMonth : option} })}
                    />
                </FlexRow>

                <FlexRow>
                    <NumberInput className={styles.numberInput}label={baseT('expiringDatePercentBestBefore')} value={analysisFilters.expiringDate.percentBestBefore} 
                        onChange={(value) => setAnalysisFilters({...analysisFilters, expiringDate: {...analysisFilters.expiringDate, percentBestBefore: value} })}
                        max={100}
                        min={0}
                    />
                    <Select 
                        label={baseT('compare')}
                        defaultOption={defaultSearchOptions[2]}
                        value={analysisFilters.expiringDate.comparePercentBestBefore}
                        options={defaultSearchOptions}
                        onSelect={(option) => setAnalysisFilters({...analysisFilters, expiringDate: {...analysisFilters.expiringDate, comparePercentBestBefore : option} })}
                    />
                </FlexRow>
                        
                
            </FlexColumn>
        
         
        </Accordion>
    </>
    
}