import { useState, FC } from "react";
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from "react-i18next";
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";
import { GridSelectorModalWindow } from "../../../../components/modalWindows/GridSelectorModalWindow";
import { CashSessionGridView } from "../CashSessionGridView";
import CashSessionGridPluginSettings from "../CashSessionGridPluginSettings";

const CashSessionSelectorModal: FC<IGridSelectorModalProps> = (props) => {
    const [entity, setEntity] = useState<IEntitySimpleDTO>(props.selectedEntity ?? {} as IEntitySimpleDTO)
    const [entities, setEntities] = useState<IEntitySimpleDTO[]>(props.selectedEntity ?? []);
    const [focusTaken, setFocusTaken] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false)
    const gridId = uuidv4();
    const plugin = CashSessionGridPluginSettings;
    const { t } = useTranslation();

    return (
        <GridSelectorModalWindow
            entityToPressEnter={entity}
            fullScreen
            gridDisplayName={plugin?.name}
            focusTaken={focusTaken}
            ok={{
                onClick: () => props.multipleSelect ? props.ok(entities) : props.ok?.(entity),
                disabled: disabled
            }}
            cancel={{
                onClick: () => props.cancel?.()
            }}
            style={props.style} >
            {
                plugin
                    ? <CashSessionGridView
                        showGroupProcessing={props.showGroupProcessing}
                        data={entities}
                        gridId={gridId}
                        plugin={plugin}
                        idTable={props.idTable}
                        isEnabledTo={props.isEnabledTo}
                        multipleSelect={props.multipleSelect}
                        baseGridFilter={props.gridFilter}
                        isModal
                        onFocusChanged={(taken: boolean) => {
                            setFocusTaken(taken);
                        }}
                        onSelect={(row, rowDto) => {
                            if (row && rowDto && rowDto.deleted === false) {
                                setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                                setDisabled(false)
                            } else if (row && rowDto && rowDto.deleted === true) {
                                setDisabled(true)
                            } else if (row) {
                                setDisabled(false)
                                setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                            } else {
                                setDisabled(true)
                                setEntity({ idGlobal: '', displayName: '' })
                            }
                        }}
                        onMultipleSelect={(rows) => {
                            if (rows.length > 0) {
                                setDisabled(false)
                                setEntities(rows.map(x => ({ idGlobal: x.idGlobal, displayName: x.displayName } as IEntitySimpleDTO)))
                            } else {
                                setDisabled(true)
                                setEntities([])
                            }
                        }}
                        onDoubleClick={(row, rowDto) => {
                            if (row && rowDto && rowDto.deleted === false) {
                                setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                                props.ok?.({ idGlobal: row.idGlobal, displayName: row.displayName })
                            }
                        }}
                        isDetail={false}
                    />
                    : <p> {t('general.directoryNotConfigured')} </p>
            }
        </GridSelectorModalWindow>
    )
}
export default CashSessionSelectorModal