import { FC, useEffect, useState } from 'react'
import { TypeOpen } from './DisposalRegistrarGrid'
import { useTranslation } from 'react-i18next'
import { TextAreaInput, TextInput } from '../../../components/controls/inputs'
import GridWrapper from '../../../components/controls/GridWrapper'
import { IDisposalRegistrarCreate, IDisposalRegistrarDTO } from '../../../libs/coreapi-dto/service/disposalRegistrar'
import { v4 as uuidv4 } from 'uuid';
import { IOption, Select } from '../../../components/selects/select'
import { DisposalRegistrarDataProvider } from '../../../Services/DataProviders/DisposalRegistrarDataProvider'
import GridSelectorInput from '../../../components/controls/GridSelectorInput'
import { ContractorSelectorModal } from '../../Dictionaries/Contractor'
import { IEntitySimpleDTO } from '../../../libs/coreapi-dto/@types/common'
import useGridFilter, { CreateSelectorGridFilter } from '../../../system/hooks/useGridFilter'
import { useForm } from '../../../system/hooks/useForm'
import { ContractorDataProvider } from '../../../Services/DataProviders/ContractorDataProvider'
import { useAppContext } from '../../../system/providers/appContextProvider'
import { Button } from '../../../components/buttons/button'
import { Accordion } from '../../../components/controls/accordion'
import { BaseModalWindow } from '../../../components/modalWindows/BaseModalWindow'
import DisposalRegistrarDriver from '../../../Devices/DisposalRegistrar/DisposalRegistrarDriver'
import { DateTime } from 'luxon'
import { CheckBox } from '../../../components/controls/checkbox'

interface IValidator {
    name: string,
    idContractorGlobal: string,
    login: string,
    password: string,
    host: string,
    port: string
}

interface IDisposalRegistrarModalModalProps {
    typeOpen: TypeOpen
    selectedItem: IGridRow
    dataProvider: DisposalRegistrarDataProvider
    refresh: () => void
    cancel: () => void
}

export const DisposalRegistrarCreateModal: FC<IDisposalRegistrarModalModalProps> = (props) => {
    const { t } = useTranslation();
    const baseT = (value: string) => t('modals.disposalRegistrar.disposalRegistrarModal.' + value);

    const appContext = useAppContext();

    const driverTypeOptions = [
        {displayName: 'ePlus.ShtrihRegistratorDriver', value: 'Shtrih'},
        {displayName: 'ePlus.AtolRegistratorDriver', value: 'Atol'}
    ] as IOption[];
    const [driverType, setDriverType] = useState<IOption>(driverTypeOptions[0]);
    
    const [contractor, setContractor] = useState<IEntitySimpleDTO>();
    const [contractorGridFilter, dispatchContractorGridFilter] = useGridFilter(CreateSelectorGridFilter);
    const contractorDataProvider = new ContractorDataProvider(appContext.coreApiService);
    
    const [settings, setSettings] = useState<IDisposalRegistrarCreate>({
        idGlobal: uuidv4(),
        driverType: driverType.displayName
    } as IDisposalRegistrarCreate);

    const [rvState, setRvState] = useState<string>();

    const errorsT = (value: string) => t('errors.' + value);
    const { isValid, errors, setErrors } = useForm<IValidator>({
        validations: {
            name: {
                required: {
                    value: settings.name ? false : true,
                    message: errorsT("required")
                }
            },
            idContractorGlobal: {
                required: {
                    value: settings.idContractorGlobal ? false : true,
                    message: errorsT("required")
                }
            },
            login: {
                required: {
                    value: settings.login ? false : true,
                    message: errorsT("required")
                }
            },
            password: {
                required: {
                    value: settings.password ? false : true,
                    message: errorsT("required")
                }
            },
            host: {
                required: {
                    value: settings.host ? false : true,
                    message: errorsT("required")
                }
            },
            port: {
                required: {
                    value: settings.port ? false : true,
                    message: errorsT("required")
                }
            }
        }
    });

    useEffect(() => {
        if (props.typeOpen === 'Edit') {
            props.dataProvider.getById(props.selectedItem?.idGlobal, (result) => {
                setSettings(result as IDisposalRegistrarCreate);
                setDriverType(driverTypeOptions.find((element) => element.displayName === result.driverType) as IOption);
                contractorDataProvider.getById(result.idContractorGlobal, e => {
                    setContractor({idGlobal: e.idGlobal, displayName: e.name});
                })
            })
        }
    }, []);

    const rvTest = (settings: IDisposalRegistrarDTO) => {
        const apiUrl = `https://${settings.host}:${settings.port}/v1`;
        DisposalRegistrarDriver.test(apiUrl, { login: settings.login, password: settings.password }, settings.driverType).then((response) => {
            if (response) {
                if (!response.err || response.err.length === 0) {
                    const expirationDt = DateTime.fromISO(`${response.expirationDate}`).toFormat("dd.MM.yyyy hh/mm/ss");
                    const stateText = `${baseT("responses.lifePhase")}: \t\t\t\t\t\t\t\t${response.lifePhase}\r\n` +
                        `${baseT("responses.processState")}: \t${response.processState}\r\n` +
                        `${baseT("responses.logState")}: \t\t${response.logState}\r\n` +
                        `${baseT("responses.expirationDate")}: \t\t${expirationDt}`;

                    setRvState(stateText);
                } else {
                    const stateText = `${baseT("responses.errConnection")}:\n${response.err}`;
                    setRvState(stateText);
                }
            } else {
                setRvState(`${baseT("responses.errState")}`);
            }
        })
    }

    const rvProperties = (settings: IDisposalRegistrarDTO) => {
        const apiUrl = `https://${settings.host}:${settings.port}/v1`;
        DisposalRegistrarDriver.getProperties(apiUrl, { login: settings.login, password: settings.password }, settings.driverType).then((response) => {
            if (response) {
                if (!response.err || response.err.length === 0) {

                    let stateText: string = "";
                    response.devices.forEach((device) => {
                        stateText += `${baseT("responses.id")}: \t\t\t\t${device.id}\r\n` +
                            `${baseT("responses.connectionType")}: \t\t\t\t${device.connectionType}\r\n` +
                            `${baseT("responses.modelInfo")}: \t\t\t\t${device.modelInfo}\r\n` +
                            `${baseT("responses.softwareVersion")}: \t\t\t\t\t\t${device.softwareVersion}\r\n` +
                            `${baseT("responses.deviceSerial")}: \t\t\t\t${device.deviceSerialNumber}\r\n` +
                            `${baseT("responses.moduleSerial")}: \t\t\t${device.moduleSerialNumber}\r\n` +
                            `${baseT("responses.startDateRegistration")}: \t\t\t\t${DateTime.fromISO(`${device.startDateRegistration}`).toFormat("dd.MM.yyyy hh/mm/ss")}\r\n` +
                            `${baseT("responses.endDateRegistration")}: \t${DateTime.fromISO(`${device.endDateRegistration}`).toFormat("dd.MM.yyyy hh/mm/ss")}\r\n` +
                            "================================================"
                    });
                    setRvState(stateText);
                } else {
                    const stateText = `${baseT("responses.errConnection")}:\n${response.err}`;
                    setRvState(stateText);
                }
            } else {
                setRvState(`${baseT("responses.errState")}`);
            }
        })
    }

    const rvRegister = (settings: IDisposalRegistrarDTO) => {
        const apiUrl = `https://${settings.host}:${settings.port}/v1`;
        /*DisposalRegistrarDriver.Register(apiUrl, settings.controlToken, { login: settings.login, password: settings.password }, settings.driverType).then((response) => {
            console.log(response);
        })*/
    }

    return (
        <>
            <BaseModalWindow
                header={baseT("settingsHeader")}
                ok={{
                    onClick: () => {
                        if (isValid()) {
                            if (props.typeOpen === 'Create') {
                                props.dataProvider.create(settings, () => {
                                    props.refresh?.();
                                });
                            } else {
                                props.dataProvider.update(props.selectedItem?.idGlobal, settings, () => {
                                    props.refresh?.();
                                });
                            }
                        }
                    }
                }}
                cancel={{ 
                    onClick: () => props.cancel?.() 
                }}
                footerStyle="confirm"
            >
                <GridWrapper cols={3}>
                    <TextInput
                        required
                        label={baseT("name")}
                        value={settings.name} 
                        onChange={(value) => setSettings({...settings, name: value})}
                        error={errors.name}
                        onFocus={() => setErrors({ ...errors, name: undefined })}
                    />
                    <GridSelectorInput
                        required
                        selectorModalJsx={ContractorSelectorModal}
                        label={baseT("contractor")}
                        selectedEntity={contractor}
                        gridFilter={contractorGridFilter}
                        onSelect={(entity) => {
                            setContractor(entity);
                            setSettings({ ...settings, idContractorGlobal: entity.idGlobal });
                        }}
                        onClear={() => {
                            setContractor(undefined);
                            setSettings({ ...settings, idContractorGlobal: '' });
                        }}
                        error={errors.idContractorGlobal}
                        onFocus={() => setErrors({ ...errors, idContractorGlobal: undefined })}
                    />
                    <Select
                        required
                        label={baseT("driverType")}
                        value={driverType}
                        defaultOption={driverType}
                        options={driverTypeOptions}
                        onSelect={(option) => {
                            setDriverType(option);
                            setSettings({...settings, driverType: option.displayName});
                        }}
                    />
                </GridWrapper>
                <GridWrapper cols={2}>
                    <TextInput
                        required
                        label={baseT("login")}
                        value={settings.login} 
                        onChange={(value) => setSettings({...settings, login: value})}
                        error={errors.login}
                        onFocus={() => setErrors({ ...errors, login: undefined })}
                    />
                    <TextInput
                        required
                        label={baseT("password")}
                        value={settings.password} 
                        onChange={(value) => setSettings({...settings, password: value})}
                        error={errors.password}
                        onFocus={() => setErrors({ ...errors, password: undefined })}
                    />
                </GridWrapper>
                <GridWrapper cols={2}>
                    <TextInput
                        required
                        label={baseT("server")}
                        value={settings.host} 
                        onChange={(value) => setSettings({...settings, host: value})}
                        error={errors.host}
                        onFocus={() => setErrors({ ...errors, host: undefined })}
                    />
                    <TextInput
                        required
                        label={baseT("port")}
                        value={settings.port} 
                        onChange={(value) => setSettings({...settings, port: value})}
                        error={errors.port}
                        onFocus={() => setErrors({ ...errors, port: undefined })}
                    />
                </GridWrapper>
                <GridWrapper cols={1}>
                    <CheckBox
                        id={"isRvDisposalOnly"}
                        label={baseT("isRvDisposalOnly")}
                        defaultChecked={settings.isRvDisposalOnly as boolean}
                        onChanged={(value: boolean) => setSettings({ ...settings, isRvDisposalOnly: value })}
                    />
                </GridWrapper>
                <GridWrapper cols={1}>
                    <CheckBox
                        id={"isAutoDisposal"}
                        label={baseT("isAutoDisposal")}
                        defaultChecked={settings.isAutoDisposal as boolean}
                        onChanged={(value: boolean) => setSettings({ ...settings, isAutoDisposal: value })}
                        disabled={!(settings.isRvDisposalOnly as boolean)}
                    />
                </GridWrapper>
                <GridWrapper cols={1}>
                    <CheckBox
                        id={"isEnabled"}
                        label={baseT("isEnabled")}
                        defaultChecked={settings.enabled as boolean}
                        onChanged={(value: boolean) => setSettings({ ...settings, enabled: value })}
                    />
                </GridWrapper>
                {/*<GridWrapper cols={2}>
                    <TextInput
                        label={t("modals.disposalRegistrar.disposalRegistrarModal.controlToken")}
                        value={settings.controlToken}
                        onChange={(value) => setSettings({...settings, controlToken: value})}
                    />
                    <TextInput
                        label={t("modals.disposalRegistrar.disposalRegistrarModal.deviceId")}
                        disabled
                    />
                    </GridWrapper>*/}
                <Accordion 
                    opened={false} 
                    caption={baseT("conditionRV")} 
                    id={uuidv4()}
                >
                    <GridWrapper cols={2}>
                        {/*<Button
                            variant="outprimary"
                            onClick={() => {
                                if (settings && settings.controlToken?.length > 0)
                                    rvRegister(settings as IDisposalRegistrarDTO);
                            }}
                        >
                            {t("modals.disposalRegistrar.disposalRegistrarModal.registrationRV")}
                        </Button>*/}
                        <Button
                            variant="outprimary"
                            onClick={() => {
                                if (settings && settings.host?.length > 0 && settings.port?.length > 0)
                                    rvTest(settings as IDisposalRegistrarDTO);
                            }}
                        >
                            {baseT("testRV")}
                        </Button>
                        <Button
                            variant="outprimary"
                            onClick={() => {
                                if (settings && settings.host?.length > 0 && settings.port?.length > 0)
                                    rvProperties(settings as IDisposalRegistrarDTO);
                            }}
                        >
                            {baseT("propertiesRV")}
                        </Button>
                    </GridWrapper>
                </Accordion>
                <TextAreaInput 
                    label={baseT("answer")}
                    value={rvState}
                    largeTextArea
                    readonly
                />
            </BaseModalWindow>
        </>
    )
}