import classNames from "classnames";
import { FC, useContext, useEffect, useState } from "react";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins";
import { TabsPanel } from "../../../components/tabs";
import styles from "../../styles/index.module.scss";
import tabsStyles from "../../../pages/styles/homePage.module.scss";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { usePluginContext } from "../../../system/providers/plugin";
import useGridFilter from "../../../system/hooks/useGridFilter";
import { useDetailsTabsPanel } from "../../../system/hooks/useTabsPanel";
import { ChequeDataProvider } from "../../../Services/DataProviders/ChequeDataProvider";
import { IChequeViewDTO } from "../../../libs/coreapi-dto/accounting/cheque";
import { ChequePanel } from "./Components/ChequePanel";
import { DatePeriodPanel } from "../../../hoc/DatePeriodPanel/DatePeriodPanel";
import { DateTime } from "luxon";
import { IDatePeriod } from "../../../components/datePeriodPicker/datePeriodPicker";
import { DocumentGridStateType } from "../../../@types/documents";
import { IKeyValuePair } from "../../Reports/Shared/Interfaces/IKeyValuePair";
import { DocumentType, IdTableVariant, LoadingStatus } from "../../../@types/enumsGlobal";
import { SearchModalWindow } from "../../../components/modalWindows/SearchModalWindow";
import { TextInput } from "../../../components/controls/inputs";
import { IOption, Select } from "../../../components/selects/select";
import { Spinner } from "../../../components/spiner/Spinner";
import FlexRow from "../../../components/controls/FlexRow";

const DefaultGridFilter = (datePeriod) => {
    return {
        numberPerPage: 10,
        pageNumber: 1,
        fieldOrderBy: "documentDate",
        orderDesc: true,
        columnFilters: [
            ...(datePeriod?.startDate
                ? [
                      {
                          name: "documentDate",
                          value: datePeriod.startDate.toFormat("yyyyMMdd"),
                          operator: "MoreOrEq",
                          invisible: true,
                      } as IGridColumnFilter,
                  ]
                : []),
            ...(datePeriod?.endDate
                ? [
                      {
                          name: "documentDate",
                          value: datePeriod.endDate.toFormat("yyyyMMdd"),
                          operator: "LessOrEq",
                          invisible: true,
                      } as IGridColumnFilter,
                  ]
                : []),
        ],
    };
};

export const ChequeGridView: FC<IGridProps> = (props) => {
    const appContext = useAppContext();
    const cdp = new ChequeDataProvider(appContext.coreApiService);
    const pluginCtx = usePluginContext();
    const DefaultDatePeriod = { startDate: DateTime.now().plus({ days: -7 }), endDate: DateTime.now() } as IDatePeriod;
    const [datePeriod, setDatePeriod] = useState<IDatePeriod>(DefaultDatePeriod);
    const [gridFilter, dispatchGridFilter] = useGridFilter(DefaultGridFilter(DefaultDatePeriod));
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [chequeSelected, setChequeSelected] = useState<IChequeViewDTO | null>(null);
    const [detailsTabsPanel, dispatchDetailsTabsPanel] = useDetailsTabsPanel(props.plugin.mnemocode);

    const [viewState, setViewState] = useState<DocumentGridStateType>("view");
    const [modalSearch, setModalSearch] = useState(<></>)
    const defaultSearchOption = {displayName: 'Позиция в чеке', value: 'lotName'} as IOption;
    const [searchOption, setSearchOption] = useState<IOption>(defaultSearchOption);
    const [searchValue, setSearchValue] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);

    const [totalCount, setTotalCount] = useState<number>(0);
    const [data, setData] = useState<IChequeViewDTO[]>([]);

    useEffect(() => {
        setIsSubmitting(true);
        cdp.getParamView(gridFilter, (entities, totalCount) => {
            setTotalCount(totalCount);
            setData(entities);
            setIsSubmitting(false);
        });
    }, [gridFilter,viewState]);

    useEffect(() => {
        switch (viewState) {
            case "refresh" : refresh(); break;
            case "search" : search(); break;
            default: setModalSearch(<></>);
        }
    }, [viewState]);

    useEffect(() => {
        if (selectedItem) {
            setChequeSelected(data.find((x) => x.idGlobal === selectedItem.idGlobal) as IChequeViewDTO);
        } else {
            setChequeSelected(null);
        }
    }, [selectedItem]);

    function refresh()
    {
        cdp.getParamView(gridFilter, (entities, totalCount) => {
            setTotalCount(totalCount);
            setData(entities);
        });
        setViewState("view");
    }

    function search()
    {
        const visibleColumns = props.plugin?.columns.filter((x) => x.favoriteFilter === true) ?? []
        setModalSearch(
            <SearchModalWindow
                defaultFilters={gridFilter.columnFilters} columns={visibleColumns}
                cancel={() => setViewState("return")}
                search={(columnFilters) => {
                    dispatchGridFilter({ type: 'search', payload: columnFilters })
                    setViewState("refresh")
                    setModalSearch(<></>)
                }}
            />
        )
    }

    return (
        <>
            <PluginWrapper>
                <ToolbarWrapper>
                    <FlexRow wrap>
                        <ChequePanel
                            process={{
                                onClick: () => {
                                    cdp.process(selectedItem?.idGlobal as string, () => {
                                        setViewState("refresh");
                                        setSelectedItem(undefined);
                                        setChequeSelected(null);
                                    });
                                },
                                disabled: selectedItem && chequeSelected?.documentState !== "proc" && chequeSelected?.documentState !== "del" ? false : true,
                            }}
                            unProcess={{
                                onClick: () => {
                                    cdp.unProcess(selectedItem?.idGlobal as string, () => {
                                        setViewState("refresh");
                                        setSelectedItem(undefined);
                                        setChequeSelected(null);
                                    });
                                },
                                disabled: selectedItem && chequeSelected?.documentState !== "save" && chequeSelected?.documentState !== "del" ? false : true,
                            }}
                            delete={{
                                onClick: () => {
                                    cdp.markDelete(selectedItem?.idGlobal as string, () => setViewState("refresh"));
                                },
                                disabled: selectedItem && chequeSelected?.documentState !== "del" ? false : true,
                            }}
                            print={{
                                onClick: () => {
                                    return IdTableVariant.Cheque;
                                },
                                onSubItemClick: () => {
                                    let arr = new Array<IKeyValuePair>();
                                    let rows: Array<IGridRow> = new Array<IGridRow>();
                                    rows.push(selectedItem as IGridRow); //ToDo siv здесь нужно поставлять все выделенные строки, но не понятно как
                                    rows.forEach(function (row) {
                                        const mnemocode = row.cells.find((e) => {
                                            return e.propertyName == "mnemocode";
                                        })?.value;
                                        if (mnemocode != undefined) arr.push({ key: row.idGlobal, value: mnemocode });
                                    });
                                    return arr;
                                },
                                disabled: selectedItem && chequeSelected?.documentState !== "del" ? false : true,
                            }}
                            refresh={{ onClick: () => setViewState("refresh") }}
                            search={{ onClick:() => setViewState("search") }}
                            permission={props.plugin.permission}
                        />
                        <TextInput
                            disabled={false}
                            label={''}
                            inputId={'inputSearch'}
                            inline={true}
                            searchButtonShow={true}
                            value={searchValue}
                            onChange={(value) => {
                                setSearchValue(value)
                                dispatchGridFilter({
                                    type: 'paramSimpleFilter',
                                    payload: {gridParamFilter: {lotName: value}, gridColumnFilter: gridFilter.columnFilters},
                                })
                            }}
                            searchButtonOnClick={() => {
                                setViewState('refresh')
                            }}
                            onKeyDown={(e) => {
                            if (e.key === 'Enter') { 
                                setViewState('refresh')
                            }
                            }}
                        />
                        <Select
                            className={styles.filterBy}
                            defaultOption={defaultSearchOption}
                            onSelect={(option) => {
                                setSearchOption(option);
                            }}
                            value={searchOption}
                            options={[defaultSearchOption]}
                        />
                        <DatePeriodPanel
                            datePeriod={datePeriod}
                            onDatePeriodChange={(date: IDatePeriod) => {
                                setDatePeriod(date);
                                dispatchGridFilter({
                                    type: "sortByFilters",
                                    payload: {
                                        gridColumnFilter: [
                                            ...(date?.startDate
                                                ? [
                                                    {
                                                        name: "documentDate",
                                                        value: date.startDate.toFormat("yyyyMMdd"),
                                                        operator: "MoreOrEq",
                                                    } as IGridColumnFilter,
                                                ]
                                                : []),
                                            ...(date?.endDate
                                                ? [
                                                    {
                                                        name: "documentDate",
                                                        value: date.endDate.toFormat("yyyyMMdd"),
                                                        operator: "LessOrEq",
                                                    } as IGridColumnFilter,
                                                ]
                                                : []),
                                        ],
                                    },
                                });
                            }}
                        />
                    </FlexRow>
                </ToolbarWrapper>

                <div className={styles.gridWrapper}>
                    <div className={styles.masterGrid}>
                        {isSubmitting ? (
                            <Spinner />
                        ) :
                            <DefaultGrid
                            gridId={props.gridId}
                            data={data}
                            filter={gridFilter}
                            totalCount={totalCount}
                            dataProvider={cdp}
                            plugin={props.plugin}
                            selectedItem={selectedItem}
                            hiddenPagination={undefined}
                            separator
                            documentStatus
                            getView={(gridFilter,callback) => {
                                cdp.getParamView(gridFilter, (entities) => {
                                    callback(entities)
                                    setSelectedItem(undefined);
                                })
                            }}
                            onSelect={(row) => {
                                setSelectedItem(row);
                                row ? pluginCtx.masterGrid.onSelectEvent(row, DocumentType.cheque) : pluginCtx.masterGrid.onUnselectEvent();
                            }}
                            onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                            onFilterDelete={(i) => {
                                i.propertyName === "documentDate" && setDatePeriod({ startDate: null, endDate: null });
                                dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName });
                            }}
                            onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                            onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                        />
                        }
                    </div>
                    {
                        <div className={styles.detailsTabView}>
                            {selectedItem && detailsTabsPanel.tabs.length > 0 && (
                                <>
                                    <div className={tabsStyles.tabsPanelWrapper}>
                                        <div id="detailsTabsPanelWrapper" style={{ position: "relative", overflow: "hidden", width: "100%" }}>
                                            <TabsPanel
                                                id="detailsTabsPanel"
                                                activeId={detailsTabsPanel.currentTab?.id}
                                                tabs={detailsTabsPanel.tabs}
                                                onActive={(id) => {
                                                    dispatchDetailsTabsPanel({ type: "activate", payload: id });
                                                }}
                                            ></TabsPanel>
                                        </div>
                                    </div>
                                    <div className={tabsStyles.contentWrapper}>
                                        {detailsTabsPanel.tabs.map((item) => {
                                            return (
                                                <div key={item.id} className={classNames(item.id === detailsTabsPanel.currentTab?.id ? tabsStyles.contentVisible : tabsStyles.contentHidden)}>
                                                    {item.view.content}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </>
                            )}
                        </div>
                    }
                </div>
            </PluginWrapper>
            {modalSearch}
        </>
    );
};
