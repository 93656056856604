import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";
import { IGetFileDTO, IPostFilesNameDTO } from "../../coreapi-dto/dirs/importInvoices";

class ImportInvoicesRequest extends BaseDirRequest<null, null> {
    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "ImportFiles/Invoices/AutoLoading", token, eventHandler);
    }

    getFilesName(idAccessPoint: string, callback?: (e) => void) {
        this.get(
            `${this._baseUrl}/${this._routePrefix}/GetFilesName/${idAccessPoint}`,
            this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
                if (this._eventHandler) {
                    if (e.respType === "isFailed") {
                        this._eventHandler.onGetResponse(e);
                    }
                }
                callback?.(e);
            }
        );
    }

    getFilesNameLocal(idContractor: string, idSupplier: string, callback?: (e) => void) {
        this.get(
            `${this._baseUrl}/${this._routePrefix}/GetFilesNameLocal/${idContractor}/${idSupplier}`,
            this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
                if (this._eventHandler) {
                    if (e.respType === "isFailed") {
                        this._eventHandler.onGetResponse(e);
                    }
                }
                callback?.(e);
            }
        );
    }

    getFileLocal(idContractor: string, idSupplier: string, dto: IGetFileDTO, callback?: (e) => void) {
        this.postFile2<Blob>(
            `${this._baseUrl}/${this._routePrefix}/GetFileLocal/${idContractor}/${idSupplier}`,
            dto,
            "",
            this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
                if (this._eventHandler) {
                    if (e.respType === "isFailed") {
                        this._eventHandler.onGetResponse(e);
                    }
                }
                callback?.(e);
            }
        );
    }

    moveFilesLocal(idContractor: string, idSupplier: string, dto: IPostFilesNameDTO, callback?: (e) => void) {
        this.post(
            `${this._baseUrl}/${this._routePrefix}/MoveFilesLocal/${idContractor}/${idSupplier}`,
            dto,
            this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
                if (this._eventHandler) {
                    if (e.respType === "isFailed") {
                        this._eventHandler.onGetResponse(e);
                    }
                }
                callback?.(e);
            }
        );
    }

    getLocal(
        idContractor: string,
        idSupplier: string,
        idAccessPoint: string,
        callback?: (e) => void
    ) {
        this.get(
            `${this._baseUrl}/${this._routePrefix}/GetLocal/${idContractor}/${idSupplier}/${idAccessPoint}`,
            this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
                if (this._eventHandler) {
                    if (e.respType === "isFailed") {
                        this._eventHandler.onGetResponse(e);
                    }
                }
                callback?.(e);
            }
        );
    }

    testAccessPoint(idAccessPoint: string, callback?: (e) => void) {
        this.get(
            `${this._baseUrl}/${this._routePrefix}/TestAccessPoint/${idAccessPoint}`,
            this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
                if (this._eventHandler) {
                    if (e.respType === "isFailed") {
                        this._eventHandler.onGetResponse(e);
                    }
                }
                callback?.(e);
            }
        );
    }

    getCompanyId(callback?: (e) => void) {
        this.get(
            `${this._baseUrl}/${this._routePrefix}/GetCompanyId`,
            this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
                if (this._eventHandler) {
                    if (e.respType === "isFailed") {
                        this._eventHandler.onGetResponse(e);
                    }
                }
                callback?.(e);
            }
        );
    }
}

export default ImportInvoicesRequest;
