import { BasicSettingsPluginSettings } from "../pluginSettings/BasicSettingsPluginSettings"
import {v4 as uuidv4} from 'uuid';

export const BasicSettingsTab = (): ITab => {
    return {
      id: uuidv4(),
      title: BasicSettingsPluginSettings.name,
      view: {
        content: <></>
      }
    }
  }
  