import { FC } from "react";
import { CodeControlBaseProps } from "./CodeControlTab";
import { CustomDiv } from "./CustomDiv";
import styles from "../styles/CodeControl.module.scss"

export interface SettingTimeProps extends CodeControlBaseProps {

}

export const SettingTime: FC<SettingTimeProps> = ({ data, onChange }) => {
    const parseData = data && JSON.parse(data?.jsonData).Value;

    const dataComponent = [
        { valueInput: parseData?.TimeOut, name: 'TimeOut', start: 'Длительность выполнения запроса', end: 'с.' },
        { valueInput: parseData?.CDNPausePeriod, name: 'CDNPausePeriod', start: 'Приостановка активностей текущей CDN площадки в случае её некорректной работы на', end: 'мин.' },
        { valueInput: parseData?.CDNUpdateHealthStatus, name: 'CDNUpdateHealthStatus', start: <>F3POS выполняет запрос в ГИС МТ на получение списка в CDN-площадок,<br />случае устаревания информации,получаемой от ??? более, чем на</>, end: 'ч.' },
        { valueInput: parseData?.LogArhivePeriod, name: 'LogArhivePeriod', start: 'Хранить информацию о сбойных запросах к ГИС МТ', end: 'д.' },
        { valueInput: parseData?.Error418FailureGisMtReRequestPeriod, name: 'Error418FailureGisMtReRequestPeriod', start: 'В случае аварийного режима работы ГИС МТ отключить проверку КИЗов на', end: 'ч.' },
    ];

    return (
        <div className="form-container">
            <fieldset className={styles.codeControl_fieldSet}>
                {dataComponent.map(({ valueInput, name, start, end }, index) => {
                    return (
                        <CustomDiv
                            key={index}
                            valueInput={valueInput ?? 0}
                            onChange={(value) => {
                                onChange(value, name)
                            }}
                            startLabel={start}
                            endLabel={end}
                        />
                    )
                }
                )}
            </fieldset>
        </div>
    )
}