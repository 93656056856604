import { FC } from "react";
import { ISysOptionsDTO } from "../../../../libs/coreapi-dto/service/sysOptions";
import { useDetailsTabsPanel } from "../../../../system/hooks/useTabsPanel";
import { PluginWrapper } from "../../../../components/plugins";
import { TabsPanel } from "../../../../components/tabs";
import classNames from "classnames";
import tabsStyles from "../../../../pages/styles/homePage.module.scss";
import { CodeControlPluginSettings } from "../pluginSettings/CodeControlPluginSettings";
import { BasicSettingsPluginSettings } from "../pluginSettings/BasicSettingsPluginSettings";
import { SettingTimePluginSettings } from "../pluginSettings/SettingTimePluginSettings";
import { ProductGroupsGISMTPluginSettings } from "../pluginSettings/ProductGroupsGISMTPluginSettings";
import { RegKeysListPluginSettings } from "../pluginSettings/RegKeysListPluginSettings";
import { BasicSettings, BasicSettingsProps } from "./BasicSettings";
import { SettingTime, SettingTimeProps } from "./SettingTime";
import { ProductGroupsGISMT, ProductGroupsGISMTProps } from "./ProductGroupsGISMT";
import { RegKeysList, RegKeysListProps } from "./RegKeysList";

export interface CodeControlBaseProps {
  data?: ISysOptionsDTO;
  onChange: (value: any, name: string) => void;
}
interface TabProps extends CodeControlBaseProps{
  item: ITab | null;
}

interface CodeControlTabProps extends CodeControlBaseProps {

}

export const CodeControlTab: FC<CodeControlTabProps> = ({ data, onChange }) => {
    const [detailsTabsPanel, dispatchDetailsTabsPanel] = useDetailsTabsPanel(CodeControlPluginSettings.mnemocode);
  
    return (
      <PluginWrapper>
        {detailsTabsPanel.tabs.length > 0 && (
          <>
            <div>
              <div id="detailsTabsPanelWrapper" style={{ position: "relative", overflow: "hidden", width: "100%" }}>
                <TabsPanel
                  switchControl
                  id="detailsTabsPanel"
                  activeId={detailsTabsPanel.currentTab?.id}
                  tabs={detailsTabsPanel.tabs}
                  onActive={(id) => dispatchDetailsTabsPanel({ type: "activate", payload: id })}
                />
              </div>
            </div>
  
            <div>
              {detailsTabsPanel.tabs.map((item) => {
                const isActiveTab = item.id === detailsTabsPanel.currentTab?.id;
  
                return (
                  <div key={item.id} className={classNames(isActiveTab ? tabsStyles.contentVisible : tabsStyles.contentHidden)}>
                    {isActiveTab && <TabContent item={item} data={data} onChange={onChange} />}
                  </div>
                );
              })}
            </div>
          </>
        )}
      </PluginWrapper>
    );
  };


  const TabContent: FC<TabProps> = ({ item, data, onChange }) => {
    if (!item) {
      return null;
    }
    const ComponentToRender = componentMapping[item.title];
  
    if (!ComponentToRender) {
      return <h2>Компонент не найден</h2>;
    }
  
    return (
      <div className="TabContent">
        <ComponentToRender
          data={data}
          onChange={onChange}
        />
      </div>
    );
  };

  type ComponentProps = BasicSettingsProps | SettingTimeProps | ProductGroupsGISMTProps | RegKeysListProps;

const componentMapping:Record<string, React.FC<ComponentProps>> = {
  [BasicSettingsPluginSettings.name]: BasicSettings,
  [SettingTimePluginSettings.name]: SettingTime,
  [ProductGroupsGISMTPluginSettings.name]: ProductGroupsGISMT,
  [RegKeysListPluginSettings.name]: RegKeysList,
};