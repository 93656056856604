import { FC, useEffect, useState } from "react";
import { CodeControlBaseProps } from "./CodeControlTab";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import BaseCommandsPanel from "../../../../components/commandsPanels/BaseCommandsPanel";
import Tooltip from "../../../../components/tooltips/Tooltip";
import { FilePlusButton } from "../../../../components/buttons/iconButtons/other/FileAndFolderCollection";
import { ClearButton, Delete2Button } from "../../../../components/buttons/iconButtons/action/MainCollection";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { v4 as uuidv4 } from 'uuid';
import { ProductGroupsGISMTPluginSettings } from "../pluginSettings/ProductGroupsGISMTPluginSettings";
import { GoodsGroupAllSelectorModal } from "../../../Dictionaries/GoodsGroup/GoodsGroupAllSelectorModal";
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";
import styles from "../styles/ProductGroupsGISMT.module.scss"

export interface ProductGroupsGISMTProps extends CodeControlBaseProps {

}
interface TableData {
  Guid: string;
  idGlobal: string;
}
export const ProductGroupsGISMT: FC<ProductGroupsGISMTProps> = ({ data, onChange }) => {
  const parseData = data && JSON.parse(data?.jsonData).Value;

  const [gridFilter, dispatchGridFilter] = useGridFilter();
  const [selectedItem, setSelectedItem] = useState<IGridRow>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [tableData, setTableData] = useState<TableData[]>([]);

  useEffect(() => {
    if (parseData?.GoodsGroups && parseData?.GoodsGroups.length > 0) {
        setTableData(parseData?.GoodsGroups.map(el => ({ ...el, idGlobal: uuidv4() })))
    }
}, [data])

  return (
    <section className={styles.contentSection}>
      <div className="panel-container">
        <div className="button-container">
          <BaseCommandsPanel
            groups={[
              [
                {
                  iconButton: (
                    <Tooltip title={'Группа товаров'}>
                      <FilePlusButton
                        sizeVariant="mini"
                        disabled={false}
                        onClick={() => {
                          setShowModal(true);
                        }}
                      />
                    </Tooltip>
                  ),
                },
                {
                  iconButton: (
                    <Tooltip title={'Удалить позицию'}>
                      <Delete2Button
                        sizeVariant="mini"
                        colorVariant="danger"
                        disabled={!selectedItem}
                        onClick={() => {
                          if (selectedItem) {
                            const deleteItem = tableData.filter(el => el.idGlobal !== selectedItem.idGlobal);
                            setTableData(deleteItem);
                            onChange(deleteItem.map(({ idGlobal, ...rest }) => ({ ...rest })), 'GoodsGroups')//Удаление idGlobal
                            setSelectedItem(undefined);
                          }
                        }}
                      />
                    </Tooltip>
                  ),
                },
                {
                  iconButton: (
                    <Tooltip title={'Удалить все позиции'}>
                      <ClearButton
                        sizeVariant="mini"
                        colorVariant="danger"
                        disabled={tableData.length === 0}
                        onClick={() => {
                          setTableData([]);
                          onChange([], 'GoodsGroups');
                        }}
                      />
                    </Tooltip>
                  ),
                },
              ],
            ]}
          />
        </div>
      </div>
      <div className="table-container">
        <DefaultGrid
          gridId={uuidv4()}
          data={tableData}
          totalCount={0}
          filter={gridFilter}
          selectedItem={selectedItem}
          plugin={ProductGroupsGISMTPluginSettings as IPluginSettings}
          onSelect={(row) => {
            setSelectedItem(row)
          }}
          onSort={(i) => dispatchGridFilter({ type: 'sort', payload: i.propertyName })}
          onFilterDelete={(i) => dispatchGridFilter({ type: 'deleteColumnFilter', payload: i.propertyName })}
          onPageNumberChange={(n) => dispatchGridFilter({ type: 'changePageNumber', payload: { pageNumber: n } })}
          onNumberPerPageChange={(n) => dispatchGridFilter({ type: 'changeNumberPerPage', payload: { numberPerPage: n } })}
        />
      </div>

      {showModal && (
        <GoodsGroupAllSelectorModal
          ok={(entity: IEntitySimpleDTO | undefined) => {
            if (entity) {
              onChange([...parseData?.GoodsGroups, { Guid: entity?.idGlobal }], 'GoodsGroups');
            }
            setShowModal(false);
          }}
          cancel={() => {
            setShowModal(false);
          }}
        />
      )
      }

    </section>
  )
}
