import classNames from "classnames"
import { FC, useEffect, useState } from "react"
import { DocumentType, IdTableVariant, LoadingStatus } from "../../../@types/enumsGlobal"
import { DefaultGrid } from "../../../components/grids/default/defaultGrid"
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins"
import { IAnalysisLotViewDTO, ILotViewDTO } from "../../../libs/coreapi-dto/accounting/lot"
import { LotDataProvider } from "../../../Services/DataProviders/LotDataProvider"
import useGridFilter, { DefaultLotGridFilter } from "../../../system/hooks/useGridFilter"
import { useAppContext } from "../../../system/providers/appContextProvider"
import { usePluginContext } from "../../../system/providers/plugin"
import styles from '../../styles/index.module.scss'
import { IDatePeriod } from "../../../components/datePeriodPicker/datePeriodPicker"
import DefaultLotsByCommandsPanel from "../../../components/commandsPanels/DefaultLotsByCommandsPanel"
import { IOption } from "../../../components/selects/select"
import { GoodsDataProvider } from "../../../Services/DataProviders/GoodsDataProvider"
import { GoodsCreatorModal } from "../../Dictionaries/Goods"
import { useTabsContext } from "../../../system/providers/tabsProvider"
import { Spinner } from "../../../components/spiner/Spinner"
import { getFiltersForPrint } from "../../../system/functions/getFiltersForPrint"
import LotsByLotsCreatorModal from "../LotsByLots/LotsByLotsCreatorModal"
import { AnalyticalFilters, defaultAnalysisFilters, IAnalysisFilters, TypeAccordionName } from "./AnalyticalFilters"
import { Button } from "../../../components/buttons/button"
import FlexRow from "../../../components/controls/FlexRow"
import stylesAnalysis from "../AnalysisLot/Styles/AnalysisFilterLot.module.scss"
import Checkbox from "../../../components/checkboxes/checkbox"
import { useTranslation } from "react-i18next"
import { MessageModalWindow } from "../../../components/modalWindows/MessageModalWindow"

export type typeSearchField = 'goodsCode' | 'goods' | 'internalBarcode' | 'externalBarcode'
export interface IFilters {
    searchInput: string | null
    filterBy: IOption
    autoFilter: boolean
    withinRange: boolean
    remainsOnly: boolean
    filterPeriodBy: IOption
    datePeriod: IDatePeriod
    balanceOffBalance: IOption
    iz: boolean
    oa: boolean
    quantityRem: boolean
    lotFilters: {
        goodsCode: string | null
        goodsName: string | null
        barcodeCode: string | null
        idGoodsGlobal: string | null
        idStoreGlobal: string | null
        idContractorGlobal: string | null
        idProducerGlobal: string | null
        internalBarcode: string | null
    }
}

export interface ILotColumnFilter {
    goodsCode: string | null
    goodsName: string | null
    barcodeCode: string | null
    idGoodsGlobal: string | null
    idStoreGlobal: string | null
    idContractorGlobal: string | null
    idProducerGlobal: string | null
    internalBarcode: string | null
}

export const defaultFilters: IFilters = {
    searchInput: null,
    filterBy: { displayName: 'Товар', value: "goods" },
    autoFilter: true,
    withinRange: false,
    remainsOnly: false,
    filterPeriodBy: { displayName: "Выберите фильтр", value: "", hidden: true },
    datePeriod: { startDate: null, endDate: null },
    balanceOffBalance: { displayName: "Выберите фильтр", value: "" },
    iz: false,
    oa: false,
    quantityRem: true,
    lotFilters: {
        goodsCode: null,
        goodsName: null,
        barcodeCode: null,
        idGoodsGlobal: null,
        idStoreGlobal: null,
        idContractorGlobal: null,
        idProducerGlobal: null,
        internalBarcode: null,
    }
}

interface IMessageModalProps {
    show: boolean,
    message: string,
    handler?: () => void
}

const AnalysisLotPluginView: FC<IGridProps> = (props) => {
    const appContext = useAppContext()
    const tabCtx = useTabsContext();
    const lotDataProvider = new LotDataProvider(appContext.coreApiService)
    const goodsDataProvider = new GoodsDataProvider(appContext.coreApiService)

    const { t } = useTranslation();
    const baseT = (value: string) => t('analysisLot.' + value)

    const pluginCtx = usePluginContext()

    const [data, setData] = useState<IAnalysisLotViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.NoData);
    const [totalCount, setTotalCount] = useState(0);
    const [viewState, setViewState] = useState<GridStateType>("view");

    const [gridFilter, dispatchGridFilter] = useGridFilter(DefaultLotGridFilter);
    const [correctGridFilter, setCorrectGridFilter] = useState<IGridFilter>()

    const [analysisFilters, setAnalysisFilters] = useState<IAnalysisFilters>(defaultAnalysisFilters)

    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [selectedItems, setSelectedItems] = useState<IGridRow[]>([]);
    const [selectedItemsInverion, setSelectedItemsInverion] = useState<IGridRow[]>([]);
    const [selectedIdGoods, setSelectedIdGoods] = useState<string | undefined>();
    const [selectedIdLot, setSelectedIdLot] = useState<string | undefined>();
    const [isAllSelect, setIsAllSelect] = useState<boolean>(false)

    const [openAccordion, setOpenAccordion] = useState<TypeAccordionName | undefined>();

    const [multipleSelect] = useState<boolean>(true);

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [pluginSettings, setPluginSettings] = useState<IPluginSettings>(props.plugin);
    const [showMessageModal, setShowMessageModal] = useState<IMessageModalProps>({ show: false, message: '' });

    const isAllowCreateDocument = !(selectedItems.length > 0 && openAccordion !== undefined && openAccordion !== 'none')

    const onChangeMultipleSelect = (rows: ISelectGridRow[], currentRow?: IGridRow): void => {
        if (rows) {
            if (isAllSelect && currentRow) {
                setSelectedItemsInverion?.(prev => {
                    const isExisting = prev.some(item => item.idGlobal === currentRow.idGlobal);

                    if (isExisting) {
                        // Если элемент найден, удаляем его.
                        return prev.filter(item => item.idGlobal !== currentRow.idGlobal);
                    } else {
                        // Если элемента нет, добавляем его.
                        return [...prev, currentRow];
                    }
                });
            }
        }
    }

    const createDocument = (idTableVariant: IdTableVariant) => {
        if (!correctGridFilter) return

        let expression = ''
        if (!isAllSelect) {
            const inExpression =  selectedItems.map(c => { return `'` + c.idGlobal + `'`}).join(',')
            expression = `"IdGlobal" in (${inExpression})`
        } else {
            if (selectedItemsInverion.length > 0) 
            {
                const inNotExpression =  selectedItemsInverion.map(c => { return `'` + c.idGlobal + `'`}).join(',')
                expression = `"IdGlobal" not in (${inNotExpression})`
            }
        }
       
        let correctGridFilterExpression = {...correctGridFilter, expression: expression}
        lotDataProvider.createDocument(idTableVariant,correctGridFilterExpression, (response) => {
            setViewState('refresh')
            setShowMessageModal({message: `Документ ${response.mnemocode} создан, желаете перейти для дальнейшей работы?`,
            show: true,
            handler: () => {
                openTabDocument(idTableVariant, response.idGlobal)
            }})
        })
    }

    const openTabDocument = (idTableVariant: IdTableVariant, idGlobal: string) => {
        switch(idTableVariant){
            case IdTableVariant.ActDeduction : {
                return tabCtx.openChild('act_deduction_creator_plugin',"edit",idGlobal)                
            }
            case IdTableVariant.Movement : {
                return tabCtx.openChild('movement_creator_plugin',"edit",idGlobal)
            }
            case IdTableVariant.ActRevaluation2 : {
                return tabCtx.openChild('act_revaluation_creator_plugin',"edit",idGlobal)
            }
        }
    }

    const getAnalysisFilterColumns = () => {
        if (!openAccordion) return[]
        if (openAccordion === "lliquidLots") {
            return [
                {
                    name: 'dateCreated',
                    operator: "MoreOrEq",
                    value: analysisFilters?.lliquidLots.datePriod?.startDate?.toFormat('yyyyMMdd')
                },
                {
                    name: 'dateCreated',
                    operator: "LessOrEq",
                    value: analysisFilters?.lliquidLots.datePriod?.endDate?.toFormat('yyyyMMdd')
                },
                analysisFilters?.lliquidLots?.lifeLot > 0 && {
                    name: 'lifeLot',
                    operator: "MoreOrEq",
                    value: analysisFilters?.lliquidLots?.lifeLot
                },
                analysisFilters?.lliquidLots?.sales > 0 && {
                    name: 'sales',
                    operator: "LessOrEq",
                    value: analysisFilters?.lliquidLots?.sales
                },
                analysisFilters?.lliquidLots?.percentRemain > 0 && {
                    name: 'percentRemain',
                    operator: "MoreOrEq",
                    value: analysisFilters?.lliquidLots?.percentRemain
                },
            ] as IGridColumnFilter[]
        }
        if (openAccordion === "expiringDate") {
            return [
                analysisFilters?.expiringDate?.bestBeforeMonth > 0 && {
                    name: 'bestBeforeMonth',
                    operator: analysisFilters?.expiringDate?.compareBestBeforeMonth.value,
                    value: analysisFilters?.expiringDate?.bestBeforeMonth
                },
                analysisFilters?.expiringDate?.percentBestBefore > 0 && {
                    name: 'percentBestBefore',
                    operator: analysisFilters?.expiringDate?.comparePercentBestBefore.value,
                    value: analysisFilters?.expiringDate?.percentBestBefore
                },
            ] as IGridColumnFilter[]
        }
        if (openAccordion === "salesSpeed") {
            return [
                {
                    name: 'dateCreated',
                    operator: "MoreOrEq",
                    value: analysisFilters?.salesSpeed.datePriod?.startDate?.toFormat('yyyyMMdd')
                },
                {
                    name: 'dateCreated',
                    operator: "LessOrEq",
                    value: analysisFilters?.salesSpeed.datePriod?.endDate?.toFormat('yyyyMMdd')
                },
                {
                    name: 'isExcessStock',
                    operator: "Eq",
                    value: analysisFilters?.salesSpeed?.isExcessStock
                }
            ] as IGridColumnFilter[]
        }
        return []
    }

    useEffect(() => {
        if (!isAllSelect) setSelectedItemsInverion([])
    },[isAllSelect])

    useEffect(() => {
        if (viewState == 'refresh') {
            setViewState('view') 
            return
        }
        if (gridFilter.columnFilters.find(c => c.name === 'idStoreGlobal') === undefined) return

        setIsSubmitting(true)
        setSelectedItems([])
        const columnFilters = getAnalysisFilterColumns().filter(c => c.name !== undefined)

        let coorectGridFilter = {
            ...gridFilter, columnFilters: [...gridFilter.columnFilters, ...columnFilters]
        }

        setCorrectGridFilter(coorectGridFilter)

        lotDataProvider.getViewAnalysis(coorectGridFilter, (data, totalCount) => {
            setData(data)
            setTotalCount(totalCount)
            data.length > 0 ? setLoadingStatus(LoadingStatus.Completed) : setLoadingStatus(LoadingStatus.NoData)
            setIsSubmitting(false)
        })
    }, [viewState, gridFilter,openAccordion,analysisFilters])

    useEffect(() => {
        let found = data.find((d) => d.idGlobal === selectedItem?.idGlobal)
        setSelectedIdGoods(found?.idGoodsGlobal)
        setSelectedIdLot(found?.idGlobal)
    }, [selectedItem])

    useEffect(() => {
        pluginCtx.masterGrid.onUnselectEvent()
    }, [tabCtx.currentTab])

    return <>
        <PluginWrapper>
            <AnalyticalFilters
                onOpenAccorion={setOpenAccordion}
                setFilters={setAnalysisFilters}
            />
            <div className={classNames(styles.separator, styles.separator_horizontal)}></div>
            <ToolbarWrapper withoutMarginBottom={true}>
                <DefaultLotsByCommandsPanel
                    rootTranslation='accounting.lotsByLots.'
                    selectedIdGoods={selectedIdGoods}
                    selectedGoodsName={selectedItem?.goodsName}
                    selectedIdLot={selectedIdLot}
                    selectedItem={[selectedItem, setSelectedItem]}
                    dispatchGridFilter={dispatchGridFilter}
                    dataProvider={lotDataProvider}
                    goodsDataProvider={goodsDataProvider}
                    creatorModalJsx={LotsByLotsCreatorModal}
                    createGoodsModalJsx={GoodsCreatorModal}
                    pluginSettings={props.plugin}
                    setViewState={(vs) => setViewState(vs)}
                    idVariantTable={IdTableVariant.LotsByLots}
                    filters={getFiltersForPrint(pluginSettings,gridFilter)}
                    isHide
                    isDefaultStore
                />
            </ToolbarWrapper>
            <FlexRow className={stylesAnalysis.flexRow}>                
                <Button className={stylesAnalysis.button} variant={"primary"} disabled={isAllowCreateDocument} 
                    onClick={() => createDocument(IdTableVariant.ActDeduction)}>
                    {baseT('createActDeduction')}
                </Button>
                <Button className={stylesAnalysis.button} variant={"primary"} disabled={isAllowCreateDocument}
                    onClick={() => createDocument(IdTableVariant.Movement)}>
                    {baseT('createMovement')}
                </Button>
                <Button className={stylesAnalysis.button} variant={"primary"} disabled={isAllowCreateDocument}
                    onClick={() => createDocument(IdTableVariant.ActRevaluation2)}>
                    {baseT('createActRevaluation')}
                </Button>
            </FlexRow>
            <div><Checkbox checked={isAllSelect} label={baseT('selectAll')} onChange={setIsAllSelect} name={""} /></div>

            <div className={styles.pluginWorkspace}>
                <div className={styles.gridWrapper}>
                    <div className={styles.masterGrid}>
                        {
                            isSubmitting ? <Spinner /> :<DefaultGrid
                                gridId={props.gridId}
                                data={data}
                                quantityPosition
                                separator                                
                                singleDirectory
                                loadingStatus={loadingStatus}
                                setLoadingStatus={setLoadingStatus}
                                totalCount={totalCount}
                                filter={gridFilter}
                                plugin={props.plugin}
                                multipleSelect={multipleSelect}
                                selectedItems={selectedItems}
                                dataProvider={lotDataProvider}
                                getView={(gridFilter,callback) => {
                                    if (gridFilter.columnFilters.find(c => c.name === 'idStoreGlobal') === undefined) return
                                    lotDataProvider.getViewAnalysis(gridFilter, (entities) => {
                                        callback(entities)
                                        setSelectedItem(undefined);
                                    })
                                }}
                                selectedItem={selectedItem}
                                setPluginSettings={(plugin) => setPluginSettings(plugin)}
                                onSelect={(row) => {
                                    setSelectedItem(row)
                                    row
                                        ? pluginCtx.masterGrid.onSelectEvent(row, DocumentType.lots)
                                        : pluginCtx.masterGrid.onUnselectEvent()
                                }}
                                onMultipleSelect={(rows,currentRow) => {
                                    setSelectedItems(rows)
                                    onChangeMultipleSelect(rows, currentRow)
                                }}
                                onSort={(i) => { dispatchGridFilter({ type: "sort", payload: i.propertyName }) }}
                                onFilterDelete={(i) => { dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName }) }}
                                onPageNumberChange={(n) => { dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } }) }}
                                onNumberPerPageChange={(n) => { dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } }) }}
                                hiddenPagination={{ hiddenNumberPage: true, hiddenCountRow: true, selectAllItems: isAllSelect }}
                            />
                        }
                    </div>                    
                </div>
            </div>
        </PluginWrapper>
            {       showMessageModal.show &&
                    <MessageModalWindow
                        ok={{ onClick: () => { 
                            if (showMessageModal.handler) { 
                                showMessageModal.handler() 
                            }
                            setShowMessageModal({ show: false, message: '' }) 
                        }}}
                        cancel={{ onClick: () => {
                            setShowMessageModal({ show: false, message: '' })
                            } }}
                        message={showMessageModal.message}
                    />
            }
    </>
}

export default AnalysisLotPluginView