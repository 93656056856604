import { v4 as uuidv4 } from 'uuid';
import { PluginProvider } from '../../../system/providers/plugin';
import AnalysisLotGridPluginSettings from './AnalysisLotGridPluginSettings';
import AnalysisLotPluginView from './AnalysisLotPluginView';

export const AnalysisLotTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Анализ товарных остатков',
        view: {
            content:    
            AnalysisLotGridPluginSettings
                        ?   <PluginProvider plugin={AnalysisLotGridPluginSettings}>
                                <AnalysisLotPluginView gridId={uuidv4()} plugin={AnalysisLotGridPluginSettings}/>
                            </PluginProvider>
                        : 
                        <p> Справочник не настроен </p>
        }
    }
}

export default AnalysisLotTab
