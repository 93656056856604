import { FC } from "react";
import { TextInput } from "../../../../components/controls/inputs";
import styles from "../styles/CustomDiv.module.scss"

const { inputContainerStyles, marginStyles, textWidthStyles } = {
  inputContainerStyles: styles.inputContainer,
  textWidthStyles: styles.inputContainer_textWidth,
  marginStyles: styles.mr

};

interface CustomDivProps {
  startLabel: any
  valueInput: string
  endLabel: string
  onChange?: (value: any, name?: string) => void;
}

export const CustomDiv: FC<CustomDivProps> = ({ startLabel, valueInput = '0', endLabel, onChange }) => {
  return (
    <div className={inputContainerStyles} >
      <Label text={startLabel} />
      <div className={inputContainerStyles}>
        <div className={marginStyles}>
          <TextInput onChange={onChange} value={valueInput} className={textWidthStyles} />
        </div>
        <Label text={endLabel} />
      </div>
    </div>
  )
}

const Label = ({ text }) => (
  <div className={marginStyles}>{text}</div>
);