import axios from "axios"
import { IOpenMessageModal } from "./ImportPricingModelView";
import { envF } from "../../../env";
import renderGlobalAlert from "../../../system/hooks/useGlobalAlert";

export const importPricingRequest = async (
    files: IFile[] | null,
    setOpenMessage: (value: IOpenMessageModal) => void

) => {
    if (files) {
        const jwtToken = localStorage.getItem("TokenData") as string;
        const token = `Bearer ${JSON.parse(jwtToken).accessToken}`;
        const formData = new FormData;

        let filesBeSent = [...files];
        filesBeSent.forEach(x => {
            formData.append('Content', x.file)
        })

        const generateRequestUrl = (prefix: string, endPoint?: string) => {
            if (endPoint) {
                return `${envF.REACT_APP_API_GATEWAY_PATH}/${prefix}/${endPoint}`
            } else {
                return `${envF.REACT_APP_API_GATEWAY_PATH}/${prefix}`
            }
        }

        await axios.post(
            generateRequestUrl('ImportFiles', 'Pricing'),
            formData,
            {
                headers: {
                    Authorization: token
                },
            }
        ).then((e) => {
            if (e.status === 200) {
                setOpenMessage({ open: true, message: 'Модель ценообразования импортирована успешно' })
            }
        }).catch((ex) => {
            renderGlobalAlert({
                variant: "exception",
                title: JSON.parse(ex.response.data.title).title,
                detail: ex.response.data.detail,
                statusCode: 500,
            });
        })

    }
}


