import { FC, useEffect, useState } from "react";
import { CodeControlBaseProps } from "./CodeControlTab";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import BaseCommandsPanel from "../../../../components/commandsPanels/BaseCommandsPanel";
import Tooltip from "../../../../components/tooltips/Tooltip";
import { Delete2Button } from "../../../../components/buttons/iconButtons/action/MainCollection";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { v4 as uuidv4 } from 'uuid';
import { RegKeysListPluginSettings } from "../pluginSettings/RegKeysListPluginSettings";
import { IOption, Select } from "../../../../components/selects/select";
import { TextInput } from "../../../../components/controls/inputs";
import { Button } from "../../../../components/buttons/button";
import styles from "../styles/CodeControl.module.scss"
import stylesReg from "../styles/RegKeysList.module.scss"

const { fieldsetStyles, legendStyles, containerSelectStyles, containerButtonStyles } = {
    fieldsetStyles: styles.codeControl_fieldSet,
    legendStyles: styles.codeControl_legend,
    containerSelectStyles: stylesReg.contentSection_containerSelect,
    containerButtonStyles: stylesReg.contentSection_containerButton
};

export interface RegKeysListProps extends CodeControlBaseProps {

}
interface TableData {
    INN: string;
    Token: string;
    idGlobal: string;
}

export const RegKeysList: FC<RegKeysListProps> = ({ data, onChange }) => {
    const parseData = data && JSON.parse(data?.jsonData).Value;
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [selectedItem, setSelectedItem] = useState<IGridRow | undefined>(undefined);
    const [tableData, setTableData] = useState<TableData[]>([]);
    const [innAndTokenTodo, setInnAndTokenTodo] = useState<TableData>({
        INN: '', //Инн
        Token: '',//"Регистрациоонный ключ",
        idGlobal: ''
    })

    function addTodo() {
        const { idGlobal, ...rest } = innAndTokenTodo; //Удаление idGlobal
        onChange([...parseData?.InnAndToken, rest], 'InnAndToken');
        setInnAndTokenTodo({ INN: '', Token: '', idGlobal: '' });
    }

    useEffect(() => {
        if (parseData?.InnAndToken && parseData?.InnAndToken.length > 0) {
            setTableData(parseData?.InnAndToken.map(el => ({ ...el, idGlobal: uuidv4() })))
        }
    }, [data])

    return (
        <div className="container">
            <fieldset className={fieldsetStyles}>
                <legend className={legendStyles}>Активные регистрационные ключи</legend>

                <section className='content-section'>
                    <div className='panel-container'>
                        <BaseCommandsPanel
                            groups={[
                                [
                                    {
                                        iconButton: (
                                            <Tooltip title={'Удалить позицию'}>
                                                <Delete2Button
                                                    sizeVariant="mini"
                                                    colorVariant="danger"
                                                    disabled={!selectedItem}
                                                    onClick={() => {
                                                        if (selectedItem) {
                                                            const deleteItem = tableData.filter(el => el.idGlobal !== selectedItem.idGlobal);
                                                            setTableData(deleteItem);
                                                            onChange(deleteItem.map(({ idGlobal, ...rest }) => ({ ...rest })), 'InnAndToken')//Удаление idGlobal
                                                            setSelectedItem(undefined);
                                                        }
                                                    }}
                                                />
                                            </Tooltip>
                                        ),
                                    },
                                ],
                            ]}
                        />
                    </div>
                    <div className='grid-container' >
                        <DefaultGrid
                            gridId={uuidv4()}
                            data={tableData}
                            className="добавить высоту"
                            totalCount={0}
                            filter={gridFilter}
                            selectedItem={selectedItem}
                            plugin={RegKeysListPluginSettings as IPluginSettings}
                            onSelect={(row) => {
                                setSelectedItem(row)
                            }}
                            onSort={(i) => dispatchGridFilter({ type: 'sort', payload: i.propertyName })}
                            onFilterDelete={(i) => dispatchGridFilter({ type: 'deleteColumnFilter', payload: i.propertyName })}
                            onPageNumberChange={(n) => dispatchGridFilter({ type: 'changePageNumber', payload: { pageNumber: n } })}
                            onNumberPerPageChange={(n) => dispatchGridFilter({ type: 'changeNumberPerPage', payload: { numberPerPage: n } })} />
                    </div>
                </section>

                <section className="content-section">
                    <div className={containerSelectStyles}>
                        <TextInput value={innAndTokenTodo.INN} onChange={(INN) => setInnAndTokenTodo(prev => ({ ...prev, INN }))} label="ИНН:" />
                    </div>
                    <div className="container-input">
                        <TextInput value={innAndTokenTodo.Token} onChange={(Token) => setInnAndTokenTodo(prev => ({ ...prev, Token }))} label="Регистрационный ключ:" />
                    </div>
                    <div className={containerButtonStyles}>
                        <div className="add-button">
                            <Button onClick={addTodo} variant="secondary">Добавить в список</Button>
                        </div>
                    </div>
                </section>

            </fieldset>
        </div>
    )
}