import React, { FC, useEffect } from 'react'
import { Accordion } from "../../../../../../components/controls/accordion"
import styles from './styles/SettingsAdditionalModalContent.module.scss'
import { v4 as uuidv4 } from 'uuid';
import { DefaultGrid } from "../../../../../../components/grids/default/defaultGrid";
import { useContext, useState } from "react";
import useGridFilter from "../../../../../../system/hooks/useGridFilter";
import SettingsAdditionalPluginSettings from "./SettingsAdditionalPluginSettings";
import { usePluginContext } from "../../../../../../system/providers/plugin";
import { Checkbox } from '../../../../../../components/checkboxes/checkbox'
import { useTranslation } from 'react-i18next';
import { IAdditionalSettingsViewDTO, ICashRegisterCreate } from '../../../../../../libs/coreapi-dto/service/cashRegisters';
import FlexColumn from '../../../../../../components/controls/FlexColumn';
import { IOption, Select } from '../../../../../../components/selects/select';

interface ISettingsAdditionalModalContentProps {
    lockFromPermission?: boolean,
    cashRegisterDto: ICashRegisterCreate
    setRegisterDto: (dto: ICashRegisterCreate) => void;
}

export const SettingsAdditionalModalContent: FC<ISettingsAdditionalModalContentProps> = (props) => {
    const pluginCtx = usePluginContext();
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [totalCount, setTotalCount] = useState<number>(0);
    const [selectedItem, setSelectedItem] = useState<IGridRow>()
    const [selectAdditionalSetting,setSelectAdditionalSetting] = useState<IAdditionalSettingsViewDTO>()
   
    const { t } = useTranslation();

    const options = [
        {displayName: 'Не печатать', value: "Не печатать"},
        {displayName: 'Процент', value: "Процент"},
        {displayName: 'Сумма', value: "Сумма"},
    ] as IOption[]

    const [option,setOption] = useState<IOption>(options[0])
   
    const [additionalSettings, setAdditionalSettings] = useState<IAdditionalSettingsViewDTO[]>([]);

    useEffect(() => {
        const additionalSettingsModel =
        [
            {
                idGlobal: uuidv4(),
                setting: t("modals.additionalTab.enableMixPayment"),
                serialNumber: 1,
                value: props.cashRegisterDto.enableMixPayment ?? false
            },
            {
                idGlobal: uuidv4(),
                setting: t("modals.additionalTab.enableAcquiring"),
                serialNumber: 2,
                value: props.cashRegisterDto.enableAcquiring ?? false
            },
            {
                idGlobal: uuidv4(),
                setting: t("modals.additionalTab.enableProvisorInterface"),
                serialNumber: 3,
                value: props.cashRegisterDto.enableProvisorInterface ?? false
            },
            {
                idGlobal: uuidv4(),
                setting: t("modals.additionalTab.askBeforeChequePrint"),
                serialNumber: 4,
                value: props.cashRegisterDto.askBeforeChequePrint ?? false
            },
            {
                idGlobal: uuidv4(),
                setting: t("modals.additionalTab.printDiscountSettings"),
                serialNumber: 5,
                data: props.cashRegisterDto.printDiscountSettings ?? {
                    printDiscountForItem: false,
                    printPriceForItemWithoutDiscount: false, 
                    printSummDiscount: false,
                    printSummForChequeWithoutDiscount: false,
                    printSummForItemWithoutDiscount: false,
                    printTypeOfDiscount: options[0].value} 
            }] as IAdditionalSettingsViewDTO[]

        setAdditionalSettings(additionalSettingsModel)
    },[props.cashRegisterDto])

    useEffect(() =>{
        if (selectedItem) setSelectAdditionalSetting(additionalSettings.find(x => x.idGlobal === selectedItem?.idGlobal))
        else setSelectAdditionalSetting(undefined)
    },[selectedItem])    

    return (
        <div className={styles.wrapper}>
            <Accordion insertedAccordion className={styles.accardion} opened={true} caption='Настройка' id="additionalInvoiceInfo">
                <DefaultGrid
                    gridId={uuidv4()}
                    data={additionalSettings}
                    filter={gridFilter}
                    hiddenPagination={undefined}
                    totalCount={totalCount}
                    plugin={SettingsAdditionalPluginSettings}
                    selectedItem={selectedItem}
                    onSelect={(row) => {
                        setSelectedItem(row)
                    }}
                    onSort={(i) => { dispatchGridFilter({ type: "sort", payload: i.propertyName }) }}
                    onFilterDelete={(i) => { dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName }) }}
                    onPageNumberChange={(n) => { dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } }) }}
                    onNumberPerPageChange={(n) => {
                        dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })
                    }}
                />
            </Accordion>
            <Accordion insertedAccordion className={styles.accardion} opened={true} caption='Описание' id="additionalInvoiceInfo">

                <textarea value={selectedItem ? additionalSettings.find(x => x.idGlobal === selectedItem?.idGlobal)?.setting : ''} cols={120} rows={8} />
                    {
                        selectAdditionalSetting !== undefined && selectAdditionalSetting.data === undefined &&
                        <Checkbox 
                            checked={selectAdditionalSetting.value} 
                            name={selectAdditionalSetting?.setting} 
                            label={selectAdditionalSetting?.setting} 
                            onChange={(value) => {
                                setSelectAdditionalSetting({...selectAdditionalSetting, value: value})
                                props.setRegisterDto({...props.cashRegisterDto, 
                                    enableMixPayment: selectAdditionalSetting.serialNumber == 1 ? value : props.cashRegisterDto.enableMixPayment,
                                    enableAcquiring: selectAdditionalSetting.serialNumber == 2 ? value : props.cashRegisterDto.enableAcquiring,
                                    enableProvisorInterface: selectAdditionalSetting.serialNumber == 3 ? value : props.cashRegisterDto.enableProvisorInterface,
                                    askBeforeChequePrint: selectAdditionalSetting.serialNumber == 4 ? value : props.cashRegisterDto.askBeforeChequePrint,
                                })
                        }}/>
                    }
                    {
                        selectAdditionalSetting?.data !== undefined && selectAdditionalSetting.data &&

                        <FlexColumn>
                            <Checkbox 
                                checked={selectAdditionalSetting.data.printPriceForItemWithoutDiscount} 
                                name={t("modals.additionalTab.printPriceForItemWithoutDiscount")} 
                                label={t("modals.additionalTab.printPriceForItemWithoutDiscount")} 
                                onChange={(value) => {
                                    if (selectAdditionalSetting?.data)
                                        setSelectAdditionalSetting({...selectAdditionalSetting, data: {...selectAdditionalSetting.data, printPriceForItemWithoutDiscount: value}})

                                    props.setRegisterDto({...props.cashRegisterDto, 
                                        printDiscountSettings: {...props.cashRegisterDto.printDiscountSettings, printPriceForItemWithoutDiscount: value}})
                                }}
                            />

                            <Checkbox 
                                checked={selectAdditionalSetting.data.printSummForItemWithoutDiscount} 
                                name={t("modals.additionalTab.printSummForItemWithoutDiscount")} 
                                label={t("modals.additionalTab.printSummForItemWithoutDiscount")} 
                                onChange={(value) => {
                                    if (selectAdditionalSetting?.data)
                                        setSelectAdditionalSetting({...selectAdditionalSetting, data: {...selectAdditionalSetting.data, printSummForItemWithoutDiscount: value}})

                                    props.setRegisterDto({...props.cashRegisterDto, 
                                        printDiscountSettings: {...props.cashRegisterDto.printDiscountSettings, printSummForItemWithoutDiscount: value}})
                                }}
                            />

                            <Checkbox 
                                checked={selectAdditionalSetting.data.printSummDiscount} 
                                name={t("modals.additionalTab.printSummDiscount")} 
                                label={t("modals.additionalTab.printSummDiscount")} 
                                onChange={(value) => {
                                    if (selectAdditionalSetting?.data)
                                        setSelectAdditionalSetting({...selectAdditionalSetting, data: {...selectAdditionalSetting.data, printSummDiscount: value}})

                                    props.setRegisterDto({...props.cashRegisterDto, 
                                        printDiscountSettings: {...props.cashRegisterDto.printDiscountSettings, printSummDiscount: value}})
                                }}
                            />

                            <Checkbox 
                                checked={selectAdditionalSetting.data.printSummForChequeWithoutDiscount} 
                                name={t("modals.additionalTab.printSummForChequeWithoutDiscount")} 
                                label={t("modals.additionalTab.printSummForChequeWithoutDiscount")} 
                                onChange={(value) => {
                                    if (selectAdditionalSetting?.data)
                                        setSelectAdditionalSetting({...selectAdditionalSetting, data: {...selectAdditionalSetting.data, printSummForChequeWithoutDiscount: value}})

                                    props.setRegisterDto({...props.cashRegisterDto, 
                                        printDiscountSettings: {...props.cashRegisterDto.printDiscountSettings, printSummForChequeWithoutDiscount: value}})
                                }}
                            />

                            <Checkbox 
                                checked={selectAdditionalSetting.data.printDiscountForItem} 
                                name={t("modals.additionalTab.printDiscountForItem")} 
                                label={t("modals.additionalTab.printDiscountForItem")} 
                                onChange={(value) => {
                                    if (selectAdditionalSetting?.data)
                                        setSelectAdditionalSetting({...selectAdditionalSetting, data: {...selectAdditionalSetting.data, printDiscountForItem: value}})

                                    props.setRegisterDto({...props.cashRegisterDto, 
                                        printDiscountSettings: {...props.cashRegisterDto.printDiscountSettings, printDiscountForItem: value}})
                                }}
                            />
                            
                            <Select
                                className={styles.select}
                                onSelect={(option) => {
                                    props.setRegisterDto({...props.cashRegisterDto, 
                                        printDiscountSettings: {...props.cashRegisterDto.printDiscountSettings, printTypeOfDiscount: option.value}})
                                }}
                                value={{
                                        displayName: props.cashRegisterDto?.printDiscountSettings?.printTypeOfDiscount ?? options[0].displayName,
                                        value: props.cashRegisterDto?.printDiscountSettings?.printTypeOfDiscount ?? options[0].value
                                    }}
                                options={options}

                            />
                        </FlexColumn>
                    }
               
            </Accordion>
        </div>
    )
}