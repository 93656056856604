import { SettingTimePluginSettings } from '../pluginSettings/SettingTimePluginSettings';
import {v4 as uuidv4} from 'uuid';


export const SettingTimeTab = (): ITab => {
    return {
      id: uuidv4(),
      title: SettingTimePluginSettings.name,
      view: {
        content: <></>
      }
    }
  }