import { FC, useEffect, useState } from "react"
import { AccessPoint2SupplierDataProvider } from "../../../../Services/DataProviders/AccessPoint2SupplierDataProvider"
import { usePluginContext } from "../../../../system/providers/plugin";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { IAccessPoint2SupplierDTO } from "../../../../libs/coreapi-dto/dirs/accessPoint2Supplier";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { DocumentType, LoadingStatus } from "../../../../@types/enumsGlobal";
import { PluginWrapper, ToolbarWrapper } from "../../../../components/plugins";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { AccessPointDetailCommandPanel } from "./AccessPointDetailCommandPanel";
import GridSelectorInput from "../../../../components/controls/GridSelectorInput";
import { ContractorSelectorModal } from "../../../Dictionaries/Contractor";
import styles from "../Styles/AccessPointView.module.scss";
import { AccessPointDetailEditModal } from "./AccessPointDetailEditModal";
import { checkLockStatus } from "../../../CommonHelperFunctions";
import { useUserContext } from "../../../../system/providers/userContextProvider";

export const AccessPointDetailPluginView: FC<IGridProps> = (props) => {
    const pluginCtx = usePluginContext();
    const appContext = useAppContext()
    const accessPoint2SupplierDataProvider = new AccessPoint2SupplierDataProvider(appContext.coreApiService)

    const [editModal, setEditModal] = useState<boolean>(false);
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [idAccessPointGlobal, setIdAccessPointGlobal] = useState<string>();
    const [data, setData] = useState<IAccessPoint2SupplierDTO[]>([]);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.InProcess);
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    
    const userContext = useUserContext();
    const lockFromPermission = checkLockStatus(props.plugin.permission as IPermission, userContext.userPermission);
    
    useEffect(() => {
        if (viewState === 'refresh') {
            setLoadingStatus(LoadingStatus.InProcess)            
            dispatchGridFilter({ type: "showDeleted", payload: false })
            accessPoint2SupplierDataProvider.getViewById(idAccessPointGlobal as string, gridFilter, async (data, totalCount) => {
                setData(data)
                setViewState('view')
                setLoadingStatus(LoadingStatus.Completed)
            })
        }
    }, [viewState])

    useEffect(() => {
        console.log("idAccessPointGlobal: ", idAccessPointGlobal)
        if (pluginCtx.masterGrid.selectedItem) {
            accessPoint2SupplierDataProvider.getViewById(pluginCtx.masterGrid.selectedItem?.idGlobal as string, gridFilter, (data) => {
                if (pluginCtx.masterGrid.document === DocumentType.accessPoint)
                    setIdAccessPointGlobal(pluginCtx.masterGrid.selectedItem?.idGlobal)

                setData(data);
                setLoadingStatus(LoadingStatus.Completed)
            });
        }
    }, [pluginCtx.masterGrid.selectedItem?.idGlobal, gridFilter]);

    useEffect(() => {
        setSelectedItem(undefined)
    }, [idAccessPointGlobal])

    return (
        <>
            <PluginWrapper>
                <ToolbarWrapper>
                    <AccessPointDetailCommandPanel
                        edit={{
                            disabled: selectedItem === undefined || selectedItem?.isDeleted,
                            onClick: () => {
                                setEditModal(true)
                            }
                        }}
                        delete={{
                            disabled: selectedItem === undefined || selectedItem?.isDeleted,
                            onClick: () => {
                                accessPoint2SupplierDataProvider.markDelete(selectedItem?.idGlobal as string, (e) => {
                                    if (e.respType=== 'isCompleted') setViewState('refresh')
                                        else setViewState('return')
                                })
                            }
                        }}                        
                        restore={{
                            onClick: () => {
                                accessPoint2SupplierDataProvider.restore(selectedItem?.idGlobal as string, (e) => {
                                    if (e.respType=== 'isCompleted') setViewState('refresh')
                                        else setViewState('return')
                                })
                            },
                            disabled: selectedItem === undefined || !selectedItem.isDeleted
                        }}                        
                        showDeleted={{
                            disabled: false,
                            onChecked: (e) => {
                                dispatchGridFilter({ type: "showDeleted", payload: e })
                                setViewState("filter")
                            }
                        }}
                        permission={props.plugin.permission}
                    />
                </ToolbarWrapper>
                <GridSelectorInput
                    variant="required"
                    selectorModalJsx={ContractorSelectorModal}
                    id={"AccessPointDetailSupplier"}
                    label={"Поставщик"}
                    className={styles.test}
                    onSelect={(value) => {
                        console.log(value)
                        let newData: IAccessPoint2SupplierDTO = {
                            idAccessPointGlobal: idAccessPointGlobal as string,
                            idSupplierGlobal: value.idGlobal as string,
                            supplierName: value.displayName as string,
                            connectionString: ""
                        }
                        accessPoint2SupplierDataProvider.create(newData, (idGlobal) => {
                            setViewState('refresh')
                        })
                    }}
                    onFocus={() => console.log("onFocus")}
                    treeViewCheckDirectoryType='supplier'
                />
                <DefaultGrid
                    gridId={props.gridId}
                    data={data}
                    filter={gridFilter}
                    hiddenPagination={undefined}
                    totalCount={data.length}
                    loadingStatus={loadingStatus}
                    plugin={props.plugin}
                    selectedItem={selectedItem}
                    onSelect={(row) => {
                        setSelectedItem(row)
                    }}
                    onSort={(i) => {
                        dispatchGridFilter({ type: "sort", payload: i.propertyName })
                    }}
                    onFilterDelete={(i) => {
                        dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })
                    }}
                    onPageNumberChange={(n) => {
                        dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })
                    }}
                    onNumberPerPageChange={(n) => {
                        dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })
                    }}
                />
            {
                editModal &&
                <AccessPointDetailEditModal                       
                    cancel={() => {
                        setEditModal(false)
                        setViewState('refresh')
                        setSelectedItem(undefined)
                    }}
                    idGlobal={selectedItem?.idGlobal as string}
                    idAccessPointGlobal={idAccessPointGlobal as string}
                    lockFromPermission={lockFromPermission}
                />
            }
         </PluginWrapper>
        </>
    )
}