import { FC, useState, useEffect } from "react";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { IDocumentCreatorProps } from "../../../../@types/documents";
import { useTabsContext } from "../../../../system/providers/tabsProvider";
import { InvoiceDataProvider } from "../../../../Services/DataProviders/InvoiceDataProvider";
import InvoiceUpdateForm from "./InvoiceUpdateForm";
import { IInoviceUpdateDTO, IInvoiceCreateDTO, IInvoiceGetDTO } from "../../../../libs/coreapi-dto/documents/invoice";
import { getInvoiceItemUpdateDTO } from "../../../../libs/core-api-requests/customRequest/InvoiceItemUpdateRequest";
import { IInvoiceItemEditDTO, IInvoiceItemGetDTO } from "../../../../libs/coreapi-dto/documents/invoiceItem";
import { v4 as uuidv4 } from "uuid";
import { useTestApiContext } from "../../../../system/providers/testApiProvider";
import { DateTime } from "luxon";

interface ICreateDocument {
    createCallback: (invoice) => void;
    saveCallback: (invoice) => void;
}

interface IEditDocument {
    id: string | undefined;
    document: any;
    documentItems: any;
    documentUpdate: any;
}

interface ICopyDocument {
    id: string | undefined;
    document: any;
    documentItems: any;
    documentUpdate: any;
}

const InvoiceCreatorView: FC<IDocumentCreatorProps<IInvoiceCreateDTO>> = (props) => {
    const appContext = useAppContext();
    const tabsContext = useTabsContext();
    const testApiContext = useTestApiContext();

    const idp = new InvoiceDataProvider(appContext.coreApiService);
    const [viewState, setViewState] = useState<GridStateType>(props.variant);
    const [id, setId] = useState<string | undefined>(props.idGlobal);

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [createDocument, setCreateDocument] = useState<ICreateDocument | null>(null);
    const [editDocument, setEditDocument] = useState<IEditDocument | null>(null);
    const [copyDocument, setCopyDocument] = useState<ICopyDocument | null>(null);

    useEffect(() => {
        if (viewState === "create") {
            const createCallback = (invoice) => {
                setIsSubmitting(true);
                if (props.isTest) {
                    idp.create(
                        invoice,
                        (id) => {
                            setId(id);
                            props.createdCallback?.();
                            setViewState("create");
                            tabsContext.close(tabsContext.tabs.find((x) => x.id === props.tabId) as ITab);
                            setIsSubmitting(false);
                        },
                        (value) => {
                            testApiContext.setApiInfo(value);
                        }, (e)=>{
                            setIsSubmitting(false);
                        }
                    );
                } else {
                    idp.create(invoice, (id) => {
                        setId(id);
                        props.createdCallback?.();
                        setViewState("create");
                        tabsContext.currentTab && tabsContext.closeCurrent();
                        setIsSubmitting(false);
                    }, ()=>{}, (e)=>{
                        setIsSubmitting(false);
                    });
                }
            };
            const saveCallback = (invoice) => {
                setIsSubmitting(true);
                idp.create(invoice, (id) => {
                    setId(id);
                    setViewState("edit");
                    props.createdCallback?.();
                    setIsSubmitting(false);
                }, ()=>{}, (e)=>{
                    setIsSubmitting(false);
                });
            };
            setCreateDocument({
                createCallback: createCallback,
                saveCallback: saveCallback,
            });
        }

        if (viewState === "edit") {
            idp.getById(id as string, async (invoiceDocument) => {
                getInvoiceItemUpdateDTO(invoiceDocument.idGlobal as string, (e) => {
                    const itemsUpdate: IInvoiceItemEditDTO[] = [];
                    e.forEach((item) => {
                        itemsUpdate.push({ ...item });
                    });

                    const documentItems: IInvoiceItemGetDTO[] = [];
                    invoiceDocument.items.forEach((item, index) => {
                        documentItems.push({ ...item, isGnvls: e[index].isGnvls });
                    });

                    const documentUpdate: IInoviceUpdateDTO = {
                        idGlobal: invoiceDocument.idGlobal,
                        documentDate: invoiceDocument.documentDate,
                        idSupplierGlobal: invoiceDocument.supplier.idGlobal,
                        idStoreGlobal: invoiceDocument.store.idGlobal,
                        idGosContractExternalGlobal: invoiceDocument.gosContract?.idGlobal ?? undefined,
                        idPricingModelGlobal: invoiceDocument.pricingModel.idGlobal,
                        supplierDocNumber: invoiceDocument.supplierDocNumber,
                        supplierDocDate: invoiceDocument.supplierDocDate,
                        supplierBillDocNumber: invoiceDocument.supplierBillDocNumber,
                        supplierBillDocDate: invoiceDocument.supplierBillDocDate,
                        payerName: invoiceDocument.payerName,
                        datePay: invoiceDocument.datePay,
                        items: itemsUpdate,
                    };
                    setEditDocument({
                        id: id,
                        document: invoiceDocument,
                        documentItems: documentItems,
                        documentUpdate: documentUpdate,
                    });
                });
            });
        }

        if (viewState === "copy") {
            idp.getById(id as string, (invoiceDocument) => {
                getInvoiceItemUpdateDTO(invoiceDocument.idGlobal as string, (e) => {
                    const itemsUpdate: IInvoiceItemEditDTO[] = [];
                    const itemsInvoiceDocumentCopy: IInvoiceItemGetDTO[] = [];
                    e.forEach((item) => {
                        const idGlobalNew = uuidv4();
                        itemsUpdate.push({
                            idGlobal: idGlobalNew,
                            idGoodsGlobal: item.idGoodsGlobal,
                            idScalingRatioGlobal: item.idScalingRatioGlobal,
                            idSeriesGlobal: item.idSeriesGlobal,
                            quantity: item.quantity,
                            producerPrice: item.producerPrice,
                            productMargin: item.productMargin,
                            supplierCostInfo: item.supplierCostInfo,
                            retailCostInfo: item.retailCostInfo,
                            supplierGoodsCode: item.supplierGoodsCode,
                            gtdNumber: item.gtdNumber,
                            barCode: item.barCode,
                            registerPrice: item.registerPrice,
                            isKiz: item.isKiz,
                            kizCount: undefined,
                            kizBoxCount: undefined,
                            kizBoxes: [],
                            kizs: [],
                            denominator: item.denominator,
                            numerator: item.numerator,
                            dateCreated: DateTime.now()
                        });
                        const itemFound = invoiceDocument.items.find((x) => x.idGlobal === item.idGlobal);
                        if (itemFound) {
                            itemsInvoiceDocumentCopy.push({
                                idGlobal: idGlobalNew,
                                goods: itemFound.goods,
                                scalingRatio: itemFound.scalingRatio,
                                series: itemFound.series,
                                quantity: itemFound.quantity,
                                producerPrice: itemFound.producerPrice,
                                productMargin: itemFound.productMargin,
                                supplierCostInfo: itemFound.supplierCostInfo,
                                retailCostInfo: itemFound.retailCostInfo,
                                supplierGoodsCode: itemFound.supplierGoodsCode,
                                gtdNumber: itemFound.gtdNumber,
                                barCode: itemFound.barCode,
                                registerPrice: itemFound.registerPrice,
                                isKiz: itemFound.isKiz,
                                isKizBox: itemFound.isKizBox,
                                kizBoxCount: itemFound.kizBoxCount,
                                kizCount: itemFound.kizCount,
                                denominator: itemFound.denominator,
                                numerator: itemFound.numerator,
                                dateCreated: DateTime.now(),
                                bestBefore: itemFound.bestBefore,
                                seriesNumber: itemFound.seriesNumber
                            });
                        }
                    });
                    const idGlobalNew = uuidv4();
                    const documentUpdate: IInoviceUpdateDTO = {
                        idGlobal: idGlobalNew,
                        documentDate: invoiceDocument.documentDate.toF3FixedZoneNow(),
                        idSupplierGlobal: invoiceDocument.supplier.idGlobal,
                        idStoreGlobal: invoiceDocument.store.idGlobal,
                        idPricingModelGlobal: invoiceDocument?.pricingModel?.idGlobal,
                        payerName: invoiceDocument.payerName,
                        items: itemsUpdate,
                    };
                    const invoiceDocumentCopy: IInvoiceGetDTO = {
                        idGlobal: idGlobalNew,
                        documentDate: invoiceDocument.documentDate.toF3FixedZoneNow(),
                        displayName: invoiceDocument.displayName,
                        documentState: invoiceDocument.documentState,
                        documentStateModified: invoiceDocument.documentStateModified,
                        mnemocode: '',
                        supplier: invoiceDocument.supplier,
                        store: invoiceDocument.store,
                        pricingModel: invoiceDocument.pricingModel,
                        averagePercentSumIncVat: invoiceDocument.averagePercentSumIncVat,
                        payerName: invoiceDocument.payerName,
                        items: itemsInvoiceDocumentCopy,
                        retailCostInfo: invoiceDocument.retailCostInfo,
                        supplierCostInfo: invoiceDocument.supplierCostInfo,
                    };
                    setCopyDocument({
                        id: id,
                        document: invoiceDocumentCopy,
                        documentItems: invoiceDocumentCopy.items,
                        documentUpdate: documentUpdate,
                    });
                });
            });
        }
    }, [viewState]);

    return (
        <>
            {viewState === "create" ? (
                createDocument && (
                    <InvoiceUpdateForm
                        isSubmitting={isSubmitting}
                        document={null}
                        documentItems={null}
                        documentUpdate={null}
                        isTest={props.isTest}
                        variant="create"
                        ok={(invoice) => {
                            createDocument?.createCallback(invoice);
                        }}
                        save={(invoice) => {
                            createDocument?.saveCallback(invoice);
                        }}
                        cancel={() => {
                            tabsContext.closeCurrent();
                        }}
                        permission={props.permission}
                    />
                )
            ) : viewState === "edit" ? (
                editDocument && (
                    <InvoiceUpdateForm
                        isSubmitting={isSubmitting}
                        idGlobal={editDocument.id}
                        variant="edit"
                        document={editDocument.document}
                        documentItems={editDocument.documentItems}
                        documentUpdate={editDocument.documentUpdate}
                        ok={(invoice) => {
                            setIsSubmitting(true);
                            idp.update(id as string, invoice, () => {
                                props.createdCallback?.(invoice);
                                tabsContext.closeCurrent();
                                setIsSubmitting(false);
                            });
                        }}
                        save={(invoice) => {
                            setIsSubmitting(true);
                            idp.update(id as string, invoice, () => {
                                props.createdCallback?.(invoice);
                                setIsSubmitting(false);
                            });
                        }}
                        cancel={() => {
                            tabsContext.closeCurrent();
                        }}
                        permission={props.permission}
                    />
                )
            ) : viewState === "copy" ? (
                copyDocument && (
                    <InvoiceUpdateForm
                        isSubmitting={isSubmitting}
                        variant="copy"
                        idGlobal={copyDocument.id}
                        document={copyDocument.document}
                        documentItems={copyDocument.documentItems}
                        documentUpdate={copyDocument.documentUpdate}
                        ok={(invoice) => {
                            setIsSubmitting(true);
                            idp.create(invoice, (id) => {
                                setId(id);
                                props.createdCallback?.();
                                tabsContext.currentTab && tabsContext.close(tabsContext.currentTab);
                                setIsSubmitting(false);
                            }, ()=>{}, (e)=>{
                                setIsSubmitting(false);
                            });
                        }}
                        save={(invoice) => {
                            setIsSubmitting(true);
                            idp.create(invoice, (id) => {
                                setId(id);
                                setViewState("edit");
                                props.createdCallback?.();
                                setIsSubmitting(false);
                            }, ()=>{}, (e)=>{
                                setIsSubmitting(false);
                            });
                        }}
                        cancel={() => {
                            tabsContext.currentTab && tabsContext.close(tabsContext.currentTab);
                        }}
                        permission={props.permission}
                    />
                )
            ) : (
                <></>
            )}
        </>
    );
};

export default InvoiceCreatorView;
