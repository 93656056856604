import React, { PropsWithChildren, useState } from "react"
import { KizExchangeFilteringPanel } from "../../Business/Accounting/KizExchangeStatus/FilteringPanel/KizExchangeFilteringPanel"
import { IKizExchangeColumnFilter, IKizParamFilter } from "../../Business/Accounting/KizExchangeStatus/KizExchangeGrid"
import FlexColumn from "../controls/FlexColumn"
import { IOption } from "../selects/select"
import { GridFilterAction } from "../../system/hooks/useGridFilter"

interface IPanelEvent{
    disabled?: boolean
    onClick:() => void
}

interface IDefaultKizExchangePanelProps {
    searchInput: [string | undefined, React.Dispatch<React.SetStateAction<string | undefined>>]
    kizExchangeColumnFilter: [IKizExchangeColumnFilter, (value: IKizExchangeColumnFilter, update: boolean) => void]
    filterBy: [IOption | undefined, React.Dispatch<React.SetStateAction<IOption | undefined>>]
    autoFilter: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
    kizParamFilter: [IKizParamFilter, (value: IKizParamFilter) => void]
    withinRange: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
    dispatchGridFilter: (value: GridFilterAction) => void,
    search?: IPanelEvent
    clearFilters?: IPanelEvent
    onWithinRange: (value)=>void
}

const KizExchangeCommandPanel = (props: PropsWithChildren<IDefaultKizExchangePanelProps>) => {

    const [modalWindow] = useState(<></>)
    return (
        <>
            <KizExchangeFilteringPanel
                searchInput={props.searchInput[0]}
                setSearchInput={(value: string) => props.searchInput[1](value)}
                kizColumnFilter={props.kizExchangeColumnFilter[0]}
                setKizColumnFilter={props.kizExchangeColumnFilter[1]}
                onFilterByChange={props.filterBy[1]}
                autoFilter={props.autoFilter[0]}
                kizParamFilter={props.kizParamFilter[0]}
                setKizParamFilter={props.kizParamFilter[1]}
                filterBy={props.filterBy[0]}
                onAutoFilterChange={props.autoFilter[1]}
                withinRange={props.withinRange[0]}
                onWithinRange={props.withinRange[1]}
                searchOption ={props.search}
                clearFilters = {props.clearFilters}
                onWithinRangeFunc={props.onWithinRange}
                dispatchGridFilter={props.dispatchGridFilter}
            />
            {modalWindow}
        </>
    )
}

export default KizExchangeCommandPanel