import _ from "lodash";
import { FC, useContext, useEffect, useState } from "react";
import { GoodsCodeCreatorModal } from ".";
import { LoadingStatus } from "../../../@types/enumsGlobal";
import DefaultDictionaryCommandsPanel from "../../../components/commandsPanels/DefaultDictionaryCommandsPanel";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins";
import { Spinner } from "../../../components/spiner/Spinner";
import { IGoodsCodeViewDTO } from "../../../libs/coreapi-dto/dirs/goodsCode";
import { GoodsCodeDataProvider } from "../../../Services/DataProviders/GoodsCodeDataProvider";
import useGridFilter, { CreateSelectorGridFilter } from "../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { useUserContext } from "../../../system/providers/userContextProvider";
import { checkAccessStatus } from "../../CommonHelperFunctions";


const GoodsCodeGrid: FC<IGridProps> = (props) => {
    const appCtx = useAppContext()
    const goodsCodeDataProvider = new GoodsCodeDataProvider(appCtx.coreApiService)
    const userContext = useUserContext()
    const [data, setData] = useState<IGoodsCodeViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [totalCount, setTotalCount] = useState(0);
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [gridFilter, dispatchGridFilter] = useGridFilter(CreateSelectorGridFilter);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);

    useEffect(() => {
        setIsSubmitting(true)
        if (checkAccessStatus(props.plugin.permission as IPermission, userContext.userPermission)) {
            goodsCodeDataProvider.getView(gridFilter, (data, totalCount) => {
                setData(data)
                setTotalCount(totalCount)
                data.length > 0 ? setLoadingStatus(LoadingStatus.Completed) : setLoadingStatus(LoadingStatus.NoData)
                setIsSubmitting(false)
            })
        } else {
            setLoadingStatus(LoadingStatus.NoAccess)
            setIsSubmitting(false)
        }

    }, [viewState, gridFilter])

    return (
        <PluginWrapper>
            {
                checkAccessStatus(props.plugin.permission as IPermission, userContext.userPermission) && <ToolbarWrapper>
                    <DefaultDictionaryCommandsPanel
                        selectedItem={[selectedItem, setSelectedItem]}
                        gridFilter={[gridFilter, dispatchGridFilter]}
                        dataProvider={goodsCodeDataProvider}
                        creatorModalJsx={GoodsCodeCreatorModal}
                        pluginSettings={props.plugin}
                        setViewState={(vs) => setViewState(vs)}

                    />
                </ToolbarWrapper>
            }
            {
                isSubmitting ? <Spinner /> : <DefaultGrid
                    gridId={props.gridId}
                    data={data}
                    loadingStatus={loadingStatus}
                    setLoadingStatus={setLoadingStatus}
                    totalCount={totalCount}
                    filter={gridFilter}
                    dataProvider={goodsCodeDataProvider}
                    plugin={props.plugin}
                    selectedItem={selectedItem}
                    singleDirectory
                    onSelect={(row) => {
                        setSelectedItem(row)
                        props.onSelect?.(row)
                    }}
                    onDoubleClick={(i) => props.onDoubleClick?.(i)}
                    onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                    onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                    onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                    onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                    onEnter={() => setViewState("edit")}
                />
            }
        </PluginWrapper>
    )
}

export default GoodsCodeGrid