import { FC, useState } from "react";
import { CodeControlBaseProps } from "./CodeControlTab";
import { IOption, Select } from "../../../../components/selects/select";
import { CheckBox } from "../../../../components/controls/checkbox";
import { v4 as uuidv4 } from 'uuid';
import { TextAreaInput, TextInput } from "../../../../components/controls/inputs";
import { CustomDiv } from "./CustomDiv";
import styles from "../styles/CodeControl.module.scss"
import stylesBasic from "../styles/BasicSettings.module.scss"

const { fieldsetStyles, legendStyles, checkboxMarginStyles, checkboxAndInput } = {
    fieldsetStyles: styles.codeControl_fieldSet,
    legendStyles: styles.codeControl_legend,
    checkboxMarginStyles: stylesBasic.basicSettingsStyles_checkbox,
    checkboxAndInput: stylesBasic.basicSettingsStyles_checkboxAndInput,
};

export interface BasicSettingsProps extends CodeControlBaseProps {

}
export const BasicSettings: FC<BasicSettingsProps> = ({ data, onChange }) => {
    const parseData = data && JSON.parse(data?.jsonData).Value;
    const options: IOption[] = [{ displayName: 'Разрешать подбор в чек', value: '1' }, { displayName: 'Запрещать подбор в чек', value: '0' }];
    const [searchOption, setSearchOption] = useState<IOption>(() => getSearchOption(parseData?.ErrorsActions));

    function getSearchOption(value?: string): IOption {
        return options.find(option => option.value === value) ?? options[0];
    }


    return (
        <div className="container">

            <section className="content-section">
                <CheckBox
                    id={uuidv4()}
                    onChanged={(value) => onChange(value ? '1' : '0', 'IsPermissionRegimeMdlpOn')}
                    defaultChecked={parseData?.IsPermissionRegimeMdlpOn === '1' ? true : false}
                    className={checkboxMarginStyles}
                    label="Использовать контроль кодов маркировки перед продажей"
                />
            </section>

            <section className="content-section">
                <fieldset className={fieldsetStyles}>
                    <legend className={legendStyles}>Настройки соединения с ГИС МТ</legend>
                    <div className="input-container">
                        <TextInput onChange={(value) => onChange(value, 'StandV4Url')} value={parseData?.StandV4Url} inline label="Базовый адрес сервиса (V4)" />
                        <TextInput onChange={(value) => onChange(value, 'RegistrationKey')} value={parseData?.RegistrationKey} inline label="Регистрационный ключ" />
                    </div>
                </fieldset>
            </section>

            <section className="content-section">
                <fieldset className={fieldsetStyles}>
                    <legend className={legendStyles}>Контроль срока годности</legend>
                    <div className="controls-container">
                        <div className="checkbox-container">
                            <CheckBox
                                id={uuidv4()}
                                onChanged={(value) => onChange(value ? '1' : '0', 'IsControlValidDate')}
                                defaultChecked={parseData?.IsControlValidDate === '1' ? true : false}
                                className={checkboxMarginStyles}
                                label="Проверять срок годности"
                            />
                        </div>
                        <CustomDiv valueInput={parseData?.HoursStopSaleUnvalidDate} onChange={(value) => onChange(value, 'HoursStopSaleUnvalidDate')} startLabel={'Запрещать продажу за'} endLabel={'часов до окончания смены.'} />
                    </div>
                </fieldset>
            </section>

            <section className="content-section">
                <div className="select-container">
                    <Select
                        inline
                        defaultOption={searchOption}
                        value={searchOption}
                        options={options}
                        label="Действие в случае непредвиденных ошибок"
                        onSelect={(option: IOption) => {
                            setSearchOption(option);
                            onChange(option.value, 'ErrorsActions')
                        }}
                    />
                </div>
            </section>

            <section className="content-section">
                <fieldset className={fieldsetStyles}>
                    <legend className={legendStyles}>Аварийный режим работы</legend>
                    <div className="controls-container" >
                        <div className={checkboxAndInput}>
                            <div className="checkbox-container">
                                <CheckBox
                                    id={uuidv4()}
                                    onChanged={(value) => onChange(value ? '1' : '0', 'IsEmergencyModeOn')}
                                    defaultChecked={parseData?.IsEmergencyModeOn === '1' ? true : false}
                                    className={checkboxMarginStyles}
                                    label="Аварийный режим"
                                />
                            </div>
                            <CustomDiv valueInput={parseData?.DisableGisMtRequestPeriod} onChange={(value) => onChange(value, 'DisableGisMtRequestPeriod')} startLabel={'Отключить проверку КИЗов на:'} endLabel={'ч.'} />
                        </div>
                        <div className="text-container">
                            <TextAreaInput value={parseData?.JustificationEmergencyText} onChange={(value) => onChange(value, 'JustificationEmergencyText')} label="Введите текст обоснования введения / отключения аварийного режима от ЧЗ" />
                        </div>
                    </div>
                </fieldset>
            </section>

        </div>
    )
}