import { Checkbox as CheckboxAnt, InputNumber, Radio, RadioChangeEvent, Space } from "antd"
import { DateTime } from "luxon"
import { FC, useEffect, useState } from "react"
import { Checkbox } from "../../../../components/checkboxes/checkbox"
import GridWrapper from "../../../../components/controls/GridWrapper"
import { DateInput, TextInput } from "../../../../components/controls/inputs"
import PluginGridSelectorInput from "../../../../components/controls/PluginGridSelectorInput"
import { IDatePeriod, MaxDate, MinDate } from "../../../../components/datePeriodPicker/datePeriodPicker"
import BaseDocumentCreator from "../../../../components/documents/baseDocumentCreator"
import { IOption, Select } from "../../../../components/selects/select"
import { DatePeriodPanel } from "../../../../hoc/DatePeriodPanel/DatePeriodPanel"
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common"
import { useAppContext } from "../../../../system/providers/appContextProvider"
import { useUserContext } from "../../../../system/providers/userContextProvider"
import styles from "../../styles/ReportViewer.module.scss"
import { ReportFilterDataType } from "../../Shared/Enums/ReportFilterDataType"
import { ReportFilter } from "../../Shared/ReportFilter"
import { ReportFilterGridPluginSettings } from "./ReportFilterGridPluginSettings"
import ReportFilterStyle from './Styles/ReportFilterCreator.module.scss'
import { GridSettings } from "../../../../components/selects/gridSettings"
import { v4 as uuidv4 } from 'uuid'
import renderGlobalAlert from "../../../../system/hooks/useGlobalAlert"
import { ReportFilterAdpriceGridPluginSettings } from "./ReportFilterAdpriceGridPluginSettings"
import { ReportsDateTimePeriodPanel } from "../../../../hoc/DatePeriodPanel/ReportsDateTimePeriodPanel"
import moment from "moment"
import { isNullOrWhiteSpace } from "../../Common/CommonFunctions"

interface IReportFilterCreator {
    filters: ReportFilter[]
    asmName: string
    userId: string
    visibleFilters: ReportFilter[]
    cancel: () => void
    process: () => void
}

interface IAdprice {
    idGlobal: string,
    adprice: number,
    highAdprice: number,
    lowAdprice: number
}

export const ReportFilterCreator: FC<IReportFilterCreator> = (props) => {
    const appCtx = useAppContext()
    const userCtx = useUserContext()
    const [filters, setFilters] = useState<Array<ReportFilter>>(props.filters)
    const defaultDisplaytems: IAdprice = { idGlobal: uuidv4(), adprice: 0, highAdprice: 0, lowAdprice: 0 }
    const [selectedItem, setSelectedItem] = useState<IGridRow | undefined>();

    const getDisplayItems = () => {
        let filter = filters.find(c => c.dataType === ReportFilterDataType.Grid)
        if (!filter)
            return []
        return filter?.values?.length === 0 ? [defaultDisplaytems] : JSON.parse(filter.values.toString()) as IAdprice[];
    }

    const [displayItems, setDisplayItems] = useState<IAdprice[]>(getDisplayItems());

    useEffect(() => {
        const filter = filters.find(c => c.name === 'adprices')
        if (filter === undefined) return
        filter.values = [JSON.stringify(displayItems)]
    }, [displayItems])

    const deleteRowtems = () => {
        if (displayItems.length === 1) return;
        if (selectedItem?.idGlobal) {
            setDisplayItems([...displayItems.filter(c => c.idGlobal != selectedItem?.idGlobal)])
        }
        else {
            setDisplayItems([...displayItems.filter(c => c.idGlobal !== displayItems[displayItems.length - 1].idGlobal)])
        }
    }

    const validateDisplayAdprice = () => {
        let lastRow = displayItems[displayItems.length - 1]
        if ((lastRow.lowAdprice + lastRow.highAdprice) === 0) {
            renderGlobalAlert({
                variant: "warning",
                title: `Ошибка добавление накопительной системы. Не указан верхний ${lastRow.lowAdprice} и нижний предел ${lastRow.highAdprice}`,
                statusCode: 500,
            });
            return
        }

        if (lastRow.highAdprice < lastRow.lowAdprice) {
            renderGlobalAlert({
                variant: "warning",
                title: `Ошибка добавление накопительной системы. Нижний предел ${lastRow.lowAdprice} не может быть больше верхнего предела ${lastRow.highAdprice}`,
                statusCode: 500,
            });
            return
        }

        let newDisplay = {
            idGlobal: uuidv4(),
            lowAdprice: Number((lastRow.highAdprice + 0.01).toFixed(2)),
            highAdprice: Number((lastRow.highAdprice + 0.02).toFixed(2)),
            adprice: Number((lastRow.adprice + 0.01).toFixed(2))
        } as IAdprice

        setDisplayItems([...displayItems, newDisplay])
    }

    function reloadArray() {
        let arr: Array<ReportFilter> = filters as Array<ReportFilter>
        setFilters([...arr])
    }

    function getSelector(filter: ReportFilter, multi: boolean) {
        return (
            <div >
                <PluginGridSelectorInput
                    label={filter.caption}
                    mnemocode={filter.pluginCode as IPlugin}
                    multipleSelect={multi}
                    selectedIds={filter.values}
                    displayNames={filter.displayNames}
                    gridPlugin={ReportFilterGridPluginSettings}
                    onClear={() => {
                        filter.values.length = 0
                        filter.displayNames.length = 0
                        reloadArray()
                    }}
                    onSelect={(option: Array<IEntitySimpleDTO>) => {
                        filter.values.length = 0
                        filter.displayNames.length = 0
                        option.forEach(item => {
                            filter.values.push(item.idGlobal)
                            filter.displayNames?.push(item.displayName)
                        })
                        reloadArray()
                    }}
                />
            </div>
        )
    }

    //Конвертор пляшущих дат, в будущем, когда поймут, что нужно делать нормально сразу - нужно будет переделать
    const onDatePeriodChange = (period: IDatePeriod, dateStrings: [string, string], filter: ReportFilter) => {
        filter.values.length = 0

        //Берётся лямая дата и время неизвестного характера, которую пользователь видит глазами на экране в ячейках календарей
        //И на основе её формируется дата для временной зоны пользователя, прислюнявив к ней как есть время от лямой даты
        //А затем преобразовав в UTC вменяемая дата пойдёт на сервер

        if (period != undefined) {
            const formattedDateFrom: string = convertDoodleDateToUTCFormattedString(dateStrings[0])
            const formattedDateTo: string = convertDoodleDateToUTCFormattedString(dateStrings[1])

            filter.values.push(formattedDateFrom)
            filter.values.push(formattedDateTo)
        }
        reloadArray()
    }

    const onDateChange = (dateString: string | undefined, filter: ReportFilter) => {
        filter.values.length = 0

        if (dateString) {
            const formattedDate: string = convertDoodleDateToUTCFormattedString(dateString)
            filter.values.push(formattedDate)
        }
        reloadArray()
    }

    const convertDoodleDateToUTCFormattedString = (doodleDateStr: string): string => {
        const dateUnknown = moment(doodleDateStr)
        const mdate = moment(dateUnknown).tz(userCtx.userTimeZoneId)
        mdate.year(dateUnknown.year())
        mdate.month(dateUnknown.month())
        mdate.day(dateUnknown.day())
        mdate.hour(dateUnknown.hours())
        mdate.minute(dateUnknown.minutes())
        mdate.second(dateUnknown.seconds())
        const formattedDate: string = moment.utc(mdate).format()
        return formattedDate
    }

    const checkListAdv = (filter: ReportFilter) => {
        return (
            <div>
                <div dangerouslySetInnerHTML={{ __html: filter.caption }} />
                {
                    filter.defaultValues.map((fi) => {
                        const isChecked = filter.values.some(s => s === fi.value)
                        return (
                            <div>
                                <CheckboxAnt onChange={(e) => {
                                    if (e.target.checked) {
                                        if (!isChecked) {
                                            filter.values.push(fi.value)
                                            reloadArray()
                                        }
                                    } else {
                                        if (isChecked) {
                                            var index = filter.values.indexOf(fi.value)
                                            filter.values.splice(index, 1)
                                            reloadArray()
                                        }
                                    }
                                }}
                                    checked={isChecked}
                                    disabled={filter.disabled}
                                >{fi.displayName}</CheckboxAnt>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const checkList = (filter: ReportFilter) => {
        return (
            <div>
                <div dangerouslySetInnerHTML={{ __html: filter.caption }} />
                {
                    filter.defaultValues.map((fi) => {
                        const isChecked = filter.values.some(s => s === fi.value)
                        const result =
                            <div>
                                <Checkbox name={fi.value} label={fi.displayName} defaultChecked={isChecked}
                                    disabled={filter.disabled}
                                    checked={isChecked}
                                    onChange={(e) => {
                                        if (e) {
                                            if (!isChecked) {
                                                filter.values.push(fi.value)
                                                reloadArray()
                                            }
                                        } else {
                                            if (isChecked) {
                                                var index = filter.values.indexOf(fi.value)
                                                filter.values.splice(index, 1)
                                                reloadArray()
                                            }
                                        }
                                    }}
                                />
                            </div>
                        return result
                    })
                }
            </div>
        )
    }

    const getFilters = (filters: Array<ReportFilter>) => {
        return <>
            <div className={ReportFilterStyle.wrapperFilter}>
                {
                    filters.map((filter) => {
                        if (filter.dataType === ReportFilterDataType.SinglePlugin) {
                            return getSelector(filter, false)
                        }
                    })
                }
            </div>
            <div className={ReportFilterStyle.wrapperFilter}>
                {
                    filters.map((filter) => {
                        if (filter.dataType === ReportFilterDataType.MultiPlugin) {
                            return getSelector(filter, true)
                        }
                    })
                }
            </div>
            {
                filters.map((filter) => {
                    if (filter.dataType === ReportFilterDataType.Boolean) {
                        let chk: boolean
                        if (filter.values.length > 0)
                            chk = JSON.parse(filter.values[0].toLowerCase())
                        else
                            chk = false
                        return (
                            <Checkbox name={filter.name} label={filter.caption} checked={chk} disabled={filter.disabled} onChange={(e) => {
                                filter.values.length = 0
                                filter.values.push(String(e))
                                reloadArray()
                            }} />
                        )
                    }
                })
            }
            <GridWrapper cols={2}>
                {
                    filters.map((filter) => {
                        if (filter.dataType === ReportFilterDataType.CheckList) {
                            return checkList(filter)
                        }
                    })
                }
            </GridWrapper>
            <GridWrapper cols={5}>
                {
                    filters.map((filter) => {
                        if (filter.dataType === ReportFilterDataType.Date) {
                            const date = DateTime.fromISO(moment.utc(moment(filter.values[0])).tz(userCtx.userTimeZoneId).toISOString())
                            return (
                                <div>
                                    <DateInput
                                        label={filter.caption}
                                        inputId={filter.name}
                                        className={styles.field_Date}
                                        value={date}
                                        disabled={filter.disabled}
                                        onBlur={(value, dateString) => {
                                            onDateChange(dateString, filter)
                                        }}
                                    />
                                </div>
                            )
                        }
                    })
                }
            </GridWrapper>
            <GridWrapper cols={5}>
                {
                    filters.map((filter) => {
                        if (filter.dataType === ReportFilterDataType.Period || filter.dataType === ReportFilterDataType.TimePeriod) {
                            let dateFrom: DateTime, dateTo: DateTime

                            if (filter.values.length == 2) {
                                dateFrom = DateTime.fromISO(filter.values[0])
                                dateTo = DateTime.fromISO(filter.values[1])
                            }
                            else {
                                dateFrom = MinDate
                                dateTo = MaxDate
                            }

                            dateFrom = DateTime.fromISO(moment.utc(moment(dateFrom.toISO())).tz(userCtx.userTimeZoneId).toISOString())
                            dateTo = DateTime.fromISO(moment.utc(moment(dateTo.toISO())).tz(userCtx.userTimeZoneId).toISOString())

                            const period: IDatePeriod = { startDate: dateFrom, endDate: dateTo } as IDatePeriod

                            return (
                                <div>
                                    {
                                        filter.dataType === ReportFilterDataType.Period &&
                                        <DatePeriodPanel
                                            label={filter.caption}
                                            datePeriod={period}
                                            disabled={filter.disabled}
                                            onDatePeriodChange={(period, dateStrings) => { onDatePeriodChange(period, dateStrings, filter) }}
                                        />
                                    }
                                    {
                                        filter.dataType === ReportFilterDataType.TimePeriod &&
                                        <ReportsDateTimePeriodPanel
                                            label={filter.caption}
                                            datePeriod={period}
                                            disabled={filter.disabled}
                                            showTime={filter.dataType === ReportFilterDataType.TimePeriod}
                                            onDatePeriodChange={(period, dateStrings) => { onDatePeriodChange(period, dateStrings, filter) }}
                                        />
                                    }
                                </div>
                            )
                        }
                    })
                }
            </GridWrapper>
            <GridWrapper cols={5}>
                {
                    filters.map((filter) => {
                        if (filter.dataType === ReportFilterDataType.Integer) {
                            const val = filter.values.length > 0 ? filter.values[0] : 0
                            return (
                                <div>
                                    <div dangerouslySetInnerHTML={{ __html: filter.caption }} />
                                    <InputNumber
                                        value={val}
                                        disabled={filter.disabled}
                                        onChange={(e) => {
                                            if (e) {
                                                filter.values[0] = e.toString()
                                                reloadArray()
                                            }
                                        }} />
                                </div>
                            )
                        }
                    })
                }
            </GridWrapper>
            <GridWrapper cols={5}>
                {
                    filters.map((filter) => {
                        if (filter.dataType === ReportFilterDataType.Float) {
                            const fval = filter.values.length > 0 ? filter.values[0] : "0"
                            return (
                                <div>
                                    <div dangerouslySetInnerHTML={{ __html: filter.caption }} />
                                    <InputNumber<string>
                                        value={fval}
                                        disabled={filter.disabled}
                                        step="0.01"
                                        onChange={(e) => {
                                            if (e) {
                                                filter.values[0] = e.toString()
                                                reloadArray()
                                            }
                                        }}
                                        stringMode
                                    />
                                </div>
                            )
                        }
                    })
                }
            </GridWrapper>
            <GridWrapper cols={6}>
                {
                    filters.map((filter) => {
                        if (filter.dataType === ReportFilterDataType.Combo) {
                            let arr: Array<IOption> = new Array<IOption>()
                            filter.defaultValues.forEach(item => {
                                const op: IOption = { displayName: item.displayName, value: item.value }
                                arr.push(op)
                            })
                            let selected: IOption = arr[0]
                            if (filter.values.length > 0) {
                                const item: string = filter.values[0]
                                //Проверить, может такого фильтра уже и нет
                                if (filter.values.some(s => s === item)) {
                                    selected = { displayName: item, value: item }
                                }
                            } else {
                                filter.values.push(selected.value)
                            }
                            return (
                                <div>
                                    <div dangerouslySetInnerHTML={{ __html: filter.caption }} />
                                    <div>
                                        <Select
                                            onSelect={(option) => {
                                                filter.values.length = 0
                                                filter.values.push(option.value)
                                                reloadArray()
                                            }}
                                            defaultOption={selected}
                                            value={selected}
                                            disabled={filter.disabled}
                                            options={arr}>
                                        </Select>
                                    </div>
                                </div>
                            )
                        }
                    })
                }
            </GridWrapper>
            <GridWrapper cols={6}>
                {
                    filters.map((filter) => {
                        if (filter.dataType === ReportFilterDataType.Grid) {
                            return (
                                <div>
                                    <div dangerouslySetInnerHTML={{ __html: filter.caption }} />
                                    <div>
                                        <GridSettings plugin={ReportFilterAdpriceGridPluginSettings}
                                            onChangeData={(value) => {
                                                const datas = displayItems.map((x) => (x.idGlobal === value.idGlobal ? {
                                                    ...x,
                                                    lowAdprice: value.propertyName === 'lowAdprice' ? value.value as number : x.lowAdprice as number,
                                                    highAdprice: value.propertyName === 'highAdprice' ? value.value as number : x.highAdprice as number,
                                                    adprice: value.propertyName === 'adprice' ? value.value as number : x.adprice as number,
                                                    idGlobal: x.idGlobal
                                                }
                                                    : x)
                                                )
                                                setDisplayItems(datas);
                                            }}
                                            onDelete={() => deleteRowtems()}
                                            onValidate={() => validateDisplayAdprice()}
                                            displayItems={displayItems}
                                            setDisplayItems={() => setDisplayItems([...displayItems, defaultDisplaytems])}
                                            selectedItem={selectedItem}
                                            setSelectedItem={(value) => setSelectedItem(value)}
                                        // disabled={filter.disabled} ToDo
                                        >
                                        </GridSettings>
                                    </div>
                                </div>
                            )
                        }
                    })
                }
            </GridWrapper>
            <GridWrapper cols={6}>
                {
                    filters.map((filter) => {
                        if (filter.dataType === ReportFilterDataType.Label) {
                            return (
                                <div>
                                    <div dangerouslySetInnerHTML={{ __html: filter.caption }} />
                                </div>
                            )
                        }
                    })
                }
            </GridWrapper>
            <GridWrapper cols={2}>
                {
                    filters.map((filter) => {
                        if (filter.dataType === ReportFilterDataType.CheckListAdv) {
                            return checkListAdv(filter)
                        }
                    })
                }
            </GridWrapper>
            <GridWrapper cols={1}>
                {
                    filters.map((filter) => {
                        if (filter.dataType === ReportFilterDataType.RadioGroup) {
                            return (
                                <div>
                                    <div dangerouslySetInnerHTML={{ __html: filter.caption }} />
                                    <Radio.Group
                                        style={{ fontSize: 'unset' }}
                                        value={filter.values[0]} disabled={filter.disabled} onChange={(e: RadioChangeEvent) => {
                                            filter.values.length = 0
                                            filter.values.push(e.target.value)
                                            reloadArray()
                                        }}
                                    >
                                        <Space direction="vertical">
                                            {
                                                filter.defaultValues.map((subFilter) => {
                                                    const isRadioChecked = filter.values.some(s => s === subFilter.value)
                                                    const disabled = !isRadioChecked
                                                    subFilter.filters.map((subSubFilter) => { subSubFilter.disabled = disabled })
                                                    return (<>
                                                        <Radio value={subFilter.value}>{subFilter.displayName}</Radio>
                                                        {subFilter.filters &&
                                                            <div className={styles.wrapper}>
                                                                {getFilters(subFilter.filters)}
                                                            </div>
                                                        }
                                                    </>
                                                    )
                                                })
                                            }
                                        </Space>
                                    </Radio.Group>
                                </div>
                            )
                        }
                    })
                }
            </GridWrapper>
            <GridWrapper cols={4}>
                {
                    filters.map((filter) => {
                        if (filter.dataType === ReportFilterDataType.String) {
                            let txt: string
                            if (filter.values.length > 0) {
                                txt = filter.values[0]
                            } else {
                                txt = ""
                            }
                            return (
                                <div>
                                    <div dangerouslySetInnerHTML={{ __html: filter.caption }} />
                                    <TextInput
                                        inputId={filter.name}
                                        inline={true}
                                        value={txt}
                                        onChange={(value) => {
                                            filter.values.length = 0
                                            if (!isNullOrWhiteSpace(value)) {
                                                filter.values.push(value.trim())
                                            }
                                            reloadArray()
                                        }}
                                    />
                                </div>
                            )
                        }
                    })
                }
            </GridWrapper>
        </>
    }

    return (
        <BaseDocumentCreator
            ok={{
                onClick: () => {
                    const encodedReportAssName = encodeURIComponent(props.asmName)
                    appCtx.reportAPIService?.reportRequest.then(r => r.saveSettings(userCtx.idGlobal, encodedReportAssName, props.filters))//ToDo siv - градусник и понять что из сеттнгс сохр возможно только для отчётов, а не ПФ
                    props.process()
                }
            }}
            cancel={{ onClick: () => { props.cancel() } }}
        >
            <div className={styles.wrapper}>
                {getFilters(filters)}
            </div>
        </BaseDocumentCreator>
    )
}