export enum ReportFilterDataType{
    IdTable = 0,
    GroupName = 1,

    String = 2,
    Boolean = 3,
    Float = 4,//ToDo Проверить как задаются значения по-умолчанию и min max?
    Integer = 5,//ToDo Проверить как задаются значения по-умолчанию и min max?

    Date = 6,
    DateTime = 7,//ToDo siv front

    Period = 8,
    TimePeriod = 9,

    MultiPlugin = 10,
    SinglePlugin = 11,

    FilePath = 12,
    FolderPath = 13,

    Combo = 14,

    RadioGroup = 15,

    CheckList = 16,
    CheckListAdv = 17,

    Grid = 18,

    Label = 19
}