import { FC, useEffect, useState } from "react";
import styles from "./accordion.module.scss"
import classNames from "classnames";
import bottomAccordionStyles from './bottomAccordion.module.scss'
import Tooltip from "../tooltips/Tooltip";

interface IAccordionProps {
    opened: boolean
    caption: string
    id: string
    className?: string,
    insertedAccordion?: boolean
    bold?: boolean
    bottom?: boolean
    isBorderTop?: boolean
    documentSticky?: boolean
    onOpen?: (isOpen: boolean) => void
}

const Accordion: FC<IAccordionProps> = (props) => {
    const [opened, setOpened] = useState(props.opened)

    const ToolTipAccordion = <Tooltip title={getTitle()}></Tooltip>

    function getTitle() : string
    {
        if (opened) return "Скрыть"
        return "Открыть"
    }

    const getStyles= (): string => {
        if (props.bold) return styles.boldAccordion 
        if (props.isBorderTop) return styles.accordionBorderTop
        if (opened) return styles.accordion
        return styles.accordionClosed
    }

    useEffect(() => {
        setOpened(props.opened)
    },[props.opened])

    return (
        <div className={classNames(getStyles(), props.documentSticky ? styles.documentSticky : null)}>
            {
                !props.bottom && 
                    <input 
                        type='checkbox' 
                        defaultChecked={opened} 
                        id={props.id}
                        onKeyDown={(e) => {
                            switch (e.key) {
                                case 'Enter':
                                    setOpened(!opened);
                                    props?.onOpen && props?.onOpen(!opened)
                                    break
                                case 'Escape':
                                    e.currentTarget.blur();
                                    break
                            }
                        }}
                        onClick={() => {
                            setOpened(!opened)
                            props?.onOpen && props?.onOpen(!opened)
                        }}
                    >
                    </input>
            }
            {!props.bottom && <label htmlFor={props.id}><p>{props.caption}</p>{!props.insertedAccordion && ToolTipAccordion}</label>}
            <div className={classNames(!opened && styles.hidden)}>{props.children}</div>
            {
                props.bottom && 
                    <input 
                        type='checkbox' 
                        defaultChecked={opened} 
                        id={props.id} 
                        onKeyDown={(e) => {
                            switch (e.key) {
                                case 'Enter':
                                    setOpened(!opened);
                                    props?.onOpen && props?.onOpen(!opened)
                                    break
                                case 'Escape':
                                    return e.currentTarget.blur();
                                    break
                            }
                        }}
                        onClick={() => {
                            setOpened(!opened)
                            props?.onOpen && props?.onOpen(!opened)
                        }}
                    >
                    </input>
            }
            {props.bottom && <label htmlFor={props.id}>{!props.insertedAccordion && ToolTipAccordion}</label>}
        </div >
    );
}


interface IBottomAccordionProps {
    opened: boolean
    setOpened: (value: boolean) => void
    caption: string
    id: string
    className?: string,
}

const BottomAccordion: FC<IBottomAccordionProps> = (props) => {

    return (
        <div className={bottomAccordionStyles.boldAccordion} >
            <div >{props.children}</div>
            <input 
                type='checkbox' 
                defaultChecked={props.opened} 
                id={props.id}
                onKeyDown={(e) => {
                    switch (e.key) {
                        case 'Enter':
                            props.setOpened(!props.opened);
                            break
                        case 'Escape':
                            e.currentTarget.blur();
                            break
                    }
                }}
                onClick={() => props.setOpened(!props.opened)}
            >
            </input>
            <label htmlFor={props.id}><span></span></label>
        </div >
    );
}



interface IAccordionDeductionProps {
    opened: boolean
    caption: string
    id: string
    className?: string,
    insertedAccordion?: boolean
    bold?: boolean
    bottom?: boolean
}

const AccordionDeduction: FC<IAccordionDeductionProps> = (props) => {
    const [opened, setOpened] = useState(props.opened)

    function getTitle() : string
    {
        if (opened) return "Скрыть"
        return "Открыть"
    }
    const ToolTipAccordion = <Tooltip title={getTitle()}></Tooltip>

    useEffect(() => {
        props.opened == true && setOpened(props.opened)
    }, [props.opened])

    return (
        <div className={props.bold ? styles.boldAccordion : opened ? styles.accordion : styles.accordionClosed} >
            {
                !props.bottom && 
                    <input 
                        type='checkbox' 
                        checked={opened} 
                        id={props.id} 
                        onKeyDown={(e) => {
                            switch (e.key) {
                                case 'Enter':
                                    setOpened(!opened);
                                    break
                                case 'Escape':
                                    e.currentTarget.blur();
                                    break
                            }
                        }}
                        onClick={() => setOpened(!opened)}
                    >
                    </input>
            }
            {!props.bottom && <label htmlFor={props.id}><p>{props.caption}</p>{!props.insertedAccordion && ToolTipAccordion}</label>}
            <div className={classNames(!opened && styles.hidden)}>{props.children}</div>
            {
                props.bottom && 
                    <input 
                        type='checkbox' 
                        checked={opened} 
                        id={props.id}
                        onKeyDown={(e) => {
                            switch (e.key) {
                                case 'Enter':
                                    setOpened(!opened);
                                    break
                                case 'Escape':
                                    e.currentTarget.blur();
                                    break
                            }
                        }}
                        onClick={() => setOpened(!opened)}
                    >
                    </input>
            }
            {props.bottom && <label htmlFor={props.id}>{!props.insertedAccordion && ToolTipAccordion}</label>}
        </div >
    );
}





export { Accordion, BottomAccordion, AccordionDeduction }