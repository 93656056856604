import { v4 as uuidv4 } from 'uuid';
import { StoreGridDetail } from './StoreGridDetail';
import StoreGridDetailPluginSettings from './StoreGridDetailPluginSettings';

export const StoreTabDetail = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Склады',
        view: {
            content:    StoreGridDetailPluginSettings
                        ? <StoreGridDetail gridId={uuidv4()} plugin={StoreGridDetailPluginSettings} />
                        : <p> Справочник не настроен </p>
        }
    }
}

export default StoreTabDetail