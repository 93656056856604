import { FC, useCallback, useEffect, useReducer, useState } from "react";
import DefaultDocumentsCommandsPanelV2 from "../../../../components/commandsPanels/DefaultDocumentsCommandsPanelV2";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../../components/plugins";
import { Spinner } from "../../../../components/spiner/Spinner";
import { TabsPanel } from "../../../../components/tabs";
import { ActReturnToContractorDataProvider } from "../../../../Services/DataProviders/ActReturnToContractorDataProvider";
import useGridFilter, { DefaultDateGridFilter } from "../../../../system/hooks/useGridFilter";
import { useDetailsTabsPanel } from "../../../../system/hooks/useTabsPanel";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { usePluginContext } from "../../../../system/providers/plugin";
import { initialState, reducer } from "../state/ActReturnToGlobal/actReturnToGlobalReducer";
import { DocumentStatusType, DocumentType, IdTableVariant } from "../../../../@types/enumsGlobal";
import { useTabsContext } from "../../../../system/providers/tabsProvider";
import { KizDataProvider } from "../../../../Services/DataProviders/KizDataProvider";
import { IReturnToContractorViewDTO } from "../../../../libs/coreapi-dto/documents/actReturnToContractor";
import { DocumentGridStateType } from "../../../../@types/documents";
import classNames from "classnames";
import tabsStyles from "../../../../pages/styles/homePage.module.scss";
import styles from "../../../styles/index.module.scss";
import { UserActionLogModal } from "../../../Service/UserActionLog/view/UserActionLogModal";
import { useTranslation } from "react-i18next";
import { ConfirmationItemsModal } from "../../../../components/modalWindows/ConfirmationItemsModal";
import { KizDocDataProvider } from "../../../../Services/DataProviders/KizDocDataProvider";
import { ProgressActionSpinner } from "../../../../components/progressActionSpinner/ProgressActionSpinner";

export const ActReturnToContractorPluginView: FC<IGridProps> = (props) => {
    const appContext = useAppContext();
    const actReturnToContractorDP = new ActReturnToContractorDataProvider(appContext.coreApiService);
    const [state, dispatch] = useReducer(reducer, initialState);
    const [gridFilter, dispatchGridFilter] = useGridFilter(DefaultDateGridFilter());
    const [showDetailsTabs, setShowDetailsTabs] = useState<boolean>(false);
    const tabCtx = useTabsContext();
    const [detailsTabsPanel, dispatchDetailsTabsPanel] = useDetailsTabsPanel(props.plugin.mnemocode);
    const [impossibilityProcess, setImpossibilityProcess] = useState<boolean>(false);
    const [multipleSelect, setMultipleSelect] = useState<boolean>(false);
    const [selectedItems, setSelectedItems] = useState<IGridRow[]>([]);
    const [selectedItemView, setSelectedItemViews] = useState<IReturnToContractorViewDTO>();
    const [data, setData] = useState<IReturnToContractorViewDTO[]>([]);
    const [selectedDocumentState, setSelectedDocumentState] = useState<DocumentStatusType | undefined>();
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const kizDP = new KizDataProvider(appContext.coreApiService);
    const kizDocDP = new KizDocDataProvider(appContext.coreApiService);
    const pluginCtx = usePluginContext();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [viewState, setViewState] = useState<DocumentGridStateType>("view");
    //forModalLog
    const [showModalLog, setShowModalLog] = useState<boolean>(false);
    const { t } = useTranslation();
    
    useEffect(() => {
        if (tabCtx.currentTab?.title === "Акт возврата поставщику") {
            setShowDetailsTabs(true);
        } else {
            setShowDetailsTabs(false);
        }
        pluginCtx.masterGrid.onUnselectEvent();
        setShowDetailsTabs(true);
    }, [tabCtx.currentTab]);

    useEffect(() => {
        if (!multipleSelect) {
            setSelectedItems([]);
        } else {
            setSelectedItem(undefined);
        }
        if (selectedItem) {
            const documentState = selectedItem.cells.find((c) => c.propertyName === "documentState")?.value as DocumentStatusType;
            setSelectedDocumentState(documentState ?? undefined);

            actReturnToContractorDP.getView(filterOneItem, (docs: IReturnToContractorViewDTO[], totalCount) => {
                if (totalCount === 1)
                    setSelectedItemViews(docs[0]);
            })
            //Получение одной штучки из дата провайдера по вьюшке с кастомным фильтром
            //setSelectedItemViews(data.find((x) => x.idGlobal === selectedItem.idGlobal));
            getKizAndId();
        } else {
            setSelectedItemViews(undefined);
        }
    }, [multipleSelect, selectedItem]);

    const filterOneItem = {
        numberPerPage: 1,
        pageNumber: 1,
        columnFilters: [
            {
                name: "IdGlobal",
                operator: 'Eq',
                value: selectedItem?.idGlobal
            }
        ],
    } as IGridFilter;

    useEffect(() => {
        if (viewState === "view") {
            setIsSubmitting(true);
            actReturnToContractorDP.getView(gridFilter, (data, totalCount) => {
                setData(data);
                setTotalCount(totalCount);
                setIsSubmitting(false);
            });
        }
        if (viewState === "refresh") {
            setViewState("view");
        }
    }, [gridFilter, viewState]);

    const getKizAndId = useCallback(() => {
        if (selectedItem) {
            actReturnToContractorDP.getById(selectedItem?.idGlobal, (entity) => {
                kizDP.getKizViewAsync(selectedItem?.idGlobal, { numberPerPage: 100, pageNumber: 1, columnFilters: [] }, (kiz, totalCount) => {
                    let kizs = entity.items
                        ?.map((x) => {
                            if (x.isKiz) {
                                return x.quantity;
                            } else {
                                return null;
                            }
                        })
                        .filter((x) => x !== null);
                    if (kizs.length > 0) {
                        if (
                            kizs.reduce((a, b) => {
                                return (a as number) + (b as number);
                            }) === totalCount
                        ) {
                            setImpossibilityProcess(false);
                        } else {
                            setImpossibilityProcess(true);
                        }
                    } else {
                        setImpossibilityProcess(false);
                    }
                });
            });
        }
    }, []);

    const deleteRow = () => {
        if (selectedItem?.[0]?.isDeleted || selectedDocumentState === "save" || selectedDocumentState === "proc") {
        } else {
            actReturnToContractorDP.markDelete(selectedItem?.idGlobal as string, () => {
                setViewState("refresh");
            });
        }
    };

    const [сonfirmationItemsModalJsx, setConfirmationItemsModalJsx] = useState<JSX.Element>(<></>);
    const renderConfirmationModal = (header: string, warning: string, items: string, question, cancel: () => void) => {
        setConfirmationItemsModalJsx(
            <ConfirmationItemsModal
                header={`${header}`}
                warning={ `${warning}` }
                listPositions={`${items}`}
                question={`${question}`}
                cancel={cancel}
            />
        );
    }

    const [progressActionSpinnerJsx, setProgressActionSpinnerJsx] = useState<JSX.Element>(<></>);
    const manageProgressView = (actionMessage?: string, show = false) => {
        if (show) {
            setProgressActionSpinnerJsx(
                <ProgressActionSpinner
                    loaderText={actionMessage}
                />
            );
        } else {
            setProgressActionSpinnerJsx(<></>);
        }
    };

    async function createRepeatSendDocumentAsync() {
        manageProgressView(t("general.sendingRepeatDocument"), true);
        try {
            await new Promise<string>((resolve, reject) => {
                kizDocDP.createRepeatSendDocument(selectedItem?.idGlobal as string, (e) => {
                    if (e.respType === 'isFailed') {
                        reject(e.message?.title)
                    } else {
                        resolve(e.message?.text)
                    }
                })
            })
            renderConfirmationModal('Успешно!', '', t("general.sendingRepeatDocumentHasBeenSent"), '', () => setConfirmationItemsModalJsx(<></>));

        } catch (error) {
            if (typeof error === 'string') {
                renderConfirmationModal(t("general.problemSendingRepeatDocument"), '', error, '', () => setConfirmationItemsModalJsx(<></>));
            }
        }
        finally {
            manageProgressView();
        }
    }

    return (
        <PluginWrapper>
            <ToolbarWrapper>
                <DefaultDocumentsCommandsPanelV2
                    selectedItems={[selectedItems,setSelectedItems]}
                    selectedItem={[selectedItem, setSelectedItem]}
                    gridFilter={[gridFilter, dispatchGridFilter]}
                    plugin="act_return_to_contractor_create_plugin"
                    documentStatusType={[state.documentStatusType, (value) => dispatch({ type: "changeDocumentStatusType", payload: value })]}
                    idTable={IdTableVariant.ActReturnToContractor}
                    dataProvider={actReturnToContractorDP}
                    pluginSettings={props.plugin}
                    setViewState={(vs) => setViewState(vs)}
                    items={[
                        {
                            label: "Просмотреть лог пользователя",
                            onClick: () => selectedItem && setShowModalLog(true),
                            disabled: selectedItem ? false : true,
                        },
                        {
                            label: t("documents.invoice.repeatSendMdlp"),  //"Отправить повторно в МДЛП",
                            onClick: () => {
                                if (selectedItemView?.isRepeatSendAllowed) {
                                    createRepeatSendDocumentAsync();
                                }
                            },
                            disabled: !selectedItemView?.isRepeatSendAllowed,
                        },
                    ]}
                    advancedFeatures
                    impossibilityAction={{ impossibilityProcess: impossibilityProcess }}
                    multipleSelect={[multipleSelect, setMultipleSelect]}
                    selectedDocumentStatusType={selectedDocumentState}
                    mnemocode={selectedItemView?.mnemocode}
                    checkLockingDocuments
                />
            </ToolbarWrapper>

            {isSubmitting ? (
                <Spinner />
            ) : (
                <DefaultGrid
                    openWithEnterForEdit={{
                    pluginTabContext:"act_return_to_contractor_create_plugin",
                    mnemocode:selectedItemView?.mnemocode,
                    selectedDocumentState}}
                    gridId={props.gridId}
                    data={data}
                    separator
                    documentStatus
                    totalCount={totalCount}
                    filter={gridFilter}
                    dataProvider={actReturnToContractorDP}
                    plugin={props.plugin}
                    selectedItem={selectedItem}
                    isServerFilter
                    searching
                    getView={(gridFilter,callback) => {
                        actReturnToContractorDP.getView(gridFilter, (entities) => {
                            callback(entities)
                            setSelectedItem(undefined);
                        })
                    }}
                    onSelect={(row) => {
                        setSelectedItem(row);
                        row ? pluginCtx.masterGrid.onSelectEvent(row, DocumentType.actReturnToContractor) : pluginCtx.masterGrid.onUnselectEvent();
                    }}
                    onDoubleClick={(row) => props.onDoubleClick?.(row)}
                    onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                    onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                    onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                    onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                    multipleSelect={multipleSelect}
                    selectedItems={selectedItems}
                    onMultipleSelect={(rows) => {
                        setSelectedItems(rows);
                    }}
                    actionGridRow={{ delete: () => deleteRow() }}
                />
            )}

            <div className={styles.detailsTabView}>
                {showDetailsTabs && detailsTabsPanel.tabs.length > 0 && selectedItem && !multipleSelect && (
                    <>
                        <div className={tabsStyles.tabsPanelWrapper}>
                            <div id="detailsTabsPanelWrapper" style={{ position: "relative", overflow: "hidden", width: "100%" }}>
                                <TabsPanel
                                    id="detailsTabsPanel"
                                    activeId={detailsTabsPanel.currentTab?.id}
                                    tabs={detailsTabsPanel.tabs}
                                    onActive={(id) => dispatchDetailsTabsPanel({ type: "activate", payload: id })}
                                ></TabsPanel>
                            </div>
                        </div>
                        <div className={tabsStyles.contentWrapper}>
                            {detailsTabsPanel.tabs.map((item) => {
                                return (
                                    <div key={item.id} className={classNames(item.id === detailsTabsPanel.currentTab?.id ? tabsStyles.contentVisible : tabsStyles.contentHidden)}>
                                        {item.view.content}
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}
                {showModalLog && <UserActionLogModal idDocumentGlobal={selectedItem?.idGlobal} onClick={() => setShowModalLog(false)} />}
                {progressActionSpinnerJsx}
                {сonfirmationItemsModalJsx}
            </div>
        </PluginWrapper>
    );
};
