import { FC, useEffect, useState } from "react"
import GridWrapper from "../../../../components/controls/GridWrapper"
import { AccessPoint2SupplierDataProvider } from "../../../../Services/DataProviders/AccessPoint2SupplierDataProvider"
import { IOption } from "../../../../components/selects/select"
import { useAppContext } from "../../../../system/providers/appContextProvider"
import { IAccessPoint2SupplierDTO } from "../../../../libs/coreapi-dto/dirs/accessPoint2Supplier"
import { AccessPointDataProvider } from "../../../../Services/DataProviders/AccessPointDataProvider"
import BaseCreator from "../../../../components/creators/baseCreator"
import { SelectControl } from "../../../../components/controls/SelectControl"

interface IAccessPointDetailEditModal {
  cancel: () => void
  idGlobal: string
  lockFromPermission?: boolean
  idAccessPointGlobal: string
}

export const AccessPointDetailEditModal: FC<IAccessPointDetailEditModal> = (props) => {
  const appContext = useAppContext()

  const accessPointDataProvider = new AccessPointDataProvider(appContext.coreApiService)
  const accessPoint2SupplierDataProvider = new AccessPoint2SupplierDataProvider(appContext.coreApiService)
  const [data, setData] = useState<IAccessPoint2SupplierDTO>();
  const [items, setItems] = useState<string[]>([]);

  useEffect(() => {
    if (!props.idGlobal) return
    accessPoint2SupplierDataProvider.getById(props.idGlobal, (entities) => {
      setData(entities);
    });
    accessPointDataProvider.getDirectories(props.idAccessPointGlobal, (response) => {
        setItems(response)
    }, () => { return })
  }, []);

  const getEnumList = (items: string[]): IOption[] => {
    return items.map((item) => ({
        displayName: item,
        value: item
    }));
};

  const save = () => {
    if (!data) return
    accessPoint2SupplierDataProvider.update(data.idGlobal as string, data, () => {
      props.cancel()
    })
  }

  return (    
    <>    
      <BaseCreator
        save={save}
        cancel={() => props.cancel()}
        valid={true}
        variant="edit"
        >
        <GridWrapper cols={1}>
            <SelectControl
                value={
                    {
                    displayName: data?.connectionString,
                    value: data?.connectionString
                    } as IOption
                }
                options={getEnumList(items)}
                onSelect={(option) => {
                    setData({
                        idGlobal: data?.idGlobal as string,
                        idAccessPointGlobal: data?.idAccessPointGlobal as string,
                        idSupplierGlobal: data?.idSupplierGlobal as string,
                        supplierName: data?.supplierName as string,
                        connectionString: option.value as string
                    })
                }}
            />
        </GridWrapper>
      </BaseCreator>    
    </>
  )
}
