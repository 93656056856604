import classNames from "classnames";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultTreeContractorViewCommandsPanel from "../../../components/commandsPanels/DefaultTreeContractorViewCommandsPanel";
import { ContextMenu } from "../../../components/contextMenu/contextMenu";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins";
import { Spinner } from "../../../components/spiner/Spinner";
import { TreeViewPanel } from "../../../components/treeViewPanel";
import { IContractorConvolution, IContractorConvolutionInfo, IContractorViewDTO } from "../../../libs/coreapi-dto/dirs/contractor";
import { ContractorDataProvider } from "../../../Services/DataProviders/ContractorDataProvider";
import { ContractorGroupDataProvider } from "../../../Services/DataProviders/ContractorGroupDataProvider";
import useGridFilter, { CreateSelectorGridFilter } from "../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { usePluginContext } from "../../../system/providers/plugin";
import { useUserContext } from "../../../system/providers/userContextProvider";
import { checkAccessStatus, checkActiveCommands } from "../../CommonHelperFunctions";
import styles from '../../styles/index.module.scss';
import { ContractorGroupCreatorModal, ContractorGroupSelectorModal } from "../ContractorGroup";
import ContractorCreatorModal from "./ContractorCreatorModal";
import { DocumentStatusType, DocumentType } from "../../../@types/enumsGlobal";
import { treeViewFolderPharmacy, treeViewFolderSupplier } from "../../../constants";
import GridWrapper from "../../../components/controls/GridWrapper";
import { TextInput } from "../../../components/controls/inputs";
import { v4 as uuidv4 } from 'uuid';
import * as MainIcons from "../../../libs/corporate/icons/outlined/action/MainCollection"
import { ContractorConvolutionModalWindow } from "./ContractorConvolutionModal";
import { ProgressActionSpinner } from "../../../components/progressActionSpinner/ProgressActionSpinner";
import { ConfirmationItemsModal } from "../../../components/modalWindows/ConfirmationItemsModal";
import TabsPanel from "../../../components/tabs/TabsPanel";
import { useDetailsTabsPanel } from "../../../system/hooks/useTabsPanel";
import tabsStyles from '../../../pages/styles/homePage.module.scss'
import { CheckBox } from "../../../components/controls/checkbox";

type GridRowViewState = "view" | "return" | "attachToGroup" | "moveToGroup" | "detachFromGroup" | "setMe"

const ContractorPluginView: FC<IGridProps & ITreeViewCheckDirectory> = (props) => {
    const appContext = useAppContext()
    const pluginContext = usePluginContext()
    const userContext = useUserContext()
    const contractorDP = new ContractorDataProvider(appContext.coreApiService)
    const contractorGroupDP = new ContractorGroupDataProvider(appContext.coreApiService)
    const [detailsTabsPanel, dispatchDetailsTabsPanel] = useDetailsTabsPanel(props.plugin.mnemocode)
    const [contractorViewState, setContractorViewState] = useState<GridStateType>("view")
    const [contractorGroupViewState, setContractorGroupViewState] = useState<TreeViewStateType>('view')
    const [contextMenuViewState, setContextMenuViewState] = useState<GridRowViewState>("view")

    const [contractorGroupGridFilter] = useGridFilter(CreateSelectorGridFilter)
    const [contractorGroupFolders, setContractorGroupFolders] = useState<ITreeViewFolder[]>([])
    const [data, setData] = useState<IContractorViewDTO[]>([])
    const [totalCount, setTotalCount] = useState(0)
    const [gridFilter, dispatchGridFilter] = useGridFilter(props.baseGridFilter ?? CreateSelectorGridFilter)
    const [selectedItem, setSelectedItem] = useState<IGridRow>();

        // selected items in invoice for multiselect
    const [selectedItems, setSelectedItems] = useState<IGridRow[]>(props?.data ?? []);
    const [selectedItemView, setSelectedItemViews] = useState<IContractorViewDTO>();
    const [multipleSelect, setMultipleSelect] = useState<boolean>(props.multipleSelect ?? false);
    const [selectedDocumentState, setSelectedDocumentState] = useState<DocumentStatusType | undefined>();

    const [canSetMe, setCanSetMe] = useState<boolean>(false);
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);

    const [clientWidth, setClientWidth] = useState<number | null>(null);
    const xDividerPos = useRef<number>(0);
    const treeViewPanelRef = useRef<HTMLDivElement>(null);

    const [searchGroup, setSearchGroup] = useState<string>('');
    const [loaderThree, setLoaderThree] = useState<boolean>(true);

    const [searchValue, setSearchValue] = useState<string>('');

    const [onlyUsers, setOnlyUsers] = useState<boolean>(false);

    const checkDirectoryType = (): boolean => props.treeViewCheckDirectoryType ? true : false; //функция для проверки props.treeViewCheckDirectoryType и блокировки useEffect
    let firstRenderWithDirectory = useRef<boolean>(false); //Реф необходимый для блокировки запросов при монтировании компонента
    
    const convolutionLocked = () => {
        const activeCommands = checkActiveCommands("ContractorConvolution", userContext.userPermission);
        return !activeCommands.convoluteContractor
    };

    const convolutionLockFromPermission = convolutionLocked();

    const [isOpenConvolution, setIsOpenConvolution] = useState<boolean>(false)
    const [contractorConvolution, setContractorConvolution] = useState<[IContractorConvolutionInfo, IContractorConvolutionInfo]>();

    // Состояние модального окна
    const [modalWindow, setModalWindow] = useState(<></>);

    const updateTree = (callBack?: () => void): void => {
        
        contractorGroupDP.getView(contractorGroupGridFilter, (entities) => {
            let tmpList: ITreeViewFolder[] = []
            entities.forEach(item => {
                tmpList.push({ folderId: item.idGlobal, displayName: item.name, childGroups: item.childGroups })
            });
            setContractorGroupFolders(tmpList)
            setContractorGroupViewState('view')
            callBack?.();
        })
    };

    useEffect(() => {
        if (!pluginContext.treeView.visiblePanel && !pluginContext.treeView.selectedFolder) {
            setLoaderThree(true);
        }

        if (pluginContext.treeView.visiblePanel) {
            if (!pluginContext.treeView.selectedFolder) {
                updateTree(() => setLoaderThree(false));
            }
            else if (contractorGroupViewState === 'return') {
                updateTree();
            }
        }
    }, [pluginContext.treeView.visiblePanel, contractorGroupViewState, pluginContext.treeView.selectedFolder])
    
    const onMouseHoldDown = (e) => {
        xDividerPos.current = e.clientX;
    }

    const onMouseHoldUp = () => {
        xDividerPos.current = 0;
    }

    const onMouseHoldMove = (e) => {
        if (!xDividerPos.current) {
            return;
        }
        setClientWidth(clientWidth + e.clientX - xDividerPos.current);
        xDividerPos.current = e.clientX;
    }

    useEffect(() => {
        setClientWidth(treeViewPanelRef.current?.clientWidth as number);
    }, [])  

    useEffect(() => {
        props.isModal && pluginContext.treeView.onModalEvent(true)
    }, [])

    useEffect(() => {
        document.addEventListener("mouseup", onMouseHoldUp);
        document.addEventListener("mousemove", onMouseHoldMove);
        return () => {
            document.removeEventListener("mouseup", onMouseHoldUp);
            document.removeEventListener("mousemove", onMouseHoldMove);
        }
    })

    useEffect(() => {
        if (!clientWidth) {
            setClientWidth(treeViewPanelRef.current?.clientWidth as number);
            return;
        }
        if (treeViewPanelRef.current) {
            treeViewPanelRef.current.style.minWidth = clientWidth + "px";
            treeViewPanelRef.current.style.maxWidth = clientWidth + "px";
        }
    }, [clientWidth, treeViewPanelRef.current])


    useEffect(() => {
        if(checkDirectoryType() && !firstRenderWithDirectory.current) 
            return; // Блокировка запроса при 1ом монтировании компонента

        setIsSubmitting(true)
        
        let correctFilter = getCorrectFilter()

        contractorDP.getView(correctFilter, (entities, totalCount) => {
            setData(entities)
            setTotalCount(totalCount)
            setIsSubmitting(false)
        })
    }, [gridFilter, onlyUsers])

    useEffect(() => {
        if(checkDirectoryType()) return;
        if (contractorViewState === 'refresh') {
            setIsSubmitting(true)
            contractorDP.getView(gridFilter, (entities, totalCount) => {
                setData(entities)
                setContractorViewState('view')
                setTotalCount(totalCount)
                setIsSubmitting(false)
            })
        }
    }, [contractorViewState])

    //Вызов справочника
    const getContractorView = ({ onDataReceived, customGridFilter }: { onDataReceived?: VoidFunction, customGridFilter?: IGridFilter }) => {
        setIsSubmitting(true);
        let filter = customGridFilter ?? gridFilter
        if (props.excludeContractors?.length as number > 0) {
            const idGlobal = props.excludeContractors?.find(x => x.name === "IdGlobal")?.value as string;
            const legalEntity = props.excludeContractors?.find(x => x.name === "LegalEntity")?.value as string;

            if (idGlobal.length > 0)
                filter.columnFilters.push({
                    name: "IdGlobal",
                    value: idGlobal,
                    operator: "NotEq",
                })
            
            if (legalEntity.length > 0)
                filter.columnFilters.push({
                    name: "LegalEntityName",
                    value: legalEntity,
                    operator: "NotEq",
                })
        }
            
        if (!props.excludeUserFilter){
            setOnlyUsers(true)

            filter.paramFilter = {
                ...filter.paramFilter,
                idUserGlobal: userContext.idGlobal
            }
        }

        contractorDP.getView(filter, (entities, totalCount) => {
            setData(entities)
            setTotalCount(totalCount)
            if (onDataReceived) {
                onDataReceived()
            }
            setIsSubmitting(false)
        })
    }

    //Типы согласно которым будет производится запрос.
    const treeViewSelectParams:Partial<Record<TreeViewCheckDirectoryType, ITreeViewFolder>> = {
        'supplier': treeViewFolderSupplier, //Поставщик
        'pharmacy': treeViewFolderPharmacy, //Аптека
    };

    //При монтировании выбирается группа согласно переданному типу.
    const openTreeStructureByTypeFirstRender = () => {
        if (props.treeViewCheckDirectoryType) {
            // При нажатии кнопки Enter при выделенном инпуте произойдет обычный запрос.
            if (props.isEnterPressed) {
                setLoaderThree(true);

                const selectParam = treeViewSelectParams[props.treeViewCheckDirectoryType] as ITreeViewFolder;
                const paramFilter = props.applyUserFilter ?
                            { idGroupGlobal: selectParam.folderId, idUserGlobal: userContext.idGlobal } :
                            { idGroupGlobal: selectParam.folderId }

                getContractorView({
                    onDataReceived: () => {
                        setLoaderThree(false);
                        firstRenderWithDirectory.current = true;
                    }, customGridFilter: { ...gridFilter, paramFilter: paramFilter }
                });
            } else {
                // Проверка при которой происходит выбор групп и открытие справочника согласно типу.
                if (treeViewSelectParams[props.treeViewCheckDirectoryType]) {
                    const selectParam = treeViewSelectParams[props.treeViewCheckDirectoryType] as ITreeViewFolder;
                    pluginContext.treeView.onVisiblePanelEvent(true);
                    pluginContext.treeView.onSelectEvent(selectParam);
    
                    setLoaderThree(true);
                    updateTree(() => {
                        const paramFilter = props.applyUserFilter ?
                            { idGroupGlobal: selectParam.folderId, idUserGlobal: userContext.idGlobal, excludeUserContractors: props.excludeUserFilter } :
                            { idGroupGlobal: selectParam.folderId }

                        getContractorView({ customGridFilter: { ...gridFilter, paramFilter: paramFilter } });
                        setLoaderThree(false);
                        firstRenderWithDirectory.current = true;
                    });
                }
            }
        }
    }

    useEffect(() => {
        contractorDP.canSetMe((value) => setCanSetMe(value))
        openTreeStructureByTypeFirstRender();

        return () => {
            pluginContext.treeView.onVisiblePanelEvent(false); //Снятия флага групп при удалении компонента.
            pluginContext.treeView.onUnselectEvent()
            pluginContext.masterGrid.onUnselectEvent()
        }
    }, [])

    useEffect(() => {
        if (selectedItem) {
            const documentState = selectedItem.cells.find((c) => c.propertyName === "documentState")?.value as DocumentStatusType;
            setSelectedDocumentState(documentState ?? undefined);
            setSelectedItemViews(data.find((x) => x.idGlobal === selectedItem.idGlobal));
        } else {
            setSelectedItemViews(undefined);
        }
    }, [multipleSelect, selectedItem]);

    useEffect(() => {
        setSelectedItem(undefined);
        pluginContext.masterGrid.onUnselectEvent()
    }, [contractorViewState, gridFilter]);

    const getCorrectFilter = (): IGridFilter => {
        const selectParam = props.treeViewCheckDirectoryType && treeViewSelectParams[props.treeViewCheckDirectoryType] as ITreeViewFolder;
        let correctFilter = gridFilter

        if ((props.applyUserFilter || onlyUsers) && gridFilter?.paramFilter?.idGroupGlobal) {
            correctFilter = {...gridFilter, paramFilter: {
                ...gridFilter.paramFilter,
                 idUserGlobal: userContext.idGlobal,
            }} 
        }
        else if ((props.applyUserFilter || onlyUsers) && !gridFilter?.paramFilter?.idGroupGlobal && selectParam) {
            correctFilter = {...gridFilter, paramFilter: {
                ...gridFilter.paramFilter,
                 idUserGlobal: userContext.idGlobal,
                 idGroupGlobal: selectParam.folderId
            }} 
        }
        else if (!props.applyUserFilter && onlyUsers) {
            correctFilter = {
                ...gridFilter,
                paramFilter: {
                    ...gridFilter.paramFilter,
                    idUserGlobal: userContext.idGlobal,
                }
            }

            // При отсуствии фильтра на группы, выбирается выборка с полем "OrderColumn",
            // которое отвечает за порядок собственных КА
            // + у администратора должны показываться все КА
            if (!userContext.userRoles.map(x => x.toLowerCase()).includes("administrator")) {
                correctFilter = {
                    ...correctFilter,
                    columnFilters: [...gridFilter.columnFilters, {"name": "OrderColumn", "value": true, "operator": "Eq"}]
                }
            }
        }

        return correctFilter;
    }

    const showConvolution = () => {

        // Сворачиваем 2 КА
        if (selectedItems?.length === 2) {
            
            const expression = `"IdGlobal" in ('${selectedItems[0].idGlobal}', '${selectedItems[1].idGlobal}')`;
            const selectionFilter = { ...gridFilter, expression: expression } as IGridCustomWhereExpressionFilter

            // Получение сводки по contractors
            contractorDP.getView(selectionFilter, (contractors, totalCount) => {

                setContractorConvolution([
                    {
                        idGlobal: contractors[0].idGlobal,
                        contractorName: contractors[0].name
                    } as IContractorConvolutionInfo,
                    {
                        idGlobal: contractors[1].idGlobal,
                        contractorName: contractors[1].name,
                    } as IContractorConvolutionInfo
                ]);
                setIsOpenConvolution(true);
            });
        }
    }

    const search = (value: string) => {

        // Вхождение с 3 символов актуально только для живого поиска
        if (value.length > 0) {
            // pgsql-выражение (блока условия, финальный and (expr)):
            // соединение через ИЛИ
            const expression = `lower("Name") like lower('%${value}%') or lower("FullName") like lower('%${value}%') or lower("LegalEntityName") like lower('%${value}%') or ` +
                `lower("Address") like lower('%${value}%') or ` +
                `lower("LegalEntityInn") = '${value}'`;

            dispatchGridFilter({ type: "addCustomWhereExpressionFilter", payload: expression });
        } else {
            dispatchGridFilter({ type: "deleteCustomWhereExpressionFilter" });
        }
    };

    function renderProgressView(actionMessage: string) {
        setModalWindow(
            <ProgressActionSpinner
                loaderText={`${actionMessage}`}
            />
        );
    }

    return (
        <PluginWrapper>
            {
                checkAccessStatus(props.plugin.permission as IPermission, userContext.userPermission) && <ToolbarWrapper>
                    <GridWrapper cols={props.isModal ? 3 : 8}>
                        <DefaultTreeContractorViewCommandsPanel
                            selectedItems={[selectedItems, setSelectedItems]}
                            selectedDocumentStatusType={selectedDocumentState}
                            multipleSelect={[multipleSelect, setMultipleSelect]}
                            selectedItem={[selectedItem, setSelectedItem]}
                            gridFilter={[gridFilter, dispatchGridFilter]}
                            dataProvider={contractorDP}
                            groupDataProvider={contractorGroupDP}
                            creatorModalJsx={ContractorCreatorModal}
                            creatorGroupModalJsx={ContractorGroupCreatorModal}
                            selectorGroupModalJsx={ContractorGroupSelectorModal}
                            pluginSettings={props.plugin}
                            setViewState={(vs) => setContractorViewState(vs)}
                            setGroupViewState={(vs) => setContractorGroupViewState(vs)}
                            contextMenuViewState={[contextMenuViewState, setContextMenuViewState]}
                            activateGroupProcessing={props.activateGroupProcessing}
                            createBindGroup={(idGroupGlobal, model, callback) => {
                                contractorDP.createBindGroup(idGroupGlobal, model, () => {
                                    callback()
                                })
                            }}
                            viewMode={props.viewMode}
                        />
                        {
                        !props.applyUserFilter && <div style={{ marginTop: 11.2, minWidth: 210 }}>
                            <CheckBox
                                id={`userStores-${uuidv4()}`}
                                label={t("directory.contractors.onlyUsers")}//'Собственные контрагенты'
                                defaultChecked={onlyUsers}
                                onChanged={(checked: boolean) => {
                                    setOnlyUsers(checked);
                                }}
                            />
                        </div>
                        }
                        {
                            !props.isModal && <div style={{minWidth: 190, margin: "0 auto"}}>
                                <button
                                    className={classNames(styles.convolutionButton)}
                                    onClick={() => showConvolution()}
                                    disabled={convolutionLockFromPermission || selectedItems.length !== 2}
                                >
                                    <MainIcons.SwapIcon />
                                    <div className={styles.contentWrapper}>
                                        {t("directory.contractors.convolution")}
                                    </div>
                                </button>
                            </div>
                        }
                    </GridWrapper>
                </ToolbarWrapper>
            }
            {
                <div style={{ marginLeft: -13 }}>
                    <GridWrapper cols={8}>
                        <TextInput
                            value={searchValue}
                            label=''
                            searchButtonShow
                            inline
                            style={{ width: props.isModal ? 412 : 412 }}
                            placeholder={t("directory.contractors.search")}
                            onKeyDown={(e) => {
                                switch (e.key) {
                                    case "Enter": {
                                        search(e.currentTarget?.value);
                                    }
                                }
                            }}
                            onChange={(value: string) => {
                                setSearchValue(value);
                            }}
                            searchButtonOnClick={() => {
                                search(searchValue);
                            }}
                            onFocus={() => {
                                if (props.isModal) props.onFocusChanged?.(true);
                            }}
                            onEndChange={() => {
                                if (props.isModal) props.onFocusChanged?.(false);
                            }}
                        >
                        </TextInput>
                    </GridWrapper>
                </div>
            }

            <div className={styles.pluginWorkspace}>
                {
                    !props.isModal && pluginContext.treeView.isModal ? <></> :
                    <>
                        <div style={{display: pluginContext.treeView.visiblePanel ? 'block' : 'none'}} className={styles.treeView} ref={treeViewPanelRef}>
                            <TreeViewPanel
                                loaderThree={loaderThree}
                                folders={contractorGroupFolders}
                                selectedFolder={pluginContext.treeView.selectedFolder}
                                onHideClick={() => pluginContext.treeView.onVisiblePanelEvent(false)}
                                onSelectFolder={(folder) => pluginContext.treeView.onSelectEvent(folder)}
                                onExpandFolder={(folder, callback) => {
                                    contractorGroupDP.getChildViewAsync(folder.folderId, contractorGroupGridFilter,
                                        (entities) => {
                                            let tmpList: ITreeViewFolder[] = []
                                            entities.forEach(item => {
                                                tmpList.push({ folderId: item.idGlobal, displayName: item.name, childGroups: item.childGroups })
                                            });
                                            callback(tmpList)
                                        })
                                }} 
                                setSearchGroup = {(value) => setSearchGroup(value)}
                                searchGroup= {searchGroup}
                                groupDataProvider={contractorGroupDP}
                                setGroupViewState={(value) => setContractorGroupViewState(value) }
                                gridFilter={[gridFilter,dispatchGridFilter]}
                                pluginSettings={props.plugin}
                            />
                        </div>
                        <div onMouseDown={onMouseHoldDown} style={{cursor: 'col-resize', display: pluginContext.treeView.visiblePanel ? 'block' : 'none'}} className={classNames(styles.separator, styles.separator_vertical)}></div>
                    </>
                }

                <div className={styles.gridWrapper}>
                    <div className={styles.masterGrid}>
                        {
                            isSubmitting ?
                                <Spinner /> :
                                <DefaultGrid
                                    gridId={props.gridId}
                                    data={data}
                                    totalCount={totalCount}
                                    filter={getCorrectFilter()}
                                    plugin={props.plugin}
                                    separator
                                    singleDirectory
                                    dataProvider={contractorDP}
                                    selectedItem={selectedItem}
                                    multipleSelect={multipleSelect}
                                    selectedItems={selectedItems}
                                    searching
                                    isServerFilter
                                    getView={(gridFilter, callback) => {
                                        contractorDP.getView(gridFilter, (data) => {
                                            callback(data);
                                        });
                                    }}
                                    onSelect={(row) => {
                                        setSelectedItem(row ? row : undefined)
                                        if (row) {
                                            pluginContext.masterGrid.onSelectEvent(row, DocumentType.contractor)
                                            pluginContext.masterGrid.onUnselectDetailEvent()
                                        } else {
                                            pluginContext.masterGrid.onUnselectEvent();
                                            pluginContext.masterGrid.onUnselectDetailEvent()
                                        }
                                        props.onSelect?.(row ? row: undefined)
                                    }}
                                    onMultipleSelect={(rows) => {
                                        setSelectedItems(rows)
                                        props?.onMultipleSelect?.(rows)
                                    }}
                                    onDoubleClick={(row) => props.onDoubleClick?.(row)}
                                    onSort={(i) => { dispatchGridFilter({ type: "sort", payload: i.propertyName }) }}
                                    onFilterDelete={(i) => { dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName }) }}
                                    onPageNumberChange={(n) => { dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } }) }}
                                    onNumberPerPageChange={(n) => { dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } }) }}
                                    onGridRowContextMenuMouseClick={(selectedRow, position) => {
                                        const checkGroupSelected = pluginContext.treeView.selectedFolder !== undefined;
                                        const multiPosition = selectedItems.find(el => el.idGlobal === selectedRow.idGlobal) ? false : true;
                                        const onePosition = (selectedRow.idGlobal === selectedItem?.idGlobal) ? false : true;
                                        const checkGroup =  checkGroupSelected ? (multipleSelect ? multiPosition : onePosition) : true;

                                        return <ContextMenu id={'ctxMenu_row_' + props.gridId} xPos={position.x - 300} yPos={position.y - 140}
                                            items={[{
                                                name: t('general.addToGroup'),
                                                onClick: () => setContextMenuViewState("attachToGroup"),
                                                disabled: multipleSelect ? multiPosition : onePosition,
                                            }, {
                                                name: t('general.moveToGroup'),
                                                disabled: checkGroup,
                                                onClick: () => setContextMenuViewState("moveToGroup")
                                            }, {
                                                name: t('general.removeFromGroup'),
                                                disabled: checkGroup,
                                                onClick: () => setContextMenuViewState("detachFromGroup")
                                            }
                                            ]}>
                                        </ContextMenu>
                                    }} 
                                />
                        }

                    </div>

                    <div className={styles.detailsTabView}>
                            {
                                detailsTabsPanel.tabs.length > 0 && pluginContext.masterGrid.selectedItem?.idGlobal &&
                                <div>
                                    <div className={tabsStyles.tabsPanelWrapper}>
                                        <div id="detailsTabsPanelWrapper" style={{ position: "relative", overflow: 'hidden', width: '100%' }}>
                                            <TabsPanel id="detailsTabsPanel" activeId={detailsTabsPanel.currentTab?.id} tabs={detailsTabsPanel.tabs}
                                                onActive={(tab) => { dispatchDetailsTabsPanel({ type: "activate", payload: tab }) }}>
                                            </TabsPanel>
                                        </div>
                                    </div>
                                    <div className={tabsStyles.contentWrapper}>
                                        {detailsTabsPanel.tabs.map(item => {
                                            return <div key={item.id} className={classNames(item.id === detailsTabsPanel.currentTab?.id ? tabsStyles.contentVisible : tabsStyles.contentHidden)}>
                                                {item.view.content}
                                            </div>
                                        })}
                                    </div>
                                </div>
                            }
                    </div>
                    
                </div>
            </div>
            {
                isOpenConvolution &&
                <ContractorConvolutionModalWindow
                    contractorSource={contractorConvolution?.[0] as IContractorConvolutionInfo}
                    contractorTarget={contractorConvolution?.[1] as IContractorConvolutionInfo}
                    convolute={(convolutionDto: IContractorConvolution) => {

                        const warning = `${t("directory.contractors.convolutionWarningQuestion")}`;
                        const items = `${convolutionDto.contractorNameSource} [родитель]\r\nзаменить на\r\n${convolutionDto.contractorNameTarget}`;
                        const question = `${t("directory.contractors.convolutionWarningText")} [${convolutionDto.contractorNameSource}]\r\n${t("directory.contractors.convolutionWarningText2")}`;
                        setModalWindow(
                            <ConfirmationItemsModal
                                header={`${t("errors.warning")}`}
                                warning={`${warning}`}
                                listPositions={`${items}`}
                                question={`${question}`}
                                cancel={() => {
                                    setModalWindow(<></>);
                                }}
                                ok={() => {
                                    setIsOpenConvolution(false);
                                    renderProgressView(t("directory.contractors.convolution"));
                                    contractorDP.convolute(convolutionDto, (e) => {

                                        setMultipleSelect(false);
                                        setContractorViewState("refresh");
                                        setModalWindow(<></>);
                                    }, (e) => {
                                        setModalWindow(<></>);
                                    });
                                }}
                            />
                        );
                    }}
                    cancel={() => {
                        setIsOpenConvolution(false);
                    }}
                />
            }
            {modalWindow}
        </PluginWrapper>
    )
}

export default ContractorPluginView