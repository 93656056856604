import { IAccessPointDTO, IAccessPointViewDTO } from "../../libs/coreapi-dto/dirs/accessPoint";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class AccessPointDataProvider extends DictionaryDataProvider<IAccessPointViewDTO, IAccessPointDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IAccessPointViewDTO, IAccessPointDTO>> {
        return this._coreApiService.accessPointRequest
    }

    getDirectories(idGlobal: string, callback: (e: string[]) => void, errCallback: () => void) {
        this._coreApiService.accessPointRequest
            .then(r => r.getDirectories(idGlobal, e => {
                if (e.respType === 'isCompleted') {
                    callback(e.data);
                }
                else
                    errCallback?.()
            }))
    }
}