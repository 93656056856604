import { FC, SetStateAction, useRef, useState } from "react";
import { IColumnToSave, IPage } from "../default/defaultGrid";
import { LoadingStatus } from "../../../@types/enumsGlobal";
import { Tooltip } from "antd";
import classNames from "classnames";
import { MultiplyIcon } from "../../../libs/corporate/icons/outlined/suggested/SymbolCollection";
import { DateInput, TextInput } from "../../controls/inputs";
import BaseIconButton from "../../buttons/iconButtons/BaseIconButton";
import { DateTime } from "luxon";
import { DecimalInputV2 } from "../../controls/inputs/BaseInput";
import { CheckBox } from "../../controls/checkbox";

interface IClsProps {
    tD: string;
    tooltip: string;
    sortingDiv: string;
    sortingDivButton: string;
    searchDropDivButton?: string;
    filteredDiv: string;
}

interface ITableHeaderProps {
    index: number;
    draggable: boolean;
    item: IGridColumn;
    filtered: boolean;
    pluginSettings: IPluginSettings;
    setGridColumnsToSave: (value: SetStateAction<IColumnToSave>) => void;
    sorting: boolean;
    onSort: (column: IGridColumn) => void;
    searching?: boolean;
    onSearch?: (value: string, column: IGridColumn,) => void;
    renderSortIcon: (item: IGridColumn) => JSX.Element;
    onFilterDelete: (column: IGridColumn) => void;
    onPageNumberChange?: (pageNumber: number) => void;
    setPageNumber?: (value: SetStateAction<IPage>) => void;
    setLoadingStatus?: ((value: LoadingStatus) => void) | undefined;
    filter?: IGridFilter;
    cls: IClsProps;
    value?: string
}

export const TableHeader: FC<ITableHeaderProps> = ({
    index,
    draggable,
    item,
    filtered,
    pluginSettings,
    setGridColumnsToSave,
    sorting,
    onSort,
    searching,
    onSearch,
    renderSortIcon,
    onFilterDelete,
    onPageNumberChange,
    setPageNumber,
    setLoadingStatus,
    filter,
    cls,
    value
}) => {
    const [spanWidth, setSpanWidth] = useState<boolean>(false);
    const [showSearchInput, setShowSearchInput] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>(value ?? '');
    const [boolean, setBoolean] = useState<boolean>();
    const TableHeaderRef = useRef<HTMLTableCellElement>(null);

    const actionForRef = (ref) => {
        if (ref && ref.current) {
            if(sorting) {
                const tdElement = ref.current;
                const tdElementCss = getComputedStyle(tdElement);
                const tdWidth = tdElement.offsetWidth - (parseFloat(tdElementCss.paddingLeft) + parseFloat(tdElementCss.paddingRight))
                
                const span = tdElement.querySelector('span');
                const spanWidth = span?.scrollWidth;
                
                const div = tdElement.querySelector('div');
                const divWidth = div?.scrollWidth;
    
                const spanWithDivWidth = spanWidth + divWidth;
                spanWithDivWidth > tdWidth ? setSpanWidth(true) : setSpanWidth(false)
            } else {
                const tdElement = ref.current;
                const tdElementCss = getComputedStyle(tdElement);
                const tdWidth = tdElement.offsetWidth - (parseFloat(tdElementCss.paddingLeft) + parseFloat(tdElementCss.paddingRight))
                
                const span = tdElement.querySelector('span');
                const spanWidth = span?.scrollWidth;
                spanWidth > tdWidth ? setSpanWidth(true) : setSpanWidth(false)
            }
           ;
        }
    };

    const setTitle = (): string => {
        if (spanWidth) {
            return item.displayName;
        } else if (searching) {
            return 'Нажмите для поиска';
        } else {
            return '';
        }
    }

    const getComponent = (): JSX.Element => {
        if (item.propertyType === 'string') {
            return (
                <TextInput
                style={{ height: pluginSettings.rowHeight - 10 }}
                autoFocus
                value={searchText}
                onChange={(value) => {
                    setSearchText(value);
                    onSearch?.(value, item);
                }}
                onEndChange={() => {
                    setShowSearchInput(false);
                }}
                onKeyDown={(e) => {
                    if (e.code === 'Enter')
                        setShowSearchInput(false);
                }}
            /> )
        }

        if (item.propertyType === 'date' || item.propertyType === 'datetime') {
            return (
                <DateInput
                    inputId={item.propertyName}
                    onBlur={(value) => {
                        if (value?.isValid === false) {
                            setSearchText('')
                            onSearch?.('', item);
                            setShowSearchInput(false)
                            return
                        }

                        if (value?.isValid === undefined) {
                            return
                        }

                        setSearchText(value?.toFormat("yyyyMMdd"));
                        onSearch?.(value?.toFormat("yyyyMMdd"), item);
                        setShowSearchInput(false)
                    }}
                />
            )
        }
        
        if (item.propertyType === 'decimal' || item.propertyType === 'number') {
            return (
                <DecimalInputV2 
                    inputId={item.propertyName}
                    autoFocus
                    value={searchText}
                    onChange={(value) => {
                        setSearchText(value.toString());
                        onSearch?.(value.toString(), item);
                    }}
                    onBlur={() => setShowSearchInput(false)}
                    onEndChange={() => setShowSearchInput(false)}
                />
            )
        }

        if (item.propertyType === 'boolean') {
            return (
                <CheckBox
                    id={item.propertyName}
                    defaultChecked={boolean}
                    onBlur={()=> setShowSearchInput(false)}
                    onChanged={(checked) => {
                        setSearchText(String(checked))
                        onSearch?.(String(checked), item)
                        setBoolean(checked)
                    }}
                />
            )
        }

        return <></>
    }

    return (
        <td
            ref={TableHeaderRef}
            onMouseEnter={() => actionForRef(TableHeaderRef)}
            key={index}
            draggable={draggable}
            className={cls.tD}
            data-order-number={item.order}
            data-property-name={item.propertyName}
            data-filter-selected={filtered}
            style={{ width: item.width, background: searchText !== '' ? 'yellow' : undefined }}
            onDragStart={(e) => {
                e.dataTransfer.dropEffect = "move";
                e.dataTransfer.setData("application/json", JSON.stringify(item));
            }}
            onDragOver={(e) => {
                e.preventDefault();
                return false;
            }}
            onDrop={(e) => {
                let firstColumn = JSON.parse(e.dataTransfer.getData("application/json")) as IGridColumn;
                let firstColumnIndex = pluginSettings.columns.findIndex((x) => x.order === firstColumn.order);

                let secondColumnOrder: number = Number((e.currentTarget as HTMLElement).getAttribute("data-order-number"));
                let secondColumn = pluginSettings.columns.find((x) => x.order === secondColumnOrder) as IGridColumn;
                let secondColumnIndex = pluginSettings.columns.findIndex((x) => x.order === secondColumnOrder);

                let columns = pluginSettings.columns;

                columns[firstColumnIndex] = { ...secondColumn, order: firstColumn.order };
                columns[secondColumnIndex] = { ...firstColumn, order: secondColumnOrder };
                setGridColumnsToSave({ columns: columns, save: true });
            }}
        >
            {!showSearchInput ?
                <>
                    <Tooltip className={cls.tooltip} title={setTitle()}>
                        {searching ?
                            <span 
                                style={{cursor: 'pointer'}}
                                onClick={() => setShowSearchInput(true)}
                            >
                                {item.displayName}
                            </span>
                        :
                            <>
                                {item.displayName}
                            </>
                        }
                    </Tooltip>
                    {sorting && (
                        <div draggable={false} className={classNames(cls.sortingDiv)}>
                            <div
                                draggable={false}
                                className={cls.sortingDivButton}
                                onClick={() => {
                                    onSort(item);
                                    if(onPageNumberChange && setPageNumber && filter && setLoadingStatus) {
                                        onPageNumberChange(1);
                                        setPageNumber({
                                            pageNumber: filter.pageNumber,
                                            prevPageNumber: filter.pageNumber,
                                        });
                                        setLoadingStatus(LoadingStatus.InProcess);
                                    }
                                }}
                            >
                                {renderSortIcon(item)}
                            </div>
                            {
                                (searching && !showSearchInput && searchText !== '') && (
                                    <div
                                        className={cls.searchDropDivButton}
                                    >
                                        <BaseIconButton
                                            sizeVariant={'normal'}
                                            onClick={() => {
                                                setSearchText('');
                                                onSearch?.('', item);
                                            }}
                                        >
                                            <MultiplyIcon />
                                        </BaseIconButton>
                                    </div>
                                )
                            }
                            {filtered && (
                                <div draggable={false} className={cls.filteredDiv} onClick={() => onFilterDelete(item)}>
                                    {" "}
                                    <MultiplyIcon />{" "}
                                </div>
                            )}
                        </div>
                    )}
                </>
                    :
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    {getComponent()}
                </div>
            }
        </td>
    );
};
