import {DateTime} from 'luxon';
import {IAnalysisLotResponse, IAnalysisLotViewDTO, ILotDTO, ILotGtdNumberDto, ILotViewDTO, IOnlineReserveDTO} from '../../../libs/coreapi-dto/accounting/lot';
import {ContentType} from '../../fetchApiDistributor/contentTypes';
import {CoreApiResponse, IRequestEventHandler} from '../common';
import {BaseDirRequest} from '../dirs/baseDirRequest';
import { IdTableVariant } from '../../../@types/enumsGlobal';
import { IKizDTO } from '../../coreapi-dto/dirs/kiz';

interface IEntitySimpleDTO {
  readonly idGlobal: string;
  readonly displayName: string;
}

export interface ILotsByIdDTO extends ILotByIdDTO {
  readonly idGlobal: string;
}

export interface ILotByIdDTO {
  idLot: number;
  lotName: string;
  idParentLotGlobal: string;
  docNum: string;
  docDate: DateTime;
  invoiceNum: string;
  invoiceDate: DateTime;
  documentInfo: {
    idTable: number;
    idDocument: string;
    idDocumentItem: string;
  };
  quantityAdd: number;
  quantitySub: number;
  quantityRem: number;
  quantityRes: number;
  goods: IEntitySimpleDTO;
  scalingRatio: IEntitySimpleDTO;
  store: IEntitySimpleDTO;
  productMargin: number;
  supplierCostInfo: {
    vat: number;
    sum: number;
    sumIncVat: number;
    vatSum: number;
    price: number;
    priceIncVat: number;
  };
  retailCostInfo: {
    vat: number;
    sum: number;
    sumIncVat: number;
    vatSum: number;
    price: number;
    priceIncVat: number;
  };
  internalBarcode: string;
  taxType: IEntitySimpleDTO;
  isKiz: boolean;
  idKizGlobalRequested?: string
  idKizOsuGlobalRequested?: string
  kizDto?: IKizDTO
}

class LotRequest extends BaseDirRequest<ILotViewDTO, ILotDTO> {
  constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
    super(baseUrl, 'Lots', token, eventHandler);
  }

  create(body, callback: (e: CoreApiResponse<string>) => void) {
    super.create(body, callback);
  }

  update(idGlobal: string, body, callback: (e: CoreApiResponse<null>) => void) {
    super.update(idGlobal, body, callback);
  }

  getLotById(idGlobal: string, callback: (e) => void) {
    this.get(
      `${this._baseUrl}/${this._routePrefix}/${idGlobal}`,
      this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]),
      (e) => {
        if (this._eventHandler) {
          if (e.respType === 'isFailed') {
            this._eventHandler.onGetResponse(e);
          }
        }
        callback(e);
      }
    );
  }
  getLotByKiz(kiz: string, callback: (e) => void) {
    this.get(
      `${this._baseUrl}/${this._routePrefix}/GetByKizBarcode/${kiz}`,
      this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]),
      (e) => {
        if (this._eventHandler) {
          if (e.respType === 'isFailed') {
            this._eventHandler.onGetResponse(e);
          }
        }
        callback(e);
      }
    );
  }
  getLotByKizBox(kizBox: string, callback: (e) => void) {
    this.get(
      `${this._baseUrl}/${this._routePrefix}/GetByKizBoxBarcode/${kizBox}`,
      this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]),
      (e) => {
        if (this._eventHandler) {
          if (e.respType === 'isFailed') {
            this._eventHandler.onGetResponse(e);
          }
        }
        callback(e);
      }
    );
  }

  getLotByGtinSgtin(gtinSgtin: string, idTable: number, callback: (e) => void) {
    this.get2(
      `${this._baseUrl}/${this._routePrefix}/GetByGtinSgtin/${gtinSgtin}/${idTable}`,
      this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]),
      (e) => {
        if (this._eventHandler) {
          if (e.respType === 'isFailed') {
            this._eventHandler.onGetResponse(e);
          }
        }
        callback(e);
      }
    );
  }

  getViewAnalysis(body: IViewBody, callback?: (e: CoreApiResponse<IViewWrapper<IAnalysisLotViewDTO>>) => void) {
    this.post<IViewWrapper<IAnalysisLotViewDTO>>(
      `${this._baseUrl}/${this._routePrefix}/ViewAnalysis`,body,this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]),
      (e) => {
        if (this._eventHandler) {
          if (e.respType === "isFailed") {
              this._eventHandler.onGetResponse(e);
          } else {
              callback?.(e);
          }
      }
      }
    );
  }

  createDocument(idTableVariant: IdTableVariant,body: IViewBody, callback?: (e: CoreApiResponse<IAnalysisLotResponse>) => void) {
    this.post<IAnalysisLotResponse>(
      `${this._baseUrl}/${this._routePrefix}/${idTableVariant}/CreateDocument`,body,this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]),
      (e) => {
        if (this._eventHandler) {
          if (e.respType === "isFailed") {
              this._eventHandler.onGetResponse(e);
          } else {
              callback?.(e);
          }
      }
      }
    );
  }

  getLotByBarcode(barcode: string, callback: (e) => void) {
    this.get(
      `${this._baseUrl}/${this._routePrefix}/GetByExternalBarcode/${barcode}`,
      this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]),
      (e) => {
        if (this._eventHandler) {
          if (e.respType === 'isFailed') {
            this._eventHandler.onGetResponse(e);
          }
        }
        callback(e);
      }
    );
  }
  getLotByGoodsCode(code: string, callback: (e) => void) {
    this.get(
      `${this._baseUrl}/${this._routePrefix}/GetByGoodsCode/${code}`,
      this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]),
      (e) => {
        if (this._eventHandler) {
          if (e.respType === 'isFailed') {
            this._eventHandler.onGetResponse(e);
          }
        }
        callback(e);
      }
    );
  }
  getLotByInternalBarcode(internalBarcode: string, callback: (e) => void) {
    this.get(
      `${this._baseUrl}/${this._routePrefix}/GetByInternalBarcode/${internalBarcode}`,
      this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]),
      (e) => {
        if (this._eventHandler) {
          if (e.respType === 'isFailed') {
            this._eventHandler.onGetResponse(e);
          }
        }
        callback(e);
      }
    );
  }

  remain(idLotGlobal: string, idDocumentGlobal: string, callback: (e) => void) {
    this.get(
      `${this._baseUrl}/${this._routePrefix}/${idLotGlobal}/remain/${idDocumentGlobal}`,
      this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]),
      (e) => {
        if (this._eventHandler) {
          if (e.respType === 'isFailed') {
            this._eventHandler.onGetResponse(e);
          }
        }
        callback(e);
      }
    );
  }

  onlineReserve(idLotGlobal: string, body: IOnlineReserveDTO, callback: (e: CoreApiResponse<null>) => void) {
    this.put(
      `${this._baseUrl}/${this._routePrefix}/${idLotGlobal}/OnlineReserve`,
      body,
      this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]),
      (e: CoreApiResponse<null>) => {
        if (this._eventHandler) {
          if (e.respType === 'isFailed') {
            this._eventHandler.onGetResponse(e);
          }
        }
        callback(e);
      }
    );
  }

  createGtdNumber(idLotGlobal: string, body: ILotGtdNumberDto, callback: (e: CoreApiResponse<null>) => void) {
    this.put(     
      `${this._baseUrl}/${this._routePrefix}/${idLotGlobal}/CreateGtdNumber`,body,
      this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]),
      (e: CoreApiResponse<null>) => {
        if (this._eventHandler) {
          if (e.respType === 'isFailed') {
            this._eventHandler.onGetResponse(e);
          }
        }
        callback(e);
      }
    );
  }

  deleteOnlineItemReserve(
    idLotGlobal: string,
    idTable: number,
    idDocument: string,
    idDocumentItem: string,
    callback: (e: CoreApiResponse<null>) => void
  ) {
    this.delete(
      `${this._baseUrl}/${this._routePrefix}/${idLotGlobal}/OnlineReserve/${idTable}/${idDocument}/${idDocumentItem}`,
      null,
      this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]),
      (e: CoreApiResponse<null>) => {
        if (this._eventHandler) {
          if (e.respType === 'isFailed') {
            this._eventHandler.onGetResponse(e);
          }
        }
        callback(e);
      }
    );
  }

  deleteOnlineReserve(
    idDocument: string,
    callback: (e: CoreApiResponse<null>) => void
  ) {
    this.delete(
      `${this._baseUrl}/${this._routePrefix}/OnlineReserve/${idDocument}`,
      null,
      this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]),
      (e: CoreApiResponse<null>) => {
        if (this._eventHandler) {
          if (e.respType === 'isFailed') {
            this._eventHandler.onGetResponse(e);
          }
        }
        callback(e);
      }
    );
  }

  getByDocument(idDocGlobal: string, callback: (e: CoreApiResponse<ILotDTO[]>) => void) {
    this.get(
      `${this._baseUrl}/${this._routePrefix}/GetByDocument/${idDocGlobal}`,
      this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]),
      (e: CoreApiResponse<ILotDTO[]>) => {
        if (this._eventHandler) {
          if (e.respType === 'isFailed') {
            this._eventHandler.onGetResponse(e);
          }
        }
        callback(e);
      }
    );
  }

  updateSeries(idLotGlobal: string, idSeriesGlobal: string, callback: (e: CoreApiResponse<null>) => void) {
    this.put(
      `${this._baseUrl}/${this._routePrefix}/${idLotGlobal}/UpdateSeries/${idSeriesGlobal}`,
      null,
      this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]),
      (e: CoreApiResponse<null>) => {
        if (this._eventHandler) {
          if (e.respType === 'isFailed') {
            this._eventHandler.onGetResponse(e);
          }
        }
        callback(e);
      }
    );
  }

}

export default LotRequest;
