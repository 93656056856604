import { RegKeysListPluginSettings } from '../pluginSettings/RegKeysListPluginSettings';
import {v4 as uuidv4} from 'uuid';

export const RegKeysListTab = (): ITab => {
    return {
      id: uuidv4(),
      title: RegKeysListPluginSettings.name,
      view: {
        content: <></>
      }
    }
  }