import React, { PropsWithChildren, useContext, useEffect, useState } from "react"
import { DocumentGridStateType } from "../../@types/documents"
import { DatePeriodPanel } from "../../hoc/DatePeriodPanel/DatePeriodPanel"
import { GridFilterAction } from "../../system/hooks/useGridFilter"
import { useTabsContext } from "../../system/providers/tabsProvider"
import FlexColumn from "../controls/FlexColumn"
import { IDatePeriod } from "../datePeriodPicker/datePeriodPicker"
import { DeleteModalWindow } from "../modalWindows/DeleteModalWindow"
import { RestoreModalWindow } from "../modalWindows/RestoreModalWindow"
import { SearchModalWindow } from "../modalWindows/SearchModalWindow"
import DictionaryCommandsPanel from "./DictionaryCommandsPanel"
import FlexRow from "../controls/FlexRow"

export type DateType = 'date' | 'documentDate'

interface IDefaultDirectoryPanelProps<TEntityViewDTO, TEntityDTO> {
    selectedItem?: [IGridRow | undefined, React.Dispatch<React.SetStateAction<IGridRow | undefined>>]
    gridFilter?: [IGridFilter, React.Dispatch<GridFilterAction>]
    multipleSelect?: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
    DefaultDatePeriod?: IDatePeriod
    DefaultGridFilter?: IGridFilter
    dataProvider: IDictionaryDataProvider<TEntityViewDTO, TEntityDTO>
    pluginSettings: IPluginSettings
    plugin: IPlugin
    setViewState?: (viewState: GridStateType) => void
    dateType?: DateType
    isInactiveToAdd?: boolean
    isInactiveToCopy?: boolean
    isInactiveToPrint?: boolean
}

const DefaultDictionaryCommandsPanelV2 = <TEntityViewDTO, TEntityDTO>(
    props: PropsWithChildren<IDefaultDirectoryPanelProps<TEntityViewDTO, TEntityDTO>>) => {

    const tabsContext = useTabsContext();
    const [datePeriod, setDatePeriod] = useState<IDatePeriod | undefined>(props.DefaultDatePeriod);
    const [isDeleted, setIsDeleted] = useState<boolean>(true);
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [modalWindow, setModalWindow] = useState(<></>)

    useEffect(() => {
        renderModalWindow()
    }, [viewState])

    useEffect(() => {
        if(viewState === 'refresh')  refreshDirectory()
    }, [viewState])

    const dispatchFilter = (dispatchGridFilter: (data: GridFilterAction) => void, datePeriod?: IDatePeriod, isDeleted?: boolean) => {
        let newColumnFilters = [] as IGridColumnFilter[];

        let defaultFilter = [
            ...(isDeleted? [{
                name: 'deleted',
                value: !isDeleted,
                operator: 'Eq'
            } as IGridColumnFilter] : [])
        ]

        if (props.gridFilter) newColumnFilters = props.gridFilter[0].columnFilters.filter(c => c.name !== 'dateStart' && c.name !== 'dateEnd' && c.name !== 'deleted')        
        newColumnFilters.push(...defaultFilter)    
        
        dispatchGridFilter({
            type: 'paramSimpleFilter',
            payload: {
                gridColumnFilter: newColumnFilters,
                gridParamFilter: { 
                    dateStart: datePeriod?.startDate,
                    dateEnd: datePeriod?.endDate
                }
            }
        })
    }

    function refreshDirectory() {
        props.setViewState?.('refresh')
        dispatchFilter(() =>props.gridFilter?.[1], datePeriod, isDeleted)
        setViewState('view')
    }

    function renderModalWindow() {
        switch (viewState) {
            case "delete": renderDeleteModalWindow(); break;
            case "restore": renderRestoreModalWindow(); break;
            case "search": renderSearchWindow(); break;
            default: setModalWindow(<></>); break;
        }
    }

    function renderDeleteModalWindow() {
        setModalWindow(
            <DeleteModalWindow
                name={props.selectedItem?.[0]?.cells["name"] as string}
                header={props.selectedItem?.[0]?.displayName}
                cancel={{ onClick: () => { setViewState('return') } }}
                delete={{
                    onClick: () => {
                        props.dataProvider?.markDelete(props.selectedItem?.[0]?.idGlobal as string, () => setViewState("refresh"))
                        props.selectedItem?.[1](undefined)
                        refreshDirectory()
                    },
                    title: 'Удалить'
                }}
            />
        )
    }

    function renderRestoreModalWindow() {
        setModalWindow(
            <RestoreModalWindow
                name={props.selectedItem?.[0]?.cells["name"] as string}
                header={props.selectedItem?.[0]?.displayName}
                cancel={{ onClick: () => { setViewState('return') } }}
                restore={{
                    onClick: () => {
                        props.dataProvider?.restore(props.selectedItem?.[0]?.idGlobal as string, () => setViewState("refresh"))
                        props.selectedItem?.[1](undefined)
                        refreshDirectory()
                    },
                    title: 'Восстановить'
                }}
            />
        )
    }

    function renderSearchWindow() {
        const visibleColumns = props.pluginSettings?.columns.filter((x) => x.visibility === true) ?? []

        setModalWindow(
            <SearchModalWindow
                columns={visibleColumns}
                cancel={() => setViewState("return")}
                defaultFilters={props.gridFilter?.[0].columnFilters}
                search={(columnFilters) => {
                    props.gridFilter?.[1]({ type: 'search', payload: columnFilters })
                    setViewState('view')
                }}
            />
        )
    }


    return <>
            <FlexRow wrap>
                <DictionaryCommandsPanel
                    add={{
                        onClick: () => tabsContext.openChild(props.plugin, "create", undefined, () => {
                            setViewState("refresh")
                            props.selectedItem?.[1]?.(undefined)
                        }),
                        disabled: props.isInactiveToAdd ?? false

                    }}
                    edit={{
                        onClick: () => tabsContext.openChild(props.plugin, "edit", props.selectedItem?.[0]?.idGlobal, () => {
                            setViewState("refresh")
                            props.selectedItem?.[1]?.(undefined)
                        }),
                        disabled: props.selectedItem?.[0] === undefined
                            || props.selectedItem?.[0]?.isDeleted
                    }}
                    copy={{
                        onClick: () => tabsContext.openChild(props.plugin, "copy", props.selectedItem?.[0]?.idGlobal, () => setViewState("refresh")),
                        disabled: props.isInactiveToCopy ?? props.selectedItem?.[0] === undefined
                    }}
                    delete={{
                        onClick: () => setViewState("delete"),
                        disabled: props.selectedItem?.[0] === undefined
                            || props.selectedItem?.[0]?.isDeleted
                    }}
                    restore={{
                        onClick: () => setViewState("restore"),
                        disabled: props.selectedItem?.[0] === undefined
                            || !props.selectedItem?.[0]?.isDeleted
                    }}
                    search={{
                        onClick: () => setViewState("search"),
                        disabled: false,
                    }}
                    refresh={{
                        onClick: () => {
                            refreshDirectory()
                        }
                    }}
                    print={{
                        onClick: () => window.alert("print"),
                        disabled: props.isInactiveToPrint
                    }}
                    showDeleted={{
                        disabled: false,
                        onChecked: (e) => {
                            if (props.gridFilter?.[1]) {
                                setIsDeleted(!e)
                                dispatchFilter(props.gridFilter[1], datePeriod,!e)
                            }
                        }
                    }}
                    permission={props.pluginSettings.permission}
                />
                {
                    props.DefaultDatePeriod ?
                        <DatePeriodPanel
                            datePeriod={datePeriod as IDatePeriod}
                            onDatePeriodChange={(date: IDatePeriod) => {
                                setDatePeriod(date)
                                if (props.gridFilter?.[1]) {
                                    dispatchFilter(props.gridFilter[1], date,isDeleted)
                                }
                            }}
                        /> : ''
                }
            </FlexRow>

        {modalWindow}
    </>
}

export default DefaultDictionaryCommandsPanelV2