import  { FC } from 'react'
import BaseCommandsPanel, { ICommandsPanelCheckboxProps, ShowDeletedCheckbox } from "../../../../components/commandsPanels/BaseCommandsPanel"
import Tooltip from "../../../../components/tooltips/Tooltip"
import { checkActiveCommands } from '../../../CommonHelperFunctions'
import { useUserContext } from '../../../../system/providers/userContextProvider'
import { Delete2Button, DeleteButton } from '../../../../components/buttons/iconButtons/action/MainCollection'
import { useTranslation } from 'react-i18next'
import { HighLightButton } from '../../../../components/buttons/iconButtons/editor/ActionCollection'
import styles from "../Styles/AccessPointView.module.scss";

interface IPanelEvent {
    disabled?: boolean
    onClick: () => void
}
interface IAccessPointDetailCommandPanelProps { 
    edit?: IPanelEvent
    delete?: IPanelEvent
    restore?: IPanelEvent
    showDeleted: ICommandsPanelCheckboxProps
    permission?: IPermission
}

export const AccessPointDetailCommandPanel: FC<IAccessPointDetailCommandPanelProps> = (props) => {

    const userContext = useUserContext();
    const activeCommands = checkActiveCommands("AccessPoint", userContext.userPermission);
    
    const { t } = useTranslation();
    const baseT = (value: string) => t('documentsCommandsPanel.' + value)

    return (
        <>
            <BaseCommandsPanel groups={[
                [                
                    {
                        iconButton: <Tooltip title={baseT('editNote')}>
                            <HighLightButton sizeVariant="mini" {...{...props.edit, disabled: props.edit?.disabled || !activeCommands.edit}} />
                        </Tooltip>
                    },
                ],
                [
                    {
                        iconButton: <Tooltip title={baseT('deleteNote')}>
                            <Delete2Button sizeVariant="mini" colorVariant="danger" {...{...props.delete, disabled: props.delete?.disabled || !activeCommands.delete}} />
                        </Tooltip>
                    },                
                    {
                        iconButton: <Tooltip title="Восстановить запись">
                            <DeleteButton sizeVariant="mini" colorVariant="success" {...{...props.restore, disabled: props.restore?.disabled || !activeCommands.restore}} />
                        </Tooltip>
                    },
                ]
            ]} />            
            <div className={styles.controlRight}>
                <ShowDeletedCheckbox {...{...props.showDeleted, disabled: props.showDeleted?.disabled || !activeCommands.showDeleted}} />
            </div>
        </>
    )
}