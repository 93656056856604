import { FC, useEffect, useState } from "react"
import { useAppContext } from "../../../../system/providers/appContextProvider"
import { useTabsContext } from "../../../../system/providers/tabsProvider"
import { MovementDataProvider } from "../../../../Services/DataProviders/MovementDataProvider";
import { IMovementCreateDTO, IMovementGetDTO, IMovementUpdateDTO } from "../../../../libs/coreapi-dto/documents/movement";
import { IDocumentCreatorProps } from "../../../../@types/documents";
import { MovementUpdateForm } from "./MovementUpdateForm";
import { getMovementItemUpdateDTO } from "../../../../libs/core-api-requests/customRequest/MovementItemUpdateRequest";
import { v4 as uuidv4 } from 'uuid';
import { IMovementItemEditDTO, IMovementItemGetDTO } from "../../../../libs/coreapi-dto/documents/movementItem";
import { DateTime } from "luxon";

interface IEditDocument {
    movementDocument: IMovementGetDTO
    documentUpdate: IMovementUpdateDTO
}

export const MovementCreatorView: FC<IDocumentCreatorProps<IMovementCreateDTO>> = (props) => {
    const appContext = useAppContext()
    const tabsContext = useTabsContext()
    const movementDP = new MovementDataProvider(appContext.coreApiService);
    const [idGlobal, setIdGlobal] = useState(props.idGlobal)
    const [viewState, setViewState] = useState<GridStateType>(props.variant)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [editDocument, setEditDocument] = useState<IEditDocument | null>(null);

    useEffect(() => {
        if (viewState === 'edit') {
            movementDP.getById(idGlobal as string, (movementDocument) => {
                getMovementItemUpdateDTO(movementDocument.idGlobal as string, (e) => {
                    const documentUpdate: IMovementUpdateDTO = {
                        idGlobal: movementDocument.idGlobal as string,
                        documentDate: movementDocument.documentDate,
                        idStoreFromGlobal: movementDocument.storeFrom.idGlobal,
                        idStoreToGlobal: movementDocument?.storeTo?.idGlobal,
                        isNewBarcode: movementDocument.isNewBarcode,
                        isCrosspharmacy: movementDocument.isCrosspharmacy,
                        idGosContractGlobal: movementDocument?.gosContract?.idGlobal ?? undefined,
                        idMovementReasonGlobal: movementDocument?.movementReason?.idGlobal ?? undefined,
                        orderNumber: movementDocument.orderNumber,
                        reserveDate: movementDocument.reserveDate,
                        userComment: movementDocument.userComment,
                        items: e
                    }
                    setEditDocument({ movementDocument: movementDocument, documentUpdate: documentUpdate })
                })
            })
        }
        if (viewState === 'copy') {
            movementDP.getById(idGlobal as string, (entity) => {
                let idGlobalNew = uuidv4();
                const documentUpdate: IMovementUpdateDTO = {
                    idGlobal: idGlobalNew,
                    baseDocumentInfo: entity.baseDocumentInfo,
                    documentDate: entity.documentDate,
                    idStoreFromGlobal: entity.storeFrom.idGlobal,
                    idStoreToGlobal: entity.storeTo?.idGlobal,
                    userComment: entity.userComment,
                    isNewBarcode: entity.isNewBarcode,
                    idGosContractGlobal: entity?.gosContract?.idGlobal ?? undefined,
                    idMovementReasonGlobal: entity?.movementReason?.idGlobal ?? undefined,
                    orderNumber: entity.orderNumber,
                    reserveDate: entity.reserveDate,
                    isCrosspharmacy: entity.isCrosspharmacy,
                    items: [] as IMovementItemEditDTO[]
                };
                setEditDocument({ 
                    movementDocument: {...entity, idGlobal: idGlobalNew, items: [] as IMovementItemGetDTO[], mnemocode: ''}, 
                    documentUpdate: documentUpdate
                })
            })           
        }
    }, [viewState])

    return (
        <>
            {
                viewState === "create"
                    ?
                    <MovementUpdateForm
                        isSubmitting={isSubmitting}
                        document={null}
                        documentItems={null}
                        documentUpdate={null}
                        idGlobal={props.idGlobal}
                        variant='create'
                        save={(movement: IMovementCreateDTO) => {
                            setIsSubmitting(true)
                            movementDP.create(movement, (id) => {
                                setIdGlobal(id)
                                setViewState('edit');
                                props.createdCallback?.()
                                setIsSubmitting(false)
                            }, ()=>{}, (e)=>{
                                setIsSubmitting(false);
                            })
                        }}
                        ok={(movement: IMovementCreateDTO) => {
                            setIsSubmitting(true)
                            movementDP.create(movement, (id) => {
                                setIdGlobal(id)
                                setViewState("create")
                                props.createdCallback?.()
                                tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)
                                setIsSubmitting(false)
                            }, ()=>{}, (e)=>{
                                setIsSubmitting(false);
                            })
                        }}
                        cancel={() => tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)}
                        permission={props.permission}
                    />
                    :
                    viewState === "edit" ?
                        editDocument && <MovementUpdateForm
                            isSubmitting={isSubmitting}
                            variant='edit'
                            idGlobal={editDocument.documentUpdate.idGlobal}
                            save={(movement: IMovementUpdateDTO) => {
                                setIsSubmitting(true)
                                movementDP.update(idGlobal as string, movement, (e) => {
                                    if (e.respType === 'isFailed') return setIsSubmitting(false);

                                    props.createdCallback?.()
                                    setIsSubmitting(false)
                                })
                            }}
                            ok={(movement: IMovementUpdateDTO) => {
                                setIsSubmitting(true);
                                movementDP.update(idGlobal as string, movement, (e) => {
                                    if (e.respType === 'isFailed') return setIsSubmitting(false);

                                    props.createdCallback?.()
                                    tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)
                                    setIsSubmitting(false);
                                })
                            }}
                            cancel={() => tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)}
                            document={editDocument.movementDocument}
                            documentItems={editDocument.movementDocument.items}
                            documentUpdate={editDocument.documentUpdate}
                            permission={props.permission}
                        />
                        :
                        viewState === "copy" ?
                            editDocument && <MovementUpdateForm
                                isSubmitting={isSubmitting}
                                variant='copy'
                                save={(movement: IMovementUpdateDTO) => {
                                    setIsSubmitting(true)
                                    movementDP.create(movement, (id) => {
                                        setIdGlobal(id)
                                        setViewState('edit')
                                        props.createdCallback?.()
                                        setIsSubmitting(false)
                                    }, ()=>{}, (e)=>{
                                        setIsSubmitting(false);
                                    })
                                }}
                                ok={(movement: IMovementUpdateDTO) => {
                                    setIsSubmitting(true);
                                    movementDP.create(movement, (id) => {
                                        setIdGlobal(id)
                                        setViewState("create")
                                        props.createdCallback?.()
                                        tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)
                                        setIsSubmitting(false);
                                    }, ()=>{}, (e)=>{
                                        setIsSubmitting(false);
                                    })
                                }}
                                cancel={() => tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)}
                                document={editDocument.movementDocument}
                                documentItems={editDocument.movementDocument.items}
                                documentUpdate={editDocument.documentUpdate}
                                permission={props.permission}
                            />
                            :
                            <></>
            }
        </>
    );
}