import { FC, useState, useEffect } from "react";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { IDocumentCreatorProps } from "../../../../@types/documents";
import { useTabsContext } from "../../../../system/providers/tabsProvider";
import { v4 as uuidv4 } from 'uuid';
import { useTestApiContext } from "../../../../system/providers/testApiProvider";
import { ImportRemainsDataProvider } from "../../../../Services/DataProviders/ImportRemainsDataProvider";
import { IImportRemainsCreateDTO, IImportRemainsGetDTO, IImportRemainsUpdateDTO } from "../../../../libs/coreapi-dto/documents/importRemains";
import { IImportRemainsItemEditDTO, IImportRemainsItemGetDTO, IImportRemainsItemViewDTO } from "../../../../libs/coreapi-dto/documents/importRemainsItem";
import { getImportRemainsItemUpdateDTO } from "../../../../libs/core-api-requests/customRequest/ImportRemainsItemUpdateRequest";
import ImportRemainsUpdateForm from "./ImportRemainsUpdateForm";
import { DateTime } from "luxon";
import { Spinner } from "../../../../components/spiner/Spinner";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { KizDataProvider } from "../../../../Services/DataProviders/KizDataProvider";
import { KizBoxDataProvider } from "../../../../Services/DataProviders/KizBoxDataProvider";
import { IKizBoxViewDTO, IKizViewDTO } from "../../../../libs/coreapi-dto/dirs/kiz";
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";
import { IKizOsuDTO } from "../../../../libs/coreapi-dto/dirs/KizOsu";

interface IEditDocument {
    importRemainsDocument: IImportRemainsGetDTO
    documentItems: IImportRemainsItemGetDTO[]
    documentUpdate: IImportRemainsUpdateDTO
}

interface ICopyDocument {
    importRemainsDocumentCopy: IImportRemainsGetDTO
    documentUpdate: IImportRemainsUpdateDTO
}

type EntitySimpleInput = string | undefined;
const createEntitySimple = (displayName: EntitySimpleInput = '', idGlobal: EntitySimpleInput = ''): IEntitySimpleDTO => ({
    displayName,
    idGlobal
});

const ImportRemainsCreatorView: FC<IDocumentCreatorProps<IImportRemainsCreateDTO>> = (props) => {
    const appContext = useAppContext();
    const tabsContext = useTabsContext();
    const testApiContext = useTestApiContext();

    const idp = new ImportRemainsDataProvider(appContext.coreApiService)

    const [viewState, setViewState] = useState<GridStateType>(props.variant)
    const [id, setId] = useState<string | undefined>(props.idGlobal)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [editDocument, setEditDocument] = useState<IEditDocument | null>(null);
    const [copyDocument, setCopyDocument] = useState<ICopyDocument | null>(null);
    const [loader, setLoader] = useState<boolean>(true);
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const kizDataProvider = new KizDataProvider(appContext.coreApiService)
    const kizBoxDataProvider = new KizBoxDataProvider(appContext.coreApiService);

    useEffect(() => {
        if (viewState === 'edit') {

            (async function () {

                try {
                    //1 Получение тела документа
                    const getBodyDocument = await new Promise<IImportRemainsGetDTO>((resolve) =>
                        idp.getByIdHeader(id as string, async (importRemainsDocument) => resolve(importRemainsDocument))
                    );

                    //2 Получение позиций документа 
                    const getItemsDocument = await new Promise<IImportRemainsItemViewDTO[]>((resolve) =>
                        idp.getItemsView(getBodyDocument.idGlobal as string, { ...gridFilter, numberPerPage: 10000 }, async (entities, totalCount) => resolve(entities))
                    );

                    //3 Получение кизов у позиций
                    const getKizItems = await new Promise<IKizViewDTO[]>((resolve) =>
                        kizDataProvider.getKizViewAsync(getBodyDocument.idGlobal, gridFilter, async (entities, totalCount) => resolve(entities))
                    );

                    //4 Получение коробов у позиций
                    const getKizBoxItems = await new Promise<IKizBoxViewDTO[]>((resolve) =>
                        kizBoxDataProvider.getKizBoxViewAsync(getBodyDocument.idGlobal, gridFilter, async (entities, totalCount) => resolve(entities))
                    );

                    const itemsUpdate: IImportRemainsItemEditDTO[] = []
                    const documentItems: IImportRemainsItemGetDTO[] = []

                    getItemsDocument.forEach(item => {
                        itemsUpdate.push({
                            idGlobal: item.idGlobal,
                            isOsu: item.isOsu,
                            kizOsuDto: item.isOsu ? {
                                idGlobal: item.idKizOsuGlobal,
                                idDocumentItem: item.idKizOsuDocumentItem,
                                idChequeItemGlobal: item.idKizOsuChequeItemGlobal,
                                barcode: item.kizOsuBarcode,
                                gtin: item.kizOsuGtin,
                                sGtin: item.kizOsuSgtin,
                                gtinSGtin: item.kizOsuGtinSgtin,
                            } as IKizOsuDTO : undefined,
                            idGoodsGlobal: item.idGoodsGlobal,
                            idScalingRatioGlobal: item.idScalingRatioGlobal,
                            idSeriesGlobal: item.idSeriesGlobal,
                            quantity: item.quantity,
                            producerPrice: item.producerPrice,
                            productMargin: item.productMargin,
                            kizCount: item.kizCount,
                            kizBoxCount: item.kizBoxCount,
                            supplierCostInfo: {
                                adprice: item.supplierAdprice,
                                vatPrice: item.supplierVatPrice,
                                vat: item.supplierVat,
                                sum: item.supplierSum,
                                sumIncVat: item.supplierSumIncVat,
                                vatSum: item.supplierVatSum,
                                price: item.supplierPrice,
                                priceIncVat: item.supplierPriceIncVat
                            },
                            retailCostInfo: {
                                adprice: item.retailAdprice,
                                vatPrice: item.retailVatPrice,
                                vat: item.retailVat,
                                sum: item.retailSum,
                                sumIncVat: item.retailSumIncVat,
                                vatSum: item.retailVatSum,
                                price: item.retailPrice,
                                priceIncVat: item.retailPriceIncVat
                            },
                            supplierGoodsCode: item.supplierGoodsCode,
                            gtdNumber: item.gtdNumber,
                            barCode: item.barCode,
                            registerPrice: item.registerPrice,
                            retailPriceIncVat: item.retailPriceIncVat,
                            isKiz: item.isKiz,
                            isKizBox: item.isKizBox,
                            kizs: [],
                            kizBoxes: [],
                            isGnvls: item.isGnvls,
                            supplierDocDate: item.supplierDocDate,
                            supplierDocNumber: item.supplierDocNumber,
                            idSupplierGlobal: item.idSupplierGlobal,
                            denominator: item.denominator,
                            numerator: item.numerator,
                            dateCreated: item.dateCreated,
                            incomingBillDate: item?.incomingBillDate,
                            incomingBillNumber: item?.incomingBillNumber,
                        })
                        documentItems.push({
                            idGlobal: item.idGlobal,
                            goods: createEntitySimple(item.goodsName, item.idGoodsGlobal),
                            kizOsuDto: {
                                idGlobal: item.idKizOsuGlobal,
                                idDocumentItem: item.idKizOsuDocumentItem,
                                idChequeItemGlobal: item.idKizOsuChequeItemGlobal,
                                barcode: item.kizOsuBarcode,
                                gtin: item.kizOsuGtin,
                                sGtin: item.kizOsuSgtin,
                                gtinSGtin: item.kizOsuGtinSgtin,
                            } as IKizOsuDTO,
                            isOsu: item.isOsu,
                            scalingRatio: createEntitySimple(item.scalingRatioName, item.idScalingRatioGlobal),
                            series: createEntitySimple(item.seriesNumber, item.idSeriesGlobal),
                            quantity: item.quantity,
                            producerPrice: item.producerPrice,
                            productMargin: item.productMargin,
                            supplierCostInfo: {
                                adprice: item.supplierAdprice,
                                vatPrice: item.supplierVatPrice,
                                vat: item.supplierVat,
                                sum: item.supplierSum,
                                sumIncVat: item.supplierSumIncVat,
                                vatSum: item.supplierVatSum,
                                price: item.supplierPrice,
                                priceIncVat: item.supplierPriceIncVat
                            },
                            retailCostInfo: {
                                adprice: item.retailAdprice,
                                vatPrice: item.retailVatPrice,
                                vat: item.retailVat,
                                sum: item.retailSum,
                                sumIncVat: item.retailSumIncVat,
                                vatSum: item.retailVatSum,
                                price: item.retailPrice,
                                priceIncVat: item.retailPriceIncVat
                            },
                            supplierGoodsCode: item.supplierGoodsCode,
                            gtdNumber: item.gtdNumber,
                            barCode: item.barCode,
                            registerPrice: item.registerPrice,
                            retailPriceIncVat: item.retailPriceIncVat,
                            isKiz: item.isKiz,
                            isKizBox: item.isKizBox,
                            kizBoxCount: item.kizBoxCount,
                            kizCount: item.kizCount,
                            isGnvls: item.isGnvls,
                            supplierDocDate: item.supplierDocDate,
                            supplierDocNumber: item.supplierDocNumber,
                            supplier: createEntitySimple(item.supplier, item.idSupplierGlobal),
                            denominator: item.denominator,
                            numerator: item.numerator,
                            dateCreated: item.dateCreated,
                            incomingBillDate: item?.incomingBillDate,
                            incomingBillNumber: item?.incomingBillNumber,
                        } as IImportRemainsItemGetDTO)
                    })

                    const generateDocument = (items: any[]) => {
                        const documentUpdate: IImportRemainsUpdateDTO = {
                            idGlobal: getBodyDocument.idGlobal,
                            documentDate: getBodyDocument.documentDate.toF3FixedZoneNow(),
                            idStoreGlobal: getBodyDocument.store.idGlobal,
                            idPricingModelGlobal: getBodyDocument.pricingModel?.idGlobal,
                            payerName: getBodyDocument.payerName,
                            datePay: getBodyDocument.datePay,
                            items: items
                        }
                        setEditDocument({
                            importRemainsDocument: getBodyDocument,
                            documentItems: documentItems,
                            documentUpdate: documentUpdate
                        })
                        setLoader(false);
                    }

                    if (getKizItems.length > 0 || getKizBoxItems.length > 0) {
                        const result: IImportRemainsItemEditDTO[] = itemsUpdate.map(el => {

                            const findKiz = getKizItems.filter(item => item.idDocumentItemAdd === el.idGlobal);
                            const findBox = getKizBoxItems.filter(item => item.idGlobal === el.idGlobal);

                            if (findKiz && findBox) {
                                return {
                                    ...el,
                                    kizs: findKiz,
                                    kizBoxes: findBox
                                };
                            } else if (findKiz) {
                                return {
                                    ...el,
                                    kizs: findKiz
                                };
                            } else if (findBox) {
                                return {
                                    ...el,
                                    kizBoxes: findBox
                                };
                            }
                            return el;
                        });
                        generateDocument(result);

                    } else {
                        generateDocument(itemsUpdate);
                    }

                } catch (error) {
                    console.log("error:", error)
                }

            }());
        }

        if (viewState === 'copy') {
            (async function () {

                try {
                    const getBodyDocument = await new Promise<IImportRemainsGetDTO>((resolve) =>
                        idp.getByIdHeader(id as string, async (importRemainsDocument) => resolve(importRemainsDocument))
                    );

                    //2 Получение позиций документа 
                    const getItemsDocument = await new Promise<IImportRemainsItemViewDTO[]>((resolve) =>
                        idp.getItemsView(getBodyDocument.idGlobal as string, { ...gridFilter, numberPerPage: 10000 }, async (entities, totalCount) => resolve(entities))
                    );

                    //3 Получение кизов у позиций
                    const getKizItems = await new Promise<IKizViewDTO[]>((resolve) =>
                        kizDataProvider.getKizViewAsync(getBodyDocument.idGlobal, gridFilter, async (entities, totalCount) => resolve(entities))
                    );

                    //4 Получение коробов у позиций
                    const getKizBoxItems = await new Promise<IKizBoxViewDTO[]>((resolve) =>
                        kizBoxDataProvider.getKizBoxViewAsync(getBodyDocument.idGlobal, gridFilter, async (entities, totalCount) => resolve(entities))
                    );

                    const itemsUpdate: IImportRemainsItemEditDTO[] = []
                    const itemsImportRemainsDocumentCopy: IImportRemainsItemGetDTO[] = []
                    getItemsDocument.forEach(item => {
                        const idGlobalNew = uuidv4()
                        itemsUpdate.push({
                            idGlobal: idGlobalNew,
                            idGoodsGlobal: item.idGoodsGlobal,
                            idScalingRatioGlobal: item.idScalingRatioGlobal,
                            idSeriesGlobal: item.idSeriesGlobal,
                            quantity: item.quantity,
                            isOsu: item.isOsu,
                            producerPrice: item.producerPrice,
                            productMargin: item.productMargin,
                            supplierCostInfo: {
                                adprice: item.supplierAdprice,
                                vatPrice: item.supplierVatPrice,
                                vat: item.supplierVat,
                                sum: item.supplierSum,
                                sumIncVat: item.supplierSumIncVat,
                                vatSum: item.supplierVatSum,
                                price: item.supplierPrice,
                                priceIncVat: item.supplierPriceIncVat
                            },
                            retailCostInfo: {
                                adprice: item.retailAdprice,
                                vatPrice: item.retailVatPrice,
                                vat: item.retailVat,
                                sum: item.retailSum,
                                sumIncVat: item.retailSumIncVat,
                                vatSum: item.retailVatSum,
                                price: item.retailPrice,
                                priceIncVat: item.retailPriceIncVat
                            },
                            supplierGoodsCode: item.supplierGoodsCode,
                            gtdNumber: item.gtdNumber,
                            barCode: item.barCode,
                            registerPrice: item.registerPrice,
                            retailPriceIncVat: item.retailPriceIncVat,
                            isKiz: item.isKiz,
                            supplierDocDate: item.supplierDocDate,
                            supplierDocNumber: item.supplierDocNumber,
                            idSupplierGlobal: item.idSupplierGlobal,
                            denominator: item.denominator,
                            numerator: item.numerator,
                            dateCreated: DateTime.now(),
                            incomingBillDate: item?.incomingBillDate,
                            incomingBillNumber: item?.incomingBillNumber,
                        })
                        itemsImportRemainsDocumentCopy.push({
                            idGlobal: idGlobalNew,
                            goods: createEntitySimple(item.goodsName, item.idGoodsGlobal),
                            kizOsuDto: {
                                idGlobal: item.idKizOsuGlobal,
                                idDocumentItem: item.idKizOsuDocumentItem,
                                idChequeItemGlobal: item.idKizOsuChequeItemGlobal,
                                barcode: item.kizOsuBarcode,
                                gtin: item.kizOsuGtin,
                                sGtin: item.kizOsuSgtin,
                                gtinSGtin: item.kizOsuGtinSgtin,
                            } as IKizOsuDTO,
                            isOsu: item.isOsu,
                            scalingRatio: createEntitySimple(item.scalingRatioName, item.idScalingRatioGlobal),
                            series: createEntitySimple(item.seriesNumber, item.idSeriesGlobal),
                            quantity: item.quantity,
                            producerPrice: item.producerPrice,
                            productMargin: item.productMargin,
                            supplierCostInfo: {
                                adprice: item.supplierAdprice,
                                vatPrice: item.supplierVatPrice,
                                vat: item.supplierVat,
                                sum: item.supplierSum,
                                sumIncVat: item.supplierSumIncVat,
                                vatSum: item.supplierVatSum,
                                price: item.supplierPrice,
                                priceIncVat: item.supplierPriceIncVat
                            },
                            retailCostInfo: {
                                adprice: item.retailAdprice,
                                vatPrice: item.retailVatPrice,
                                vat: item.retailVat,
                                sum: item.retailSum,
                                sumIncVat: item.retailSumIncVat,
                                vatSum: item.retailVatSum,
                                price: item.retailPrice,
                                priceIncVat: item.retailPriceIncVat
                            },
                            supplierGoodsCode: item.supplierGoodsCode,
                            gtdNumber: item.gtdNumber,
                            barCode: item.barCode,
                            registerPrice: item.registerPrice,
                            retailPriceIncVat: item.retailPriceIncVat,
                            isKiz: item.isKiz,
                            isKizBox: item.isKizBox,
                            kizBoxCount: item.kizBoxCount,
                            kizCount: item.kizCount,
                            isGnvls: item.isGnvls,
                            supplierDocDate: item.supplierDocDate,
                            supplierDocNumber: item.supplierDocNumber,
                            supplier: createEntitySimple(item.supplier, item.idSupplierGlobal),
                            denominator: item.denominator,
                            numerator: item.numerator,
                            dateCreated: item.dateCreated,
                            incomingBillDate: item?.incomingBillDate,
                            incomingBillNumber: item?.incomingBillNumber,
                        } as IImportRemainsItemGetDTO)
                    });

                    const generateDocument = (items: any[]) => {
                        const idGlobalNew = uuidv4();
                        const documentUpdate: IImportRemainsUpdateDTO = {
                            idGlobal: idGlobalNew,
                            documentDate: getBodyDocument.documentDate.toF3FixedZoneNow(),
                            idStoreGlobal: getBodyDocument.store.idGlobal,
                            idPricingModelGlobal: getBodyDocument?.pricingModel?.idGlobal,
                            payerName: getBodyDocument.payerName,
                            datePay: getBodyDocument.datePay,
                            items: items
                        }
                        const importRemainsDocumentCopy: IImportRemainsGetDTO = {
                            idGlobal: idGlobalNew,
                            documentDate: getBodyDocument.documentDate.toF3FixedZoneNow(),
                            displayName: getBodyDocument.displayName,
                            documentState: getBodyDocument.documentState,
                            documentStateModified: getBodyDocument.documentStateModified,
                            mnemocode: '',
                            store: getBodyDocument.store,
                            pricingModel: getBodyDocument.pricingModel,
                            averagePercentSumIncVat: getBodyDocument.averagePercentSumIncVat,
                            payerName: getBodyDocument.payerName,
                            datePay: getBodyDocument.datePay,
                            items: itemsImportRemainsDocumentCopy
                        }
                        setCopyDocument({
                            importRemainsDocumentCopy: importRemainsDocumentCopy,
                            documentUpdate: documentUpdate
                        })
                    }

                    if (getKizItems.length > 0 || getKizBoxItems.length > 0) {
                        const result: IImportRemainsItemEditDTO[] = itemsUpdate.map(el => {

                            const findKiz = getKizItems.filter(item => item.idDocumentItemAdd === el.idGlobal);
                            const findBox = getKizBoxItems.filter(item => item.idGlobal === el.idGlobal);

                            if (findKiz && findBox) {
                                return {
                                    ...el,
                                    kizs: findKiz,
                                    kizBoxes: findBox
                                };
                            } else if (findKiz) {
                                return {
                                    ...el,
                                    kizs: findKiz
                                };
                            } else if (findBox) {
                                return {
                                    ...el,
                                    kizBoxes: findBox
                                };
                            }
                            return el;
                        });
                        generateDocument(result);
                    } else {
                        generateDocument(itemsUpdate);
                    }
                    setLoader(false);
                } catch (error) {
                    console.log("error:", error)
                }
            }())
        }

    }, [viewState])

    return (
        <>  
            {viewState !== 'create' && loader && <Spinner/>}
            { 
                viewState === "create" ? 
                    <ImportRemainsUpdateForm
                        isSubmitting={isSubmitting}
                        document={null}
                        documentItems={null}
                        documentUpdate={null}
                        isTest={props.isTest}
                        variant="create"
                        save={(importRemains) => {
                            setIsSubmitting(true)
                            idp.create(importRemains, (id) => {
                                setId(id);
                                setViewState('edit');
                                props.createdCallback?.();
                                setIsSubmitting(false);
                            }, ()=>{}, (e)=>{
                                setIsSubmitting(false);
                            })
                        }}
                        ok={(importRemains) => {
                            if (props.isTest) {
                                setIsSubmitting(true)
                                idp.create(importRemains, (id) => {
                                    setId(id)
                                    props.createdCallback?.()
                                    setViewState("create")
                                    tabsContext.close(tabsContext.tabs.find(x => x.id === props.tabId) as ITab)
                                    setIsSubmitting(false)
                                }, (value) => {
                                    testApiContext.setApiInfo(value)
                                }, (e)=>{
                                    setIsSubmitting(false);
                                })
                            } else {
                                setIsSubmitting(true)
                                idp.create(importRemains, (id) => {
                                    setId(id)
                                    props.createdCallback?.()
                                    setViewState("create")
                                    tabsContext.currentTab && tabsContext.closeCurrent()
                                    setIsSubmitting(false)
                                }, ()=>{}, (e)=>{
                                    setIsSubmitting(false);
                                })
                            }
                        }}
                        cancel={() => { tabsContext.closeCurrent() }}
                        permission={props.permission}
                    />
                : 
                viewState === "edit" ? 
                    editDocument && <ImportRemainsUpdateForm
                        isSubmitting={isSubmitting}
                        idGlobal={id}
                        variant="edit"
                        document={editDocument.importRemainsDocument}
                        documentItems={editDocument.documentItems}
                        documentUpdate={editDocument.documentUpdate}
                        save={(importRemainsDocument) => {
                            setIsSubmitting(true)
                            idp.update(id as string, importRemainsDocument, () => {
                                props.createdCallback?.(importRemainsDocument)
                                setIsSubmitting(false)
                            })
                        }}
                        ok={(importRemainsDocument) => {
                            setIsSubmitting(true)
                            idp.update(id as string, importRemainsDocument, () => {
                                props.createdCallback?.(importRemainsDocument)
                                setIsSubmitting(false)
                            })
                            tabsContext.closeCurrent()
                        }}
                        cancel={() => {
                            tabsContext.closeCurrent()
                        }}
                        permission={props.permission}
                    />
                : 
                viewState === "copy" ? 
                    copyDocument && <ImportRemainsUpdateForm
                        isSubmitting={isSubmitting}
                        idGlobal={id}
                        variant="copy"
                        document={copyDocument.importRemainsDocumentCopy}
                        documentItems={copyDocument.importRemainsDocumentCopy.items}
                        documentUpdate={copyDocument.documentUpdate}
                        save={(importRemains) => {
                            setIsSubmitting(true)
                            idp.create(importRemains, (id) => {
                                setId(id)
                                setViewState('edit')
                                props.createdCallback?.()
                                setIsSubmitting(false)
                            }, ()=>{}, (e)=>{
                                setIsSubmitting(false);
                            })
                        }}
                        ok={(importRemains) => {
                            setIsSubmitting(true)
                            idp.create(importRemains, (id) => {
                                setId(id)
                                props.createdCallback?.()
                                tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)
                                setIsSubmitting(false)
                            }, ()=>{}, (e)=>{
                                setIsSubmitting(false);
                            })
                        }}
                        cancel={() => {
                            tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)
                        }}
                        permission={props.permission}
                    />
                : 
                    <></>
            }
        </>
    );
}

export default ImportRemainsCreatorView