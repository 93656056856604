import { getDocumentItemColumns, getDocumentItemCostInfoColumns } from "../../../../system/providers/plugin/defaultPluginSettings"

let index: number = 0;

const MovementItemsGridPluginSettings: IPluginSettings = {
    name: 'Перемещение товара',
    mnemocode: 'movement_items_plugin',
    permission: 'Movement',
    columns: [
        ...getDocumentItemColumns(index, (i) => index = i),
        {
            order: index++,
            propertyName: 'goodsName',
            propertyType: 'string',
            displayName: 'Наименование товара',
            visibility: true,
            width: 100
        }, {
            order: index++,
            propertyName: 'actualQuantity',
            propertyType: 'number',
            displayName: 'Кол-во факт',
            visibility: true,
            width: 100,
            changeable: true
        }, {
            order: index++,
            propertyName: 'quantity',
            propertyType: 'number',
            displayName: 'Кол-во отправлено',
            visibility: true,
            width: 100,
            changeable: true
        },
        {
            order: index++,
            propertyName: 'supplier',
            propertyType: 'string',
            displayName: 'Поставщик',
            visibility: true,
            width: 100
        },
        {
            order:  index++,
            propertyName: 'isKiz',
            propertyType: 'boolean',
            displayName: 'Ввод КИЗ',
            visibility: true,
            width: 150
        },
        {
            order: index++,
            propertyName: 'countKizScan',
            propertyType: 'number',
            displayName: 'Кол-во отсканированных кизов',
            visibility: true,
            width: 100
        },
        {
            order: index++,
            propertyName: 'isKizDocReady',
            propertyType: 'boolean',
            displayName: 'Идентификатор документа МДЛП',
            visibility: true,
            width: 100
        },
        ...getDocumentItemCostInfoColumns(index, (i) => index = i),
    ]
}



export default MovementItemsGridPluginSettings