import { FC, useEffect, useState } from "react";
import { LoadingStatus } from "../../../@types/enumsGlobal";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { PluginWrapper } from "../../../components/plugins";
import { IPricingModelViewDTO } from "../../../libs/coreapi-dto/dirs/pricingModel";
import { PricingModelDataProvider } from "../../../Services/DataProviders/PricingModelDataProvider";
import useGridFilter, { CreateSelectorGridFilter } from "../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { PricingModelCommandsPanel } from "./PricingModelCommandsPanel";

interface IPricingModelGridProps extends IGridProps {
    showCommandsPanel?: boolean;
}

const PricingModelGrid: FC<IPricingModelGridProps> = (props) => {
    const appCtx = useAppContext()
    const pricingModelDataProvider = new PricingModelDataProvider(appCtx.coreApiService)

    const [data, setData] = useState<IPricingModelViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [totalCount, setTotalCount] = useState(0);
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [gridFilter, dispatchGridFilter] = useGridFilter(props.baseGridFilter ?? { ...CreateSelectorGridFilter, fieldOrderBy: 'name' });
    const [selectedItem, setSelectedItem] = useState<IGridRow>();

    useEffect(() => {
        getView();
    }, [viewState, gridFilter])


    const getView = () => {
        setSelectedItem(undefined);
        setLoadingStatus(LoadingStatus.InProcess)
        pricingModelDataProvider.getView(gridFilter, (data, totalCount) => {
            setData(data)
            setTotalCount(totalCount)
            data.length > 0 ? setLoadingStatus(LoadingStatus.Completed) : setLoadingStatus(LoadingStatus.NoData)
        })
    }

    return (
        <PluginWrapper>
            {props.showCommandsPanel && (
                <PricingModelCommandsPanel
                    getView={getView}
                    data={data}
                    selectedItem={selectedItem}
                    dispatchGridFilter={dispatchGridFilter}
                    permission={props.plugin.permission}
                />
            )}
            {
                 <DefaultGrid
                    separator
                    gridId={props.gridId}
                    data={data}
                    loadingStatus={loadingStatus}
                    setLoadingStatus={setLoadingStatus}
                    totalCount={totalCount}
                    filter={gridFilter}
                    dataProvider={pricingModelDataProvider}
                    plugin={props.plugin}
                    selectedItem={selectedItem}
                    onSelect={(row) => {
                        setSelectedItem(row)
                        props.onSelect?.(row)
                    }}
                    onDoubleClick={(i) => props.onDoubleClick?.(i)}
                    onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                    onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                    onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                    onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                    onEnter={() => setViewState("edit")}
                />
            }
        </PluginWrapper>
    )
}

export default PricingModelGrid