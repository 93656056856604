import { v4 as uuidv4 } from 'uuid';
import { FC } from "react"
import { IdTableVariant, SearchOption } from "../../@types/enumsGlobal"
import GridSelectorInput from "../controls/GridSelectorInput"
import { IEntitySimpleDTO } from "../../libs/coreapi-dto/@types/common"
import { ILotDTO } from "../../libs/coreapi-dto/accounting/lot"
import { LotDataProvider } from "../../Services/DataProviders/LotDataProvider"
import { useAppContext } from "../../system/providers/appContextProvider"
import { LotsSelectorModal } from "../../Business/Accounting/Lots"
import { GridFilterAction } from "../../system/hooks/useGridFilter"
import { BarcodeType, IKizBoxDTO, IKizDTO } from "../../libs/coreapi-dto/dirs/kiz"
import { KizDataProvider } from "../../Services/DataProviders/KizDataProvider"
import buffer from 'buffer'
import { IKizOsuDTO } from '../../libs/coreapi-dto/dirs/KizOsu';
import { KizParsed } from '../../Business/Dictionaries/Kiz/KizParsed';


export type ISearchQueryInfo =
    {type: 'kiz', data: IKizDTO} |
    {type: 'kizBox', data: IKizBoxDTO} |
    {type: 'kizOsu', data: IKizOsuDTO}


interface ILotSelectorInputProps {
    disabled?: boolean
    barcodeScan?: boolean
    onlyLotRemain?: boolean
    searchOption?: SearchOption
    gridFilter?: IGridFilter
    dispatchGridFilter?: (value: GridFilterAction) => void
    onSelect?: (value: ILotDTO, searchQuery?: ISearchQueryInfo) => void
    setScanData?: (value) => void
    documentIdTable?: IdTableVariant
}

const LotSelectorInput: FC<ILotSelectorInputProps> = (props) => {
    const appContext = useAppContext();
    const ldp = new LotDataProvider(appContext.coreApiService);
    const kdp = new KizDataProvider(appContext.coreApiService);

    const onSelectLot = (lot: IEntitySimpleDTO, searchQuery?: string) => {
        if (lot) {
            ldp.getById(lot.idGlobal, (lot) => {
                let kizParsed = new KizParsed(searchQuery ?? '')
                if (kizParsed.isKiz ||
                    props.searchOption === SearchOption.kiz ||
                    props.searchOption === SearchOption.kizBox ||
                    props.searchOption === SearchOption.kizOrKizBox ||
                    props.searchOption === SearchOption.gtinSgtin)
                {                       
                    if (searchQuery) {                       
                        const barcode = buffer.Buffer.from(searchQuery, 'ascii').toString('base64')
                        kdp.parse(barcode, (parsedData) => {
                            switch(parsedData.barcodeType) {
                                case BarcodeType.Kiz: {
                                    const kizData = parsedData.kizData;
                                    props.onSelect?.(lot, {type: 'kiz', data: {...kizData}} as ISearchQueryInfo)
                                    break;
                                }
                                case BarcodeType.KizBox: {
                                    const kizBoxData = parsedData.kizBoxData;
                                    props.onSelect?.(lot, {type: 'kizBox', data: {...kizBoxData}} as ISearchQueryInfo)
                                    break;
                                }
                                case BarcodeType.kizOsu: {
                                    const kizOsuData = parsedData.kizOsuData;
                                    props.onSelect?.(lot, {type: 'kizOsu', data: {...kizOsuData}} as ISearchQueryInfo)
                                    break;
                                }
                            }
                        })
                    } else {
                        props.onSelect?.(lot)
                    }
                }
                else {
                    props.onSelect?.(lot)
                }
            })
        }
    }

    return <>
        <GridSelectorInput
            disabled={props.disabled}
            barcodeScan={props.barcodeScan}
            onlyLotRemain={props.onlyLotRemain}
            selectorModalJsx={LotsSelectorModal}
            searchOption={props.searchOption}
            gridFilter={props.gridFilter}
            dispatchGridFilter={props.dispatchGridFilter}
            id={uuidv4()}
            inline={true}
            label='Поиск партии'
            setScanData={props.setScanData}
            onSelect={(lot, query) => onSelectLot(lot, query)}
            onChange={(e) => props.setScanData?.(e)}
            idTable={props.documentIdTable}
        />
    </>
}

export default LotSelectorInput