import { FC,useEffect, useState } from 'react'
import { DefaultGrid } from '../../../../components/grids/default/defaultGrid'
import { PluginWrapper, ToolbarWrapper } from '../../../../components/plugins'
import { CommandsPanel } from './CommandsPanel'
import useGridFilter, { CreateSelectorGridFilter } from '../../../../system/hooks/useGridFilter'
import styles from '../../../styles/index.module.scss'
import styles2 from './BunchInvoices.module.scss'
import { TextInput } from '../../../../components/controls/inputs'
import BaseBunchInvoicesCreator from './BunchInvoicesForm/BaseBunchInvocesCreator'
import { BunchType, IBunchItemBody, IImportedInvoiceItemDTO, IImportedInvoiceItemViewDTO } from '../../../../libs/coreapi-dto/documents/importedInvoiceItem'
import { useAppContext } from '../../../../system/providers/appContextProvider'
import { GoodsDataProvider } from '../../../../Services/DataProviders/GoodsDataProvider'
import { IGoodsDTO, IGoodsViewDTO } from '../../../../libs/coreapi-dto/dirs/goods'
import { v4 as uuidv4 } from 'uuid';
import { useTabsContext } from '../../../../system/providers/tabsProvider'
import BunchGrid from './BunchGrid/BunchGrid'
import { isArray } from 'lodash'
import { MessageModalWindow } from '../../../../components/modalWindows/MessageModalWindow'
import { ImportedInvoiceDataProvider } from '../../../../Services/DataProviders/ImportedInvoiceDataProvider'
import { IDataForMoveFiles, IPostFilesNameDTO } from '../../../../libs/coreapi-dto/dirs/importInvoices'
import { ImportInvoicesProvider } from '../../../../Services/DataProviders/ImportInvoicesProvider';
import { SearchButton } from '../../../../components/buttons/iconButtons/action/MainCollection'
import GoodsBunchGridPluginSettings from './GoodsBunchGridPluginSettings'
import { Spinner } from '../../../../components/spiner/Spinner'
import renderGlobalAlert from '../../../../system/hooks/useGlobalAlert'


interface IBunchInvoicesProps extends IGridProps {
    importedInvoiceItems: IImportedInvoiceItemDTO[]
    idInvoice: string
    isSucceed: boolean
    dataForMoveInvoices: IDataForMoveFiles
    callbackAfterImport: ()=> void
}

interface IMessageModalProps {
    show: boolean,
    message: string,
    handler?: () => void
}

export const BunchInvoices: FC<IBunchInvoicesProps> = (props) => {

    const appContext = useAppContext()
    const tabsContext = useTabsContext()

    const importedInvoiceDataProvider = new ImportedInvoiceDataProvider(appContext.coreApiService);
    const goodsDataProvider = new GoodsDataProvider(appContext.coreApiService)

    const [invoiceImportedItemsGridFilter, dispatchInvoiceImportedItemsGridFilter] = useGridFilter();
    const [goodsGridFilter, dispatchGoodsGridFilter] = useGridFilter(CreateSelectorGridFilter);

    const [invoiceSelectedItem, setInvoiceSelectedItem] = useState<IGridRowWithMultipleValue | undefined>(undefined);
    const [goodsSelectedItem, setGoodsSelectedItem] = useState<IGridRow>();

    const [importedInvoiceItemsTotalCount, setImportedInvoiceItemsTotalCount] = useState<number>(0);
    const [goodsTotalCount, setGoodsTotalCount] = useState<number>(0);

    const [goodsData, setGoodsData] = useState<IGoodsViewDTO[]>([])
    const [goodsName, setGoodsName] = useState<string>('');
    const [importedInovoceItemsData, setImportedInovoceItemsData] = useState<IImportedInvoiceItemViewDTO[]>([])
    const [showMessageModal, setShowMessageModal] = useState<IMessageModalProps>({ show: false, message: '' });
    const [showMessageModalBunch, setShowMessageModalBunch] = useState<IMessageModalProps>({ show: false, message: '' });
    const [showMessageGosContract, setShowMessageGosContract] = useState<IMessageModalProps>({ show: false, message: '' });

    const [goodsItem, setGoodsItem] = useState<IImportedInvoiceItemViewDTO | null>(null)

    const importInvoicesProvider = new ImportInvoicesProvider(appContext.coreApiService);

    const [importedInvoiceItems, setImportedInvoiceItems] = useState<IImportedInvoiceItemDTO[]>(props.importedInvoiceItems ?? []);

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isSubmittingSearch, setIsSubmittingSearch] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(false);

    function sendMoveInvoicesToOldFolder()
    {
        if (props.dataForMoveInvoices)
        {
            let index = props.dataForMoveInvoices.idsInvoices.indexOf(props.idInvoice)
            if (index!=-1)
            {
                let dto: IPostFilesNameDTO = {
                    Names: [props.dataForMoveInvoices.fileName[index]]
                }
                importInvoicesProvider.moveFilesLocal(props.dataForMoveInvoices.idContractor, props.dataForMoveInvoices.idSupplier, dto, (data)=>{
                    props.callbackAfterImport?.();
                }, ()=>{} )
            }
        }

    }

    const close = () => 
    {
        deleteImportedInvoice()
        tabsContext.closeCurrent();
    }

    function saveAllBunch()
    {
        importedInvoiceDataProvider.saveGoodsCode(props.idInvoice, (e) => {
        })
    }

    function refreshAllBunch()
    {
        setInvoiceSelectedItem(undefined)
        importedInvoiceDataProvider.refreshGoodsCode(props.idInvoice, (g) => {
            setImportedInovoceItemsData([]);

            importedInvoiceDataProvider.getById(props.idInvoice, (e) => {
                let errorList = g.data.errorsList.map(x => x.split(' ')[0]);
                let items = [...e.items.map(i => {
                    let c = g.data.byBarcode.find(z=>z.idInvoiceItem === i.idGlobal);
                    return ({ ...i, success: errorList.find(y => y === i.idGlobal) ? false : true, byBarcode: c?.byBarcode })
                }
                )] as any;
                setImportedInvoiceItems(items);
            })

            
        })
    }
    
    function unsetBunch() {
        if (invoiceSelectedItem) {

            let selectGoods = importedInovoceItemsData.find(x => x.idGlobal[0] === invoiceSelectedItem?.idGlobal) as IImportedInvoiceItemViewDTO
            if (!selectGoods.connected) return;
            let idGlobalItem = isArray(invoiceSelectedItem?.idGlobal) ? invoiceSelectedItem?.idGlobal[0] : invoiceSelectedItem?.idGlobal;

            if (isArray(selectGoods?.idGlobal)) {
                importedInvoiceDataProvider.unBunchItem([{ idImportedInvoiceItemGlobal: idGlobalItem, idGoodGlobal: selectGoods.idGlobal[1] }] as IBunchItemBody[], e => {
                    setImportedInovoceItemsData(importedInovoceItemsData.map((x) => (x.idGlobal[0] === invoiceSelectedItem?.idGlobal ? {
                        idGlobal: x.idGlobal[0],
                        connected: false,
                        goodsCode: x.goodsCode[0],
                        goodsBarcode: x.goodsBarcode[0],
                        goodsName: x.goodsName[0],
                        goodsProducerName: x.goodsProducerName[0],
                        country: x.country[0],
                        registerPrice: x.registerPrice[0],
                        vat: x.vat[0],
                        success: false,
                        bunchType: BunchType.none,
                    } : x)))
                    setInvoiceSelectedItem({ ...invoiceSelectedItem, success: false });
                });
            }
        }
    }

    const saveBunch = (goodsItem?: IGridRow) => {
        if (invoiceSelectedItem) {
            if (!goodsSelectedItem) return;
            importedInvoiceDataProvider.bunchItem([{ idImportedInvoiceItemGlobal: isArray(invoiceSelectedItem?.idGlobal) ? invoiceSelectedItem?.idGlobal[0] : invoiceSelectedItem?.idGlobal, idGoodGlobal: goodsSelectedItem?.idGlobal }] as IBunchItemBody[], e => {
                let selectGoods = importedInovoceItemsData.find(x => x.idGlobal === invoiceSelectedItem?.idGlobal) as IImportedInvoiceItemViewDTO
                goodsDataProvider.getById(goodsItem?.idGlobal ?? goodsSelectedItem?.idGlobal as string, e => {
                    let goods = e;
                    setImportedInovoceItemsData(importedInovoceItemsData.map((element) => (element.idGlobal === invoiceSelectedItem?.idGlobal ? { ...element,
                        idGlobal: [isArray(selectGoods?.idGlobal) ? selectGoods.idGlobal[0] : selectGoods.idGlobal, goodsItem?.idGlobal ?? goodsSelectedItem?.idGlobal as string] as [string, string],
                        connected: true,
                        goodsCode: [isArray(selectGoods?.goodsCode) ? selectGoods.goodsCode[0] : selectGoods.goodsCode, goods.code] as [string, string],
                        goodsBarcode: [isArray(selectGoods?.goodsBarcode) ? selectGoods.goodsBarcode[0] : selectGoods.goodsBarcode, goods.barcode] as [string, string],
                        goodsName: [isArray(selectGoods?.goodsName) ? selectGoods.goodsName[0] : selectGoods.goodsName, goods.goodsName.name] as [string, string],
                        goodsProducerName: [isArray(selectGoods?.goodsProducerName) ? selectGoods.goodsProducerName[0] : selectGoods.goodsProducerName, goods.producer.displayName] as [string, string],
                        country: [isArray(selectGoods.country) ? selectGoods.country[0] : selectGoods.country, ''] as [string, string],
                        registerPrice: [isArray(selectGoods.registerPrice) ? selectGoods.registerPrice[0] : selectGoods.registerPrice, goods.gnvls?.registerPrice ?? 0] as [number, number],
                        vat: [isArray(selectGoods.vat) ? selectGoods.vat[0] : selectGoods.vat, e.vatSupplier ?? 0] as [number, number],
                        success: true,
                        bunchType: BunchType.manual,
                    } : element)));
                    setInvoiceSelectedItem({ ...invoiceSelectedItem, success: true });
                    setGoodsSelectedItem(undefined); //нужно для того чтобы удалить выбраный элемент в нижнем гриде после привязки
                })
            })
        }
    }

    const unsetAndSaveBunch = async (goodsItem: IGridRow) => {

        if (invoiceSelectedItem) {
            
            let selectGoods = importedInovoceItemsData.find(x => x.idGlobal[0] === invoiceSelectedItem?.idGlobal) as IImportedInvoiceItemViewDTO
            if (!selectGoods.connected) return;
            let idGlobalItem = isArray(invoiceSelectedItem?.idGlobal) ? invoiceSelectedItem?.idGlobal[0] : invoiceSelectedItem?.idGlobal;
            
            if (isArray(selectGoods?.idGlobal)) {
                
                //отвязка
                await new Promise((resolve) => importedInvoiceDataProvider.unBunchItem([{ idImportedInvoiceItemGlobal: idGlobalItem, idGoodGlobal: selectGoods.idGlobal[1] }] as IBunchItemBody[], e => resolve(e)));
                //привязка
                await new Promise((resolve) => importedInvoiceDataProvider.bunchItem([{ idImportedInvoiceItemGlobal: isArray(invoiceSelectedItem?.idGlobal) ? invoiceSelectedItem?.idGlobal[0] : invoiceSelectedItem?.idGlobal, idGoodGlobal: goodsItem?.idGlobal }] as IBunchItemBody[], e => resolve(e)));

                const e = await new Promise<IGoodsDTO>(resolve =>  goodsDataProvider.getById(goodsItem?.idGlobal as string, e => resolve(e)));
                const goods = e;

                setImportedInovoceItemsData(importedInovoceItemsData.map((element) => (element.idGlobal[0] === invoiceSelectedItem?.idGlobal ? {
                    idGlobal: [isArray(element?.idGlobal) ? element.idGlobal[0] : selectGoods.idGlobal, goodsItem?.idGlobal ?? '' as string] as [string, string],
                    connected: true,
                    goodsCode: [isArray(element?.goodsCode) ? element.goodsCode[0] : selectGoods.goodsCode, goods.code ?? ''] as [string, string],
                    goodsBarcode: [isArray(element?.goodsBarcode) ? element.goodsBarcode[0] : selectGoods.goodsBarcode, goods.barcode ?? ''] as [string, string],
                    goodsName: [isArray(element?.goodsName) ? element.goodsName[0] : selectGoods.goodsName, goods.goodsName.name ?? ''] as [string, string],
                    goodsProducerName: [isArray(element?.goodsProducerName) ? element.goodsProducerName[0] : selectGoods.goodsProducerName, goods.producer.displayName ?? ''] as [string, string],
                    country: [isArray(element.country) ? element.country[0] : selectGoods.country, goods.country?.displayName ?? ''] as [string, string],
                    registerPrice: [isArray(element.registerPrice) ? element.registerPrice[0] : selectGoods.registerPrice, goods.gnvls?.registerPrice ?? 0] as [number, number],
                    vat: [isArray(element.vat) ? element.vat[0] : selectGoods.vat, e.vatSupplier ?? 0] as [number, number],
                    success: true,
                    bunchType: BunchType.manual,
                } : element)));

            }
        }
    }

    const deleteImportedInvoice = () => {
        importedInvoiceDataProvider.deleteImportedInvoice(props.idInvoice)
    }

    const createInvoice = () => {
        importedInvoiceDataProvider.createInvoice(props.idInvoice, (e) => {
            if (e.respType === "isFailed") { 
                close()
                return
            }
            setShowMessageModal({
                show: true, 
                message: 'Товар импортирован успешно, желаете перейти в "Приходные накладные" для дальнейшей работы?',
                handler: () => {
                    close()
                    tabsContext.open('invoice_plugin')
                    
                }
            })
            sendMoveInvoicesToOldFolder()
            setIsSubmitting(false);
        })
    }

    const onSave = () => {
        if (props.isSucceed) {
            setIsSubmitting(true);
            importedInvoiceDataProvider.importedValidateGosContracts(props.idInvoice, (e) => {
                console.log('importedValidateGosContracts',e)
                if (e.result && e.message === null) {
                    createInvoice()
                    setIsSubmitting(false)
                }
                else if (e.result && e.message !== '') {
                    setShowMessageGosContract({show: true, message: e.message, handler() {
                        createInvoice()
                        setIsSubmitting(false)
                    },})
                }
                else if (!e.result) {
                    setShowMessageGosContract({show: true, message: e.message, handler() {
                        setIsSubmitting(false)
                    }})
                }
            })
        } else {
            if (importedInovoceItemsData.filter(x => x.connected === false).length > 0) {
                setShowMessageModalBunch({ show: true, message: 'Свяжите все позиции' })
            } else {
                setIsSubmitting(true);
                createInvoice()
            }
        }
    }

    const searchHandler = (value: string) => {
        setGoodsName(value)
        dispatchGoodsGridFilter({
            ...goodsGridFilter,
            type: "paramFilter",
            payload: {
                gridParamFilter: {
                    goodsName: value,
                },
                sortDesc: "Perc"
            },
        })
    }

    useEffect(() => {
        const handleTabClose = (event) => {
            deleteImportedInvoice();
            return (event.returnValue = "");
        };

        window.addEventListener("beforeunload", handleTabClose);

        return () => {
            window.removeEventListener("beforeunload", handleTabClose);
        };
    }, []);

    useEffect(() => {
        goodsItem && setImportedInovoceItemsData([...importedInovoceItemsData, goodsItem])
    }, [goodsItem])

    useEffect(() => {
        if (!invoiceSelectedItem) {
            setGoodsName('')
            return
        }
        var result = invoiceSelectedItem?.cells.find(c => c.propertyName === 'goodsName')?.value as string
        if (!result) return
        let goods: string
        if (Array.isArray(result))
            goods = result.length > 0 ? result[0] : ''
        else
            goods= result

        setGoodsName(goods)
        searchHandler(goods)
        
    }, [invoiceSelectedItem])

    const  generateToUpperCase = (arr) =>  arr.map(item => item && item.toUpperCase()); 
    
    function updateBunches() {
        importedInvoiceItems.map((x) => {
            if (x.idGoodsExternalGlobal && x.idGoodsExternalGlobal !== '')
                goodsDataProvider.getById(x.idGoodsExternalGlobal, (e) => {
                    setGoodsItem({
                        idGlobal: [x.idGlobal, x.idGoodsExternalGlobal],
                        connected: true,
                        goodsCode: generateToUpperCase([x.goodsСode, e.code]),
                        goodsBarcode: generateToUpperCase([x.barcode, e.barcode]),
                        goodsName: generateToUpperCase([x.goodsName, e.goodsName.name]),
                        goodsProducerName: generateToUpperCase([x.producer, e.producer.displayName]),
                        country: generateToUpperCase([x.country, x.country]),
                        registerPrice: [x.registerPrice, x.registerPrice],
                        vat: [x.supplierVat, e.vatSupplier],
                        success: x.success,
                        bunchType: x.byBarcode ? BunchType.barcode : BunchType.code
                    } as IImportedInvoiceItemViewDTO)
                })
            else
            {
                const tmpToUpperCase = (tmp: string) => tmp.toUpperCase(); 
                let tmp = {
                    idGlobal: x.idGlobal,
                    connected: false,
                    goodsCode: tmpToUpperCase(x.goodsСode),
                    goodsBarcode: tmpToUpperCase(x.barcode),
                    goodsName: tmpToUpperCase(x.goodsName),
                    goodsProducerName: tmpToUpperCase(x.producer),
                    country: tmpToUpperCase(x.country),
                    registerPrice: x.registerPrice,
                    vat: x.supplierVat,
                    success: x.success,
                    bunchType: BunchType.none
                } as IImportedInvoiceItemViewDTO
                setImportedInovoceItemsData(importedInovoceItemsData => [...importedInovoceItemsData, tmp])
            }
        })
    }

    useEffect(() => {
        if (props.isSucceed) {
            importedInvoiceItems.map((x) => {
                goodsDataProvider.getById(x.idGoodsExternalGlobal, (e) => {
                    setGoodsItem({
                        idGlobal: [x.idGlobal, x.idGoodsExternalGlobal],
                        connected: true,
                        goodsCode: generateToUpperCase([x.goodsСode, e.code]),
                        goodsBarcode: generateToUpperCase([x.barcode, e.barcode]),
                        goodsName: generateToUpperCase([x.goodsName, e.goodsName.name]),
                        goodsProducerName: generateToUpperCase([x.producer, e.producer.displayName]),
                        country: generateToUpperCase([x.country, x.country]),
                        registerPrice: [x.registerPrice, x.registerPrice],
                        vat: x.supplierVat,
                        success: x.success,
                        bunchType: x.byBarcode ? BunchType.barcode : BunchType.code
                    } as IImportedInvoiceItemViewDTO)
                })
            })
        } else {
            updateBunches();
        }

    }, [importedInvoiceItems])

    useEffect(() => {
        if (!goodsGridFilter.paramFilter) return;
        if (goodsGridFilter.paramFilter.length === 0) return;

        const abortController = new AbortController()
        const signal = abortController.signal
        setIsSubmittingSearch(true)

        goodsDataProvider.getViewByName(goodsGridFilter, (entities, totalCount) => {
            setGoodsData(entities)
            setGoodsTotalCount(totalCount)
            setIsSubmittingSearch(false)
        },signal);

        return () => {
            abortController.abort('abort')
        };

    }, [goodsGridFilter])

    useEffect(() => {
        if (importedInovoceItemsData && importedInovoceItemsData.length > 0 && importedInovoceItemsData[0].goodsName) {
            if (!isArray(importedInovoceItemsData[0]?.goodsName)) {
                setGoodsName(importedInovoceItemsData[0]?.goodsName as string)
            } else {
                setGoodsName('')
            }
        }
    }, [importedInovoceItemsData])

    function addBunch() {

        if (invoiceSelectedItem && isArray(invoiceSelectedItem?.idGlobal) === false) {
            setLoader(true);
            
            importedInvoiceDataProvider.createGoods(invoiceSelectedItem?.idGlobal as string, (e, err) => {
                if (err) {
                    setLoader(false);
                    renderGlobalAlert({
                        variant: "error",
                        title: 'Ошибка',
                        statusCode: 500,
                    });

                    return;
                }

                setInvoiceSelectedItem({...invoiceSelectedItem, success: true});
                importedInvoiceDataProvider.viewImportedInvoicesItemById(props.idInvoice, invoiceSelectedItem?.idGlobal as string, (data) => {
                    
                    setImportedInovoceItemsData((prev) => {
                        return prev.map((elem) => {
                            if (elem.idGlobal === invoiceSelectedItem?.idGlobal) {
                                return {
                                    ...elem,
                                    idGlobal: [elem.idGlobal, data.idGlobal],
                                    connected: true,
                                    goodsCode: [elem.goodsCode, data.goodsСode],
                                    goodsBarcode: elem.goodsBarcode,
                                    goodsName: [elem.goodsName, data?.goodsName],
                                    goodsProducerName: [elem.goodsProducerName, data?.producer],
                                    country: [elem.country, data?.country],
                                    registerPrice: [elem.registerPrice, data.registerPrice],
                                    vat: [elem.vat, data.supplierCostInfo.vat],
                                    success: true,
                                    bunchType: BunchType.manual,
                                } as IImportedInvoiceItemViewDTO;
                            } else {
                                return elem;
                            }
                        });
                    });

                    setLoader(false)
                })

            })

        }
    }

    return (
        <BaseBunchInvoicesCreator
            ok={{
                onClick: () => {
                    onSave();
                },
                disabled: isSubmitting || importedInovoceItemsData.filter((element) => !element.connected).length !== 0,
                sendRequestGlobal: isSubmitting,
            }}
            cancel={() => {
                deleteImportedInvoice()
                tabsContext.closeCurrent()
            }}
            saveBuch={() => { saveAllBunch() }}
            autoCreateGoods={() => { }}
            autoSelectionGoods={() => { }}
        >
            <PluginWrapper>
                <ToolbarWrapper>
                    <CommandsPanel
                        add={{
                            onClick: addBunch,
                            disabled: invoiceSelectedItem?.success === false ? false : true,
                        }}
                        save={{
                            onClick: () => { saveBunch() },
                            disabled: goodsSelectedItem && invoiceSelectedItem ? false : true
                        }}
                        delete={{
                            onClick: () => { unsetBunch() },
                            disabled: invoiceSelectedItem?.success ? false : true
                        }}
                        refresh={{
                            onClick: () => { refreshAllBunch() },
                            disabled: false
                        }}
                    />
                </ToolbarWrapper>
                <div className={styles2.title}>
                </div>
                {loader && <Spinner />}
                <div className={styles.gridWrapper}>
                    <div className={styles.masterGrid}>
                        <BunchGrid
                            gridId={props.gridId}
                            data={importedInovoceItemsData}
                            filter={invoiceImportedItemsGridFilter}
                            hiddenPagination = {undefined}
                            totalCount={importedInvoiceItemsTotalCount}
                            plugin={props.plugin}
                            separator
                            searching
                            selectedItem={invoiceSelectedItem}
                            onSelect={(row) => {
                                setInvoiceSelectedItem(row ? row : undefined);
                                setGoodsData([])
                            }}
                            onSort={(i, data) => {
                                setImportedInovoceItemsData(data as IImportedInvoiceItemViewDTO[]);
                                invoiceSelectedItem && setInvoiceSelectedItem(undefined)
                            }}
                            onFilterDelete={(i) => { dispatchInvoiceImportedItemsGridFilter({ type: "deleteColumnFilter", payload: i.propertyName }) }}
                            onPageNumberChange={(n) => { dispatchInvoiceImportedItemsGridFilter({ type: "changePageNumber", payload: { pageNumber: n } }) }}
                            onNumberPerPageChange={(n) => { dispatchInvoiceImportedItemsGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } }) }}
                        />
                    </div>
                </div>
                <div className={styles2.searchPanel}>
                    <span className={styles2.searchTitle}>Подобранные товары</span>
                    <TextInput
                        className={styles2.searchGoods}
                        value={goodsName}
                        onChange={(e) => {
                            setGoodsName(e)
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') { 
                                let value = e.currentTarget.value
                                searchHandler(value)
                            }
                        }}
                    />
                     <div className={styles2.searchIcon}>
                        <SearchButton sizeVariant="mini" onClick={() => {
                            searchHandler(goodsName)
                        }
                    }/>
                    </div>
                </div>
                <div className={styles.gridWrapper}>
                    <div className={styles.masterGrid}> {
                        invoiceSelectedItem && (
                            isSubmittingSearch ? (
                                <Spinner />
                                ) :     
                                <DefaultGrid
                                    separator
                                    quantityPosition
                                    autoSelect
                                    gridId={uuidv4()}
                                    data={goodsData}
                                    totalCount={goodsTotalCount}
                                    filter={goodsGridFilter}
                                    plugin={GoodsBunchGridPluginSettings}
                                    selectedItem={goodsSelectedItem}
                                    dataProvider={goodsDataProvider}
                                    getView={(gridFilter,callback) => {
                                        goodsDataProvider.getViewByName(gridFilter, (entities) => {
                                            callback(entities)
                                        })
                                    }}
                                    onSelect={(row) => {
                                        setGoodsSelectedItem(row? row : undefined)
                                    }}
                                    onDoubleClick={(row) => {
                                        setGoodsSelectedItem(row)
                                        if (invoiceSelectedItem) {
                                            invoiceSelectedItem.success ? unsetAndSaveBunch(row) : saveBunch(row)
                                        }
                                    }}
                                    onSort={(i) => { dispatchGoodsGridFilter({ type: "sort", payload: i.propertyName }) }}
                                    onFilterDelete={(i) => { dispatchGoodsGridFilter({ type: "deleteColumnFilter", payload: i.propertyName }) }}
                                    onPageNumberChange={(n) => { dispatchGoodsGridFilter({ type: "changePageNumber", payload: { pageNumber: n } }) }}
                                    onNumberPerPageChange={(n) => { dispatchGoodsGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } }) }}
                                    searching
                                />
                        )
                        }
                    </div>
                </div>
                {
                    showMessageModal.show &&
                    <MessageModalWindow
                        ok={{ onClick: () => { 
                            if (showMessageModal.handler) { 
                                showMessageModal.handler() 
                            }
                            setShowMessageModal({ show: false, message: '' }) 
                        }}}
                        cancel={{ onClick: () => {
                            setShowMessageModal({ show: false, message: '' }) 
                            close();
                            } }}
                        message={showMessageModal.message}
                    />
                }
                {
                    showMessageModalBunch.show &&
                    <MessageModalWindow
                        ok={{ onClick: () => { 
                            if (showMessageModalBunch.handler) { 
                                showMessageModalBunch.handler() 
                            }
                            setShowMessageModalBunch({ show: false, message: '' }) 
                        }}}
                        cancel={{ onClick: () => {
                            setShowMessageModalBunch({ show: false, message: '' }) 
                            } }}
                        message={showMessageModalBunch.message}
                    />
                }
                {
                    showMessageGosContract.show &&
                    <MessageModalWindow
                        ok={{ onClick: () => { 
                            if (showMessageGosContract.handler) { 
                                showMessageGosContract.handler() 
                            }
                            setShowMessageGosContract({ show: false, message: '' }) 
                        }}}
                        cancel={{ 
                            hidden: true,
                            onClick() {},
                        }}
                        message={showMessageGosContract.message}
                    />
                }
            </PluginWrapper>
        </BaseBunchInvoicesCreator>
    )
}