import classNames from "classnames";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Accordion } from "../../../../../components/controls/accordion";
import GridSelectorInput from "../../../../../components/controls/GridSelectorInput";
import GridWrapper from "../../../../../components/controls/GridWrapper";
import { CurrencyInput, NumberInput, TextInput } from "../../../../../components/controls/inputs";
import BaseDocumentCreator from "../../../../../components/documents/baseDocumentCreator";
import { IEntitySimpleDTO } from "../../../../../libs/coreapi-dto/@types/common";
import { GoodsDataProvider } from "../../../../../Services/DataProviders/GoodsDataProvider";
import { TaxTypeDataProvider } from "../../../../../Services/DataProviders/TaxTypeDataProvider";
import { useAppContext } from "../../../../../system/providers/appContextProvider";
import { useTabsContext } from "../../../../../system/providers/tabsProvider";
import { GoodsSelectorModal } from "../../../../Dictionaries/Goods";
import { ScalingRatioSelectorModal } from "../../../../Dictionaries/ScalingRatio";
import { SeriesModalWindow } from "../../../../Dictionaries/Series/SeriesAndCertificateModal/SeriesModalWindow";
import { StoreSelectorModal } from "../../../../Dictionaries/Store";
import { TaxTypeSelectorModal } from "../../../../Dictionaries/TaxType";
import styles from "../../ImportRemainsItem/styles/ImportRemainsItemCreatorView.module.scss";
import { v4 as uuidv4, NIL as uuidNull } from "uuid";
import { ScalingRatioDataProvider } from "../../../../../Services/DataProviders/ScalingRatioDataProvider";
import { useForm } from "../../../../../system/hooks/useForm";
import { Checkbox } from "../../../../../components/checkboxes/checkbox";
import { PricingInvoiceDataProvider } from "../../../../../Services/DataProviders/PricingInvoiceDataProvider";
import CollectPricingInvoiceItemDTO, { ICollectPricingInvoiceItemProps } from "../../../Invoice/CollectPricingInvoiceItemDTO";
import { IPricingInvoiceItemViewDTO } from "../../../../../libs/coreapi-dto/dirs/pricingInvoice";
import { ContractorSelectorModal } from "../../../../Dictionaries/Contractor";
import { IImportRemainsItemCreateDTO } from "../../../../../libs/coreapi-dto/documents/importRemainsItem";
import { IDisplayItem } from "../ImportRemainsUpdateForm";
import { CurrencyInputWithValidation,  DateInput,  DecimalInputV2 } from "../../../../../components/controls/inputs/BaseInput";
import { ISerialDTO } from "../../../../../libs/coreapi-dto/dirs/serial";
import { ResendingTimer } from "../../../../../constants";
import { DateTime } from "luxon";
import RegisterPriceSelectorModal from "../../../../Dictionaries/RegisterPrice/RegisterPriceSelectorModal";
import FlexRow from "../../../../../components/controls/FlexRow";
import { SerialDataProvider } from "../../../../../Services/DataProviders/SerialDataProvider";

interface IValidator {
    idGoodsGlobal: string;
    idScalingRatioGlobal: string;
    quantity: number;
    supplierVat: IEntitySimpleDTO;
    retailVat: IEntitySimpleDTO;
    producerPrice: number;
    supplierPrice: number;
    supplierSum: number;
    supplierVatSum: number;
    supplierSumWithVat: number;
    retailSum: number;
    retailSumWithVat: number;
    retailVatSum: number;
    supplier: IEntitySimpleDTO;
}

interface IImportRemainsItemCreateProps {
    idPricingModel: string;
    supplier: IEntitySimpleDTO;
    idStore: string;
    goods: IEntitySimpleDTO;
    parentCallback?: (entity: IImportRemainsItemCreateDTO, displayItem: IDisplayItem) => void;
    item?: IImportRemainsItemCreateDTO;
    displayItem: IDisplayItem;
    documentStateProc?: boolean;
    documentDate?: DateTime;
    scalingRatio: IEntitySimpleDTO;
}

interface ICalcSum {
    percentageOfMarkupRetail: number;
    supplierPriceWithVat: number;
    supplierMarkupPercentage: number;
    actualMarkupPercentage: number;
    supplierVatPerUnit: number;
    retailVatPerUnit: number;
}

const ImportRemainsItemCreator: FC<IImportRemainsItemCreateProps> = (props) => {
    const appContext = useAppContext();
    const tabsContext = useTabsContext();
    const gdp = new GoodsDataProvider(appContext.coreApiService);
    const scalingRatioDP = new ScalingRatioDataProvider(appContext.coreApiService);
    const pricingInvoiceDataProvider = new PricingInvoiceDataProvider(appContext.coreApiService);
    const taxTypeDataProvider = new TaxTypeDataProvider(appContext.coreApiService);
    const sdp = new SerialDataProvider(appContext.coreApiService)
    const [data, setData] = useState(
        props.item ??
            ({
                quantity: 1,
                supplierCostInfo: { vat: 0, sum: 0, sumIncVat: 0, vatSum: 0, price: 0 },
                retailCostInfo: { vat: 0, sum: 0, sumIncVat: 0, vatSum: 0, price: 0 },
                registerPrice: 0,
            } as IImportRemainsItemCreateDTO)
    );
    const [goods, setGoods] = useState<IEntitySimpleDTO | undefined>(props.goods);
    const [scalingRatio, setScalingRatio] = useState<IEntitySimpleDTO | undefined>(props.scalingRatio ?? undefined);
    const [supplier, setSupplier] = useState<IEntitySimpleDTO>(props.supplier);
    const [vatRateWholesale, setVatRateWholesale] = useState<IEntitySimpleDTO | undefined>({} as IEntitySimpleDTO);
    const [retailVatRate, setRetailVatRate] = useState<IEntitySimpleDTO | undefined>({} as IEntitySimpleDTO);
    const [itemSeries, setItemSeries] = useState<ISerialDTO>({} as ISerialDTO);
    const [calcSum, setCalcSum] = useState<ICalcSum>({
        percentageOfMarkupRetail: 0,
        supplierMarkupPercentage: 0,
        supplierPriceWithVat: 0,
        supplierVatPerUnit: 0,
        retailVatPerUnit: 0,
        actualMarkupPercentage: 0,
    });
    const [sendFetch, setSendFetch] = useState<boolean>(false);
    const requestPricingInvoiceTimeout = useRef<NodeJS.Timeout>();
    const [isKiz, setIsKiz] = useState<boolean>();
    const [isGnvls, setIsGnvls] = useState<boolean>(false);
    const validateReestr = { isGnvls, documentDate: props.documentDate };
    const [isOpenSeriesModal, setIsOpenSeriesModal] = useState<boolean>(false);
    const [pricingInvoice, setPricingInvoice] = useState<Partial<IPricingInvoiceItemViewDTO | undefined>>(() => {
        if (props.item) {
            return {
                //supplierCostInfo    
                supplierMarkupPercentage: props.item?.supplierCostInfo.adprice ?? 0,
                supplierVatPerUnit: props.item?.supplierCostInfo.vatPrice ?? 0,
                supplierPriceWithVat: props.item?.supplierCostInfo?.priceIncVat ?? 0,
                //retailCostInfo
                retailAmountWithVat: props.item?.retailCostInfo?.sumIncVat ?? 0,
                retailVatPerUnit: props.item.retailCostInfo.vatPrice ?? 0,
            }
        }
        return undefined;
    });

    const { t } = useTranslation();
    const baseT = (value: string) => t("documents.importRemains." + value);
    const errorsT = (value: string) => t("errors." + value);

    const { isValid, errors, setErrors } = useForm<IValidator>({
        validations: {
            supplier: {
                required: {
                    value: data?.idSupplierGlobal ? false : true,
                    message: errorsT("required"),
                },
            },
            idGoodsGlobal: {
                required: {
                    value: goods ? false : true,
                    message: errorsT("required"),
                },
            },
            idScalingRatioGlobal: {
                required: {
                    value: data?.idScalingRatioGlobal ? false : true,
                    message: errorsT("required"),
                },
            },
            quantity: {
                required: {
                    value: data?.quantity && data?.quantity > 0 ? false : true,
                    message: errorsT("required"),
                },
            },
            supplierVat: {
                required: {
                    value: vatRateWholesale ? false : true,
                    message: errorsT("required"),
                },
            },
            retailVat: {
                required: {
                    value: retailVatRate ? false : true,
                    message: errorsT("required"),
                },
            },
            producerPrice: {
                custom: {
                    isValid: () => {
                        return isValidProducerPrice().bool;
                    },
                    message: errorsT(isValidProducerPrice().message),
                },
            }
        },
    });

    function isValidProducerPrice() {
        if (!isGnvls) return { bool: true, message: "" };
        if (data.producerPrice === undefined || data.producerPrice <= 0) return { bool: false, message: "required" };
        if (data.producerPrice > data.registerPrice) return { bool: false, message: "exceededProducerPrice" };
        return { bool: true, message: "" };
    }

    const collectionData: ICollectPricingInvoiceItemProps = {
        eventTypes: "None",
        idInvoice: "",
        idSupplier: data?.idSupplierGlobal,
        idStore: props.idStore,
        idItem: data?.idGlobal,
        idGoods: goods?.idGlobal as string,
        idScalingRatio: data.idScalingRatioGlobal,
        quantity: data?.quantity,
        producerPrice: data?.producerPrice,
        retailCostInfo: data?.retailCostInfo,
        supplierCostInfo: data?.supplierCostInfo,
        registerPrice: data?.registerPrice,
    }

    useEffect(() => {
        if (props.item?.idSeriesGlobal)
        {
            sdp.getById(props.item?.idSeriesGlobal, (e) => setItemSeries(e))
        }
    },[])

    useEffect(() => {
        setCalcSum({
            ...calcSum,
            actualMarkupPercentage: pricingInvoice?.actualMarkupPercentage ?? 0,
            supplierVatPerUnit: pricingInvoice?.supplierVatPerUnit ?? 0,
            retailVatPerUnit: pricingInvoice?.retailVatPerUnit ?? 0,
            percentageOfMarkupRetail: pricingInvoice?.percentageOfMarkupRetail ?? 0, //"Наценка %" ? (процент розничной наценки)
            supplierMarkupPercentage: pricingInvoice?.supplierMarkupPercentage ?? 0, //Опт. надбавка, %
            supplierPriceWithVat: pricingInvoice?.supplierPriceWithVat ?? 0, //Цена постащика с НДС
        });
        setData({
            ...data,
            quantity: pricingInvoice?.quantity ?? 1,
            retailCostInfo: {
                ...data?.retailCostInfo,
                sum: pricingInvoice?.retailAmount ?? 0, //Сумма розничная без НДС
                sumIncVat: pricingInvoice?.retailAmountWithVat ?? 0, //Сумма розничная с НДС
                price: pricingInvoice?.retailPrice ?? 0, //Цена отпускная без НДС
                priceIncVat: pricingInvoice?.retailPriceWithVat ?? 0, //Цена отпускная с НДС
                vatSum: pricingInvoice?.vatAmountRetail ?? 0, //Сумма НДС розничная
                adprice: pricingInvoice?.percentageOfMarkupRetail ?? 0, //"Наценка %" ? (процент розничной наценки)
                vatPrice: pricingInvoice?.retailVatPerUnit ?? 0,
            },
            supplierCostInfo: {
                ...data?.supplierCostInfo,
                sum: pricingInvoice?.supplierAmountExcludingVat ?? 0, //Сумма поставщика без НДС
                sumIncVat: pricingInvoice?.supplierAmountWithVat ?? 0, //Сумма поставщика с НДС
                price: pricingInvoice?.supplierPriceExcludingVat ?? 0, //Цена поставщика без НДС
                priceIncVat: pricingInvoice?.supplierPriceWithVat ?? 0, //Цена поставщика с НДС
                vatSum: pricingInvoice?.supplierVatAmount ?? 0, //Сумма НДС поставщика
                adprice: pricingInvoice?.supplierMarkupPercentage ?? 0,
                vatPrice: pricingInvoice?.supplierVatPerUnit ?? 0,
            },
            producerPrice: pricingInvoice?.manufacturerPrice ?? 0,
        });
    }, [pricingInvoice]);

    const getCalculatedInvoiceItem = (collectionProps: ICollectPricingInvoiceItemProps) => {
        if (goods !== undefined && props.idPricingModel && data.idSupplierGlobal) {
            setSendFetch(true);
            let pricingInvoiceDTO = CollectPricingInvoiceItemDTO(collectionProps);
            clearTimeout(requestPricingInvoiceTimeout.current as NodeJS.Timeout);
            requestPricingInvoiceTimeout.current = setTimeout(() => {
                pricingInvoiceDataProvider.getCalculatedInvoiceItemViewAsync(props.idPricingModel, pricingInvoiceDTO, (entity) => {
                    setPricingInvoice((prev) => {
                        if (prev && prev.eventType === entity.eventType) {
                          return {
                            ...prev,
                            ...entity
                          };
                        } else {
                          return entity;
                        }
                      });
                    setSendFetch(false);
                });
            }, ResendingTimer);
        }
    };

    const getCalculatedInvoiceItemEndChange = (collectionProps: ICollectPricingInvoiceItemProps) => {
        if (goods !== undefined && props.idPricingModel) {
            setSendFetch(true);
            let pricingInvoiceDTO = CollectPricingInvoiceItemDTO(collectionProps);
            clearTimeout(requestPricingInvoiceTimeout.current as NodeJS.Timeout);
                pricingInvoiceDataProvider.getCalculatedInvoiceItemViewAsync(props.idPricingModel, pricingInvoiceDTO, (entity) => {
                    setPricingInvoice((prev) => {
                        if (prev && prev.eventType === entity.eventType) {
                          return {
                            ...prev,
                            ...entity
                          };
                        } else {
                          return entity;
                        }
                      });
                    setSendFetch(false);
                });
        }
    };
    
    const calcBySupplierVatRatePercentageChanged = (newVatValue: number) => {
        getCalculatedInvoiceItem({
            eventTypes: "SupplierVatRatePercentageChanged",
            idInvoice: "",
            idSupplier: props.supplier.idGlobal,
            idStore: props.idStore,
            idItem: data?.idGlobal,
            idGoods: goods?.idGlobal as string,
            idScalingRatio: data.idScalingRatioGlobal,
            quantity: data?.quantity,
            producerPrice: data?.producerPrice,
            retailCostInfo: data?.retailCostInfo,
            supplierCostInfo: { ...data?.supplierCostInfo, vat: newVatValue },
            registerPrice: data?.registerPrice,
        });
    };

    const calcByPercentageOfVatRateRetailChanged = (newVatValue: number) => {
        getCalculatedInvoiceItem({
            eventTypes: "PercentageOfVatRateRetailChanged",
            idInvoice: "",
            idSupplier: props.supplier.idGlobal,
            idStore: props.idStore,
            idItem: data?.idGlobal,
            idGoods: goods?.idGlobal as string,
            idScalingRatio: data.idScalingRatioGlobal,
            quantity: data?.quantity,
            producerPrice: data?.producerPrice,
            retailCostInfo: { ...data?.retailCostInfo, vat: newVatValue },
            supplierCostInfo: data?.supplierCostInfo,
            registerPrice: data?.registerPrice,
        });
    };

    const getCerificateId = (): string | undefined => {
        if (!itemSeries) return undefined;
        if (!itemSeries.certificates) return undefined;
        return itemSeries.certificates[0]?.idGlobal;
    };

    useEffect(() => {
        if (goods?.idGlobal)
            gdp.getByIdAndScalingRatio(goods.idGlobal as string, scalingRatio?.idGlobal ?? "00000000-0000-0000-0000-000000000000", (entity) => {
                if (entity?.taxType && entity?.taxTypeSupplier) {
                    taxTypeDataProvider.getById(entity.taxType.idGlobal, (taxType) => {
                        taxTypeDataProvider.getById(entity.taxTypeSupplier.idGlobal, (taxTypeSupplier) => {
                            setRetailVatRate(entity.taxType);
                            setVatRateWholesale(entity.taxTypeSupplier);
                            setData({
                                ...data,
                                registerPrice: (data?.registerPrice ?? undefined) as number,
                                supplierCostInfo: { ...data.supplierCostInfo, vat: taxTypeSupplier.taxRate },
                                retailCostInfo: { ...data.retailCostInfo, vat: taxType.taxRate },
                                idScalingRatioGlobal: entity?.scalingRatio.idGlobal ?? undefined,
                            });
                            setIsKiz((props.item?.isKiz || props.item?.isKizBox) ?? entity.attributes.isKiz ?? false);
                            setIsGnvls(entity.gnvls.important ?? false);
                        });
                    });
                } else if (entity?.taxType) {
                    taxTypeDataProvider.getById(entity.taxType.idGlobal, (taxType) => {
                        setRetailVatRate(entity.taxType);
                        setVatRateWholesale(entity.taxType);
                        setData({
                            ...data,
                            registerPrice: (entity.gnvls.registerPrice ?? undefined) as number,
                            supplierCostInfo: { ...data.supplierCostInfo, vat: taxType.taxRate },
                            retailCostInfo: { ...data.retailCostInfo, vat: taxType.taxRate },
                            idScalingRatioGlobal: entity?.scalingRatio.idGlobal ?? undefined,
                        });
                        setIsKiz((props.item?.isKiz || props.item?.isKizBox) ?? entity.attributes.isKiz ?? false);
                        setIsGnvls(entity.gnvls.important ?? false);
                    });
                } else if (entity?.taxTypeSupplier) {
                    taxTypeDataProvider.getById(entity.taxTypeSupplier.idGlobal, (taxTypeSupplier) => {
                        setRetailVatRate(entity.taxType);
                        setVatRateWholesale(entity.taxTypeSupplier);
                        setData({
                            ...data,
                            registerPrice: (data?.registerPrice ?? undefined) as number,
                            supplierCostInfo: { ...data.supplierCostInfo, vat: taxTypeSupplier.taxRate },
                            idScalingRatioGlobal: entity?.scalingRatio.idGlobal ?? undefined,
                        });
                        setIsKiz((props.item?.isKiz || props.item?.isKizBox) ?? entity.attributes.isKiz ?? false);
                        setIsGnvls(entity.gnvls.important ?? false);
                    });
                }
            });
        else {
            setData({ ...data, registerPrice: 0, idScalingRatioGlobal: undefined, idGoodsGlobal: "" });
            setIsKiz((props.item?.isKiz || props.item?.isKizBox) ?? false);
            setScalingRatio(undefined);
            setIsGnvls(false);
        }
    }, [goods]);

    useEffect(() => {
        if (data?.idScalingRatioGlobal && goods?.idGlobal) {
            scalingRatioDP.overrideGetById(goods.idGlobal, data.idScalingRatioGlobal, (e) => {
                setScalingRatio({ displayName: e.displayName, idGlobal: e.idGlobal });
                setData({ ...data, denominator: e.denominator, numerator: e.numerator })
            });
        }
    }, [data?.idScalingRatioGlobal]);

    function isEmpty(obj) {
        for (let key in obj) {
            return false;
        }
        return true;
    }

    function saveInvoiceItem() {
        if (goods) {
            let idGlobal: string = uuidv4();
            data &&
                props.parentCallback?.(
                    {
                        idGlobal: data.idGlobal ?? idGlobal,
                        idGoodsGlobal: goods.idGlobal,
                        idScalingRatioGlobal: data.idScalingRatioGlobal,
                        idSeriesGlobal: isEmpty(itemSeries) ? undefined : itemSeries.idGlobal,
                        IdCertificateExternalGlobal: getCerificateId(),
                        quantity: data.quantity,
                        producerPrice: data.producerPrice,
                        productMargin: calcSum.percentageOfMarkupRetail,
                        supplierCostInfo: data.supplierCostInfo,
                        retailCostInfo: data.retailCostInfo,
                        supplierGoodsCode: data.supplierGoodsCode,
                        gtdNumber: data.gtdNumber,
                        barCode: data.barCode,
                        registerPrice: data.registerPrice,
                        retailPriceIncVat: data.retailCostInfo.priceIncVat ?? 0,
                        isKiz: isKiz,
                        isOsu: data.isOsu,
                        kizOsuDto: data.kizOsuDto,
                        kizs: data.kizs ?? [],
                        kizBoxes: data.kizBoxes ?? [],
                        kizBoxCount: data.kizBoxCount,
                        kizCount: data.kizBoxCount,
                        supplierDocDate: data?.supplierDocDate,
                        supplierDocNumber: data?.supplierDocNumber,
                        idSupplierGlobal: data.idSupplierGlobal,
                        denominator: data?.denominator,
                        numerator: data?.numerator,
                        dateCreated: DateTime.now(),
                        incomingBillNumber: data?.incomingBillNumber,
                        incomingBillDate: data?.incomingBillDate
                    },
                    {
                        idGlobal: data.idGlobal ?? idGlobal,
                        goodsName: goods.displayName,
                        scalingRatioName: scalingRatio?.displayName,
                        quantity: data.quantity,
                        producerPrice: data.producerPrice,
                        retailPrice: data.retailCostInfo.priceIncVat,
                        productMargin: calcSum.percentageOfMarkupRetail,
                        registerPrice: data.retailCostInfo.priceIncVat,
                        isKiz: isKiz,
                        isGnvls: isGnvls,
                        countKizScan: isKiz === false ? undefined : props.displayItem?.countKizScan ?? 0,
                        supplier: supplier.displayName,
                        denominator: data?.denominator,
                        numerator: data?.numerator,
                        dateCreated: DateTime.now(),
                        isOsu: data.isOsu,
                        retailSum: data.retailCostInfo?.sum,
                        retailSumIncVat: data.retailCostInfo?.sumIncVat,
                        retailVat: data.retailCostInfo?.vat,
                        retailPriceIncVat: data.retailCostInfo?.priceIncVat,
                        supplierVat: data.supplierCostInfo?.vat,
                        supplierPrice: data.supplierCostInfo?.price,
                        supplierSum: data.supplierCostInfo?.sum,
                        supplierSumIncVat: data.supplierCostInfo?.sumIncVat
                    } as IDisplayItem
                );
        }
    }
    
    function checkSameValue (value, sameValue, callback) {
        if(value !== sameValue) {
            callback();
        }
    }

    return (
        <>
            <BaseDocumentCreator
                valid={true}
                cancel={{ onClick: () => tabsContext.closeCurrent() }}
                ok={{
                    onClick: () => {
                        if (isValid() && !sendFetch) {
                            saveInvoiceItem();
                            tabsContext.closeCurrent();
                        }
                    },
                    disabled: sendFetch,
                    sendRequestSpinner: sendFetch
                }}
            >
                <GridWrapper cols={30} className={styles.gridContainer}>
                    <GridSelectorInput
                        required
                        disabled={props?.documentStateProc}
                        variant="required"
                        label={baseT("name")}
                        className={styles.field_Goods}
                        selectedEntity={goods}
                        id="idGoodsGlobal"
                        onSelect={(entity) => setGoods(entity)}
                        selectorModalJsx={GoodsSelectorModal}
                        error={errors.idGoodsGlobal}
                        onFocus={() => setErrors({ ...errors, idGoodsGlobal: undefined })}
                        onClear={() => {
                            setGoods(undefined);
                        }}
                    />

                    <GridSelectorInput
                        required
                        disabled={props?.documentStateProc}
                        variant="required"
                        label={baseT("unit")}
                        className={styles.field_ScalingRatio}
                        selectedEntity={scalingRatio}
                        id="idScalingRatioGlobal"
                        onSelect={(entity) => {
                            setData({ ...data, idScalingRatioGlobal: entity.idGlobal });
                            setScalingRatio(entity);
                        }}
                        masterIdGlobal={goods?.idGlobal}
                        error={errors.idScalingRatioGlobal}
                        onFocus={() => setErrors({ ...errors, idScalingRatioGlobal: undefined })}
                        selectorModalJsx={ScalingRatioSelectorModal}
                        onClear={() => {
                            setData({ ...data, idScalingRatioGlobal: undefined });
                            setScalingRatio(undefined);
                        }}
                    />
                    <div className={classNames(styles.field_Kiz, !isKiz && styles.field_Kiz_Disabled)}> {t("general.kiz")} </div>
                    <div className={classNames(styles.field_Jv, !isGnvls && styles.field_Jv_Disabled)}> {t("general.jnvls")} </div>
                </GridWrapper>

                <GridWrapper cols={8}>
                    <GridSelectorInput
                        required
                        disabled={props?.documentStateProc}
                        variant="required"
                        className={styles.field_Contract}
                        selectorModalJsx={ContractorSelectorModal}
                        id={"dictionaryInputInvoiceSupplier"}
                        label={t("documents.invoice.supplier")}
                        labelClassName={styles.supplierLabel}
                        selectedEntity={supplier}
                        onSelect={(entity) => {
                            setData({ ...data, idSupplierGlobal: entity.idGlobal });
                            setSupplier(entity);
                            getCalculatedInvoiceItemEndChange({
                                eventTypes: "QuantityСhanged",
                                idInvoice: "",
                                idSupplier: entity.idGlobal,
                                idStore: props.idStore,
                                idItem: data?.idGlobal,
                                idGoods: goods?.idGlobal as string,
                                idScalingRatio: data.idScalingRatioGlobal,
                                quantity: data?.quantity,
                                producerPrice: data.producerPrice,
                                retailCostInfo: data?.retailCostInfo,
                                supplierCostInfo: data?.supplierCostInfo,
                                registerPrice: data?.registerPrice,
                            });
                        }}
                        onClear={() => {
                            setData({ ...data, idSupplierGlobal: uuidNull });
                        }}
                        error={errors.supplier}
                        onFocus={() => {
                            setErrors({ ...errors, supplier: undefined });
                        }}
                        treeViewCheckDirectoryType='supplier'
                    />
                    <CurrencyInputWithValidation
                        required
                        disabled={props?.documentStateProc}
                        labelVariant="required"
                        keyBan={true}
                        className={styles.field_CountDocs}
                        value={data?.quantity}
                        error={errors.quantity}
                        min={0}
                        onFocus={() => setErrors({ ...errors, quantity: undefined })}
                        inputId={"countDocs"}
                        label={t("documents.invoice.quantityDoc")}//"Кол-во по док."
                        onChange={(value) => {
                            setData({ ...data, quantity: value });
                            getCalculatedInvoiceItem({
                                ...collectionData,
                                eventTypes: "QuantityСhanged",
                                quantity: value,
                            });
                        } }
                        onEndChange={(value) => {
                            checkSameValue(value, pricingInvoice?.quantity, () => {
                                setData({ ...data, quantity: value });
                                getCalculatedInvoiceItemEndChange({
                                    ...collectionData,
                                    eventTypes: "QuantityСhanged",
                                    quantity: value,
                                });
                            });
                        } } 
                        validation={null}
                        />

                    <NumberInput
                        className={styles.field_CountFact}
                        disabled
                        variant="disabled"
                        label={t("documents.invoice.quantityDoc")}//"Количество факт"
                        inputId={"countFact"} /*value={creatorModal.countFact} onChange={(value) => setCreatorModal({ ...creatorModal, countFact: value as number })}*/
                    />
                    <NumberInput
                        className={styles.field_Boi}
                        disabled
                        label={t("documents.invoice.boi")}//"Бой"
                        inputId={"boi"} /* value={creatorModal.boi} onChange={(value) => setCreatorModal({ ...creatorModal, boi: value as number })}*/
                    />
                    <NumberInput
                        className={styles.field_Defect}
                        disabled
                        label={t("documents.invoice.defect")}//"Брак"
                        inputId={"defect"} /*value={creatorModal.defect} onChange={(value) => setCreatorModal({ ...creatorModal, defect: value as number })}*/
                    />
                    <NumberInput
                        className={styles.field_Shortage}
                        disabled
                        label={t("documents.invoice.shortage")}//"Недостача"
                        inputId={"shortage"} /* value={creatorModal.shortage} onChange={(value) => setCreatorModal({ ...creatorModal, shortage: value as number })} */
                    />
                    <NumberInput
                        className={styles.field_MinCount}
                        disabled
                        variant="disabled"
                        label={t("documents.invoice.quantityMin")}//"Мин. количество"
                        inputId={"minCount"} /*value={creatorModal.minCount} onChange={(value) => setCreatorModal({ ...creatorModal, minCount: value as number })}*/
                    />
                    <div className={styles.field_CheckBox}>
                        {" "}
                        <Checkbox 
                            disabled={(props.documentStateProc || props?.displayItem?.countKizScan) ? true : false}
                            tooltip={props?.displayItem?.countKizScan ? t("documents.invoice.kizScannedNoChange") : null}
                            name="isKiz" 
                            label={t("directory.goods.inputKiz")}//"Ввод КИЗ" 
                            checked={isKiz} 
                            onChange={(checked) => setIsKiz(checked)} 
                        />
                        {" "}
                    </div>
                </GridWrapper>

                <GridWrapper cols={8}>
                    <DecimalInputV2
                        required={isGnvls}
                        disabled={props?.documentStateProc}
                        labelVariant={isGnvls ? "required" : "normal"}
                        className={styles.field_ManufacturerPrice}
                        label={t("documents.invoice.manufacturerPrice")}//"Цена производителя"
                        inputId={"manufacturerPrice"}
                        error={errors.producerPrice}
                        onFocus={() => setErrors({ ...errors, producerPrice: undefined })}
                        value={data?.producerPrice}
                        onChange={(value) => {
                            setData({ ...data, producerPrice: value });
                            getCalculatedInvoiceItem({
                                ...collectionData,
                                eventTypes: "ManufacturerPriceChanged",
                                producerPrice: value,
                            })
                        }}
                        onEndChange={(value) => {
                            checkSameValue(value, pricingInvoice?.manufacturerPrice, () => {
                                setData({ ...data, producerPrice: value });
                                getCalculatedInvoiceItemEndChange({
                                    ...collectionData,
                                    eventTypes: "ManufacturerPriceChanged",
                                    producerPrice: value,
                                })
                            })
                        }}
                    />
                    <GridSelectorInput
                        numereticInput
                        disabled={props?.documentStateProc || (validateReestr.isGnvls === false) ? true : false}
                        label={t("documents.invoice.manufacturerMarginalPrice")}//"Предельная цена произв." 
                        id="manufacturerMarginalPrice" 
                        data={{...goods, validateReestr}}
                        selectedEntity={data.registerPrice}
                        selectorModalJsx={RegisterPriceSelectorModal}
                        onSelect={(entity) => {
                            setData({ ...data, registerPrice: entity});
                            if(data?.producerPrice > entity ) {
                                setErrors({...errors, producerPrice: errorsT('exceededProducerPrice')});
                            }
                        }}
                        onClear={() => setData({ ...data, registerPrice: 0 })}
                    />
                    <GridSelectorInput 
                        className={styles.field_Store} 
                        disabled 
                        label={t("documents.invoice.storePlace")}//"Место хранения" 
                        id="idGoodsGlobal" 
                        selectorModalJsx={StoreSelectorModal} 
                    />
                    <TextInput
                        className={styles.field_Gtd}
                        disabled
                        label={t("documents.invoice.gtd")}//"ГТД"
                        inputId={"gtd"} /*value={creatorModal.gtd} onChange={(value) => setCreatorModal({ ...creatorModal, gtd: value as string })}*/
                    />
                    <NumberInput
                        className={styles.field_AdditionalShK}
                        disabled
                        label={t("documents.invoice.addBarcode")}//"Добавление ШК"
                        inputId={"additionalShK"} /*value={creatorModal.additionalShK} onChange={(value) => setCreatorModal({ ...creatorModal, additionalShK: value as number })}*/
                    />
                </GridWrapper>

                <Accordion opened={true} caption={t("documents.invoice.calcSellingPrice")} id="CalculationOfTheSellingPriceInvoiceItem">
                    <FlexRow wrap>
                        <GridSelectorInput
                            required
                            disabled={props?.documentStateProc}
                            variant="required"
                            selectedEntity={vatRateWholesale}
                            label={t("documents.invoice.vatRateWholesale")}//"Ставка НДС, пост."
                            id="idGoodsGlobal"
                            selectorModalJsx={TaxTypeSelectorModal}
                            onSelect={(entity) => {
                                setVatRateWholesale(entity);
                                let vatValue = 0;
                                if (entity?.idGlobal) {
                                    taxTypeDataProvider.getById(entity.idGlobal as string, (t) => {
                                        vatValue = t.taxRate;
                                        setData({ ...data, supplierCostInfo: { ...data?.supplierCostInfo, vat: vatValue } });
                                        calcBySupplierVatRatePercentageChanged(vatValue);
                                    });
                                } else {
                                    setData({ ...data, supplierCostInfo: { ...data?.supplierCostInfo, vat: vatValue } });
                                    calcBySupplierVatRatePercentageChanged(vatValue);
                                }
                            }}
                            onClear={() => {
                                setVatRateWholesale(undefined);
                            }}
                            error={errors.supplierVat}
                            onFocus={() => setErrors({ ...errors, supplierVat: undefined })}
                        />
                        <DecimalInputV2
                            disabled
                            label={t("documents.invoice.supplierMarkupPercentage")}//"Наценка, %, пост"
                            inputId={"wholesaleSurcharge"}
                            value={calcSum.supplierMarkupPercentage}
                            onChange={(value) => setCalcSum({ ...calcSum, supplierMarkupPercentage: value })}
                        />
                        <DecimalInputV2
                            disabled
                            label={t("documents.invoice.supplierVatPerUnit")}//"НДС за единицу, пост."
                            inputId={"supplierVatPerUnit"}
                            value={calcSum.supplierVatPerUnit}
                            onChange={(value) => setCalcSum({ ...calcSum, supplierVatPerUnit: value })}
                        />
                        <DecimalInputV2
                            disabled={props?.documentStateProc}
                            label={t("documents.invoice.suppliersPrice")}
                            inputId={"suppliersPrice"}
                            value={data?.supplierCostInfo?.price}
                            onChange={(value) => {
                                setData({ ...data, supplierCostInfo: { ...data?.supplierCostInfo, price: value } });
                                getCalculatedInvoiceItem({
                                    ...collectionData,
                                    eventTypes: "SupplierPriceExcludingVatChanged",
                                    supplierCostInfo: { ...data?.supplierCostInfo, price: value },
                                })
                            }}
                            onEndChange={(value) => {
                                checkSameValue(value, pricingInvoice?.supplierPriceExcludingVat, () => {
                                    setData({ ...data, supplierCostInfo: { ...data?.supplierCostInfo, price: value } });
                                    getCalculatedInvoiceItemEndChange({
                                        ...collectionData,
                                        eventTypes: "SupplierPriceExcludingVatChanged",
                                        supplierCostInfo: { ...data?.supplierCostInfo, price: value },
                                    })
                                })
                            }}
                        />
                        <DecimalInputV2
                            disabled={props?.documentStateProc}
                            label={t("documents.invoice.supplierAmount")}
                            inputId={"supplierAmount"}
                            value={data?.supplierCostInfo?.sum}
                            onChange={(value) => {
                                setData({ ...data, supplierCostInfo: { ...data?.supplierCostInfo, sum: value } });
                                getCalculatedInvoiceItem({
                                    ...collectionData,
                                    eventTypes: "SupplierAmountExcludingVatChanged",
                                    supplierCostInfo: { ...data?.supplierCostInfo, sum: value },
                                })
                            }}
                            onEndChange={(value) => {
                                checkSameValue(value, pricingInvoice?.supplierAmountExcludingVat, () => {
                                    setData({ ...data, supplierCostInfo: { ...data?.supplierCostInfo, sum: value } });
                                    getCalculatedInvoiceItemEndChange({
                                        ...collectionData,
                                        eventTypes: "SupplierAmountExcludingVatChanged",
                                        supplierCostInfo: { ...data?.supplierCostInfo, sum: value },
                                    })
                                })
                            }}
                        />
                        <DecimalInputV2
                            disabled={props?.documentStateProc}
                            label={t("documents.invoice.supplierVatAmount")}
                            inputId={"supplierVatAmount"}
                            value={data?.supplierCostInfo?.vatSum}
                            onChange={(value) => {
                                setData({ ...data, supplierCostInfo: { ...data?.supplierCostInfo, vatSum: value } });
                                getCalculatedInvoiceItem({
                                    ...collectionData,
                                    eventTypes: "SupplierVatAmountChanged",
                                    supplierCostInfo: { ...data?.supplierCostInfo, vatSum: value },
                                })
                            }}
                            onEndChange={(value) => {
                                checkSameValue(value, pricingInvoice?.supplierVatAmount, () => {
                                    setData({ ...data, supplierCostInfo: { ...data?.supplierCostInfo, vatSum: value } });
                                    getCalculatedInvoiceItemEndChange({
                                        ...collectionData,
                                        eventTypes: "SupplierVatAmountChanged",
                                        supplierCostInfo: { ...data?.supplierCostInfo, vatSum: value },
                                    })
                                })
                            }}
                        />
                        <DecimalInputV2
                            disabled={props?.documentStateProc}
                            label={t("documents.invoice.supplierPriceWithVat")}
                            inputId={"supplierPriceWithVat"}
                            value={calcSum.supplierPriceWithVat}
                            onChange={(value) => setCalcSum({ ...calcSum, supplierPriceWithVat: value })}
                        />
                        <DecimalInputV2
                            disabled={props?.documentStateProc}
                            label={t("documents.invoice.amountOfThePaymentWithVat")}
                            inputId={"amountOfThePaymentWithVat"}
                            value={data?.supplierCostInfo?.sumIncVat}
                            onChange={(value) => {
                                setData({ ...data, supplierCostInfo: { ...data?.supplierCostInfo, sumIncVat: value } });
                                getCalculatedInvoiceItem({
                                    ...collectionData,
                                    eventTypes: "SupplierAmountWithVatChanged",
                                    supplierCostInfo: { ...data?.supplierCostInfo, sumIncVat: value },
                                })
                            }}
                            onEndChange={(value) => {
                                checkSameValue(value, pricingInvoice?.supplierAmountWithVat, () => {
                                    setData({ ...data, supplierCostInfo: { ...data?.supplierCostInfo, sumIncVat: value } });
                                    getCalculatedInvoiceItemEndChange({
                                        ...collectionData,
                                        eventTypes: "SupplierAmountWithVatChanged",
                                        supplierCostInfo: { ...data?.supplierCostInfo, sumIncVat: value },
                                    })
                                })
                            }}
                        />
                    </FlexRow>

                    <FlexRow wrap>
                        <GridSelectorInput
                            required
                            disabled={props?.documentStateProc}
                            variant="required"
                            selectedEntity={retailVatRate}
                            label={t("documents.invoice.retailVatRate")}//"Ставка НДС, розн."
                            id="idGoodsGlobal"
                            selectorModalJsx={TaxTypeSelectorModal}
                            onSelect={(entity) => {
                                setRetailVatRate(entity);
                                let vatValue = 0;
                                if (entity?.idGlobal) {
                                    taxTypeDataProvider.getById(entity.idGlobal, (t) => {
                                        vatValue = t.taxRate;
                                        setData({ ...data, retailCostInfo: { ...data?.retailCostInfo, vat: vatValue } });
                                        calcByPercentageOfVatRateRetailChanged(vatValue);
                                    });
                                } else {
                                    setData({ ...data, retailCostInfo: { ...data?.retailCostInfo, vat: vatValue } });
                                    calcByPercentageOfVatRateRetailChanged(vatValue);
                                }
                            }}
                            onClear={() => setRetailVatRate(undefined)}
                            error={errors.retailVat}
                            onFocus={() => setErrors({ ...errors, retailVat: undefined })}
                        />
                        <DecimalInputV2
                            disabled={props?.documentStateProc}
                            label={t("documents.invoice.adprice")}//"Наценка %, розн."
                            inputId={"interestMargin"}
                            value={data.retailCostInfo.adprice}
                            onChange={(value) => {
                                setData({ ...data, retailCostInfo: { ...data?.retailCostInfo, adprice: value } });
                                getCalculatedInvoiceItem({
                                    ...collectionData,
                                    eventTypes: "PercentageOfMarkupRetailChanged",
                                    retailCostInfo: { ...data?.retailCostInfo, adprice: value },
                                })
                            }}
                            onEndChange={(value) => {
                                checkSameValue(value, pricingInvoice?.percentageOfMarkupRetail, () => {
                                    setData({ ...data, retailCostInfo: { ...data?.retailCostInfo, adprice: value } });
                                    getCalculatedInvoiceItemEndChange({
                                        ...collectionData,
                                        eventTypes: "PercentageOfMarkupRetailChanged",
                                        retailCostInfo: { ...data?.retailCostInfo, adprice: value },
                                    });
                                });
                            }}
                        />
                        <DecimalInputV2
                            disabled
                            label={t("documents.invoice.vatPrice")}//"НДС за единицу, розн."
                            inputId={"retailAmountWithVat"}
                            value={data?.retailCostInfo?.vatSum}
                            onChange={(value) => {
                                setData({ ...data, retailCostInfo: { ...data?.retailCostInfo, vatSum: value } });
                                getCalculatedInvoiceItem({
                                    ...collectionData,
                                    eventTypes: "VatAmountRetailChanged",
                                    retailCostInfo: { ...data?.retailCostInfo, vatSum: value },
                                })
                            }}
                            onEndChange={(value) => {
                                checkSameValue(value, pricingInvoice?.vatAmountRetail, () => {
                                    setData({ ...data, retailCostInfo: { ...data?.retailCostInfo, vatSum: value } });
                                    getCalculatedInvoiceItemEndChange({
                                        ...collectionData,
                                        eventTypes: "VatAmountRetailChanged",
                                        retailCostInfo: { ...data?.retailCostInfo, vatSum: value },
                                    })
                                })
                            }}
                        />
                        <DecimalInputV2
                            label={t("documents.invoice.retailPrice")}//"Цена б/НДС, розн."
                            inputId={"retailPrice"}
                            value={data?.retailCostInfo?.price}
                            onChange={(value) => {
                                setData({ ...data, retailCostInfo: { ...data?.retailCostInfo, price: value } });
                                getCalculatedInvoiceItem({
                                    ...collectionData,
                                    eventTypes: "RetailPriceChanged",
                                    retailCostInfo: { ...data?.retailCostInfo, price: value },
                                });
                            }}
                            onEndChange={(value) => {
                                checkSameValue(value, pricingInvoice?.retailPrice, () => {
                                    setData({ ...data, retailCostInfo: { ...data?.retailCostInfo, price: value } });
                                    getCalculatedInvoiceItemEndChange({
                                        ...collectionData,
                                        eventTypes: "RetailPriceChanged",
                                        retailCostInfo: { ...data?.retailCostInfo, price: value },
                                    });
                                })
                            }}
                            disabled={props.documentStateProc}
                        />
                        <DecimalInputV2
                            disabled={props?.documentStateProc}
                            label={t("documents.invoice.retailAmountWithoutVat")}//"Сумма б/НДС, розн."
                            inputId={"retailAmountWithoutVat"}
                            value={data?.retailCostInfo?.sum}
                            onChange={(value) => {
                                setData({ ...data, retailCostInfo: { ...data?.retailCostInfo, sum: value } });
                                getCalculatedInvoiceItem({
                                    ...collectionData,
                                    eventTypes: "RetailAmountChanged",
                                    retailCostInfo: { ...data?.retailCostInfo, sum: value },
                                })
                            }}
                            onEndChange={(value) => {
                                checkSameValue(value, pricingInvoice?.retailAmount, () => {
                                    setData({ ...data, retailCostInfo: { ...data?.retailCostInfo, sum: value } });
                                    getCalculatedInvoiceItemEndChange({
                                        ...collectionData,
                                        eventTypes: "RetailAmountChanged",
                                        retailCostInfo: { ...data?.retailCostInfo, sum: value },
                                    })
                                })
                            }}
                        />
                        <DecimalInputV2
                            disabled={props?.documentStateProc}
                            label={t("documents.invoice.retailVatAmount")}//"Сумма НДС, розн."
                            inputId={"vatAmount"}
                            value={calcSum.retailVatPerUnit}
                            onChange={(value) => setCalcSum({ ...calcSum, retailVatPerUnit: value })}
                        />
                        <DecimalInputV2
                            disabled={props?.documentStateProc}
                            variant="green"
                            labelVariant="green"
                            className={styles.boldLabel}
                            label={t("documents.invoice.sellingPrice")}//"Цена с НДС, розн."
                            inputId={"sellingPrice"}
                            value={data?.retailCostInfo?.priceIncVat}
                            onChange={(value) => {
                                setData({ ...data, retailCostInfo: { ...data?.retailCostInfo, priceIncVat: value } });
                                getCalculatedInvoiceItem({
                                    ...collectionData,
                                    eventTypes: "RetailPriceWithVatChanged",
                                    retailCostInfo: { ...data?.retailCostInfo, priceIncVat: value },
                                });
                            }}
                            onEndChange={(value) => {
                                checkSameValue(value, pricingInvoice?.retailPriceWithVat, () => {
                                    setData({ ...data, retailCostInfo: { ...data?.retailCostInfo, priceIncVat: value } });
                                    getCalculatedInvoiceItemEndChange({
                                        ...collectionData,
                                        eventTypes: "RetailPriceWithVatChanged",
                                        retailCostInfo: { ...data?.retailCostInfo, priceIncVat: value },
                                    });
                                })
                            }}
                        />
                        <DecimalInputV2
                            disabled={props?.documentStateProc}
                            variant="green"
                            labelVariant="green"
                            className={styles.boldLabel}
                            label={t("documents.invoice.retailAmountWithVat")}//"Сумма с НДС, розн."
                            inputId={"retailAmountWithVat"}
                            value={+data?.retailCostInfo?.sumIncVat.toFixed(2)}
                            onChange={(value) => {
                                setData({ ...data, retailCostInfo: { ...data?.retailCostInfo, sumIncVat: value } });
                                getCalculatedInvoiceItem({
                                    ...collectionData,
                                    eventTypes: "RetailAmountWithVatChanged",
                                    retailCostInfo: { ...data?.retailCostInfo, sumIncVat: value },
                                })
                            }}
                            onEndChange={(value) => {
                                checkSameValue(value, pricingInvoice?.retailAmountWithVat, () => {
                                    setData({ ...data, retailCostInfo: { ...data?.retailCostInfo, sumIncVat: value } });
                                    getCalculatedInvoiceItemEndChange({
                                        ...collectionData,
                                        eventTypes: "RetailAmountWithVatChanged",
                                        retailCostInfo: { ...data?.retailCostInfo, sumIncVat: value },
                                    })
                                })
                            }}
                        />
                    </FlexRow>

                    <GridWrapper cols={8}>
                        <span className={styles.link_active} onClick={() => setIsOpenSeriesModal(true)}> 
                            {t("documents.invoice.certsAndSeries")/*Серии и сертификаты F9*/}
                        </span>
                        <span className={styles.link}>{t("documents.invoice.regCerts")}</span>
                        <span className={styles.link}>{t("documents.invoice.historyDelivery")}</span>
                    </GridWrapper>
                </Accordion>

                <Accordion opened={true} caption={t("documents.invoice.accompanyingDocuments")} id="AccompanyingDocuments">
                    <GridWrapper cols={4}>
                        <TextInput 
                            disabled={props?.documentStateProc}
                            className={styles.field__SupplierDocNumber} 
                            label={t("documents.invoice.invoice")}//"Накладная" 
                            value={data.supplierDocNumber} 
                            onChange={(value) => setData({ ...data, supplierDocNumber: value })} 
                        />
                        <DateInput
                            disabled={props?.documentStateProc}
                            className={styles.field__SupplierDocDate}
                            label={baseT("invoiceDate")}//"Дата накладной"
                            inputId={"inputAccompanyingSupplierDocDate"}
                            value={data?.supplierDocDate}
                            onBlur={(value) => {
                                value && setData({ ...data, supplierDocDate: value });
                            }}
                        />
                        <TextInput
                            disabled={props?.documentStateProc}
                            className={styles.field__IncomingBillNumber}
                            label={baseT("incomingBillNumber")}//"Счёт фактура"
                            value={data.incomingBillNumber}
                            onChange={(value) => setData({ ...data, incomingBillNumber: value })}
                        />
                        <DateInput
                            disabled={props?.documentStateProc}
                            className={styles.field__IncomingBillDate}
                            label={baseT("incomingBillDate")}//"Дата счёт фактура"
                            value={data.incomingBillDate}
                            onBlur={(value) => {
                                value && setData({ ...data, incomingBillDate: value });
                            }}
                        />
                    </GridWrapper>
                </Accordion>
                
                {(isOpenSeriesModal && !props.documentStateProc) && (
                    <SeriesModalWindow
                        cancel={() => setIsOpenSeriesModal(false)}
                        goods={goods as IEntitySimpleDTO}
                        setItemSeries={(value: ISerialDTO) => setItemSeries(value)}
                        itemSeries={itemSeries}
                    />
                )}
            </BaseDocumentCreator>
        </>
    );
};

export default ImportRemainsItemCreator;
