import { FC, useEffect, useState } from "react";
import { DocumentType, LoadingStatus } from "../../../@types/enumsGlobal";
import DefaultAccessPointDictionaryCommandsPanel from "../../../components/commandsPanels/DefaultAccessPointDictionaryCommandsPanel";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins";
import { Spinner } from "../../../components/spiner/Spinner";
import { IAccessPointViewDTO } from "../../../libs/coreapi-dto/dirs/accessPoint";
import { AccessPointDataProvider } from "../../../Services/DataProviders/AccessPointDataProvider";
import useGridFilter, { CreateSelectorGridFilter } from "../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { useUserContext } from "../../../system/providers/userContextProvider";
import { checkAccessStatus } from "../../CommonHelperFunctions";
import { AccessPointCreatorModal } from "./AccessPointCreatorModal";
import TabsPanel from "../../../components/tabs/TabsPanel";
import { useDetailsTabsPanel } from "../../../system/hooks/useTabsPanel";
import classNames from "classnames";
import tabsStyles from "../../../pages/styles/homePage.module.scss";
import styles from "../../styles/index.module.scss";
import { useTabsContext } from "../../../system/providers/tabsProvider";
import { useTranslation } from "react-i18next";
import { usePluginContext } from "../../../system/providers/plugin";

export const AccessPointGrid: FC<IGridProps> = (props) => {
    const appCtx = useAppContext()
    const tabCtx = useTabsContext();
    const pluginCtx = usePluginContext();
    const accessPointDataProvider = new AccessPointDataProvider(appCtx.coreApiService);
    const userContext = useUserContext()
    const [data, setData] = useState<IAccessPointViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>();
    const [totalCount, setTotalCount] = useState(0);
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [gridFilter, dispatchGridFilter] = useGridFilter(props.baseGridFilter ?? CreateSelectorGridFilter);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);

    const { t } = useTranslation();
    const baseT = (value: string) => t("accessPoint." + value);
    
    const [detailsTabsPanel, dispatchDetailsTabsPanel] = useDetailsTabsPanel(props.plugin.mnemocode);
    const [showDetailsTabs, setShowDetailsTabs] = useState<boolean>(false);

    useEffect(() => {
        setIsSubmitting(true)
        accessPointDataProvider.getView(gridFilter, async (data, totalCount) => {
            setData(data)
            setTotalCount(totalCount)
            setLoadingStatus(LoadingStatus.Completed)
            setIsSubmitting(false)
        })
    }, [gridFilter])

    useEffect(() => {
        if (tabCtx.currentTab?.title === `${baseT("tabName")}` /*'Точки доступа'*/) {
            setShowDetailsTabs(true);
        } else {
            setShowDetailsTabs(false);
        }
        pluginCtx.masterGrid.onUnselectEvent();
        setShowDetailsTabs(true);
    }, [tabCtx.currentTab]);

    useEffect(() => {
        if (viewState === 'refresh') {
            setIsSubmitting(true)
            accessPointDataProvider.getView(gridFilter, async (data, totalCount) => {
                setData(data)
                setViewState('view')
                setTotalCount(totalCount)
                setLoadingStatus(LoadingStatus.Completed)
                setIsSubmitting(false)
            })
        }
    }, [viewState])

    return (
        <PluginWrapper>
            {
                checkAccessStatus(props.plugin.permission as IPermission, userContext.userPermission) && <ToolbarWrapper>
                    <DefaultAccessPointDictionaryCommandsPanel
                        selectedItem={[selectedItem, setSelectedItem]}
                        gridFilter={[gridFilter, dispatchGridFilter]}
                        dataProvider={accessPointDataProvider}
                        creatorModalJsx={AccessPointCreatorModal}
                        pluginSettings={props.plugin}
                        setViewState={(vs) => setViewState(vs)}
                    />
                </ToolbarWrapper>
            }
            {
                isSubmitting ? <Spinner />
                    :
                    <DefaultGrid
                        gridId={props.gridId}
                        data={data}
                        loadingStatus={loadingStatus}
                        setLoadingStatus={setLoadingStatus}
                        separator
                        singleDirectory
                        totalCount={totalCount}
                        filter={gridFilter}
                        dataProvider={accessPointDataProvider}
                        plugin={props.plugin}
                        selectedItem={selectedItem}
                        onDoubleClick={(row) => props.onDoubleClick?.(row)}
                        onSelect={(row) => {
                            setSelectedItem(row)
                            props.onSelect?.(row)
                            row ? pluginCtx.masterGrid.onSelectEvent(row, DocumentType.accessPoint) : pluginCtx.masterGrid.onUnselectEvent();
                        }}
                        onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                        onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                        onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                        onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                        onEnter={() => setViewState("edit")}
                    />
            }
            {
                <div className={styles.detailsTabView} style={{marginTop: '25px'}}>
                    {showDetailsTabs && detailsTabsPanel.tabs.length > 0 && selectedItem && (
                        <>
                            <div className={tabsStyles.tabsPanelWrapper}>
                                <div id="detailsTabsPanelWrapper" style={{ position: "relative", overflow: "hidden", width: "100%" }}>
                                    <TabsPanel
                                        id="detailsTabsPanel"
                                        activeId={detailsTabsPanel.currentTab?.id}
                                        tabs={detailsTabsPanel.tabs}
                                        switchControl={true}
                                        onActive={(id) => {
                                            dispatchDetailsTabsPanel({ type: "activate", payload: id });
                                        }}
                                    ></TabsPanel>
                                </div>
                            </div>
                            <div className={tabsStyles.contentWrapper}>
                                {detailsTabsPanel.tabs.map((item) => {
                                    return (
                                        <div key={item.id} className={classNames(item.id === detailsTabsPanel.currentTab?.id ? tabsStyles.contentVisible : tabsStyles.contentHidden)}>
                                            {item.view.content}
                                        </div>
                                    );
                                })}
                            </div>
                        </>
                    )}
                </div>
            }
        </PluginWrapper>
    )
}

