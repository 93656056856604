import {CoreApiResponse, IRequestEventHandler} from '../../libs/core-api-requests/common';
import ContractorRequest from '../../libs/core-api-requests/dirs/contractorRequest';
import CountryRequest from '../../libs/core-api-requests/dirs/countryRequest';
import CureFormRequest from '../../libs/core-api-requests/dirs/cureformRequest';
import GoodsGroupRequest from '../../libs/core-api-requests/dirs/goodsGroupRequest';
import GoodsRequest from '../../libs/core-api-requests/dirs/goodsRequest';
import LegalEntityRequest from '../../libs/core-api-requests/dirs/legalentityRequest';
import ProducerRequest from '../../libs/core-api-requests/dirs/producerRequest';
import StoreRequest from '../../libs/core-api-requests/dirs/storeRequest';
import StoreTypeRequest from '../../libs/core-api-requests/dirs/storeTypeRequest';
import SubstanceRequest from '../../libs/core-api-requests/dirs/substanceRequest';
import TaxTypeRequest from '../../libs/core-api-requests/dirs/taxTypeRequest';
import TradeNameRequest from '../../libs/core-api-requests/dirs/tradeNameRequest';
import UnitRequest from '../../libs/core-api-requests/dirs/unitRequest';
import TokenRequest from '../../libs/core-api-requests/token/tokenRequest';
import GoodsCodeRequest from '../../libs/core-api-requests/dirs/goodsCodeRequest';
import {readTokenFromLocalStorage, writeTokenToLocalStorage} from '../../system/hooks/useToken';
import InvoiceRequest from '../../libs/core-api-requests/documents/invoiceRequest';
import ContractorGroupRequest from '../../libs/core-api-requests/dirs/contractorGroupRequest';
import PricingModelRequest from '../../libs/core-api-requests/dirs/pricingModelRequest';
import KizRequest from '../../libs/core-api-requests/dirs/kizRequest';
import KizDisposalRequest from '../../libs/core-api-requests/dirs/kizDisposalRequest';
import ScalingRatioRequest from '../../libs/core-api-requests/dirs/scalingRatioRequest';
import SerialRequest from '../../libs/core-api-requests/dirs/serialRequest';
import LotRequest from '../../libs/core-api-requests/accounting/lotRequest';
import LotMovementRequest from '../../libs/core-api-requests/accounting/lotMovementRequest';
import PricingInvoiceRequest from '../../libs/core-api-requests/dirs/pricingInvoiceRequest';
import {TokenDataProvider} from '../../Services/DataProviders/TokenDataProvider';
import jwtDecode from 'jwt-decode';
import CashRegister from '../../libs/core-api-requests/service/cashRegisterRequest';
import CashRegister2Store from '../../libs/core-api-requests/service/cashRegister2StoreRequest';
import RoleRequest from '../../libs/core-api-requests/service/roleRequest';
import UserRequest from '../../libs/core-api-requests/service/userRequest';
import CompanyRequest from '../../libs/core-api-requests/service/companyRequest';
import KizBoxRequest from '../../libs/core-api-requests/dirs/kizBoxRequest';
import ImportedInvoiceRequest from '../../libs/core-api-requests/documents/importedInvoiceRequest';
import ModuleRequest from '../../libs/core-api-requests/service/moduleRequest';
import ChequeRequest from '../../libs/core-api-requests/accounting/chequeRequest';
import {MovementRequest} from '../../libs/core-api-requests/documents/movementRequest';
import CashSessionRequest from '../../libs/core-api-requests/accounting/cashSessionRequest';
import ActDisassemblingRequest from '../../libs/core-api-requests/documents/ActDisassemblingRequest';
import {ActRevaluationRequest} from '../../libs/core-api-requests/documents/actRevaluationRequest';
import ActDisagregationRequest from '../../libs/core-api-requests/documents/actDisagregationRequest';
import InvoiceSettingsRequest from '../../libs/core-api-requests/dirs/invoiceSettingsRequest';
import DiscountConditionTypeRequest from '../../libs/core-api-requests/service/discountConditionTypeRequest';
import DiscountProgramRequest from '../../libs/core-api-requests/dirs/discountProgramRequest';
import KizDocRequest from '../../libs/core-api-requests/dirs/kizDocRequest';
import DefecturaRequest from '../../libs/core-api-requests/service/defecturaRequest';
import KizExchangeRequest from '../../libs/core-api-requests/dirs/kizExchangeRequest';
import PricingFormulaRequest from '../../libs/core-api-requests/dirs/pricingFormulaRequest';
import PricingDisassemblingRequest from '../../libs/core-api-requests/dirs/pricingDisassemblingRequest';
import TimeZoneRequest from '../../libs/core-api-requests/dirs/timeZoneRequest';
import DiscountCardTypeRequest from '../../libs/core-api-requests/dirs/discountCardTypeRequest';
import DiscountCardRequest from '../../libs/core-api-requests/dirs/discountCardRequest';
import DiscountMemberRequest from '../../libs/core-api-requests/dirs/discountMemberRequest';
import PricingRevaluationRequest from '../../libs/core-api-requests/dirs/pricingRevaluationRequest';
import LabelTemplateRequest from '../../libs/report-api-requests/labelTemplateRequest';
import PricingDisaggregationRequest from '../../libs/core-api-requests/dirs/pricingDisaggregationRequest';
import ImportRemainsRequest from '../../libs/core-api-requests/documents/importRemainsRequest';
import InvoiceOutRequest from '../../libs/core-api-requests/documents/invoiceOutRequest';
import GosContractRequest from '../../libs/core-api-requests/dirs/gosContractRequest';
import TaskProgramRequest from '../../libs/core-api-requests/dirs/taskProgramRequest';
import GosContractFundingSourceRequest from '../../libs/core-api-requests/dirs/gosContractFundingSourceRequest';
import GoodsKindRequest from '../../libs/core-api-requests/dirs/goodsKindRequest';
import LoggerRequest from '../../libs/core-api-requests/dirs/loggerRequest';
import BarcodeRequest from '../../libs/core-api-requests/dirs/barcodeRequest';
import {ConstructorSchemesRequest} from '../../libs/core-api-requests/dirs/constructorSchemesRequest';
import {envF} from '../../env';
import {ActReturnToContractorRequest} from '../../libs/core-api-requests/documents/actReturnToContractorRequest';
import ActDeductionRequest from '../../libs/core-api-requests/documents/actDeductionRequest';
import DeductionReasonRequest from '../../libs/core-api-requests/dirs/deductionReasonRequest';
import KizFilesRequest from '../../libs/core-api-requests/dirs/kizFilesRequest';
import AccessPointRequest from '../../libs/core-api-requests/dirs/accessPointRequest';
import ImportInvoicesRequest from '../../libs/core-api-requests/dirs/importInvoicesRequest';
import ImportAutoInvoicesStatusRequest from '../../libs/core-api-requests/dirs/importAutoInvoicesStatusRequest';
import KizDocumentRequest from '../../libs/core-api-requests/dirs/KizDocumentRequest';
import DatabaseRequest from '../../libs/core-api-requests/service/databaseRequest';
import UserLocalRequest from '../../libs/core-api-requests/dirs/userLocalRequest';
import RoleLocalRequest from '../../libs/core-api-requests/dirs/roleLocalRequest';
import User2ContractorRequest from '../../libs/core-api-requests/dirs/user2ContractorRequest';
import InventoryVedRequest from "../../libs/core-api-requests/accounting/inventoryVedRequest";
import InventorySvedRequest from '../../libs/core-api-requests/accounting/InventorySvedRequest';
import LockingDocumentsRequest from '../../libs/core-api-requests/dirs/lockingDocumentsRequest';
import RegisterPriceRequest from '../../libs/core-api-requests/dirs/registerPriceRequest';
import SysOptionsRequest from '../../libs/core-api-requests/service/sysOptionsRequest';
import EsExchangeSettingsRequest from '../../libs/core-api-requests/dirs/esExchangeSettingsRequest';
import InternetOrderRequest from '../../libs/core-api-requests/accounting/internetOrderRequest';
import Kiz2InvetoryVedRequest from '../../libs/core-api-requests/dirs/kiz2InventoryVedRequest';
import PricingAdpriceScaleTypeRequest from '../../libs/core-api-requests/dirs/pricingAdpriceScaleTypeRequest';
import PricingAdpriceScaleRequest from '../../libs/core-api-requests/dirs/pricingAdpriceScaleRequest';
import JwtBearerToken from '../jwtBearerToken';
import "navigator.locks";
import PricingConditionRequest from '../../libs/core-api-requests/dirs/pricingConditionRequest';
import PricingConditionParamRequest from '../../libs/core-api-requests/dirs/pricingConditionParamRequest';
import PricingRoundSchemaRequest from '../../libs/core-api-requests/dirs/pricingRoundSchemaRequest';
import PricingRoundScaleRequest from '../../libs/core-api-requests/dirs/pricingRoundScaleRequest';
import PricingExtremeAdpriceSchemaTypeRequest from '../../libs/core-api-requests/dirs/pricingExtremeAdpriceSchemaTypeRequest';
import PricingExtremeAdpriceSchemaRequest from '../../libs/core-api-requests/dirs/pricingExtremeAdpriceSchemaRequest';
import PricingMinAdpriceSchemaTypeRequest from '../../libs/core-api-requests/dirs/pricingMinAdpriceSchemaTypeRequest';
import PricingMinAdpriceSchemaRequest from '../../libs/core-api-requests/dirs/pricingMinAdpriceSchemaRequest';
import PricingPriceCalcSchemaRequest from '../../libs/core-api-requests/dirs/pricingPriceCalcSchemaRequest';
import PricingPriceCalcSchemaTypeRequest from '../../libs/core-api-requests/dirs/pricingPriceCalcSchemaTypeRequest';
import EsDataCompanyRequest from '../../libs/core-api-requests/dirs/esDataCompanyRequest';
import KizInventoryRequest from '../../libs/core-api-requests/accounting/KizInventoryRequest';
import { DisposalRegistrarRequest } from '../../libs/core-api-requests/service/disposalRegistrarRequest';
import StoreType2DocRequest from '../../libs/core-api-requests/dirs/storeType2DocRequest';
import ActiveSessionsRequest from '../../libs/core-api-requests/service/activeSessionsRequest';
import AuthDataRequest from '../../libs/core-api-requests/service/authDataRequest';
import AccessPoint2SupplierRequest from '../../libs/core-api-requests/dirs/accessPoint2SupplierRequest';

class CoreApiService {
    private readonly _baseUrl: string;
    private readonly _eventHandler: IRequestEventHandler;
    public onTokenChanged?: (oldToken: string) => void;

    constructor(baseUrl: string) {
        this._baseUrl = baseUrl;
        this._eventHandler = { onGetResponse: () => {} };
    }

    private readonly lifetimeDeltaMs: number = 0;

    getAccessToken(): Promise<string> {
        return navigator.locks.request("access_token", async () => {
            return new Promise<string>((resolve, reject) => {

                let token = readTokenFromLocalStorage();
                if (token !== null) {

                    const access_token = (token as JwtBearerToken).getAccessToken();
                    if (access_token.lifeTimeInMs <= this.lifetimeDeltaMs) {

                        this.accessTokenRequest.refreshAccessToken((token as JwtBearerToken).getRefreshToken(), (e) => {

                            if (e.respType === 'isCompleted') {
                                const decoded: DecodedToken = jwtDecode(e.data.access_token);
                                writeTokenToLocalStorage({
                                    accessToken: e.data.access_token,
                                    refreshToken: e.data.refresh_token,
                                    payload: {
                                        idGlobal: decoded.sub,
                                        name: decoded.preferred_username,
                                    },
                                });

                                this.onTokenChanged?.(access_token.value);
                                resolve(e.data.access_token);
                            } else {
                                reject('Токен не может быть обновлен.');
                            }

                        });
                    } else {
                        resolve((token as JwtBearerToken).getAccessToken().value);
                    }
                } else {
                    resolve('');
                }
            });
        });
    }

    subscribeOnGetResponse(event: (e: CoreApiResponse<any>) => void) {
        this._eventHandler.onGetResponse = event;
    }

    get tokenRequest() {
        return new TokenRequest(this._baseUrl, this._eventHandler);
    }

    get accessTokenRequest() {
        return new TokenDataProvider(envF.REACT_APP_TOKEN_PATH as string, this._eventHandler);
    }

    get roleRequest(): Promise<RoleRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
                    resolve(new RoleRequest(envF.REACT_APP_OAUTH_API_PATH as string, accessToken, this._eventHandler));
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }
    get companyRequest(): Promise<CompanyRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new CompanyRequest(envF.REACT_APP_OAUTH_API_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get activeSessionsRequest(): Promise<ActiveSessionsRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new ActiveSessionsRequest(envF.REACT_APP_OAUTH_API_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get databaseRequest(): Promise<DatabaseRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new DatabaseRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get discountConditionTypeRequest(): Promise<DiscountConditionTypeRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new DiscountConditionTypeRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get defecturaRequest(): Promise<DefecturaRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new DefecturaRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get discountProgramRequest(): Promise<DiscountProgramRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new DiscountProgramRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get discountMemberRequest(): Promise<DiscountMemberRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new DiscountMemberRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get taskProgramRequest(): Promise<TaskProgramRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new TaskProgramRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get goodsKindRequest(): Promise<GoodsKindRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new GoodsKindRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get discountCardTypeRequest(): Promise<DiscountCardTypeRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new DiscountCardTypeRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get discountCardRequest(): Promise<DiscountCardRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new DiscountCardRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get userRequest(): Promise<UserRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
                    resolve(new UserRequest(envF.REACT_APP_OAUTH_API_PATH as string, accessToken, this._eventHandler));
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get moduleRequest(): Promise<ModuleRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new ModuleRequest(envF.REACT_APP_OAUTH_API_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get taxTypeRequest(): Promise<TaxTypeRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new TaxTypeRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => reject(ex));
        });
    }

    get registerPriceRequest(): Promise<RegisterPriceRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new RegisterPriceRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => reject(ex));
        });
    }

    get chequeRequest(): Promise<ChequeRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new ChequeRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => reject(ex));
        });
    }

    get cashSessionRequest(): Promise<CashSessionRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new CashSessionRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => reject(ex));
        });
    }

    get storeTypeRequest(): Promise<StoreTypeRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new StoreTypeRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get storeType2DocRequest(): Promise<StoreType2DocRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new StoreType2DocRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get countryRequest(): Promise<CountryRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new CountryRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get deductionReasonRequest(): Promise<DeductionReasonRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new DeductionReasonRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get gosContractRequest(): Promise<GosContractRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new GosContractRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get constructorSchemesRequest(): Promise<ConstructorSchemesRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new ConstructorSchemesRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get gosContractFundingSourceRequest(): Promise<GosContractFundingSourceRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new GosContractFundingSourceRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get producerRequest(): Promise<ProducerRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new ProducerRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get lotRequest(): Promise<LotRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
                    resolve(new LotRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler));
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get lotMovementRequest(): Promise<LotMovementRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new LotMovementRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get substanceRequest(): Promise<SubstanceRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new SubstanceRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get tradeNameRequest(): Promise<TradeNameRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new TradeNameRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get goodsCodeRequest(): Promise<GoodsCodeRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new GoodsCodeRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get cureFormRequest(): Promise<CureFormRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new CureFormRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get storeRequest(): Promise<StoreRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new StoreRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get legalEntityRequest(): Promise<LegalEntityRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new LegalEntityRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get contractorRequest(): Promise<ContractorRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new ContractorRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }
    get barcodeRequest(): Promise<BarcodeRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new BarcodeRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get goodsRequest(): Promise<GoodsRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new GoodsRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get pricingFormulaRequest(): Promise<PricingFormulaRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new PricingFormulaRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get loggerRequest(): Promise<LoggerRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new LoggerRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get invoiceRequest(): Promise<InvoiceRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new InvoiceRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get invoiceOutRequest(): Promise<InvoiceOutRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new InvoiceOutRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get importRemainsRequest(): Promise<ImportRemainsRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new ImportRemainsRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get invoiceSettings(): Promise<InvoiceSettingsRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new InvoiceSettingsRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get actRevaluationRequest(): Promise<ActRevaluationRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new ActRevaluationRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get actDisassemblingRequest(): Promise<ActDisassemblingRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new ActDisassemblingRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get actReturnToContractorRequest(): Promise<ActReturnToContractorRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new ActReturnToContractorRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get actDisagregationRequest(): Promise<ActDisagregationRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new ActDisagregationRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get actDeduction(): Promise<ActDeductionRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
            .then(accessToken => {
                resolve(new ActDeductionRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler))
                })
            .catch(ex => {
                reject(ex)
            })
    })
    }

    get movementRequest(): Promise<MovementRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new MovementRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get importedInvoiceRequest(): Promise<ImportedInvoiceRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new ImportedInvoiceRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get unitRequest(): Promise<UnitRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new UnitRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }
    get goodsGroupRequest(): Promise<GoodsGroupRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new GoodsGroupRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }
    get contractorGroupRequest(): Promise<ContractorGroupRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new ContractorGroupRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }
    get kizExchangeRequest(): Promise<KizExchangeRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new KizExchangeRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }
    get pricingModelRequest(): Promise<PricingModelRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new PricingModelRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get pricingAdpriceScaleTypeRequest(): Promise<PricingAdpriceScaleTypeRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new PricingAdpriceScaleTypeRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get pricingAdpriceScaleRequest(): Promise<PricingAdpriceScaleRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new PricingAdpriceScaleRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get pricingExtremeAdpriceSchemaTypeRequest(): Promise<PricingExtremeAdpriceSchemaTypeRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new PricingExtremeAdpriceSchemaTypeRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get pricingMinAdpriceSchemaTypeRequest(): Promise<PricingMinAdpriceSchemaTypeRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new PricingMinAdpriceSchemaTypeRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get pricingMinAdpriceSchemaRequest(): Promise<PricingMinAdpriceSchemaRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new PricingMinAdpriceSchemaRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get pricingPriceCalcSchemaTypeRequest(): Promise<PricingPriceCalcSchemaTypeRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new PricingPriceCalcSchemaTypeRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get pricingPriceCalcSchemaRequest(): Promise<PricingPriceCalcSchemaRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new PricingPriceCalcSchemaRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get pricingExtremeAdpriceSchemaRequest(): Promise<PricingExtremeAdpriceSchemaRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new PricingExtremeAdpriceSchemaRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get pricingConditionRequest(): Promise<PricingConditionRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new PricingConditionRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get pricingConditionParamRequest(): Promise<PricingConditionParamRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new PricingConditionParamRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get pricingRoundSchemaRequest(): Promise<PricingRoundSchemaRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new PricingRoundSchemaRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get pricingRoundScaleRequest(): Promise<PricingRoundScaleRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new PricingRoundScaleRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get pricingInvoiceRequest(): Promise<PricingInvoiceRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new PricingInvoiceRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get pricingDisassemblingRequest(): Promise<PricingDisassemblingRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
        .then((accessToken) =>
          resolve(
            new PricingDisassemblingRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          )
        )
                .catch((ex) => reject(ex));
        });
    }

    get pricingDisaggregationRequest(): Promise<PricingDisaggregationRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
        .then((accessToken) =>
          resolve(
            new PricingDisaggregationRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          )
        )
                .catch((ex) => reject(ex));
        });
    }

    get pricingRevaluationRequest(): Promise<PricingRevaluationRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
        .then((accessToken) =>
          resolve(
            new PricingRevaluationRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          )
        )
                .catch((ex) => reject(ex));
        });
    }

    get kizRequest(): Promise<KizRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
                    resolve(new KizRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler));
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }
    get kizDocumentRequest(): Promise<KizDocumentRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
                    resolve(new KizDocumentRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler));
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }
    get kizDocRequest(): Promise<KizDocRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new KizDocRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }
    get kizBoxRequest(): Promise<KizBoxRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new KizBoxRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get scalingRatioRequest(): Promise<ScalingRatioRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new ScalingRatioRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }
    get serialRequest(): Promise<SerialRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new SerialRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }
    get cashRegisterRequest(): Promise<CashRegister> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new CashRegister(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get disposalRegistrarRequest(): Promise<DisposalRegistrarRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new DisposalRegistrarRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get sysOptionsRequest(): Promise<SysOptionsRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new SysOptionsRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get cashRegister2StoreRequest(): Promise<CashRegister2Store> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new CashRegister2Store(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }
    get timeZoneRequest(): Promise<TimeZoneRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new TimeZoneRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }
    get labelTemplateRequest(): Promise<LabelTemplateRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new LabelTemplateRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get kizFilesRequest(): Promise<KizFilesRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
                    resolve(new KizFilesRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler));
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get accessPoint2SupplierRequest(): Promise<AccessPoint2SupplierRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new AccessPoint2SupplierRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get accessPointRequest(): Promise<AccessPointRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new AccessPointRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get importInvoicesRequest(): Promise<ImportInvoicesRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
                    resolve(new ImportInvoicesRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler));
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get importAutoInvoicesStatusRequest(): Promise<ImportAutoInvoicesStatusRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new ImportAutoInvoicesStatusRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

  get userLocalRequest(): Promise<UserLocalRequest> {
    return new Promise((resolve, reject) => {
      this.getAccessToken()
        .then((accessToken) => {
          resolve(
            new UserLocalRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
        })
        .catch((ex) => {
          reject(ex);
        });
    });
  }

  get roleLocalRequest(): Promise<RoleLocalRequest> {
    return new Promise((resolve, reject) => {
      this.getAccessToken()
        .then((accessToken) => {
          resolve(
            new RoleLocalRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
        })
        .catch((ex) => {
          reject(ex);
        });
    });
  }

  get user2ContractorRequest(): Promise<User2ContractorRequest> {
    return new Promise((resolve, reject) => {
      this.getAccessToken()
        .then((accessToken) => {
          resolve(
            new User2ContractorRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
          );
        })
        .catch((ex) => {
          reject(ex);
            });
        });
    }

    get inventoryVedRequest(): Promise<InventoryVedRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
                    resolve(new InventoryVedRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler));
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get inventorySvedRequest(): Promise<InventorySvedRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
                    resolve(new InventorySvedRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler));
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get lockingDocumentsRequest(): Promise<LockingDocumentsRequest> {
        return new Promise((resolve, reject) => {
          this.getAccessToken()
            .then((accessToken) => {
              resolve(
                new LockingDocumentsRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
              );
            })
            .catch((ex) => {
              reject(ex);
                });
            });
        }

    get esExchangeSettingsRequest(): Promise<EsExchangeSettingsRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
                  resolve(
                    new EsExchangeSettingsRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
                  );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get internetOrderRequest(): Promise<InternetOrderRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
                    resolve(
                        new InternetOrderRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
                    );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get kiz2InventoryVedRequest(): Promise<Kiz2InvetoryVedRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
                    resolve(
                        new Kiz2InvetoryVedRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
                    );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get kizDisposalRequest(): Promise<KizDisposalRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
                    resolve(
                        new KizDisposalRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
                    );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get esDataCompanyRequest(): Promise<EsDataCompanyRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
          resolve(
            new EsDataCompanyRequest(
              envF.REACT_APP_API_GATEWAY_PATH as string,
              accessToken,
              this._eventHandler
            )
          );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get kizInventoryRequest(): Promise<KizInventoryRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
                    resolve(new KizInventoryRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler));
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    get authDataRequest(): Promise<AuthDataRequest> {
        return new Promise((resolve, reject) => {
            this.getAccessToken()
                .then((accessToken) => {
                    resolve(
                        new AuthDataRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this._eventHandler)
                    );
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }
    
}

export default CoreApiService;