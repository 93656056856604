import { FC, useEffect, useState } from "react";
import { LoadingStatus } from "../../../@types/enumsGlobal";
import DefaultDictionaryCommandsPanel from "../../../components/commandsPanels/DefaultDictionaryCommandsPanel";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins";
import { Spinner } from "../../../components/spiner/Spinner";
import { ILegalEntityConvolution, ILegalEntityConvolutionInfo, ILegalEntityViewDTO } from "../../../libs/coreapi-dto/dirs/legalEntity";
import { LegalEntityDataProvider } from "../../../Services/DataProviders/LegalEntityDataProvider";
import useGridFilter, { CreateSelectorGridFilter } from "../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { useUserContext } from "../../../system/providers/userContextProvider";
import { checkAccessStatus, checkActiveCommands } from "../../CommonHelperFunctions";
import LegalEntityCreatorModal from "./LegalEntityCreatorModal";
import * as MainIcons from "../../../libs/corporate/icons/outlined/action/MainCollection"
import styles from '../../styles/index.module.scss'
import legalEntityStyles from './styles/LegalEntityCreatorModal.module.scss'
import classNames from "classnames"
import { useTranslation } from "react-i18next";
import { LegalEntityConvolutionModal } from "./LegalEntityConvolutionModal";
import { ProgressActionSpinner } from "../../../components/progressActionSpinner/ProgressActionSpinner";

const LegalEntityGrid: FC<IGridProps> = (props) => {
    const appCtx = useAppContext();
    const userContext = useUserContext();
    const legalEntityDataProvider = new LegalEntityDataProvider(appCtx.coreApiService)

    const [data, setData] = useState<ILegalEntityViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [totalCount, setTotalCount] = useState(0);
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [gridFilter, dispatchGridFilter] = useGridFilter(props.baseGridFilter ?? CreateSelectorGridFilter);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);
    const [selectedItems, setSelectedItems] = useState<IGridRow[]>([]);
    const [multipleSelect, setMultipleSelect] = useState<boolean>(false);
    const [legalEntityConvolution, setLegalEntityConvolution] = useState<[ILegalEntityConvolutionInfo, ILegalEntityConvolutionInfo]>();
    const [isOpenConvolution, setIsOpenConvolution] = useState<boolean>(false)
    const [modalWindow, setModalWindow] = useState(<></>);
    
    const { t } = useTranslation();

    const convolutionLocked = () => {
        const activeCommands = checkActiveCommands("LegalEntityConvolution", userContext.userPermission);
        return !activeCommands.convoluteLegalEntity
    };

    const convolutionLockFromPermission = convolutionLocked();

    useEffect(() => {
        setIsSubmitting(true)
        legalEntityDataProvider.getView(gridFilter, (data, totalCount) => {
            setData(data)
            setTotalCount(totalCount)
            data.length > 0 ? setLoadingStatus(LoadingStatus.Completed) : setLoadingStatus(LoadingStatus.NoData)
            setIsSubmitting(false)
        })
    }, [viewState, gridFilter])

    const showConvolution = () => {
        if (selectedItems?.length === 2) {
            const expression = `"IdGlobal" in ('${selectedItems[0].idGlobal}', '${selectedItems[1].idGlobal}')`;
            const selectedLegalEntityFilter = { ...gridFilter, expression: expression } as IGridCustomWhereExpressionFilter

            legalEntityDataProvider.getView(selectedLegalEntityFilter,(legalEntity,totalCount) => {
                if (totalCount === 2) {
                    setLegalEntityConvolution([
                        {
                            idGlobal: legalEntity[0].idGlobal,
                            name: legalEntity[0].name,
                            Inn: legalEntity[0].inn
                        } as ILegalEntityConvolutionInfo,
                        {
                            idGlobal: legalEntity[1].idGlobal,
                            name: legalEntity[1].name,
                            Inn: legalEntity[1].inn
                        } as ILegalEntityConvolutionInfo
                    ]);
                    setIsOpenConvolution(true);
                }
            })
        }
    };

    const renderProgressView = (actionMessage: string) => {
        setModalWindow(
            <ProgressActionSpinner
                loaderText={`${actionMessage}`}
            />
        );
    };

    return <PluginWrapper>
            {
                checkAccessStatus(props.plugin.permission as IPermission, userContext.userPermission) && <ToolbarWrapper>                    
                    <DefaultDictionaryCommandsPanel
                        selectedItems={[selectedItems, setSelectedItems]}
                        selectedItem={[selectedItem, setSelectedItem]}
                        gridFilter={[gridFilter, dispatchGridFilter]}
                        dataProvider={legalEntityDataProvider}
                        creatorModalJsx={LegalEntityCreatorModal}
                        pluginSettings={props.plugin}
                        setViewState={(vs) => setViewState(vs)}
                        multipleSelect={[multipleSelect, setMultipleSelect]}
                        showGroupProcessing
                    />
                     <div>
                        <button
                            className={classNames(legalEntityStyles.convolutionButton)}
                            onClick={() => showConvolution()}
                            disabled={convolutionLockFromPermission || selectedItems.length !== 2}
                        >
                            <MainIcons.SwapIcon/>
                            <div className={styles.contentWrapper}>
                                {t("directory.legalEntity.convolution")}
                            </div>
                        </button>
                    </div>
                </ToolbarWrapper>
                
            }

            {
                isSubmitting ? <Spinner /> : <DefaultGrid
                    gridId={props.gridId}
                    data={data}
                    loadingStatus={loadingStatus}
                    setLoadingStatus={setLoadingStatus}
                    totalCount={totalCount}
                    multipleSelect={multipleSelect}
                    selectedItems={selectedItems}
                    filter={gridFilter}
                    dataProvider={legalEntityDataProvider}
                    plugin={props.plugin}
                    selectedItem={selectedItem}
                    onDoubleClick={(row) => props.onDoubleClick?.(row)}
                    onSelect={(row) => {
                        setSelectedItem(row)
                        props.onSelect?.(row)
                    }}
                    onMultipleSelect={(rows) => setSelectedItems(rows) }
                    onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                    onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                    onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                    onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                    onEnter={() => setViewState("edit")}
                />
            }

            {
                isOpenConvolution && 
                <LegalEntityConvolutionModal
                    legalEntitySource={ legalEntityConvolution?.[0] as ILegalEntityConvolutionInfo}
                    legalEntityTarget={ legalEntityConvolution?.[1] as ILegalEntityConvolutionInfo}
                    convolute={(convolutionDto: ILegalEntityConvolution) => {                      
                        setIsOpenConvolution(false);
                        renderProgressView(t("directory.legalEntity.convolution"));
                        legalEntityDataProvider.convolute(convolutionDto, (e) => {
                            setMultipleSelect(false);
                            setViewState("refresh");
                            setModalWindow(<></>);
                        }, (e) => {
                            setModalWindow(<></>);
                        })
                    }}
                    cancel={() => {
                        setIsOpenConvolution(false);
                    }}
                />
            }
            {modalWindow}
        </PluginWrapper>
        
    
}

export default LegalEntityGrid