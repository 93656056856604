import { ChequeGridPluginSettings } from "../Cheque/ChequeGrid/ChequeGridPluginSettings"

const CashSessionGridPluginSettings: IPluginSettings = {
    name: 'Кассовые смены',
    mnemocode: 'cash_session_plugin',
    permission: 'CashSession',
    columns: [{
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'Номер смены',
        visibility: true,
        width: 150
    }, {
        order: 1,
        propertyName: 'mnemocode',
        propertyType: 'string',
        displayName: 'Мнемокод',
        visibility: true,
        width: 150
    }, {
        order: 2,
        propertyName: 'dateOpen',
        propertyType: 'datetime',
        displayName: 'Дата открытия кассы',
        visibility: true,
        width: 150
    }, {
        order: 3,
        propertyName: 'dateClose',
        propertyType: 'datetime',
        displayName: 'Дата закрытия кассы',
        visibility: true,
        width: 150
    }, {
        order: 4,
        propertyName: 'shortNameUserDataOpen',
        propertyType: 'string',
        displayName: 'Пользователь открытия кассы',
        visibility: true,
        width: 150
    }, {
        order: 5,
        propertyName: 'shortNameUserDataClose',
        propertyType: 'string',
        displayName: 'Пользователь закрытия кассы',
        visibility: true,
        width: 150
    }, {
        order: 6,
        propertyName: 'documentState',
        propertyType: 'string',
        displayName: 'Статус',
        visibility: true,
        width: 100
    }, {
        order: 7,
        propertyName: 'quantity',
        propertyType: 'number',
        displayName: 'Сум кол-во продаж',
        visibility: true,
        width: 150
    }, {
        order: 8,
        propertyName: 'sum',
        propertyType: 'number',
        displayName: 'Сум продаж розничные',
        visibility: true,
        width: 150
    }, {
        order: 9,
        propertyName: 'sumCash',
        propertyType: 'number',
        displayName: 'Сум продаж наличными',
        visibility: true,
        width: 150
    }, {
        order: 10,
        propertyName: 'sumCredit',
        propertyType: 'number',
        displayName: 'Сум продаж безналичными',
        visibility: true,
        width: 150
    }, {
        order: 11,
        propertyName: 'quantityReturns',
        propertyType: 'number',
        displayName: 'Сум кол-во возвратов',
        visibility: true,
        width: 150
    }, {
        order: 12,
        propertyName: 'sumReturns',
        propertyType: 'number',
        displayName: 'Сум возврат розничные',
        visibility: true,
        width: 150
    }, {
        order: 13,
        propertyName: 'sumCashReturns',
        propertyType: 'number',
        displayName: 'Сум возврат наличными',
        visibility: true,
        width: 150
    }, {
        order: 14,
        propertyName: 'sumCreditReturns',
        propertyType: 'number',
        displayName: 'Сум возврат безналичными',
        visibility: true,
        width: 150
    }, {
        order: 15,
        propertyName: 'sumDiscount',
        propertyType: 'number',
        displayName: 'Сумма скидки',
        visibility: true,
        width: 150
    },
    {
        order: 16,
        propertyName: 'cashRegisterName',
        propertyType: 'string',
        displayName: 'ККМ',
        visibility: true,
        width: 150
    },
    {
        order: 17,
        propertyName: 'contractor',
        propertyType: 'string',
        displayName: 'Аптека',
        visibility: true,
        width: 150
    }],
    detailsPlugins: [
        ChequeGridPluginSettings
    ]
}

export default CashSessionGridPluginSettings