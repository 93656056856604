import { FC, useEffect, useState } from "react";
import { LoadingStatus } from "../../../../@types/enumsGlobal";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../../components/plugins";
import { ICreateUser, IUserViewDTO } from "../../../../libs/coreapi-dto/service/user";
import { UserDataProvider } from "../../../../Services/DataProviders/UserDataProvider";
import useGridFilter, { CreateSelectorGridFilter, DefaultGridFilter } from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import styles from '../../../styles/index.module.scss'
import { v4 as uuidv4 } from 'uuid';
import { IRoleViewDTO } from "../../../../libs/coreapi-dto/service/role";
import { RoleDataProvider } from "../../../../Services/DataProviders/RoleDataProvider";
import { CreateUserModal, TypeOpen } from "../../User/Components/modal/user/CreateUserModal";
import { UserCommandPanel } from "../../User/Components/modal/user/UserPanel";
import { UserRolePanel } from "../../User/Components/modal/roles/UserRolePanel";
import { UserLocalDataProvider } from "../../../../Services/DataProviders/UserLocalDataProvider";
import { CompanyDataProvider } from "../../../../Services/DataProviders/CompanyDataProvider";
import { IUserLocalDTO, IUserLocalViewDTO } from "../../../../libs/coreapi-dto/dirs/user";
import { RoleLocalDataProvider } from "../../../../Services/DataProviders/RoleLocalDataProvider";
import { UserLocalRolesModal } from "../components/modal/roles/UserLocalRolesModal";
import GridMultipleSelectorInput from "../../../../components/controls/GridMultipleSelectorInput";
import { ContractorSelectorModal } from "../../../Dictionaries/Contractor";
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";
import { SelectedEntityContractorPluginSettings } from "../../ConstructorSchemes/pluginSettings/SelectedEntityContractorPluginSettings";
import { User2ContractorDataProvider } from "../../../../Services/DataProviders/User2ContractorDataProvider";
import { IUser2ContractorUpdateDto, IUser2ContractorViewDTO } from "../../../../libs/coreapi-dto/dirs/user2Contractor";
import RoleGridMinimalPluginSettings from "../../Role/RoleGridMinimalPluginSettings";
import { checkLockStatus } from "../../../CommonHelperFunctions";
import { useUserContext } from "../../../../system/providers/userContextProvider";
import { ChangeableGrid } from "../../../../components/grids/changeableGrid/ChangeableGrid";
import { useTranslation } from "react-i18next";
import { Spinner } from "../../../../components/spiner/Spinner";
import { IRoleLocalViewDTO } from "../../../../libs/coreapi-dto/dirs/role";
import renderGlobalAlert from "../../../../system/hooks/useGlobalAlert";
import FlexRow from "../../../../components/controls/FlexRow";

export const UserLocalGridPluginView: FC<IGridProps> = (props) => {
    const appCtx = useAppContext();
    const userDataProvider = new UserDataProvider(appCtx.coreApiService);
    const roleDataProvider = new RoleDataProvider(appCtx.coreApiService);
    const companyDataProvider = new CompanyDataProvider(appCtx.coreApiService);
    const userLocalDataProvider = new UserLocalDataProvider(appCtx.coreApiService);
    const roleLocalDataProvider = new RoleLocalDataProvider(appCtx.coreApiService);
    const user2ContractorDataProvider = new User2ContractorDataProvider(appCtx.coreApiService);
    const [contractors, setContractors] = useState<IUser2ContractorViewDTO[]>([]);
    const [contractorsTotal, setContractorsTotal] = useState<number>(0);
    const [selectContractors, setSelectContractors] = useState<IEntitySimpleDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [totalCount, setTotalCount] = useState(0);
    const [viewState, setViewState] = useState<GridStateType>("view");

    const [gridFilter, dispatchGridFilter] = useGridFilter({ ...DefaultGridFilter, numberPerPage: 15, fieldOrderBy: `ShortName`});
    const [contractorGridFilter, contractorDispatchGridFilter] = useGridFilter(CreateSelectorGridFilter);

    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [selectedItems, setSelectedItems] = useState<IGridRow[]>([]);
    const [data, setData] = useState<IUserViewDTO[]>([]);
    const [dataFull, setDataFull] = useState<IUserLocalViewDTO[]>([]);
    const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
    const [typeOpen, setTypeOpen] = useState<TypeOpen>('Create');
    const [selectedUser, setSelectedUser] = useState<IUserViewDTO | null>(null);
    const [userRoles, setUserRoles] = useState<IRoleViewDTO[]>([])
    const [selectedRoleItem, setSelectedRoleItem] = useState<IGridRow>();
    const [selectedRole, setSelectedRole] = useState<IRoleViewDTO | null>(null)
    const [roleGridFilter, dispatchRoleGridFilter] = useGridFilter();
    const [openAddRole, setOpenAddRole] = useState<boolean>(false);

    const [gridFilterContractors, dispatchGridFilterContractors] = useGridFilter();

    const userContext = useUserContext();
    const lockFromPermission = checkLockStatus(props.plugin.permission as IPermission, userContext.userPermission);

    const { t } = useTranslation();
    const [clearSig, setClearSig] = useState<boolean>(false);

    const refresh = () => {
        setSelectedItem(undefined)
        setSelectedUser(null)
        setViewState('refresh')
    }

    const loadData = () => {
        setLoadingStatus(LoadingStatus.InProcess)
        try {
                userLocalDataProvider.getView(gridFilter, (entities, totalCount) => {
                    if (entities.length > 0) {
                        companyDataProvider.findCompany(entities[0]?.companyId as string, (company) => {

                            setData([...entities.map(x => {
                                return {
                                    idGlobal: x.idGlobal,
                                    fullName: x.fullName,
                                    shortName: x.shortName,
                                    userName: x.userName,
                                    email: x.email,
                                    phoneNumber: x.phoneNumber,
                                    companyName: company.name,
                                    lockoutEnd: x.lockoutEnd,
                                    dateCreated: x.dateCreated,
                                    dateModified: x.dateModified
                                } as IUserViewDTO
                            })])

                            setDataFull(entities);
                            setTotalCount(totalCount);
                            setLoadingStatus(LoadingStatus.Completed);
                            setIsSubmitting(false);
                        });
                    } else {
                        setData([]);
                        setDataFull([]);
                        setTotalCount(0);
                        setLoadingStatus(LoadingStatus.Completed);
                        setIsSubmitting(false);
                    }
                })

        } catch {
            setLoadingStatus(LoadingStatus.Failed)
        }
    }

    function syncUser () {
        if (selectedItem)
        {
            userDataProvider.getUserById(selectedItem.idGlobal as string, (user) => {
                let userData = dataFull.find(x=>x.idGlobal === selectedItem.idGlobal);
                let user_ = {
                    companyName: user.companyName,
                    email: user.email,
                    fullName: user.fullName,
                    idGlobal: user.id,
                    lockoutEnd: user.lockoutEnd,
                    phoneNumber: user.phoneNumber,
                    shortName: user.shortName,
                    userName: user.userName,
                    userNum: userData?.userNum,
                    lockoutEnabled: userData?.lockoutEnabled,
                    concurrencyStamp: userData?.concurrencyStamp,
                    companyId: userData?.companyId,
                    inn: userData?.inn
                } as IUserLocalDTO;
                userLocalDataProvider.update(selectedItem?.idGlobal as string, user_, ()=> {
                    refresh();
                })
            })
        }
    }

    const blockUser = () => {
        userDataProvider.blockUser(selectedItem?.idGlobal as string, () => {
            syncUser();
        })
    }

    const unBlockUser = () => {
        userDataProvider.unBlockUser(selectedItem?.idGlobal as string, () => {
            syncUser();
        })
    }

    const removeRole = () => {
        if (selectedItem && selectedRole) {
            userDataProvider.removeRole(selectedItem.idGlobal, selectedRole.name, () => {
                roleLocalDataProvider.removeRoleForUser(selectedRole.idGlobal, selectedItem.idGlobal, ()=> {
                    getUserRoles()
                    setSelectedRoleItem(undefined)
                    setSelectedRole(null)
                })
            })
        }
    }

    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);

    useEffect(() => {
        if (viewState === 'refresh') {
            setIsSubmitting(true)

            setClearSig(!clearSig)
            setData([])
            setDataFull([])

            dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: 1 }})
            searchUserWithCustomWhereFilter("")
            setViewState('view')
        }
    }, [viewState])

    useEffect(() => {
        try {
            loadData()
            setLoadingStatus(LoadingStatus.Completed)
        } catch {
            setLoadingStatus(LoadingStatus.Failed)
        }
    }, [gridFilter])

    const getUserRoles = () => {
        if (selectedItem) {
            roleLocalDataProvider.getRoles(selectedItem.idGlobal as string, (e: IRoleLocalViewDTO[])=>{
                let userRolesArr: IRoleViewDTO[] = e.map(x => ({ 
                    idGlobal: uuidv4(), 
                    name: x.name, 
                    dateCreated: x.dateCreated,
                    dateModified: x.dateModified,
                    dateDeleted: x.dateDeleted 
                }))
                setUserRoles(userRolesArr)
            })
        }
    }

    function clickCreateOk(userData: ICreateUser)
    {
        if (typeOpen === 'Create') {
            userDataProvider.create(userData, (e) => {
                //#88381 Ввиду новой синхронизации реббита убираем старую синхру.
                if (e.respType === 'isFailed') {
                    if (e.message?.statusCode === 400) {
                        renderGlobalAlert({
                            variant: e.message.type,
                            statusCode: e.message?.statusCode ?? 500,
                            title: "Пользователь с таким логином уже существует",
                            detail: e.message.title,
                            instance: "Пользователь"
                        })
                    }
                } else {
                    refresh();
                    setOpenCreateModal(false);
                }
            })
        }
        else
            userDataProvider.update(selectedItem?.idGlobal as string, userData, () => {
            //#88381 Ввиду новой синхронизации реббита убираем старую синхру.
                    refresh()
                    setOpenCreateModal(false)
            })
    }

    useEffect(() => {
        getUserRoles()
        contractorDispatchGridFilter(
            {
                type: 'paramSimpleFilter',
                payload: {
                    gridColumnFilter: contractorGridFilter.columnFilters,
                    gridParamFilter: {idUserGlobal: selectedItem?.idGlobal}
                }
            }
        )
    }, [selectedItem])

    useEffect(() => {
        if (selectedRoleItem) {
            roleDataProvider.findRoleByName(selectedRoleItem.displayName, (e) => {
                setSelectedRole({ 
                    idGlobal: e[0].id,
                    name: e[0].name, 
                    dateCreated: e[0].dateCreated,
                    dateModified: e[0].dateModified,
                    dateDeleted: e[0].dateDeleted 
                });
            })
        }
    }, [selectedRoleItem])


       useEffect(() => {
        if (selectedItem?.idGlobal) {
            let user = dataFull.find(x => x.idGlobal === selectedItem.idGlobal)
            if (user) {
                setSelectedUser(
                    {
                        idGlobal: user.idGlobal,
                        fullName: user.fullName,
                        shortName: user.shortName,
                        userName: user.userName,
                        phoneNumber: user.phoneNumber,
                        email: user.email,
                        companyName: user.companyName,
                        lockoutEnd: user.lockoutEnd,
                        dateCreated: user.dateCreated,
                        dateModified: user.dateModified
                    } as IUserViewDTO
                )
            }
            dispatchGridFilterContractors({ type: 'addColumnFilter', payload: { name: "IdUserGlobal", value: selectedItem.idGlobal, operator: "Eq" } });
        } else {
            setSelectedUser(null)
            setContractors([])
            setContractorsTotal(0);
        }
    }, [selectedItem])

    useEffect(() => {
        getContractors()
    }, [gridFilterContractors]); // selectedItem

    function getContractors() {
        if (selectedItem?.idGlobal) 
        {
            user2ContractorDataProvider.getView(gridFilterContractors, (values, totalCount) => {
                setContractors(values)
                setContractorsTotal(totalCount);
                setSelectContractors(values.map((x) => {
                    return {
                        displayName: x.contractorName,
                        idGlobal: x.idContractorGlobal
                    } as IEntitySimpleDTO 
                }))
            })
        }
    }

    function updateContractors(entity: IEntitySimpleDTO[])
    {
        let obj = {
            idUserGlobal: selectedItem?.idGlobal,
            contractorsIds: entity.map(x=>x.idGlobal)
        } as IUser2ContractorUpdateDto;
        user2ContractorDataProvider.updateContractors(obj,()=> {
            getContractors()
        })
    }

    function searchUserWithCustomWhereFilter(value: string) {
        if (value.length > 0) {
            // pgsql-выражение (блока условия, финальный and (expr)):
            // - не учитывает регистр
            // - shortName - вхождение с первого символа
            // - userName - выхождение с первого символа
            // - cotractors.name - вхождение по названию контрагента, если существует запись в таблице
            // соединение через ИЛИ
            const expression = `lower("ShortName") like lower('${value}%') or lower("UserName") like lower('%${value}%') ` +
                `or (exists (select from users_2_contractors u2c left join contractor c on c.id_contractor_global = u2c.id_contractor_global ` +
                `where lower(c.name) like lower('%${value}%') and "IdGlobal" = u2c.id_user_global))`;
            dispatchGridFilter({ type: "addCustomWhereExpressionFilter", payload: expression });
        } else {
            dispatchGridFilter({ type: "deleteCustomWhereExpressionFilter" });
        }
    }

    return (
        <PluginWrapper>
            <ToolbarWrapper>
                <UserCommandPanel
                    add={{
                        onClick: () => {
                            setTypeOpen('Create')
                            setOpenCreateModal(true)
                        }
                    }}

                    edit={{
                        onClick: () => {
                            setTypeOpen('Edit')
                            setOpenCreateModal(true)
                        },
                        disabled: selectedItem && selectedUser ? false : true
                    }}
                    block={{
                        onClick: () => {
                            blockUser()
                        },
                        disabled: selectedUser && selectedUser.lockoutEnd === null ? false : true
                    }}
                    unBlock={{
                        onClick: () => {
                            unBlockUser()
                        },
                        disabled: selectedUser && selectedUser.lockoutEnd !== null ? false : true

                    }}
                    refresh={{
                        onClick: () => {
                            refresh()
                        }
                    }}
                    permission={props.plugin.permission}
                    search={{
                        placeholder: t("userPanel.placeholderNameLoginContractor"),//"Имя, логин, контрагент"
                        call: (value: string) => searchUserWithCustomWhereFilter(value),
                        clearSig: clearSig
                    }}
                />
            </ToolbarWrapper>
            <div className={styles.gridWrapper}>
                <div>
                    {isSubmitting ? <div><Spinner /></div> : <DefaultGrid
                        hiddenPagination={{ hiddenCountRow: false, hiddenNumberPage: false }}
                        getView={(gridFilter, callback) => {
                            companyDataProvider.getAllCompany((companies) => {
                                userLocalDataProvider.getView(gridFilter, (entities, totalCount) => {
                                    setSelectedUser(null);
                                    setDataFull([...dataFull.filter(x => !entities.includes(x)), ...entities]);
                                    setTotalCount(totalCount)
                                    setLoadingStatus(LoadingStatus.Completed)

                                    callback([...entities.map(x => {
                                        return {
                                            idGlobal: x.idGlobal,
                                            fullName: x.fullName,
                                            shortName: x.shortName,
                                            userName: x.userName,
                                            email: x.email,
                                            phoneNumber: x.phoneNumber,
                                            companyId: x.companyId as string,
                                            companyName: companies.find(c => c.id.toLocaleLowerCase() === x.companyId?.toLocaleLowerCase())?.name ?? '',
                                            lockoutEnd: x.lockoutEnd
                                        }
                                    })]);

                                    setIsSubmitting(false);
                                })
                            })
                        }}
                        gridId={props.gridId}
                        data={data}
                        loadingStatus={loadingStatus}
                        setLoadingStatus={setLoadingStatus}
                        totalCount={totalCount}
                        filter={gridFilter}
                        plugin={props.plugin}
                        selectedItem={selectedItem}
                        selectedItems={selectedItems}
                        onMultipleSelect={(rows) => {
                            setSelectedItems(rows)
                            props?.onMultipleSelect?.(rows)
                        }}
                        multipleSelect={props.multipleSelect}
                        onSelect={(row) => {
                            setSelectedItem(row)
                            props?.onSelect?.(row)
                        }}
                        onDoubleClick={(i) => props.onDoubleClick?.(i)}
                        onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                        onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                        onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                        onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                        onEnter={() => setViewState("edit")}
                        separator
                    />}
                    {/*<div className={classNames(styles.separator, styles.separator_horizontal)}></div>*/}
                    <div className={styles.detailsTabView}>
                        {
                            selectedItem?.idGlobal && <>
                                <FlexRow wrap>
                                    <div>
                                        <UserRolePanel
                                            add={{
                                                onClick: () => setOpenAddRole(true)
                                            }}
                                            delete={{
                                                onClick: () => {
                                                    removeRole()
                                                },
                                                disabled: selectedRoleItem ? false : true

                                            }}
                                            permission={props.plugin.permission}
                                        />
                                        <DefaultGrid
                                            gridId={uuidv4()}
                                            data={userRoles}
                                            totalCount={0}
                                            filter={roleGridFilter}
                                            plugin={RoleGridMinimalPluginSettings}
                                            selectedItem={selectedRoleItem}
                                            onSelect={(row) => {
                                                setSelectedRoleItem(row)
                                            }}
                                            onSort={(i) => dispatchRoleGridFilter({ type: "sort", payload: i.propertyName })}
                                            onFilterDelete={(i) => dispatchRoleGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                                            onPageNumberChange={(n) => dispatchRoleGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                                            onNumberPerPageChange={(n) => dispatchRoleGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                                        />
                                    </div>
                                    <div>
                                        <GridMultipleSelectorInput
                                            selectedEntity={selectContractors?.[0]}
                                            selectedEntities={selectContractors}
                                            id={`idContractor-${uuidv4()}`}
                                            switchOnchange
                                            gridFilter={contractorGridFilter}
                                            selectorModalJsx={ContractorSelectorModal}
                                            label={'Контрагенты'}
                                            onSelect={(entities: IEntitySimpleDTO[]) => {
                                                setSelectContractors(entities);
                                                updateContractors(entities);
                                            }}
                                            disabled={lockFromPermission}
                                            treeViewCheckDirectoryType={'pharmacy'}
                                            activateGroupProcessing
                                            viewMode
                                        />
                                        <ChangeableGrid
                                            documentStateProc={lockFromPermission}
                                            gridId={uuidv4()}
                                            data={contractors}
                                            filter={gridFilterContractors}
                                            dataProvider={gridFilterContractors}
                                            totalCount={contractorsTotal}
                                            plugin={SelectedEntityContractorPluginSettings}
                                            selectedItem={undefined}
                                            onSelect={(row) => { }}
                                            onChangeData={(value) => {
                                                let contractor = contractors.find(c => c.idGlobal === value.idGlobal)
                                                if (!contractor?.idUserGlobal) return

                                                user2ContractorDataProvider.mainContractor(
                                                    contractor?.idUserGlobal,
                                                    contractor?.idContractorGlobal,
                                                    value.value as boolean, 
                                                    () => {
                                                        setContractors(contractors.map((x) => 
                                                        (x.idGlobal === value.idGlobal ? {
                                                            ...x, mainContractor: value.value as boolean
                                                            
                                                        } 
                                                        : {...x, mainContractor: (value.value as boolean) === true ? false : x.mainContractor})
                                                        ));
                                                    })
                                            }}
                                            onSort={(i) => { dispatchGridFilterContractors({ type: "sort", payload: i.propertyName }) }}
                                            onFilterDelete={(i) => { dispatchGridFilterContractors({ type: "deleteColumnFilter", payload: i.propertyName }) }}
                                            onPageNumberChange={(n) => { dispatchGridFilterContractors({ type: "changePageNumber", payload: { pageNumber: n } }) }}
                                            onNumberPerPageChange={(n) => { dispatchGridFilterContractors({ type: "changeNumberPerPage", payload: { numberPerPage: n } }) }}
                                            searching
                                            hiddenPagination={{ hiddenNumberPage: true, hiddenCountRow: true }} //show pagination
                                        />
                                    </div>
                                </FlexRow>
                            </>
                        }
                    </div>
                </div>
            </div>
            {
                openAddRole &&
                <UserLocalRolesModal
                    ok={() => {
                        getUserRoles()
                    }}
                    cancel={() => {
                        setOpenAddRole(false)
                    }}
                    userId={selectedItem?.idGlobal ? selectedItem.idGlobal : null}
                />
            }
            {
                openCreateModal &&
                <CreateUserModal
                    ok={(userData: ICreateUser) => {
                        clickCreateOk(userData);
                    }}
                    userEditData={selectedItem ? selectedUser : null}
                    cancel={() => setOpenCreateModal(false)}
                    typeOpen={typeOpen}
                    disableCompany = {true}
                    baseCompanyName= {data && data.length > 0 ? data[0].companyName : '' }
                    lockFromPermission={lockFromPermission}
                />
            }
        </PluginWrapper>
    )
}
