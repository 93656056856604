import classNames from "classnames";
import { FC, useState } from "react";
import styles from "./checkbox.module.scss"

export interface ICheckboxProps {
    defaultChecked?: boolean
    disabled?: boolean
    readonly?: boolean
    label?: string
    id?: string
    onChanged?: (checked: boolean) => void
    className?: string
    onBlur?: () => void
}

const CheckBox: FC<ICheckboxProps> = (props) => {

    return (
        <div className={classNames(styles.checkBox, props.className)}>
            <input
                type='checkbox'
                id={props.id}
                checked={props.defaultChecked}
                disabled={props.disabled}
                readOnly={props.readonly}
                onBlur={props?.onBlur}
                onKeyDown={(e) => {
                    switch (e.key) {
                        case 'Enter':
                            props.onChanged?.(!props.defaultChecked);
                            break
                        case 'Escape':
                            e.currentTarget.blur();
                            break
                    }
                }}
                onChange={() => props.onChanged?.(!props.defaultChecked)} />
            <label htmlFor={props.id}>{props.label}</label>
        </div>
    );
}
export { CheckBox }
