import { ProductGroupsGISMTPluginSettings } from '../pluginSettings/ProductGroupsGISMTPluginSettings';
import {v4 as uuidv4} from 'uuid';

export const ProductGroupsGISMTTab = (): ITab => {
    return {
      id: uuidv4(),
      title: ProductGroupsGISMTPluginSettings.name,
      view: {
        content: <></>
      }
    }
  }