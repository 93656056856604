import { IGetFileDTO, IPostFilesNameDTO } from "../../libs/coreapi-dto/dirs/importInvoices";
import CoreApiService from "../../system/api/coreApiService";

export class ImportInvoicesProvider {
    private _coreApiService: CoreApiService;

    constructor(coreApiService: CoreApiService) {
        this._coreApiService = coreApiService;
    }

    moveFilesLocal(
        idContractor: string,
        idSupplier: string,
        dto: IPostFilesNameDTO,
        callback: (data: boolean) => void,
        errCallback: () => void
    ) {
        this._coreApiService.importInvoicesRequest
            .then(r =>
                r.moveFilesLocal(idContractor, idSupplier, dto, e => {
                    if (e.respType === "isCompleted") {
                        callback(e.data);
                    } else {
                        errCallback?.();
                    }
                })
            )
            .catch(() => errCallback?.());
    }

    getFileLocal(
        idContractor: string,
        idSupplier: string,
        dto: IGetFileDTO,
        callback: (data: Blob) => void,
        errCallback: () => void
    ) {
        this._coreApiService.importInvoicesRequest
            .then(r =>
                r.getFileLocal(idContractor, idSupplier, dto, e => {
                    if (e.respType === "isCompleted") {
                        callback(e.data);
                    } else {
                        errCallback?.();
                    }
                })
            )
            .catch(() => errCallback?.());
    }

    getFilesNameLocal(
        idContractor: string,
        idSupplier: string,
        callback: (data: string[]) => void,
        errCallback: () => void
    ) {
        this._coreApiService.importInvoicesRequest
            .then(r =>
                r.getFilesNameLocal(idContractor, idSupplier, e => {
                    if (e.respType === "isCompleted") {
                        callback(e.data);
                    } else {
                        errCallback?.();
                    }
                })
            )
            .catch(() => errCallback?.());
    }

    getFilesName(
        idAccessPoint: string,
        callback: (data: string[]) => void,
        errCallback: () => void
    ) {
        this._coreApiService.importInvoicesRequest
            .then(r =>
                r.getFilesName(idAccessPoint, e => {
                    if (e.respType === "isCompleted") {
                        callback(e.data);
                    } else {
                        errCallback?.();
                    }
                })
            )
            .catch(() => errCallback?.());
    }

    getLocal(
        idContractor: string,
        idSupplier: string,
        idAccessPoint: string,
        callback: (data: boolean) => void,
        errCallback: () => void
    ) {
        this._coreApiService.importInvoicesRequest
            .then(r =>
                r.getLocal(idContractor, idSupplier, idAccessPoint, e => {
                    if (e.respType === "isCompleted") {
                        callback(e.data);
                    } else {
                        errCallback?.();
                    }
                })
            )
            .catch(() => errCallback?.());
    }

    testAccessPoint(
        idAccessPoint: string,
        callback: (data: string) => void,
        errCallback: () => void
    ) {
        this._coreApiService.importInvoicesRequest
            .then(r =>
                r.testAccessPoint(idAccessPoint, e => {
                    if (e.respType === "isCompleted") {
                        callback(e.data);
                    } else {
                        errCallback?.();
                    }
                })
            )
            .catch(() => errCallback?.());
    }
}
