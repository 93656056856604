import { FC, useEffect, useState } from "react";
import { IdTableVariant, SearchOption } from "../../@types/enumsGlobal";
import { IEntitySimpleDTO } from "../../libs/coreapi-dto/@types/common";
import { LotDataProvider } from "../../Services/DataProviders/LotDataProvider";
import useGridFilter, { CreateSelectorGridFilter } from "../../system/hooks/useGridFilter";
import { useAppContext } from "../../system/providers/appContextProvider";
import { DictionaryInput } from "./dictionaryInput";
import { GoodsDataProvider } from "../../Services/DataProviders/GoodsDataProvider";
import { KizBoxEntity } from "../../Business/Dictionaries/Kiz/KizBoxEntity";
import buffer from "buffer";
import { KizParsed } from "../../Business/Dictionaries/Kiz/KizParsed";
import { KizDataProvider } from "../../Services/DataProviders/KizDataProvider";
import { ILotByIdDTO } from "../../libs/core-api-requests/accounting/lotRequest";
import { v4 as uuidv4, NIL as uuidNull } from 'uuid';
import { cyrillicKeyboardTranslation } from "../../system/functions/keyboardLanguageTranslation";

const GridSelectorInput: FC<IGridSelectorInputProps> = (props) => {
    const appContext = useAppContext();
    const lotDP = new LotDataProvider(appContext.coreApiService);
    const goodsDP = new GoodsDataProvider(appContext.coreApiService);
    const kizDP = new KizDataProvider(appContext.coreApiService);

    const [visible, setVisible] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>(props.value ?? "");
    const [gridFilter, dispatchGridFilter] = useGridFilter(props.gridFilter ?? CreateSelectorGridFilter);
    const [isEnterPressed, setIsEnterPressed] = useState<boolean>(false);

    useEffect(() => {
        if (props.gridFilter) {
            dispatchGridFilter({
                ...gridFilter,
                type: "newGridFilters",
                payload: props.gridFilter,
            });
        }
    }, [props.gridFilter]);

    useEffect(() => {
        if (visible) props.setScanData?.(undefined)
    }, [visible]);

    useEffect(() => {
        if (props?.selectedEntity) {
            setSearchQuery(props.numereticInput ? props?.selectedEntity : props.selectedEntity.displayName);
            //addColumnFilter(props.selectedEntity.displayName);
        } else {
            setSearchQuery("");
        }
    }, [props?.selectedEntity]);

    useEffect(() => {
        if (props?.searchOption) {
            setSearchQuery("")
            Object.values(SearchOption).forEach(option => {
                if (props.dispatchGridFilter) {
                    props.dispatchGridFilter?.({
                        type: "deleteColumnFilter",
                        payload: option,
                    });
                } else {
                    dispatchGridFilter({
                        type: "deleteColumnFilter",
                        payload: option,
                    });
                }
            });
        }       
    }, [props?.searchOption]);

    useEffect(() => {
        if (props?.excludeContractor)
            dispatchGridFilter?.({
                type: "addColumnFilter",
                payload: {
                    name: "IdGlobal",
                    value: props?.excludeContractor,
                    operator: "NotEq",
                } as IGridColumnFilter
            });
    }, [props?.excludeContractor])

    const addColumnParamFilter = (payloadValue: string): void => {
        if (payloadValue != null && payloadValue.trim().length != 0) {
            if (props.dispatchGridFilter) {
                props.dispatchGridFilter?.({
                    type: "paramFilter",
                    payload: {
                        gridParamFilter: {
                            goodsName: payloadValue,
                            idGosContractGlobal: props.gosContract
                        },
                        sortDesc: "Perc"
                    },
                  });
            } else {
                dispatchGridFilter?.({
                    type: "paramFilter",
                    payload: {
                        gridParamFilter: {
                            goodsName: payloadValue,
                            idGosContractGlobal: props.gosContract
                        },
                        sortDesc: "Perc"
                    },
                  });
            }
        }
    };

    const addColumnSimpleFilter = (payloadValue: string, nameColumn: string): void => {
        if(payloadValue) {
            dispatchGridFilter?.({
                type: "addColumnFilter",
                payload: {
                    name: nameColumn,
                    operator: 'Like',
                    value: payloadValue + '%',
                } as IGridColumnFilter
            });
        }
    };

    const deleteColumnFilter = (): void => {
        if (props.dispatchGridFilter) {
            props.dispatchGridFilter?.({
                type: 'paramSimpleFilter',
                payload: {
                  gridColumnFilter: props.gridFilter?.columnFilters,
                  gridParamFilter: []
                },
            });
        }
        else
            dispatchGridFilter?.({
                type: 'paramSimpleFilter',
                payload: {
                gridColumnFilter: props.gridFilter?.columnFilters ?? CreateSelectorGridFilter.columnFilters,
                gridParamFilter: []
                },
            });
    };

    function mainClick(value, enter: boolean) {
        enter && setIsEnterPressed(true);
        props.onFocus?.();

        let kizParsed = new KizParsed(searchQuery)

        if (kizParsed.isKiz && props.searchOption !== SearchOption.gtinSgtin) {
            if (value?.length && value?.length > 100) return
            lotDP.getLotByGtinSgtin(kizParsed.gtinSgtin, props.idTable as number, (lot: ILotByIdDTO) => {
                    
                // Для РН и ПТ перезаписываем raw_barcode
                if (props.idTable === IdTableVariant.InvoiceOut || props.idTable === IdTableVariant.Movement) {
                    if (lot && lot.idKizGlobalRequested && lot.idKizGlobalRequested.length > 0 && lot.idKizGlobalRequested != uuidNull) {
                        kizDP.saveRawBarcode(lot.idKizGlobalRequested, kizParsed.rawBarcodeCorrectedBase64);
                    }
                }
                setSearchQuery("");
                props?.setScanData && props?.setScanData(kizParsed.rawBarcodeCorrected)
                props.onSelect?.(lot, kizParsed.rawBarcodeCorrected);
            });
            return;
        }

        if (props.searchOption === SearchOption.name || props.searchOption === SearchOption.goodsName) {
            if (props.valid) {
                if (props.valid()) {
                    setVisible(true);
                    addColumnParamFilter(searchQuery);
                }
            } else {
                setVisible(true);
            }
            setSearchQuery(value ?? "");
            if (enter) {
                addColumnParamFilter(searchQuery);
            }
        }
        else if (props.searchOption === undefined) {
            if (enter) {
                setVisible(true);
                addColumnSimpleFilter(searchQuery, 'name');
            } else if (!enter) {
                setVisible(true);
            }
        }
        else if (props.searchOption === SearchOption.gtinSgtin) 
        {
            if (!searchQuery) {
                setVisible(true) 
                return
            }

            lotDP.getLotByGtinSgtin(searchQuery, props.idTable as number, (lot: ILotByIdDTO) => {
                const lotKizDB = lot?.kizDto?.decodedBarcode;
                props?.setScanData && props?.setScanData(lotKizDB)
                props.onSelect?.(lot, lotKizDB);
                setSearchQuery("");
            });

        }
        else if (props.searchOption === SearchOption.kiz) {
            if (!searchQuery) {
                setVisible(true) 
                return
            }

            lotDP.getLotByGtinSgtin(kizParsed.gtinSgtin, props.idTable as number, (lot: ILotByIdDTO) => {
                // Для РН и ПТ перезаписываем raw_barcode
                if (props.idTable === IdTableVariant.InvoiceOut || props.idTable === IdTableVariant.Movement) {
                    if (lot && lot.idKizGlobalRequested && lot.idKizGlobalRequested.length > 0 && lot.idKizGlobalRequested != uuidNull) {
                        kizDP.saveRawBarcode(lot.idKizGlobalRequested, kizParsed.rawBarcodeCorrectedBase64);
                    }
                }

                props.onSelect?.(lot, searchQuery);
                setSearchQuery("");
            });
        } else if (props.searchOption === SearchOption.kizBox) {
            if (!searchQuery) {
                setVisible(true) 
                return
            }
            const scanDataBase64: string = buffer.Buffer.from(searchQuery, "ascii").toString("base64");
            lotDP.getLotByKizBox(scanDataBase64, (e) => {
                setVisible(false);
                props.onSelect?.(e, searchQuery);
                setSearchQuery("");
            });
        } else if (props.searchOption === SearchOption.kizOrKizBox) {
            if (!searchQuery) {
                setVisible(true) 
                return
            }
            let valueSearchQuery = (searchQuery.length === 18) ? ('00' + searchQuery) : searchQuery;
            const scanDataBase64: string = buffer.Buffer.from(valueSearchQuery, "ascii").toString("base64");
            if (KizBoxEntity.isKizBox(searchQuery)) {
                lotDP.getLotByKizBox(scanDataBase64, (e) => {
                    setVisible(false);
                    props.onSelect?.(e, valueSearchQuery);
                    setSearchQuery("");
                });
            } else {
                lotDP.getLotByGtinSgtin(kizParsed.gtinSgtin, props.idTable as number,(lot: ILotByIdDTO) => {
                    
                    // Для РН и ПТ перезаписываем raw_barcode
                    if (props.idTable === IdTableVariant.InvoiceOut || props.idTable === IdTableVariant.Movement) {
                        if (lot && lot.idKizGlobalRequested && lot.idKizGlobalRequested.length > 0 && lot.idKizGlobalRequested != uuidNull) {
                            kizDP.saveRawBarcode(lot.idKizGlobalRequested, kizParsed.rawBarcodeCorrectedBase64);
                        }
                    }

                    props.onSelect?.(lot, valueSearchQuery);
                    setSearchQuery("");
                });
            }
        } else if (props.searchOption === SearchOption.gtin) {
            if (!searchQuery) {
                setVisible(true) 
                return
            }
            let valueSearchQuery = (searchQuery.length === 18) ? ('00' + searchQuery) : searchQuery;
            props.onSelect?.(null, valueSearchQuery)
            setSearchQuery("");
        } else if (props.searchOption === SearchOption.barcode) {
            if (!searchQuery) {
                setVisible(true) 
                return
            }
            if (props.gosContract) {
                goodsDP.getGoodsByBarcodeWithGosContract(searchQuery, props.gosContract, (e) => {
                    props.onSelect?.({displayName: e[0].goodsName.name, idGlobal: e[0].idGlobal} as IEntitySimpleDTO, searchQuery);
                    setSearchQuery(value ?? "");
                });
            }
            else {
                goodsDP.getGoodsByBarcode(searchQuery, (e) => {
                    props.onSelect?.({displayName: e[0].goodsName.name, idGlobal: e[0].idGlobal} as IEntitySimpleDTO, searchQuery);
                    setSearchQuery(value ?? "");
                });
            }
        } else if (props.searchOption === SearchOption.goodsCode) {
            if (!searchQuery) {
                setVisible(true) 
                return
            }
            if (props.gosContract) {
                goodsDP.getGoodsByCodeWithGosContract(searchQuery, props.gosContract, (e) => {
                    if (e) {
                        props.onSelect?.({displayName: e[0].goodsName.name, idGlobal: e[0].idGlobal} as IEntitySimpleDTO, searchQuery);
                        setSearchQuery(value ?? "");
                    }
                    else {
                        addColumnSimpleFilter(searchQuery,'code')
                        setVisible(true) 
                        setSearchQuery(value ?? "");
                    }                
                })
            }
            else {
                goodsDP.getGoodsByCode(searchQuery, (e) => {
                    if (e) {
                        props.onSelect?.({displayName: e[0].goodsName.name, idGlobal: e[0].idGlobal} as IEntitySimpleDTO, searchQuery);
                        setSearchQuery(value ?? "");
                    }
                    else {
                        addColumnSimpleFilter(searchQuery,'code')
                        setVisible(true) 
                        setSearchQuery(value ?? "");
                    }                
                });
            }
        } else if (props.searchOption === SearchOption.goodsCodeBylot) {
            if (!searchQuery) {
                setVisible(true) 
                return
            }
            
            addColumnSimpleFilter(searchQuery,SearchOption.goodsCode)
            setVisible(true) 
            setSearchQuery(value ?? "");
            
        } else if (props.searchOption === SearchOption.internalBarcode) {
            if (!searchQuery) {
                setVisible(true) 
                return
            }
            lotDP.getLotByInternalBarcode(searchQuery, (e) => {
                props.onSelect?.(e, searchQuery);
                setSearchQuery(value ?? "");
                if (props.idTable && (props.idTable === IdTableVariant.ActDisassembling || props.idTable === IdTableVariant.Movement)) {}
                else
                    setVisible(true)
            });
        }
    }
    
    return (
        <>
            <DictionaryInput
                numereticInput={props.numereticInput}
                disabledInput={props.disabledInput}
                barcodeScan={props.barcodeScan}
                inputId={props.id}
                required={props.required}
                label={props.label}
                placeholder={props.placeholder ?? "-- Не выбрано --"}
                className={props.className}
                labelClassName={props.labelClassName}
                inline={props.inline}
                variant={props.variant}
                disabled={props.disabled}
                value={searchQuery}
                autoFocus={props.autoFocus}
                error={props.error}
                change={props.change ?? undefined}
                hiddenClearButton={props.hiddenClearButton}
                onEnterClick={(value)=> {
                    mainClick(value, true);
                }}
                onMoreClick={(value) => {
                    mainClick(value, false);
                }}
                onClearClick={() => {
                    deleteColumnFilter();
                    props.onChange?.("");
                    setSearchQuery("");
                    props.onClear?.();
                }}
                onChange={(e) => {
                    let value = e as string;
                    let trimValue = (props.searchOption === 'kizOrKizBox' || props.searchOption === 'kizBox') ? cyrillicKeyboardTranslation(value.trim()) : value;
                    if (props.searchOption === SearchOption.kizBox && trimValue[0] !== '0' && trimValue[0] !== undefined) {
                        trimValue = '00' + trimValue;
                        props.onChange?.(trimValue);
                        setSearchQuery(trimValue);
                    } else {
                        props.onChange?.(trimValue);
                        setSearchQuery(trimValue);
                    }
                    if (trimValue != null && trimValue.length != 0) {
                        //addColumnFilter(e as string);
                    } else {
                        deleteColumnFilter();
                    }
                }}
                onFocus={() => props.onFocus?.()}
            />
            {visible && (
                <props.selectorModalJsx
                    idGlobal={props.idGlobal}
                    idTable={props?.idTable}
                    isEnabledTo={props.isEnabledTo}
                    masterIdGlobal={props.masterIdGlobal}
                    gosContract={props.gosContract}
                    onlyLotRemain={props.onlyLotRemain}
                    gridFilter={gridFilter}
                    dispatchGridFilter={props.dispatchGridFilter}
                    selectedEntity={props.selectedEntity}
                    searchQuery={searchQuery}
                    isCasheSave={props?.isCasheSave}
                    data={props?.data}
                    ok={(entity: IEntitySimpleDTO) => {
                        setVisible(false);
                        setSearchQuery("");
                        deleteColumnFilter()
                        props.onSelect?.(props.numereticInput ? parseFloat(entity.displayName) : entity, searchQuery);
                    }}
                    cancel={(verified: boolean) => {
                        verified && props.onClear?.();
                        deleteColumnFilter();
                        setVisible(false);
                    }}
                    setCasheData={(data) => props.setCasheData && props?.setCasheData(data)}
                    treeViewCheckDirectoryType={props.treeViewCheckDirectoryType}
                    isEnterPressed={isEnterPressed}
                    setIsEnterPressed={() => setIsEnterPressed(false)}
                    applyUserFilter={props.applyUserFilter}
                    excludeUserFilter={props.excludeUserFilter}
                    excludeContractors={props.excludeContractors}
                />
            )}
        </>
    );
};

export default GridSelectorInput;
