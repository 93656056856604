import classNames from "classnames";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Accordion } from "../../../../../components/controls/accordion";
import GridSelectorInput from "../../../../../components/controls/GridSelectorInput";
import GridWrapper from "../../../../../components/controls/GridWrapper";
import { CurrencyInput, NumberInput, TextInput } from "../../../../../components/controls/inputs";
import BaseDocumentCreator from "../../../../../components/documents/baseDocumentCreator";
import { IEntitySimpleDTO } from "../../../../../libs/coreapi-dto/@types/common";
import { IPricingInvoiceItemViewDTO } from "../../../../../libs/coreapi-dto/dirs/pricingInvoice";
import { GoodsDataProvider } from "../../../../../Services/DataProviders/GoodsDataProvider";
import { PricingInvoiceDataProvider } from "../../../../../Services/DataProviders/PricingInvoiceDataProvider";
import { TaxTypeDataProvider } from "../../../../../Services/DataProviders/TaxTypeDataProvider";
import { useAppContext } from "../../../../../system/providers/appContextProvider";
import { useTabsContext } from "../../../../../system/providers/tabsProvider";
import { GoodsSelectorModal } from "../../../../Dictionaries/Goods";
import { ScalingRatioSelectorModal } from "../../../../Dictionaries/ScalingRatio";
import { SeriesModalWindow } from "../../../../Dictionaries/Series/SeriesAndCertificateModal/SeriesModalWindow";
import { StoreSelectorModal } from "../../../../Dictionaries/Store";
import { TaxTypeSelectorModal } from "../../../../Dictionaries/TaxType";
import CollectPricingInvoiceItemDTO, { ICollectPricingInvoiceItemProps } from "../../CollectPricingInvoiceItemDTO";
import styles from "../../InvoiceItem/styles/InvoiceItemCreatorView.module.scss";
import { v4 as uuidv4, NIL as uuidNull } from "uuid";
import { IInvoiceItemCreateDTO } from "../../../../../libs/coreapi-dto/documents/invoiceItem";
import { ScalingRatioDataProvider } from "../../../../../Services/DataProviders/ScalingRatioDataProvider";
import { useForm } from "../../../../../system/hooks/useForm";
import { IDisplayItem, IGosContractFields } from "../InvoiceUpdateForm";
import { Checkbox } from "../../../../../components/checkboxes/checkbox";
import { CurrencyInputWithValidation, DecimalInputV2, ICurrencyInputValidation } from "../../../../../components/controls/inputs/BaseInput";
import { ISerialDTO } from "../../../../../libs/coreapi-dto/dirs/serial";
import { getKizCount } from "../../../../../system/functions/getKizCount";
import { MessageModalWindow } from "../../../../../components/modalWindows/MessageModalWindow";
import { ResendingTimer } from "../../../../../constants";
import useGridFilter from "../../../../../system/hooks/useGridFilter";
import { SerialDataProvider } from "../../../../../Services/DataProviders/SerialDataProvider";
import FlexRow from "../../../../../components/controls/FlexRow";
import { DateTime } from "luxon";
import RegisterPriceSelectorModal from "../../../../Dictionaries/RegisterPrice/RegisterPriceSelectorModal";
import { IContractorDTO } from "../../../../../libs/coreapi-dto/dirs/contractor";
import { ITaxTypeDTO } from "../../../../../libs/coreapi-dto/dirs/taxType";
import { CreateVariant } from "../../../../MasterTabsProvider";

interface IValidator {
    idGoodsGlobal: string;
    idScalingRatioGlobal: string;
    quantity: number;
    producerPrice: number;
    registerPrice: number;
    supplierPrice: number;
    retailPrice: number;
    supplierVat: IEntitySimpleDTO;
    retailVat: IEntitySimpleDTO;
    supplierPriceWithVat: number;
    retailPriceWithVat: number;
    supplierSum: number;
    supplierVatSum: number;
    supplierSumWithVat: number;
    retailSum: number;
    retailSumWithVat: number;
    retailVatSum: number;
    }

interface IInvoiceItemCreateProps {
    idPricingModel: string;
    idSupplier: string;
    idStore: string;
    gosContractFields: IGosContractFields;
    goods: IEntitySimpleDTO;
    scalingRatio: IEntitySimpleDTO;
    parentCallback?: (entity: IInvoiceItemCreateDTO, displayItem: IDisplayItem) => void;
    item?: IInvoiceItemCreateDTO;
    displayItem: IDisplayItem;
    currentSupplier?: IContractorDTO;
    currentPharmacy?: IContractorDTO;
    defaultTax?: ITaxTypeDTO
    variant?: CreateVariant;
    isHardUnProcess?: boolean;
    documentStateProc?: boolean;
    documentDate?: DateTime;
    invoiceItemsGroup?: IInvoiceItemCreateDTO[];
}

interface ICalcSum {
    percentageOfMarkupRetail: number;
    supplierPriceWithVat: number;
    supplierMarkupPercentage: number;
    actualMarkupPercentage: number;
    supplierVatPerUnit: number;
    retailVatPerUnit: number;
}

const InvoiceItemCreator: FC<IInvoiceItemCreateProps> = (props) => {
    const appContext = useAppContext();
    const tabsContext = useTabsContext();

    const gdp = new GoodsDataProvider(appContext.coreApiService);
    const scalingRatioDP = new ScalingRatioDataProvider(appContext.coreApiService);
    const pricingInvoiceDataProvider = new PricingInvoiceDataProvider(appContext.coreApiService);
    const taxTypeDataProvider = new TaxTypeDataProvider(appContext.coreApiService);
    const sdp = new SerialDataProvider(appContext.coreApiService)

    const defValue: IInvoiceItemCreateDTO = {
        quantity: 1,
        supplierCostInfo: { vat: 0, sum: 0, sumIncVat: 0, vatSum: 0, price: 0 },
        retailCostInfo: { vat: 0, sum: 0, sumIncVat: 0, vatSum: 0, price: 0 },
        registerPrice: 0,
    } as IInvoiceItemCreateDTO;

    const [data, setData] = useState(initializeValue());
    const [goods, setGoods] = useState<IEntitySimpleDTO | undefined>(props.goods);
    const [scalingRatio, setScalingRatio] = useState<IEntitySimpleDTO | undefined>(props.scalingRatio ?? undefined);
    const [vatRateWholesale, setVatRateWholesale] = useState<IEntitySimpleDTO | undefined>({} as IEntitySimpleDTO);
    const [retailVatRate, setRetailVatRate] = useState<IEntitySimpleDTO | undefined>({} as IEntitySimpleDTO);
    const [itemSeries, setItemSeries] = useState<ISerialDTO>({} as ISerialDTO);
    const [calcSum, setCalcSum] = useState<ICalcSum>({
        percentageOfMarkupRetail: 0,
        supplierMarkupPercentage: 0,
        supplierPriceWithVat: 0,
        supplierVatPerUnit: 0,
        retailVatPerUnit: 0,
        actualMarkupPercentage: 0,
    });
    const [sendFetch, setSendFetch] = useState<boolean>(false);

    const requestPricingInvoiceTimeout = useRef<NodeJS.Timeout>();
    const [isKiz, setIsKiz] = useState<boolean>(false);
    const [isGnvls, setIsGnvls] = useState<boolean>(false);
    const validateReestr = { isGnvls, documentDate: props.documentDate };
    const [isOpenSeriesModal, setIsOpenSeriesModal] = useState<boolean>(false);
    const [pricingInvoice, setPricingInvoice] = useState<Partial<IPricingInvoiceItemViewDTO | undefined>>(() => {
        if(props.variant === 'create') {
            return undefined
        }
        return {
            actualMarkupPercentage: props.item?.productMargin ?? 0
        }
    });

    const [itemsGroupAlert, setItemsGroupAlert] = useState<{ show: boolean }>({ show: false });
    const [itemsGroupAlertFlag, setItemsGroupAlertFlag] = useState<boolean>(false);

    const [taxTypeGridFilter] = useGridFilter();

    const collectionData: ICollectPricingInvoiceItemProps = {
        eventTypes: "None",
        idInvoice: "",
        idSupplier: props.idSupplier,
        idStore: props.idStore,
        idItem: data?.idGlobal,
        idGoods: goods?.idGlobal as string,
        idScalingRatio: scalingRatio?.idGlobal,
        quantity: data?.quantity,
        producerPrice: data.producerPrice,
        retailCostInfo: data?.retailCostInfo,
        supplierCostInfo: data?.supplierCostInfo,
        registerPrice: data?.registerPrice,
    }

    const { t } = useTranslation();
    const baseT = (value: string) => t("documents.invoice." + value);
    const errorsT = (value: string) => t("errors." + value);

    const { isValid, errors, setErrors } = useForm<IValidator>({
        validations: {
            idGoodsGlobal: {
                required: {
                    value: goods ? false : true,
                    message: errorsT("required"),
                },
            },
            idScalingRatioGlobal: {
                required: {
                    value: data.idScalingRatioGlobal ? false : true,
                    message: errorsT("required"),
                },
            },
            quantity: {
                required: {
                    value: data.quantity && data?.quantity > 0 ? false : true,
                    message: errorsT("onlyPositiveValue"),
                },
            },
            supplierVat: {
                required: {
                    value: vatRateWholesale ? false : true,
                    message: errorsT("required"),
                },
            },
            retailVat: {
                required: {
                    value: retailVatRate ? false : true,
                    message: errorsT("required"),
                },
            },
            producerPrice: {
                custom: {
                    isValid: () => {
                        return isValidProducerPrice().bool;
                    },
                    message: errorsT(isValidProducerPrice().message),
                },
            },
            registerPrice: {
                required: {
                    value: isGnvls && !data.registerPrice ? true : false,
                    message: errorsT("required"),
                },
            },
            supplierPrice: {
                required: {
                    value: data.supplierCostInfo.price > 0 ? false : true,
                    message: errorsT("required"),
                },
            },
            retailPrice: {
                required: {
                    value: data.retailCostInfo.price > 0 ? false : true,
                    message: errorsT("required"),
                },
            },
            supplierPriceWithVat: {
                required: {
                    value: data.supplierCostInfo.priceIncVat > 0 ? false : true,
                    message: errorsT("required"),
                },
            },
            retailPriceWithVat: {
                required: {
                    value: data.retailCostInfo.price > 0 ? false : true,
                    message: errorsT("required"),
                },
            },
            retailSum: {
                required: {
                    value: data.retailCostInfo.sum > 0 ? false : true,
                    message: errorsT("required"),
                },
            },
            supplierSum: {
                required: {
                    value: data.supplierCostInfo.sum > 0 ? false : true,
                    message: errorsT("required"),
                },
            },
            retailVatSum: {
                required: {
                    value: data.retailCostInfo.vatSum >= 0 ? false : true,
                    message: errorsT("required"),
                },
            },
            supplierVatSum: {
                required: {
                    value: data.supplierCostInfo.vatSum >= 0 ? false : true,
                    message: errorsT("required"),
                },
            },
            retailSumWithVat: {
                required: {
                    value: data.retailCostInfo.sumIncVat > 0 ? false : true,
                    message: errorsT("required"),
                },
            },
            supplierSumWithVat: {
                required: {
                    value: data.supplierCostInfo.sumIncVat > 0 ? false : true,
                    message: errorsT("required"),
                },
            },
        },
    });

    function isValidProducerPrice() {
        if (!isGnvls) return { bool: true, message: "" };
        if (data.producerPrice === undefined || data.producerPrice <= 0) return { bool: false, message: "required" };
        if (data.producerPrice > data.registerPrice) return { bool: false, message: "exceededProducerPrice" };
        return { bool: true, message: "" };
    }

    function initializeValue(): IInvoiceItemCreateDTO {
        if (props.item) {
            return { ...props.item };
        }

        if (props.gosContractFields.isGosContract && props.gosContractFields.gosContractGoods) {
            return {
                ...defValue,
                retailCostInfo: {
                    ...defValue.retailCostInfo,
                    priceIncVat: props.gosContractFields.gosContractGoods.price,
                },
            } as IInvoiceItemCreateDTO;
        } else {
            return { ...defValue };
        }
    }

    useEffect(() => {
        if (props.item?.idSeriesGlobal) {
            sdp.getById(props.item?.idSeriesGlobal, (e) => setItemSeries(e))
        }
        if (props?.invoiceItemsGroup) {
            for (const char of props?.invoiceItemsGroup as IInvoiceItemCreateDTO[]) {
                if (char.kizBoxes?.length as number > 1) {
                    setItemsGroupAlertFlag(true);
                    break;
                } else {
                    setItemsGroupAlertFlag(false);
                }
            }
        }
    }, [])

    useEffect(() => {
        setCalcSum({
            ...calcSum,
            actualMarkupPercentage: pricingInvoice?.actualMarkupPercentage ?? 0,
            supplierVatPerUnit: pricingInvoice?.supplierVatPerUnit ?? 0,
            retailVatPerUnit: pricingInvoice?.retailVatPerUnit ?? 0,
            percentageOfMarkupRetail: pricingInvoice?.percentageOfMarkupRetail ?? 0, //"Наценка %" ? (процент розничной наценки)
            supplierMarkupPercentage: pricingInvoice?.supplierMarkupPercentage ?? 0, //Опт. надбавка, %
            supplierPriceWithVat: pricingInvoice?.supplierPriceWithVat ?? 0, //Цена поставщика с НДС
        });
        setData({
            ...data,
            quantity: pricingInvoice?.quantity ?? 1,
            retailCostInfo: {
                ...data?.retailCostInfo,
                price: pricingInvoice?.retailPrice ?? 0, //Цена отпускная без НДС
                vatPrice: pricingInvoice?.retailVatPerUnit ?? 0,
                priceIncVat: props.gosContractFields.gosContractGoods ?
                    data.retailCostInfo.priceIncVat :
                    pricingInvoice?.retailPriceWithVat ?? 0, //Цена отпускная с НДС
                sum: pricingInvoice?.retailAmount ?? 0, //Сумма розничная без НДС
                vatSum: pricingInvoice?.vatAmountRetail ?? 0, //Сумма НДС розничная
                sumIncVat: pricingInvoice?.retailAmountWithVat ?? 0, //Сумма розничная с НДС
                adprice: pricingInvoice?.percentageOfMarkupRetail ?? 0,
            },
            supplierCostInfo: {
                ...data?.supplierCostInfo,
                price: pricingInvoice?.supplierPriceExcludingVat ?? 0, //Цена поставщика без НДС
                vatPrice: pricingInvoice?.supplierVatPerUnit ?? 0,
                priceIncVat: pricingInvoice?.supplierPriceWithVat ?? 0, //Цена поставщика с НДС
                sum: pricingInvoice?.supplierAmountExcludingVat ?? 0, //Сумма поставщика без НДС
                vatSum: pricingInvoice?.supplierVatAmount ?? 0, //Сумма НДС поставщика
                sumIncVat: pricingInvoice?.supplierAmountWithVat ?? 0, //Сумма поставщика с НДС
                adprice: pricingInvoice?.supplierMarkupPercentage,
            },
            producerPrice: pricingInvoice?.manufacturerPrice ?? 0,
        });
    }, [pricingInvoice]);
    const getCalculatedInvoiceItem = (collectionProps: ICollectPricingInvoiceItemProps) => {
        if (goods !== undefined && props.idPricingModel) {
            setSendFetch(true);
            let pricingInvoiceDTO = CollectPricingInvoiceItemDTO(collectionProps);
            clearTimeout(requestPricingInvoiceTimeout.current as NodeJS.Timeout);
            requestPricingInvoiceTimeout.current = setTimeout(() => {
                pricingInvoiceDataProvider.getCalculatedInvoiceItemViewAsync(props.idPricingModel, pricingInvoiceDTO, (entity) => {
                    setPricingInvoice((prev) => {
                        if (prev && prev.eventType === entity.eventType) {
                            return {
                                ...prev,
                                ...entity,
                            };
                        } else {
                            return entity;
                        }
                    });
                    setSendFetch(false);
                });
            }, ResendingTimer);
        }
    };

    const getCalculatedInvoiceItemEndChange = (collectionProps: ICollectPricingInvoiceItemProps) => {
        if (goods !== undefined && props.idPricingModel) {
            setSendFetch(true);
            let pricingInvoiceDTO = CollectPricingInvoiceItemDTO(collectionProps);
            clearTimeout(requestPricingInvoiceTimeout.current as NodeJS.Timeout);
            pricingInvoiceDataProvider.getCalculatedInvoiceItemViewAsync(props.idPricingModel, pricingInvoiceDTO, (entity) => {
                setPricingInvoice((prev) => {
                    if (prev && prev.eventType === entity.eventType) {
                        return {
                            ...prev,
                            ...entity,
                        };
                    } else {
                        return entity;
                    }
                });
                setSendFetch(false);
            });
        }
    };

    const calcBySupplierVatRatePercentageChanged = (newVatValue: number) => {
        getCalculatedInvoiceItem({
            ...collectionData,
            eventTypes: 'SupplierVatRatePercentageChanged',
            supplierCostInfo: { ...data?.supplierCostInfo, vat: newVatValue },
        })
    };

    const calcByPercentageOfVatRateRetailChanged = (newVatValue: number) => {
        getCalculatedInvoiceItem({
            ...collectionData,
            eventTypes: 'PercentageOfVatRateRetailChanged',
            retailCostInfo: { ...data?.retailCostInfo, vat: newVatValue },
        })
    };

    const getCerificateId = (): string | undefined => {
        if (!itemSeries) return undefined;
        if (!itemSeries.certificates) return undefined;
        return itemSeries.certificates[0]?.idGlobal;
    };

    function updateTaxRateFromDoc()
    {
        taxTypeDataProvider.getView(taxTypeGridFilter, (dat) => {
            if (props.item?.supplierCostInfo.vat)
            {
                let supVat = dat.find(x=>x.taxRate === props.item?.supplierCostInfo.vat);
                if (supVat)
                {
                    setVatRateWholesale({idGlobal: supVat.idGlobal, displayName: supVat.displayName});
                    setData({ ...data, supplierCostInfo: { ...data?.supplierCostInfo, vat: supVat.taxRate } });
                    //calcBySupplierVatRatePercentageChanged(supVat.taxRate);
                }
            }
            if (props.item?.retailCostInfo.vat)
            {
                let retVat = dat.find(x=>x.taxRate === props.item?.retailCostInfo.vat);
                if (retVat)
                {
                    setRetailVatRate({idGlobal: retVat.idGlobal, displayName: retVat.displayName});
                    setData({ ...data, retailCostInfo: { ...data?.retailCostInfo, vat: retVat.taxRate } });
                    //calcByPercentageOfVatRateRetailChanged(retVat.taxRate);
                }
            }

        })
    }

    function setTaxRates(taxTypeRetail: ITaxTypeDTO, taxTypeSupplier: ITaxTypeDTO, registerPrice: number) {
        setRetailVatRate(taxTypeRetail);
        setVatRateWholesale(taxTypeSupplier);
        setData({
            ...data,
            supplierCostInfo: { ...data.supplierCostInfo, vat: taxTypeSupplier.taxRate },
            retailCostInfo: { ...data.retailCostInfo, vat: taxTypeRetail.taxRate },
            registerPrice: (registerPrice ?? undefined),
            idScalingRatioGlobal: scalingRatio?.idGlobal ?? undefined,
        });
        
    }

    function updateTaxRates(goodsTaxType, goodsTaxTypeSupplier, taxType, taxTypeSupplier, registerPrice, idScalingRatioGlobal)
    {   
      if (goodsTaxType && goodsTaxTypeSupplier)
        {
            setRetailVatRate(goodsTaxType);
            setVatRateWholesale(goodsTaxTypeSupplier);
            setData({
                ...data,
                supplierCostInfo: { ...data.supplierCostInfo, vat: taxTypeSupplier.taxRate },
                retailCostInfo: { ...data.retailCostInfo, vat: taxType.taxRate },
                registerPrice: (registerPrice ?? undefined),
                idScalingRatioGlobal: idScalingRatioGlobal ?? undefined,
            });
        } else if (goodsTaxType) {
            setRetailVatRate(goodsTaxType);
            setVatRateWholesale(goodsTaxType);
            setData({
                ...data,
                supplierCostInfo: { ...data.supplierCostInfo, vat: taxType.taxRate },
                retailCostInfo: { ...data.retailCostInfo, vat: taxType.taxRate },
                registerPrice: (registerPrice ?? undefined),
                idScalingRatioGlobal: idScalingRatioGlobal ?? undefined,
            });
        } else if (goodsTaxTypeSupplier) {
            setRetailVatRate(goodsTaxType);
            setVatRateWholesale(goodsTaxTypeSupplier);
            setData({
                ...data,
                supplierCostInfo: { ...data.supplierCostInfo, vat: taxTypeSupplier.taxRate },
                retailCostInfo: { ...data.retailCostInfo, vat: taxTypeSupplier.taxRate },
                registerPrice: (registerPrice ?? undefined),
                idScalingRatioGlobal: idScalingRatioGlobal ?? undefined,
            });
        }

        if (props.item?.supplierCostInfo.vat || props.item?.retailCostInfo.vat)
            updateTaxRateFromDoc();
    }

    useEffect(() => {
        if (goods?.idGlobal)
            gdp.getByIdAndScalingRatio(goods.idGlobal as string, scalingRatio?.idGlobal ?? uuidNull, (entity) => {
                if (props.variant === 'edit') {
                    taxTypeDataProvider.getByRate(data.retailCostInfo.vat, (taxType) => {
                        taxTypeDataProvider.getByRate(data.supplierCostInfo.vat, (taxTypeSupplier) => {
                            setTaxRates(taxType, taxTypeSupplier, entity.gnvls.registerPrice as number)
                            setIsKiz((props.item?.isKiz || props.item?.isKizBox) ?? entity.attributes.isKiz ?? false);
                            setIsGnvls(entity.gnvls.important ?? false);
                            setScalingRatio(entity.scalingRatio);
                        });
                    });
                }
                else if (entity?.taxType && entity?.taxTypeSupplier) {
                    taxTypeDataProvider.getById(entity.taxType.idGlobal, (taxType) => {
                        taxTypeDataProvider.getById(entity.taxTypeSupplier.idGlobal, (taxTypeSupplier) => {
                            updateTaxRates(entity.taxType, entity.taxTypeSupplier, taxType, taxTypeSupplier,entity.gnvls.registerPrice,entity?.scalingRatio.idGlobal);
                            setIsKiz((props.item?.isKiz || props.item?.isKizBox) ?? entity.attributes.isKiz ?? false);
                            setIsGnvls(entity.gnvls.important ?? false);
                        });
                    });
                } else if (entity?.taxType) {
                    taxTypeDataProvider.getById(entity.taxType.idGlobal, (taxType) => {
                        updateTaxRates(entity.taxType, entity.taxTypeSupplier, taxType, undefined, entity.gnvls.registerPrice, entity?.scalingRatio.idGlobal);
                        setIsKiz((props.item?.isKiz || props.item?.isKizBox) ?? entity.attributes.isKiz ?? false);
                        setIsGnvls(entity.gnvls.important ?? false);
                    });
                } else if (entity?.taxTypeSupplier) {
                    taxTypeDataProvider.getById(entity.taxTypeSupplier.idGlobal, (taxTypeSupplier) => {
                        updateTaxRates(entity.taxType, entity.taxTypeSupplier, undefined, taxTypeSupplier,entity.gnvls.registerPrice,entity?.scalingRatio.idGlobal );
                        setIsKiz((props.item?.isKiz || props.item?.isKizBox) ?? entity.attributes.isKiz ?? false);
                        setIsGnvls(entity.gnvls.important ?? false);
                    });
                }
            });
        else {
            setData({ ...data, registerPrice: 0, idScalingRatioGlobal: undefined, idGoodsGlobal: "" });
            setIsKiz((props.item?.isKiz || props.item?.isKizBox) ?? false);
            setScalingRatio(undefined);
            setIsGnvls(false);
        }
    }, [goods]);

    useEffect(() => {
        if (data?.idScalingRatioGlobal && goods?.idGlobal) {
            scalingRatioDP.overrideGetById(goods?.idGlobal, data.idScalingRatioGlobal, (e) => {
                setScalingRatio({ displayName: e.displayName, idGlobal: e.idGlobal });
                setData({ ...data, numerator: e.numerator, denominator: e.denominator });
            });
        }
    }, [data?.idScalingRatioGlobal]);

    function isEmpty(obj) {
        for (let key in obj) {
            return false;
        }
        return true;
    }

    const saveInvoiceItem = () => {
        if (goods) {
            let idGlobal: string = uuidv4();
            props.parentCallback?.(
                {
                    idGlobal: data.idGlobal ?? idGlobal,
                    idGoodsGlobal: goods.idGlobal,
                    idScalingRatioGlobal: scalingRatio?.idGlobal,
                    idSeriesGlobal: isEmpty(itemSeries) ? undefined : itemSeries.idGlobal,
                    IdCertificateExternalGlobal: getCerificateId(),
                    idInvoiceItemGroupGlobal: data.idInvoiceItemGroupGlobal,
                    idGosContractGoodsExternalGlobal: props.gosContractFields.isGosContract ? props.gosContractFields.gosContractGoods?.idGlobal : undefined,
                    quantity: data.quantity,
                    producerPrice: data.producerPrice,
                    productMargin: calcSum.actualMarkupPercentage,
                    supplierCostInfo: data.supplierCostInfo,
                    retailCostInfo: data.retailCostInfo,
                    supplierGoodsCode: data.supplierGoodsCode,
                    gtdNumber: data.gtdNumber,
                    barCode: data.barCode,
                    registerPrice: data.registerPrice,
                    isKiz: isKiz,
                    kizs: data.kizs ?? [],
                    kizBoxes: data.kizBoxes ?? [],
                    kizBoxCount: data.kizBoxCount,
                    kizCount: data.kizBoxCount,
                    denominator: data.denominator,
                    numerator: data.numerator,
                    dateCreated: DateTime.now(),
                    isOsu: data.isOsu,
                    kizOsuDto: data.kizOsuDto
                },
                {
                    idGlobal: data.idGlobal ?? idGlobal,
                    absentInGC: props.gosContractFields.isGosContract ? (props.gosContractFields.gosContractGoods ? false : true) : null,
                    goodsName: goods.displayName,
                    scalingRatioName: scalingRatio?.displayName as string,
                    quantity: data.quantity,
                    productMargin: calcSum.actualMarkupPercentage,
                    producerPrice: data.producerPrice,
                    retailPrice: data.retailCostInfo.price as number,
                    retailPriceIncVat: data.retailCostInfo.priceIncVat as number,
                    retailAmount: data.retailCostInfo.sum,
                    retailVat: data.retailCostInfo.vat,
                    retailAmountIncVat: data.retailCostInfo.sumIncVat,
                    supplierAmount: data.supplierCostInfo.sum,
                    supplierAmountIncVat: data.supplierCostInfo.sumIncVat,
                    supplierPrice: data.supplierCostInfo.price,
                    supplierVat: data.supplierCostInfo.vat,
                    isKiz: isKiz,
                    isGnvls: isGnvls,
                    countKizScan: isKiz === false ? undefined : props.displayItem?.countKizScan ?? 0,
                    idInvoiceItemGroupGlobal: data.idInvoiceItemGroupGlobal,
                    denominator: data.denominator,
                    numerator: data.numerator,
                    dateCreated: DateTime.now(),
                    bestBefore: itemSeries?.bestBefore,
                    seriesNumber: itemSeries?.seriesNumber,    
                    isOsu: data.isOsu                
                }
            );
        }
    };

    const getCurrencyInputValidation = (): ICurrencyInputValidation[] => {
        const result = [] as ICurrencyInputValidation[];
        if (props.gosContractFields.isGosContract && props.gosContractFields.gosContractGoods) {
            result.push({
                warning: {
                    maxValue: {
                        max: props.gosContractFields.gosContractGoods.qty,
                        name: baseT("gosContractOutOfRange"),
                    },
                },
            } as ICurrencyInputValidation);
        }
        return result;
    };

    function checkSameValue (value, sameValue, callback) {
        if (value !== sameValue) {
            callback();
        }
    }

    return (
        <BaseDocumentCreator
            valid={true}
            cancel={{ onClick: () => tabsContext.closeCurrent() }}
            ok={{
                onClick: () => {
                    if (isValid() && !sendFetch) {
                        saveInvoiceItem();
                        if (data.idInvoiceItemGroupGlobal && itemsGroupAlertFlag && ((data.registerPrice !== props.item?.registerPrice) || (data.producerPrice !== props.item?.producerPrice))) {
                            setItemsGroupAlert({ show: true });
                        } else {
                            tabsContext.closeCurrent();
                        }
                    }
                },
                disabled: sendFetch,
                sendRequestSpinner: sendFetch
            }}
        >
            <GridWrapper cols={30} className={styles.gridContainer}>
                <GridSelectorInput
                    required
                    label={baseT("name")/*"Наименование"*/}
                    className={styles.field_Goods}
                    selectedEntity={goods}
                    id="idGoodsGlobal"
                    onSelect={(entity) => setGoods(entity)}
                    selectorModalJsx={GoodsSelectorModal}
                    error={errors.idGoodsGlobal}
                    onFocus={() => setErrors({ ...errors, idGoodsGlobal: undefined })}
                    onClear={() => setGoods(undefined)}
                    disabled={props.documentStateProc ? true : false}
                />
                <GridSelectorInput
                    required
                    label={baseT("unit")/*"Единица измерения"*/}
                    className={styles.field_ScalingRatio}
                    selectedEntity={scalingRatio}
                    id="idScalingRatioGlobal"
                    onSelect={(entity) => {
                        setData({ ...data, idScalingRatioGlobal: entity.idGlobal });
                        setScalingRatio(entity);
                    }}
                    masterIdGlobal={goods?.idGlobal}
                    error={errors.idScalingRatioGlobal}
                    onFocus={() => setErrors({ ...errors, idScalingRatioGlobal: undefined })}
                    selectorModalJsx={ScalingRatioSelectorModal}
                    onClear={() => {
                        setData({ ...data, idScalingRatioGlobal: undefined });
                        setScalingRatio(undefined);
                    }}
                    disabled={props.documentStateProc ? true : false || props.isHardUnProcess}
                />
                <div className={classNames(styles.field_Kiz, !isKiz && styles.field_Kiz_Disabled)}> КИЗ </div>
                <div className={classNames(styles.field_Jv, !isGnvls && styles.field_Jv_Disabled)}> ЖВ </div>
            </GridWrapper>

            <GridWrapper cols={8}>
                <CurrencyInputWithValidation
                    required
                    error={errors.quantity}
                    keyBan={true}
                    className={styles.field_CountDocs}
                    value={data?.quantity}
                    validation={getCurrencyInputValidation()}
                    min={getKizCount(props.item)}
                    onFocus={() => setErrors({ ...errors, quantity: undefined })}
                    inputId={"countDocs"}
                    label={baseT("quantityDoc")}
                    onChange={(value) => {
                        setData({ ...data, quantity: value });
                        getCalculatedInvoiceItem({
                            ...collectionData,
                            eventTypes: "QuantityСhanged",
                            quantity: value,
                        });
                    }}
                    onEndChange={(value) => {
                        checkSameValue(value, pricingInvoice?.quantity, () => {
                            setData({ ...data, quantity: value });
                            getCalculatedInvoiceItemEndChange({
                                ...collectionData,
                                eventTypes: "QuantityСhanged",
                                quantity: value,
                            });
                        });
                    }}
                    disabled={(props.documentStateProc ? true : false) || props.isHardUnProcess}
                />
                <NumberInput className={styles.field_CountFact} disabled variant="disabled" label={baseT("quantityFact")} inputId="countFact" />
                <NumberInput className={styles.field_Boi} disabled label={baseT("boi")}/*"Бой"*/ inputId="boi" />
                <NumberInput className={styles.field_Defect} disabled label={baseT("defect")}/*"Брак"*/ inputId="defect" />
                <NumberInput className={styles.field_Shortage} disabled label={baseT("shortage")}/*"Недостача"*/ inputId="shortage" />
                <NumberInput className={styles.field_MinCount} disabled variant="disabled" label={baseT("quantityMin")} inputId="minCount" />
                <div className={styles.field_CheckBox}>
                    <Checkbox 
                        name="isKiz" 
                        label={t("directory.goods.inputKiz")}//"Ввод КИЗ" 
                        checked={isKiz} 
                        onChange={(checked) => setIsKiz(checked)} 
                        disabled={(props.documentStateProc || props?.displayItem?.countKizScan) ? true : false}
                        tooltip={props?.displayItem?.countKizScan ? baseT("kizScannedNoChange") : null}
                    />
                </div>
            </GridWrapper>

            <GridWrapper cols={8}>
                <DecimalInputV2
                    required={data.registerPrice ? true : false}
                    label={baseT("manufacturerPrice")}
                    inputId={"manufacturerPrice"}
                    error={errors.producerPrice}
                    onFocus={() => setErrors({ ...errors, producerPrice: undefined })}
                    value={data?.producerPrice}
                    onChange={(value) => {
                        setData({ ...data, producerPrice: value });
                        getCalculatedInvoiceItem({
                            ...collectionData,
                            eventTypes: "ManufacturerPriceChanged",
                            producerPrice: value,
                        });
                    }}
                    onEndChange={(value) => {
                        checkSameValue(value, pricingInvoice?.manufacturerPrice, () => {
                            setData({ ...data, producerPrice: value });
                            getCalculatedInvoiceItemEndChange({
                                ...collectionData,
                                eventTypes: "ManufacturerPriceChanged",
                                producerPrice: value,
                            });
                        });
                    }}
                    disabled={props.documentStateProc ? true : false}
                />
                <GridSelectorInput
                    numereticInput
                    label={baseT("manufacturerMarginalPrice")}
                    id="manufacturerMarginalPrice" 
                    data={{...goods, validateReestr}}
                    selectedEntity={data.registerPrice}
                    selectorModalJsx={RegisterPriceSelectorModal}
                    onSelect={(entity) => {
                        setData({ ...data, registerPrice: entity});
                        if(data?.producerPrice > entity ) {
                            setErrors({...errors, producerPrice: errorsT('exceededProducerPrice')});
                        }
                    }}
                    onClear={() => setData({ ...data, registerPrice: 0 })}
                    disabled={props.documentStateProc || (validateReestr.isGnvls === false) ? true : false}
                    error={errors.registerPrice}
                    onFocus={() => setErrors({ ...errors, registerPrice: undefined })}
                />
                <GridSelectorInput className={styles.field_Store} disabled label={baseT("storePlace")} id="idGoodsGlobal" selectorModalJsx={StoreSelectorModal} />
                <TextInput className={styles.field_Gtd} disabled label={baseT("gtd")} inputId={"gtd"} />
                <NumberInput className={styles.field_AdditionalShK} disabled label={baseT("addBarcode")} inputId={"additionalShK"} />
            </GridWrapper>

            <Accordion opened={true} caption={baseT("calcSellingPrice")} id="CalculationOfTheSellingPriceInvoiceItem">
                <FlexRow wrap>
                    <GridSelectorInput
                        required
                        selectedEntity={vatRateWholesale}
                        label={baseT("vatRateWholesale")}
                        id="idGoodsGlobal"
                        disabled={((!props.currentSupplier?.useVat && props.variant === 'create') || props.documentStateProc) ? true : false}
                        selectorModalJsx={TaxTypeSelectorModal}
                        onSelect={(entity) => {
                            setVatRateWholesale(entity);
                            let vatValue = 0;
                            if (entity?.idGlobal) {
                                taxTypeDataProvider.getById(entity.idGlobal as string, (t) => {
                                    vatValue = t.taxRate;
                                    setData({ ...data, supplierCostInfo: { ...data?.supplierCostInfo, vat: vatValue } });
                                    calcBySupplierVatRatePercentageChanged(vatValue);
                                });
                            } else {
                                setData({ ...data, supplierCostInfo: { ...data?.supplierCostInfo, vat: vatValue } });
                                calcBySupplierVatRatePercentageChanged(vatValue);
                            }
                        }}
                        onClear={() => {
                            setVatRateWholesale(undefined);
                        }}
                        error={errors.supplierVat}
                        onFocus={() => setErrors({ ...errors, supplierVat: undefined })}
                    />
                    <DecimalInputV2 className={styles.field_WholesaleSurcharge} disabled label={baseT("supplierMarkupPercentage")} inputId={"SupplierMarkupPercentageChanged"} value={data?.supplierCostInfo?.adprice} />
                    <DecimalInputV2 disabled label={baseT("supplierVatPerUnit")} inputId={"SupplierVatPerUnitChanged"} value={data?.supplierCostInfo?.vatPrice} />

                    <DecimalInputV2
                        required
                        label={baseT("suppliersPrice")}
                        inputId={"suppliersPrice"}
                        value={data?.supplierCostInfo?.price}
                        error={errors.supplierPrice}
                        onFocus={() => setErrors({ ...errors, supplierPrice: undefined })}
                        onChange={(value) => {
                            setData({ ...data, supplierCostInfo: { ...data?.supplierCostInfo, price: value } });
                            getCalculatedInvoiceItem({
                                ...collectionData,
                                eventTypes: "SupplierPriceExcludingVatChanged",
                                supplierCostInfo: { ...data?.supplierCostInfo, price: value },
                            });
                        }}
                        onEndChange={(value) => {
                            checkSameValue(value, pricingInvoice?.supplierPriceExcludingVat, () => {
                                setData({ ...data, supplierCostInfo: { ...data?.supplierCostInfo, price: value } });
                                getCalculatedInvoiceItemEndChange({
                                    ...collectionData,
                                    eventTypes: "SupplierPriceExcludingVatChanged",
                                    supplierCostInfo: { ...data?.supplierCostInfo, price: value },
                                });
                            })
                        }}
                        disabled={props.documentStateProc ? true : false}
                    />
                    <DecimalInputV2
                        required
                        label={baseT("supplierAmount")}
                        inputId={"supplierAmount"}
                        value={data?.supplierCostInfo?.sum}
                        error={errors.supplierSum}
                        onFocus={() => setErrors({ ...errors, supplierSum: undefined })}
                        onChange={(value) => {
                            setData({ ...data, supplierCostInfo: { ...data?.supplierCostInfo, sum: value } });
                            getCalculatedInvoiceItem({
                                ...collectionData,
                                eventTypes: "SupplierAmountExcludingVatChanged",
                                supplierCostInfo: { ...data?.supplierCostInfo, sum: value },
                            });
                        }}
                        onEndChange={(value) => {
                            checkSameValue(value, pricingInvoice?.supplierAmountExcludingVat, () => {
                                setData({ ...data, supplierCostInfo: { ...data?.supplierCostInfo, sum: value } });
                                getCalculatedInvoiceItemEndChange({
                                    ...collectionData,
                                    eventTypes: "SupplierAmountExcludingVatChanged",
                                    supplierCostInfo: { ...data?.supplierCostInfo, sum: value },
                                });
                            })
                        }}
                        disabled={props.documentStateProc ? true : false}
                    />
                    <DecimalInputV2
                        label={baseT("supplierVatAmount")}
                        inputId={"supplierVatAmount"}
                        value={data?.supplierCostInfo?.vatSum}
                        error={errors.supplierVatSum}
                        onFocus={() => setErrors({ ...errors, supplierVatSum: undefined })}
                        onChange={(value) => {
                            setData({ ...data, supplierCostInfo: { ...data?.supplierCostInfo, vatSum: value } });
                            getCalculatedInvoiceItem({
                                ...collectionData,
                                eventTypes: "SupplierVatAmountChanged",
                                supplierCostInfo: { ...data?.supplierCostInfo, vatSum: value },
                            });
                        }}
                        onEndChange={(value) => {
                            checkSameValue(value, pricingInvoice?.supplierVatAmount, () => {
                                setData({ ...data, supplierCostInfo: { ...data?.supplierCostInfo, vatSum: value } });
                                getCalculatedInvoiceItemEndChange({
                                    ...collectionData,
                                    eventTypes: "SupplierVatAmountChanged",
                                    supplierCostInfo: { ...data?.supplierCostInfo, vatSum: value },
                                });
                            })
                        }}
                        disabled={props.documentStateProc ? true : false}
                    />
                    <DecimalInputV2
                        required
                        label={baseT("supplierPriceWithVat")}
                        inputId={"supplierPriceWithVat"}
                        value={data?.supplierCostInfo?.priceIncVat}
                        error={errors.supplierPriceWithVat}
                        onFocus={() => setErrors({ ...errors, supplierPriceWithVat: undefined })}
                        onChange={(value) => {
                            setData({ ...data, supplierCostInfo: { ...data?.supplierCostInfo, priceIncVat: value } });
                            getCalculatedInvoiceItem({
                                ...collectionData,
                                eventTypes: "SupplierPriceWithVatChanged",
                                supplierCostInfo: { ...data?.supplierCostInfo, priceIncVat: value },
                            });
                        }}
                        onEndChange={(value) => {
                            checkSameValue(value, pricingInvoice?.supplierPriceWithVat, () => {
                                setData({ ...data, supplierCostInfo: { ...data?.supplierCostInfo, priceIncVat: value } });
                                getCalculatedInvoiceItemEndChange({
                                    ...collectionData,
                                    eventTypes: "SupplierPriceWithVatChanged",
                                    supplierCostInfo: { ...data?.supplierCostInfo, priceIncVat: value },
                                });
                            })
                        }}
                        disabled={props.documentStateProc ? true : false}
                    />
                    <DecimalInputV2
                        required
                        label={baseT("amountOfThePaymentWithVat")}
                        inputId={"amountOfThePaymentWithVat"}
                        value={data?.supplierCostInfo?.sumIncVat}
                        error={errors.supplierSumWithVat}
                        onFocus={() => setErrors({ ...errors, supplierSumWithVat: undefined })}
                        onChange={(value) => {
                            setData({ ...data, supplierCostInfo: { ...data?.supplierCostInfo, sumIncVat: value } });
                            getCalculatedInvoiceItem({
                                ...collectionData,
                                eventTypes: "SupplierAmountWithVatChanged",
                                supplierCostInfo: { ...data?.supplierCostInfo, sumIncVat: value },
                            });
                        }}
                        onEndChange={(value) => {
                            checkSameValue(value, pricingInvoice?.supplierAmountWithVat, () => {
                                setData({ ...data, supplierCostInfo: { ...data?.supplierCostInfo, sumIncVat: value } });
                                getCalculatedInvoiceItemEndChange({
                                    ...collectionData,
                                    eventTypes: "SupplierAmountWithVatChanged",
                                    supplierCostInfo: { ...data?.supplierCostInfo, sumIncVat: value },
                                });
                            })
                        }}
                        disabled={props.documentStateProc ? true : false}
                    />
                </FlexRow>

                <FlexRow wrap>
                    <GridSelectorInput
                        required
                        selectedEntity={retailVatRate}
                        label={baseT("retailVatRate")}
                        id="idGoodsGlobal"
                        selectorModalJsx={TaxTypeSelectorModal}
                        onSelect={(entity) => {
                            setRetailVatRate(entity);
                            let vatValue = 0;
                            if (entity?.idGlobal) {
                                taxTypeDataProvider.getById(entity.idGlobal, (t) => {
                                    vatValue = t.taxRate;
                                    setData({ ...data, retailCostInfo: { ...data?.retailCostInfo, vat: vatValue } });
                                    calcByPercentageOfVatRateRetailChanged(vatValue);
                                });
                            } else {
                                setData({ ...data, retailCostInfo: { ...data?.retailCostInfo, vat: vatValue } });
                                calcByPercentageOfVatRateRetailChanged(vatValue);
                            }
                        }}
                        onClear={() => setRetailVatRate(undefined)}
                        error={errors.retailVat}
                        onFocus={() => setErrors({ ...errors, retailVat: undefined })}
                        disabled={props.documentStateProc ? true : false}
                    />
                    <DecimalInputV2
                        label={baseT("adprice")}
                        inputId={"PercentageOfMarkupRetailChanged"}
                        value={data.retailCostInfo.adprice}
                        onChange={(value) => {
                            setData({ ...data, retailCostInfo: { ...data?.retailCostInfo, adprice: value } });
                            getCalculatedInvoiceItem({
                                ...collectionData,
                                eventTypes: "PercentageOfMarkupRetailChanged",
                                retailCostInfo: { ...data?.retailCostInfo, adprice: value },
                            });
                        }}
                        onEndChange={(value) => {
                            checkSameValue(value, pricingInvoice?.percentageOfMarkupRetail, () => {
                                setData({ ...data, retailCostInfo: { ...data?.retailCostInfo, adprice: value } });
                                getCalculatedInvoiceItemEndChange({
                                    ...collectionData,
                                    eventTypes: "PercentageOfMarkupRetailChanged",
                                    retailCostInfo: { ...data?.retailCostInfo, adprice: value },
                                });
                            });
                        }}
                        disabled={props.documentStateProc ? true : false}
                    />
                    <DecimalInputV2 disabled label={baseT("vatPrice")} inputId={"interestMargin"} value={data.retailCostInfo.vatPrice} />
                    <DecimalInputV2
                        required
                        label="Цена б/НДС, розн."
                        inputId={"retailPrice"}
                        value={data?.retailCostInfo?.price}
                        error={errors.retailPrice}
                        onFocus={() => setErrors({ ...errors, retailPrice: undefined })}
                        onChange={(value) => {
                            setData({ ...data, retailCostInfo: { ...data?.retailCostInfo, price: value } });
                            getCalculatedInvoiceItem({
                                ...collectionData,
                                eventTypes: "RetailPriceChanged",
                                retailCostInfo: { ...data?.retailCostInfo, price: value },
                            });
                        }}
                        onEndChange={(value) => {
                            checkSameValue(value, pricingInvoice?.retailPrice, () => {
                                setData({ ...data, retailCostInfo: { ...data?.retailCostInfo, price: value } });
                                getCalculatedInvoiceItemEndChange({
                                    ...collectionData,
                                    eventTypes: "RetailPriceChanged",
                                    retailCostInfo: { ...data?.retailCostInfo, price: value },
                                });
                            })
                        }}
                        disabled={props.documentStateProc ? true : false}
                    />
                    <DecimalInputV2
                        required
                        label={baseT("retailAmountWithoutVat")} // "Сумма розн. б/НДС"
                        inputId={"retailAmountWithoutVat"}
                        value={data?.retailCostInfo?.sum}
                        error={errors.retailSum}
                        onFocus={() => setErrors({ ...errors, retailSum: undefined })}
                        onChange={(value) => {
                            setData({ ...data, retailCostInfo: { ...data?.retailCostInfo, sum: value } });
                            getCalculatedInvoiceItem({
                                ...collectionData,
                                eventTypes: "RetailAmountChanged",
                                retailCostInfo: { ...data?.retailCostInfo, sum: value },
                            });
                        }}
                        onEndChange={(value) => {
                            checkSameValue(value, pricingInvoice?.retailAmount, () => {
                                setData({ ...data, retailCostInfo: { ...data?.retailCostInfo, sum: value } });
                                getCalculatedInvoiceItemEndChange({
                                    ...collectionData,
                                    eventTypes: "RetailAmountChanged",
                                    retailCostInfo: { ...data?.retailCostInfo, sum: value },
                                });
                            })
                        }}
                        disabled={props.documentStateProc ? true : false}
                    />
                    <DecimalInputV2
                        label={baseT("retailVatAmount")}//"Сумма НДС, розн."
                        inputId={"retailAmountWithVatSum"}
                        value={data?.retailCostInfo?.vatSum}
                        error={errors.retailVatSum}
                        onFocus={() => setErrors({ ...errors, retailVatSum: undefined })}
                        onChange={(value) => {
                            setData({ ...data, retailCostInfo: { ...data?.retailCostInfo, vatSum: value } });
                            getCalculatedInvoiceItem({
                                ...collectionData,
                                eventTypes: "VatAmountRetailChanged",
                                retailCostInfo: { ...data?.retailCostInfo, vatSum: value },
                            });
                        }}
                        onEndChange={(value) => {
                            checkSameValue(value, pricingInvoice?.vatAmountRetail, () => {
                                setData({ ...data, retailCostInfo: { ...data?.retailCostInfo, vatSum: value } });
                                getCalculatedInvoiceItemEndChange({
                                    ...collectionData,
                                    eventTypes: "VatAmountRetailChanged",
                                    retailCostInfo: { ...data?.retailCostInfo, vatSum: value },
                                });
                            })
                        }}
                        disabled={props.documentStateProc ? true : false}
                    />
                    <DecimalInputV2
                        required
                        variant="green"
                        labelVariant="green"
                        className={styles.boldLabel}
                        label={baseT("sellingPrice")}
                        inputId={"sellingPrice"}
                        value={data?.retailCostInfo?.priceIncVat ?? 0}
                        disabled={(!!props.gosContractFields.gosContractGoods || props.documentStateProc) ? true : false}
                        error={errors.retailPriceWithVat}
                        onFocus={() => setErrors({ ...errors, retailPriceWithVat: undefined })}
                        onChange={(value) => {
                            setData({ ...data, retailCostInfo: { ...data?.retailCostInfo, priceIncVat: value } });
                            getCalculatedInvoiceItem({
                                ...collectionData,
                                eventTypes: "RetailPriceWithVatChanged",
                                retailCostInfo: { ...data?.retailCostInfo, priceIncVat: value },
                            });
                        }}
                        onEndChange={(value) => {
                            checkSameValue(value, pricingInvoice?.retailPriceWithVat, () => {
                                setData({ ...data, retailCostInfo: { ...data?.retailCostInfo, priceIncVat: value } });
                                getCalculatedInvoiceItemEndChange({
                                    ...collectionData,
                                    eventTypes: "RetailPriceWithVatChanged",
                                    retailCostInfo: { ...data?.retailCostInfo, priceIncVat: value },
                                });
                            })
                        }}
                    />
                    <DecimalInputV2
                        required
                        variant="green"
                        labelVariant="green"
                        className={styles.boldLabel}
                        label={baseT("retailAmountWithVat")}
                        inputId={"retailAmountWithVat"}
                        value={+data?.retailCostInfo?.sumIncVat.toFixed(2)}
                        error={errors.retailSumWithVat}
                        onFocus={() => setErrors({ ...errors, retailSumWithVat: undefined })}
                        onChange={(value) => {
                            setData({ ...data, retailCostInfo: { ...data?.retailCostInfo, sumIncVat: value } });
                            getCalculatedInvoiceItem({
                                ...collectionData,
                                eventTypes: "RetailAmountWithVatChanged",
                                retailCostInfo: { ...data?.retailCostInfo, sumIncVat: value },
                            });
                        }}
                        onEndChange={(value) => {
                            checkSameValue(value, pricingInvoice?.retailAmountWithVat, () => {
                                setData({ ...data, retailCostInfo: { ...data?.retailCostInfo, sumIncVat: value } });
                                getCalculatedInvoiceItemEndChange({
                                    ...collectionData,
                                    eventTypes: "RetailAmountWithVatChanged",
                                    retailCostInfo: { ...data?.retailCostInfo, sumIncVat: value },
                                });
                            })
                        }}
                        disabled={props.documentStateProc ? true : false}
                    />
                </FlexRow>
                <GridWrapper cols={8}>
                    <span className={styles.link_sertificate} onClick={() => setIsOpenSeriesModal(true)}>
                        {t("documents.invoice.certsAndSeries")/*Серии и сертификаты F9*/}
                    </span>
                    <span className={styles.link}>{t("documents.invoice.regCerts")}</span>
                    <span className={styles.link}>{t("documents.invoice.historyDelivery")}</span>
                </GridWrapper>
            </Accordion>

            {itemsGroupAlert.show && (
                <MessageModalWindow
                    message={baseT("messages.kizLotPriceChanged")}
                    ok={{
                        onClick: () => {
                            setItemsGroupAlert({ show: false });
                            tabsContext.closeCurrent();
                        },
                    }}
                    cancel={{ onClick: () => {}, hidden: true }}
                />
            )}

            {(isOpenSeriesModal && !props.documentStateProc) && (
                <SeriesModalWindow 
                    cancel={() => setIsOpenSeriesModal(false)} 
                    goods={goods as IEntitySimpleDTO} 
                    setItemSeries={(value: ISerialDTO) => setItemSeries(value)} 
                    itemSeries={itemSeries} 
                />
            )}
        </BaseDocumentCreator>
    );
};

export default InvoiceItemCreator;
