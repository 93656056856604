import { FC, RefObject, useRef } from "react";
import classNames from "classnames";
import { PropsWithChildren, useEffect, useState } from "react";
import styles from "./defaultGrid.module.scss";
import defaultStyles from "../../../Business/styles/index.module.scss";
import GridPaginator from "../gridPaginator";
import { ContextMenu } from "../../contextMenu/contextMenu";
import GridUISettings from "./gridUiSettings";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { useUserContext } from "../../../system/providers/userContextProvider";
import { DownIcon, UpIcon, SortIcon } from "../../../libs/corporate/icons/outlined/directions/ChevronCollection";
import { CheckBoxMultiple } from "../../checkboxes/checkBoxMultiple";
import { Tooltip } from "antd";
import { DateTime } from "luxon";
import {} from "../../../Services/Extensions/Boolean";
import {} from "../../../Services/Extensions/DateTime";
import { DocumentStatusType, GridRowHeightEnum, LoadingStatus } from "../../../@types/enumsGlobal";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { Spinner } from "../../spiner/Spinner";
import { returnKizEditIcon, returnKizStatusIcon, returnStatusIcon } from "../../../system/functions/returnStatusIcon";
import { cellValueIsKiz } from "../../../system/functions/cellValueIsKiz";
import { TypeScroll } from "../enum/enumScroll";
import { useTabsContext } from "../../../system/providers/tabsProvider";
import { TableHeader } from "../components/TableHeader";
import { FormattedValueView, getFormattedCellValue } from "../components/FormattedValueView";
import * as ActionIcons from "../../../libs/corporate/icons/outlined/editor/ActionCollection"
import { copyToClipboard } from "../../../system/functions/copyToClipboard";
import { GridFilterAction } from "../../../system/hooks/useGridFilter";

export interface IGridCellWithWidth extends IGridCell {
    width: number;
}

export interface IPage {
    pageNumber: number;
    prevPageNumber: number;
}

export interface actionGridRow {
    delete?: () => void;
}
function compareByOrder(a: IComparer, b: IComparer) {
    if (a.order === undefined || b.order === undefined) return 0;

    if (a.order < b.order) return -1;

    if (a.order > b.order) return 1;

    return 0;
}

interface IMousePosition {
    x: number;
    y: number;
}

export interface IColumnToSave {
    columns: IGridColumn[];
    save: boolean;
}
interface IopenWithEnterForEditProps {
    pluginTabContext?: IPlugin;
    selectedDocumentState?: DocumentStatusType | undefined;
    mnemocode?: string | undefined;
  }

interface ISearchRows {
    value: string;
    gridColumn?: IGridColumn;
}

interface IDefaultGridProps {
    gridId: string;
    plugin: IPluginSettings; //настройка шапки грида
    data: any[];
    filter: IGridFilter;
    totalCount: number; // макс.кол записей для пагинации
    actionGridRow?: actionGridRow;
    multipleSelect?: boolean; // возможность выбирать несколько строк
    selectedItem?: IGridRow; // выделенный элемент
    selectedItems?: IGridRow[]; // выделенные элементы
    selectedFirstPosition?: boolean; // выделение первой позиции
    documentStatus?: boolean; //добавление столбца с отображение статуса документа
    kizStatus?: boolean; // добавление столбца с отображением статуса киз
    boxStatus?: boolean; // добавление столбца с отображением статуса короба
    openKizEdit?: {
        open: boolean;
        action: (row: IGridRow) => void;
    }; // Добавление столбца для редактирования киз
    numbering?: boolean; // добавление столбца с нумераций
    isEditKizs?: boolean;
    hiddenPagination?: { hiddenCountRow: boolean | undefined; hiddenNumberPage: boolean | undefined, selectAllItems?: boolean};
    autoSelect?: boolean; // автоматическое выделение первой строки
    separator?: boolean; // расширяемый разделитель
    contextMenuItems?: IContextMenuItem[];
    loadingStatus?: LoadingStatus;
    setLoadingStatus?: (value: LoadingStatus) => void; // TODO: насколько уместен?
    dataProvider?: any; // реквест для валидации
    getStyleRow?: (row: IGridRow) => string | null; // стиль для строки
    getStyleCell?: (processedCell: IGridCell, cells: IGridCell[]) => string | null; // стиль для столбца
    onSelect?: (row: IGridRow | undefined) => void; // экш для выделения элемента
    onMultipleSelect?: (rows: IGridRow[], currentRow?: IGridRow) => void; // экш для выделения элементов
    singleDirectory?: boolean; // для отображения грида на всю высоту если это грид без detailsGrids
    onDoubleClick?: (row: IGridRow) => void;
    onEnter?: (row: IGridRow) => void;
    goods?: boolean;
    onSort: (column: IGridColumn) => void;
    searching?: boolean;
    isServerFilter?: boolean;
    onFilterDelete: (column: IGridColumn) => void;
    onPageNumberChange: (pageNumber: number) => void;
    onNumberPerPageChange: (numberPerPage: number) => void;
    onGridRowContextMenuMouseClick?: (selectedRow: IGridRow, position: IMousePosition) => JSX.Element;
    getView?: (gridFilter: IGridFilter, callback: (data: any[]) => void) => void;
    openWithEnterForEdit?: IopenWithEnterForEditProps;
    useUiService?: boolean;
    setPluginSettings?: (plugin: IPluginSettings) => void;
    quantityPosition?: boolean;
    localData?: boolean;
    className?: string;
}

export const DefaultGrid = <TViewDTO,>(props: PropsWithChildren<IDefaultGridProps>) => {
    const appCtx = useAppContext();
    const userCtx = useUserContext();
    const tabCtx = useTabsContext();
    let gridRef = useRef<HTMLTableSectionElement>(null);

    const [useUiService, ] = useState<boolean>(props.useUiService ?? true);
    const [gridRowContextMenuVisible, setGridRowContextMenuVisible] = useState(false);
    const [gridHeaderContextMenuVisible, setGridHeaderContextMenuVisible] = useState(false);
    const [showGridHeaderSettings, setShowGridHeaderSettings] = useState(false);
    const [gridRowContextMenu, setGridRowContextMenu] = useState<JSX.Element | undefined>();
    const [headerCtxPosY, setHeaderCtxPosY] = useState(0);
    const [headerCtxPosX, setHeaderCtxPosX] = useState(0);
    const [allChecked, setAllChecked] = useState<boolean>(false);
    const [typeChecked, setTypeChecked] = useState<string>("emptyField");

    const [, setPluginSettingsLoaded] = useState<boolean>(false);

    const defaultPlugin: IPluginSettings = JSON.parse(JSON.stringify(props.plugin)) as IPluginSettings;
    const [pluginSettings, setPluginSettings] = useState<IPluginSettings>(defaultPlugin);

    const [gridColumnsToSave, setGridColumnsToSave] = useState<IColumnToSave>({ columns: [], save: false });
    const [data, setData] = useState<any[]>(props?.data?.map((x) => ({ ...x, idRow: uuidv4() })));
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [loadingRow, setLoadingRow] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const { t } = useTranslation();
    const [, setViewState] = useState<GridStateType>("view");
    let tableRef = useRef<HTMLDivElement>(null);
    //separator
    const [clientHeight, setClientHeight] = useState<number | null>(getClientHeight(data.length));
    const yDividerPos = useRef<number>(0);
    const [sendRequest, setSendRequest] = useState<boolean>(false);

    const [searchRows, setSearchRows] = useState<ISearchRows>({value: ''});
    const [searchRowsArray, setSearchRowsArray] = useState<ISearchRows[]>([]);

    const getOperator = (columnType: ColumnType ):OperatorType => {
        if (columnType === 'string') return "Like"
        if (columnType === 'boolean') return 'Eq'
        return "MoreOrEq"
    }

    const getValue = (columnType: ColumnType, value: string):string => {
        if (columnType === 'string') return `%${value}%`
        return value
    }

    useEffect(() => {
        if (!searchRows.gridColumn) return

        let newArr: ISearchRows[] = [];
        for (let i = 0; i < searchRowsArray.length; i++) {
            if ((searchRowsArray[i]?.gridColumn?.order !== searchRows?.gridColumn?.order) && (searchRowsArray[i].value !== '')) {
                newArr.push(searchRowsArray[i]);
            }
        }
        
        newArr.push({value: searchRows.value, gridColumn: searchRows.gridColumn});

        if (searchRows.value === '') {
            newArr = newArr.filter(c => c.gridColumn?.order !== searchRows.gridColumn?.order)
        }
        
        setSearchRowsArray(([...newArr]));
    }, [searchRows]);

    useEffect(() => {
        if (!props.isServerFilter) return

        const columnFilters = searchRowsArray.map(x => {return {
            name: x.gridColumn?.propertyName,
            operator: getOperator(x.gridColumn?.propertyType as ColumnType),
            value: getValue(x.gridColumn?.propertyType as ColumnType, x.value), 
        } as IGridColumnFilter})

        props?.getView?.(
            {
                columnFilters: [...props.filter.columnFilters, ...columnFilters],
                numberPerPage: props.filter.numberPerPage,
                pageNumber: props.filter.pageNumber,
                fieldOrderBy: props.filter.fieldOrderBy,
                orderDesc: props.filter.orderDesc,
                paramFilter: props.filter.paramFilter
            }, (data) => {
                setData([...data.map((x) => ({ ...x, idRow: uuidv4() }))]);
            })
    },[searchRowsArray])

    function getClientHeight(dataLength: number, rowHeight: number =  pluginSettings?.rowHeight ?? GridRowHeightEnum.normal) {
        if (dataLength >= 10 && props.singleDirectory) {
            const height = (props.filter.numberPerPage) * rowHeight
            if (tableRef.current) {
                tableRef.current.style.height = height + "px";
            }
            return height;
        } else if (dataLength >= 10 && !props.singleDirectory) {
            const height = (props.filter.numberPerPage - 1) * rowHeight
            if (tableRef.current) {
                tableRef.current.style.height = height + "px";
            }
            return height
        } else if (dataLength === 1) {
            return 100;
        } else if (dataLength === 0) {
            return 65;
        } else {
            return dataLength * 47 + 50;
        }
    }


    const onMouseHoldDown = (e) => {
        yDividerPos.current = e.clientY;
    };
    const onMouseHoldUp = () => {
        yDividerPos.current = 0;
    };

    const onMouseHoldMove = (e) => {
        if (!yDividerPos.current) {
            return;
        }
        setClientHeight(clientHeight + e.clientY - yDividerPos.current);
        yDividerPos.current = e.clientY;
    };

    const getView = (gridFilter: IGridFilter, typeScroll: TypeScroll) => {
        if (props.getView) getViewMethod(gridFilter, typeScroll)
        else if (props.dataProvider) dataProviderGetView(gridFilter, typeScroll)
    }

    const getViewMethod = (gridFilter: IGridFilter, typeScroll: TypeScroll) =>
    {
        props.getView?.(gridFilter, (newRows) => {
            if (newRows) {
                typeScroll === TypeScroll.up? scrollUp(newRows) : scrollDown(newRows)
            }
        })
    }

    const dataProviderGetView = (gridFilter: IGridFilter, typeScroll: TypeScroll) =>
    {
        props?.dataProvider?.getView(gridFilter, (newRows: TViewDTO[]) => {
            typeScroll === TypeScroll.up? scrollUp(newRows) : scrollDown(newRows)
        });
    }

    const scrollUp = (newRows: TViewDTO[]) => {
        setSendRequest(false);
        tableRef.current?.scrollTo({ top: tableRef.current?.scrollHeight / 3 });
        if (data.length >= props.filter.numberPerPage * 2) {
            tableRef.current?.scrollTo({ top: tableRef.current?.scrollTop / 2 });
            setData([...newRows.map((x) => ({ ...x, idRow: uuidv4() })), ...data.slice(0, props.filter.numberPerPage)]);
        } else {
            setData([...newRows.map((x) => ({ ...x, idRow: uuidv4() })), ...data.slice(0, props.filter.numberPerPage)]);
        }
    }

    const scrollDown = (newRows: TViewDTO[]) => {
        setSendRequest(false);
        if (pageNumber.pageNumber <= Math.ceil(props.totalCount / props.filter.numberPerPage)) {
            if (data.length >= props.filter.numberPerPage * 2) {
                tableRef.current?.scrollTo({ top: tableRef.current?.scrollTop / 2 });
                setData([...data.slice(props.filter.numberPerPage), ...newRows.map((x) => ({ ...x, idRow: uuidv4() }))]);
            } else {
                setData([...data, ...newRows.map((x) => ({ ...x, idRow: uuidv4() }))]);
            }
        }
    }

    useEffect(() => {
        document.addEventListener("mouseup", onMouseHoldUp);
        document.addEventListener("mousemove", onMouseHoldMove);
        return () => {
            document.removeEventListener("mouseup", onMouseHoldUp);
            document.removeEventListener("mousemove", onMouseHoldMove);
        };
    });

    useEffect(() => {
        if (props.separator) {
            if (!clientHeight) {
                setClientHeight(getClientHeight(data.length));
                return;
            }
            if (tableRef.current) {	
                tableRef.current.style.minHeight = clientHeight + "px";	
                tableRef.current.style.maxHeight = clientHeight + "px";	
            }
        }
    }, [clientHeight, tableRef.current]);

    // separator
    const [pageNumber, setPageNumber] = useState<IPage>({
        pageNumber: props.filter.pageNumber,
        prevPageNumber: props.filter.pageNumber,
    });
    let dataPaginator = getRows();
    useEffect(() => {
        setData(props?.data?.map((x) => ({ ...x, idRow: uuidv4() })));
    }, [props.data]);


    useEffect(() => {
        pluginSettingsLoad();
        gridRef?.current?.focus();
    }, []);

    useEffect(() => {
        if (!true && !props?.multipleSelect) {
            setSelectedItem(getRows()[0]);
            props.onSelect?.(getRows()[0]);
        }
    }, [data, props.filter.pageNumber]);

    const pluginSettingsLoad = () => {
        if (useUiService) {
            setLoadingRow(LoadingStatus.InProcess);
            appCtx.uiApiService.uiPluginSettingsRequest.load(pluginSettings.mnemocode, userCtx.idGlobal, (e) => {
                if (e.respType === "isCompleted") {
                    let columns: IGridColumn[] = [];
                    e.data.gridColumns.forEach((item) => columns.push(item));
                    const plugin: IPluginSettings = {
                        name: e.data.name,
                        mnemocode: e.data.mnemocode,
                        columns: [...columns],
                        permission: pluginSettings.permission,
                        rowHeight: e.data.rowHeight
                    };
                    setPluginSettings({ ...plugin });
                    props?.setPluginSettings?.({ ...plugin })
                    setPluginSettingsLoaded(true);
                    setClientHeight(getClientHeight(data.length, e.data.rowHeight));
                    setLoadingRow(LoadingStatus.Completed);
                } else {
                    appCtx.uiApiService.uiPluginSettingsRequest.save(
                        {
                            idUser: userCtx.idGlobal,
                            name: pluginSettings.name,
                            mnemocode: pluginSettings.mnemocode,
                            gridColumns: pluginSettings.columns,
                            detailsPlugins: [] as string[],
                            rowHeight: pluginSettings.rowHeight ?? GridRowHeightEnum.normal
                        },
                        () => {
                            setClientHeight(getClientHeight(data.length, pluginSettings.rowHeight));
                            setPluginSettingsLoaded(true);
                            setLoadingRow(LoadingStatus.Completed);
                        }
                    );
                }
            });
        } else {
            setLoadingRow(LoadingStatus.InProcess);
            setClientHeight(getClientHeight(data.length));
            setLoadingRow(LoadingStatus.Completed);
        }
    };

    useEffect(() => setAllChecked(props.selectedItems && props.selectedItems.length > 0 ? true: false), [props.selectedItems]);

    function onClickAll(check) {
        if (check) {
            let multipleArr: IGridRow[] = [];
            getRows().map((i) => {
                multipleArr.push(i);
                props.onMultipleSelect?.([...multipleArr]);
                return i;
            });
            setAllChecked(check);
        } else {
            let multipleArr: IGridRow[] = [];
            getRows().map((i) => {
                multipleArr.push(i);
                props.onMultipleSelect?.([]);
                return i;
            });
            setAllChecked(check);
        }
    }

    const hiddenPagination = props.hiddenPagination?.hiddenNumberPage === true && props.hiddenPagination.selectAllItems;
    useEffect(() => {
        if(hiddenPagination) {
            let multipleArr: IGridRow[] = [];
            getRows().map((i) => {
                multipleArr.push(i);
                props.onMultipleSelect?.([...multipleArr]);
                return i;
            });
            setAllChecked(true);
        }
        if (props.hiddenPagination?.selectAllItems === false) {
            props.onMultipleSelect?.([]);
            setAllChecked(false);
        }
    }, [pageNumber.pageNumber, props.hiddenPagination?.selectAllItems])
    

    useEffect(() => {
        if (props.autoSelect && data.length === 1) {
            getRows().map((item, index) => {
                const cells = item.cells;
                let orderedCells: IGridCellWithWidth[] = [];
                for (let i = 0; i < cells.length; i++) {
                    orderedCells.push({
                        ...cells[i],
                        order: pluginSettings.columns.find((item) => item.propertyName === cells[i].propertyName)?.order as number,
                        width: pluginSettings.columns.find((item) => item.propertyName === cells[i].propertyName)?.width as number,
                    });
                }
                orderedCells = orderedCells.sort(compareByOrder);
                if (index === 0) {
                    props.onSelect?.(item);
                    setSelectedItem(item);
                }
            });
        }
        if (props.selectedFirstPosition) {
            setSelectedItem(getRows()[0]);
            props.onSelect?.(getRows()[0]);
        }
    }, [data]);

    useEffect(() => {
        const selectedRows = getRows().filter((x) => x.isSelected);
        const rowsLength = getRows().length;

        if (selectedRows.length === rowsLength) setTypeChecked("allChecked");
        else if (selectedRows.length === 0) setTypeChecked("emptyField");
        else if (selectedRows.length < rowsLength) setTypeChecked("indeterminate");
    }, [getRows()]);

    
    function addMissingFieldsToViewTable(currentData: any[]): any[] {
        return currentData.map(item => {
            let newItem = Object.assign({}, item);
            pluginSettings.columns.forEach(setting => {
                if (setting.visibility && !newItem.hasOwnProperty(setting.propertyName)) {
                    newItem[setting.propertyName] = null;
                }
            });
            return newItem;
        })
    }
    function getRows() {
        let rows: IGridRow[] = [];
        
        if (props.data) {
            addMissingFieldsToViewTable(data).forEach((i, index) => {
                let cells: IGridCell[] = [];
                Object.entries(i).forEach((prop) => {
                    try {
                        const propertyName = prop[0];
                        let value = prop[1];
                        const column = pluginSettings.columns.find((item) => item.propertyName === propertyName);
                        
                        // Get default translation if not exist in db
                        let translation = column?.translation ??
                            props.plugin.columns.find((x) => x.propertyName === propertyName)?.translation;

                        // Value translation
                        if (translation) {
                            value = Object.entries(i).find(x => x[0] === translation)?.[1];
                        }

                        let nullable = column?.nullable ??
                            props.plugin.columns.find((x) => x.propertyName === propertyName)?.nullable;
                        
                        if (value && value !== null && value !== "" && column) {
                            switch (column.propertyType) {
                                case "boolean":
                                    value = (value as boolean).toYesNoString();
                                    break;
                                case "date":
                                    if (typeof value !== "string") {
                                        value = (value as DateTime).toF3DateFormat(userCtx);
                                        break;
                                    } else {
                                        value = null;
                                        break;
                                    }
                                case "datetime":
                                    if (typeof value !== "string") {
                                        value = (value as DateTime).toF3DateTimeFormat(userCtx);
                                        break;
                                    } else {
                                        value = null;
                                        break;
                                    }
                            }
                        }

                        const order = column?.order as number;
                        //if (propertyName == 'documentState') {
                        //    cells.push({ value: returnStatusIcon(DocumentStateType[prop[1]]), propertyName: 'СД', order: index - 1, visibility: true })
                        //}
                        cells.push({
                            value,
                            propertyName,
                            order,
                            propertyType: column?.propertyType as string,
                            visibility: pluginSettings.columns.find((x) => x.propertyName === propertyName)?.visibility as boolean,
                            nullable: nullable,
                        });
                    } catch (ex) {
                        console.trace(prop[0] + " -> " + prop[1]);
                        console.trace(ex);
                    }
                });

                props?.multipleSelect
                    ? rows.push({
                          idGoodsGlobal: i["idGoodsGlobal"] as string,
                          idGlobal: i["idGlobal"] as string,
                          idRow: i["idRow"] as string,
                          displayName: i["displayName"] as string,
                          goodsName: i['goodsName'] as string,
                          cells: cells.filter((item) => item.order !== undefined && item.order !== null).sort(compareByOrder),
                          isDeleted: i["deleted"] as boolean,
                          isSelected: props?.selectedItems?.find((x) => x.idGlobal === i["idGlobal"]) === undefined ? false : true,
                      })
                    : rows.push({
                          idGoodsGlobal: i["idGoodsGlobal"] as string,
                          idGlobal: i["idGlobal"] as string,
                          idRow: i["idRow"] as string,
                          displayName: i["displayName"] || (i["name"] as string),
                          goodsName: i['goodsName'] as string,
                          cells: cells.filter((item) => item.order !== undefined && item.order !== null).sort(compareByOrder),
                          isDeleted: i["deleted"] as boolean,
                          isSelected: i["idRow"] === selectedItem?.idRow ? true : false,
                      });
            });
        }
        return rows;
    }

    function getContextId(type: "header" | "row", baseId: string) {
        return `ctxMenu_${type}_${baseId}`;
    }

    useEffect(() => {
        const handler = () => setGridHeaderContextMenuVisible(false);

        if (gridHeaderContextMenuVisible === true) {
            let ctxMenu = document.getElementById(getContextId("header", props.gridId)) as HTMLElement;
            if (ctxMenu) {
                ctxMenu.onmouseleave = handler;
                return ctxMenu.removeEventListener("mouseleave", handler);
            }
        }
    }, [gridHeaderContextMenuVisible]);

    useEffect(() => {
        const handler = () => setGridRowContextMenuVisible(false);
        if (gridRowContextMenuVisible === true) {
            let ctxMenu = document.getElementById(getContextId("row", props.gridId)) as HTMLElement;
            if (ctxMenu) {
                ctxMenu.onmouseleave = handler;
                return ctxMenu.removeEventListener("mouseleave", handler);
            }
        }
    }, [gridRowContextMenuVisible]);

    useEffect(() => {
        if (gridRowContextMenu) {
            let ctxMenu = document.getElementById(getContextId("row", props.gridId)) as HTMLElement;

            if (ctxMenu) {
                ctxMenu.onmouseleave = () => setGridRowContextMenu(undefined);
                return ctxMenu.removeEventListener("mouseleave", () => setGridRowContextMenu(undefined));
            }
        }
    }, [gridRowContextMenu]);

    useEffect(() => {
        if (useUiService && gridColumnsToSave.save) {
            appCtx.uiApiService.uiPluginSettingsRequest.save(
                {
                    idUser: userCtx.idGlobal,
                    name: pluginSettings.name,
                    mnemocode: pluginSettings.mnemocode,
                    gridColumns: gridColumnsToSave.columns,
                    detailsPlugins: [] as string[],
                    rowHeight: pluginSettings.rowHeight ?? GridRowHeightEnum.normal
                },
                () => {
                    setPluginSettings({ ...pluginSettings, columns: gridColumnsToSave.columns });
                    props?.setPluginSettings?.({ ...pluginSettings, columns: gridColumnsToSave.columns })
                    setGridColumnsToSave({ ...gridColumnsToSave, save: false });
                    setClientHeight(getClientHeight(data.length, pluginSettings.rowHeight));
                }
            );
        }
    }, [gridColumnsToSave]);

    useEffect(() => { props?.multipleSelect && setSelectedItem(undefined) }, [props?.multipleSelect])

    const savePluginSettingsWidth = () => {
        let grid = document.getElementById(props.gridId) as HTMLElement;
        let columnCells = grid.getElementsByClassName(styles.gridColumnCell);
        const newState: IGridColumn[] = [...pluginSettings.columns];
        let changed: boolean = false;
        for (var i = 0; i < columnCells.length; i++) {
            const pName = columnCells[i].getAttribute("data-property-name");
            const column = newState.find((x) => x.propertyName === pName) as IGridColumn;
            if (column?.width !== columnCells[i].getBoundingClientRect()?.width) {
                changed = true;
                if (column?.width) {
                    column.width = parseInt(String(columnCells[i].getBoundingClientRect()?.width));
                }
            }
        }
        if (changed && pluginSettings.columns) {
            setGridColumnsToSave({ columns: newState, save: true });
        }
    };

    const fullScreenTable = () => {
        let grid = document.getElementById(props.gridId) as HTMLElement;
        let tableWidth = grid.clientWidth;
        let columnCells = grid.getElementsByClassName(styles.gridColumnCell);

        let columnWidth = parseInt(String(tableWidth / columnCells.length));
        const newState: IGridColumn[] = [...pluginSettings.columns];
        if (pluginSettings.columns) {
            for (var i = 0; i < columnCells.length; i++) {
                newState[i].width = columnWidth;
                setGridColumnsToSave({ columns: newState, save: true });
            }
        }
    };

    function renderSortIcon(item: IGridColumn) {
        if (props.filter.fieldOrderBy === item.propertyName && props.filter.orderDesc) return <DownIcon className={classNames(styles.sortIcon, styles.sortIcon_Selected)} />;

        if (props.filter.fieldOrderBy === item.propertyName && !props.filter.orderDesc) return <UpIcon className={classNames(styles.sortIcon, styles.sortIcon_Selected)} />;

        return <SortIcon className={styles.sortIcon} />;
    }
    const checkUpScroll = (): boolean => {
        if (tableRef.current?.scrollHeight) {
            if (pageNumber.prevPageNumber !== 1 && pageNumber.prevPageNumber !== 2 && pageNumber.pageNumber !== 1) {
                return true;
            } else if (pageNumber.prevPageNumber == 2 && pageNumber.pageNumber == 3) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    const handleEnterKeyForEdit = () => {
        if (selectedItem === undefined || selectedItem?.isDeleted || props?.openWithEnterForEdit?.selectedDocumentState === "del") {
          return;
        }
        tabCtx.openChild(
            props?.openWithEnterForEdit?.pluginTabContext as IPlugin,
          "edit",
          selectedItem?.idGlobal,
          () => {
              setViewState("refresh");
              setSelectedItem?.(undefined);
          },
          props?.openWithEnterForEdit?.mnemocode
      );
    };

    const getStyleTd = (width: string, minWidth: string) => {
        return { width: width, minWidth: minWidth, height: pluginSettings.rowHeight }
    }

    if (loadingRow === LoadingStatus.InProcess) return <Spinner />
     
    return (
        <>
            {props.loadingStatus === LoadingStatus.InProcess ? (
                <Spinner />
            ) : props.loadingStatus === LoadingStatus.NoAccess ? (
                <div className={styles.noDataInfo}>
                    <span>Нет доступа</span>
                </div>
            ) : props.loadingStatus === LoadingStatus.NoData ? (
                <div className={styles.noDataInfo}>
                    <span>{t("general.noData")}</span>
                </div>
            ) : (
                <>
                    <div className={classNames(styles.gridWrapper, props.className)}>
                    {props.quantityPosition && <div className={styles.quantityPositions}>Кол-во позиций: {props.totalCount}</div>}
                        <div
                            className={classNames(styles.gridWrapper__tables, sendRequest ? styles.gridWrapper__tables__disabledScroll : "")}
                            ref={tableRef}
                            onScroll={(scroll) => {
                                if (!props.localData) {
                                    if (!props.hiddenPagination?.hiddenNumberPage) {

                                        let lastScrollTop: number = 0;
                                        const scrollTop: number = scroll.currentTarget.scrollTop;
                                        const scrollHeight: number = scroll.currentTarget.scrollHeight;
                                        const clientHeight: number = scroll.currentTarget.clientHeight;
                                        const conditionScroll: boolean = (scrollHeight - scrollTop) === clientHeight ||
                                            Math.round(scrollHeight - scrollTop) === clientHeight ||
                                            Math.round(scrollHeight - scrollTop - 1) === clientHeight ||
                                            Math.round(scrollHeight - scrollTop + 1) === clientHeight;
                                        
                                        if (scrollTop > lastScrollTop) {
                                            if (
                                                tableRef.current?.scrollHeight &&
                                                pageNumber.pageNumber < Math.ceil(props.totalCount / props.filter.numberPerPage) &&
                                                conditionScroll &&
                                                !sendRequest
                                            ) {
                                                setPageNumber({
                                                    pageNumber: pageNumber.pageNumber + 1,
                                                    prevPageNumber: pageNumber.pageNumber,
                                                });
                                                setSendRequest(true);
                                                getView({ ...props.filter, pageNumber: pageNumber.pageNumber + 1 },TypeScroll.down)
                                            }
                                        } else {
                                            if (checkUpScroll()) {
                                                if (scroll.currentTarget.scrollTop < 5 || scroll.currentTarget.scrollTop === 0) {
                                                    const pageNum = pageNumber.pageNumber - 2;
                                                    if (pageNum > 0) {
                                                        setPageNumber({
                                                            pageNumber: pageNumber.pageNumber - 1,
                                                            prevPageNumber: pageNumber.pageNumber,
                                                        });
                                                        setSendRequest(true);
                                                        getView({ ...props.filter, pageNumber: pageNum },TypeScroll.up)
                                                    }
                                                }
                                            }
                                        }
                                        lastScrollTop = scrollTop;
                                    }
                                };
                            }}
                        >
                            <table id={props.gridId} className={styles.gridTable}>
                                <thead
                                    className={styles.gridColumnsHeader}
                                    onContextMenu={(e) => {
                                        if (process.env.REACT_APP_SHOW_GRID_CONTEXT === "true") {
                                            e.preventDefault();
                                            setHeaderCtxPosX(e.pageX - 300);
                                            setHeaderCtxPosY(e.pageY - 140);
                                            setGridHeaderContextMenuVisible(true);
                                        }
                                    }}
                                    style={{height: pluginSettings.rowHeight}}
                                >
                                    <tr className={styles.gridColumn} onDoubleClick={(e) => fullScreenTable()} onMouseUp={(e) => savePluginSettingsWidth()}>
                                        {props.multipleSelect && (
                                            <td draggable={false} className={styles.additionalGridColumnCell} style={{ width: "45px", minWidth: "45px" }}>
                                                <CheckBoxMultiple checked={typeChecked} onChange={() => onClickAll(!allChecked)} />
                                            </td>
                                        )}
                                        {props.documentStatus && (
                                            <td draggable={false} className={styles.additionalGridColumnCell} style={{ width: "55px", minWidth: "55px" }}>
                                                <span className={styles.gridColumnHeaderName}>СД</span>
                                            </td>
                                        )}
                                        {props.openKizEdit && (
                                            <td draggable={false} className={styles.additionalGridColumnCell} style={{ width: "55px", minWidth: "55px" }}>
                                                <span className={styles.gridColumnHeaderName}></span>
                                            </td>
                                        )}
                                        {props.kizStatus && (
                                            <td draggable={false} className={styles.additionalGridColumnCell} style={{ width: "55px", minWidth: "55px" }}>
                                                <span className={styles.gridColumnHeaderName}>СК</span>
                                            </td>
                                        )}
                                        {props.boxStatus && (
                                            <td draggable={false} className={styles.additionalGridColumnCell} style={{ width: "55px", minWidth: "55px" }}>
                                                <span className={styles.gridColumnHeaderName}>СК</span>
                                            </td>
                                        )}
                                        {props.numbering && (
                                            <td draggable={false} className={styles.additionalGridColumnCell} style={{ width: "45px", minWidth: "45px" }}>
                                                <span className={styles.gridColumnHeaderName}>№</span>
                                            </td>
                                        )}
                                        {props.isEditKizs && (
                                            <td draggable={false} className={styles.additionalGridColumnCell} style={{ width: "75px", minWidth: "75px" }}>
                                                <span className={styles.gridColumnHeaderName}>IdError</span>
                                            </td>
                                        )}

                                        <>
                                            {pluginSettings.columns.sort(compareByOrder).map((item, index) => {
                                                const defaultPropertySetting = props.plugin.columns.find((i) => i.propertyName === item.propertyName);
                                                const sorting = defaultPropertySetting?.sorting ?? true;
                                                let filtered = props.filter.columnFilters.filter((i) => i.name === item.propertyName && i.invisible === false).length > 0;
                                                return (
                                                    item.visibility && (
                                                        <TableHeader
                                                            index={index}
                                                            draggable={true}
                                                            item={item}
                                                            filtered={filtered}
                                                            pluginSettings={pluginSettings}
                                                            setGridColumnsToSave={setGridColumnsToSave}
                                                            sorting={sorting}
                                                            onSort={props.onSort}
                                                            searching={props.searching}
                                                            value={searchRowsArray.find(c => c.gridColumn == item)?.value}
                                                            onSearch={(value, column) => {
                                                                setSearchRows({value: value, gridColumn: column});
                                                            }}
                                                            onPageNumberChange={props.onPageNumberChange}
                                                            setPageNumber={setPageNumber}
                                                            renderSortIcon={renderSortIcon}
                                                            setLoadingStatus={props.setLoadingStatus}
                                                            onFilterDelete={props.onFilterDelete}
                                                            filter={props.filter}
                                                            cls={{ tD: styles.gridColumnCell, tooltip: styles.gridColumnHeaderName, sortingDiv: styles.gridColumnCellButtons, sortingDivButton: styles.gridSortButton, searchDropDivButton: styles.searchDropDivButton, filteredDiv: styles.gridFilterButton }} 
                                                        />
                                                    )
                                                );
                                            })}
                                        </>
                                    </tr>
                                </thead>
                                <tbody className={styles.gridRowsGroup} ref={gridRef}>
                                    {getRows().filter(element => {
                                        let itСheck: boolean = true;
                                        if (props.isServerFilter) return itСheck
                                        searchRowsArray.forEach((el) => {
                                            switch (element?.cells.find(x => x.order === el.gridColumn?.order)?.propertyType) {
                                                case 'string': 
                                                case 'date':
                                                case 'datetime':
                                                    if (element?.cells.find(x => x.order === el.gridColumn?.order)?.value !== null && !element?.cells.find(x => x.order === el.gridColumn?.order)?.value.toLowerCase().includes(el?.value.toLowerCase())) {
                                                        itСheck = false;
                                                    }
                                                    break
                                                case 'number': 
                                                case 'decimal':
                                                    if (!String(element?.cells.find(x => x.order === el.gridColumn?.order)?.value).includes(el?.value)) {
                                                        itСheck = false;
                                                    }
                                                    break
                                                case 'boolean':
                                                    let isSearchingTrue: boolean = /^[да]*$/.test(el?.value.toLowerCase());
                                                    let isSearchingFalse: boolean = /^[нет]*$/.test(el?.value.toLowerCase());
                                                    if (isSearchingTrue && (element?.cells.find(x => x.order === el.gridColumn?.order)?.value === false || element?.cells.find(x => x.order === el.gridColumn?.order)?.value === null || element?.cells.find(x => x.order === el.gridColumn?.order)?.value === undefined)) {
                                                        itСheck = false;
                                                    } else if (isSearchingFalse && (element?.cells.find(x => x.order === el.gridColumn?.order)?.value === 'Да' || element?.cells.find(x => x.order === el.gridColumn?.order)?.value === true)) {
                                                        itСheck = false;
                                                    }
                                                    break
                                        }})
                                        return itСheck;
                                    }).map((item, index) => {
                                        const cells = item.cells;
                                        let orderedCells: IGridCellWithWidth[] = [];
                                        for (let i = 0; i < cells.length; i++) {
                                            orderedCells.push({
                                                ...cells[i],
                                                order: pluginSettings.columns.find((item) => item.propertyName === cells[i].propertyName)?.order as number,
                                                width: pluginSettings.columns.find((item) => item.propertyName === cells[i].propertyName)?.width as number,
                                            });
                                        }
                                        orderedCells = orderedCells.sort(compareByOrder);

                                        return (
                                            <tr
                                                onContextMenu={(e) => {
                                                    if (process.env.REACT_APP_SHOW_GRID_CONTEXT === "true") {
                                                        e.preventDefault();
                                                        setGridRowContextMenu(props.onGridRowContextMenuMouseClick?.(item, { x: e.pageX - 5, y: e.pageY - 5 }));
                                                    }

                                                    if (props.contextMenuItems && selectedItem) {
                                                        setHeaderCtxPosX(e.pageX);
                                                        setHeaderCtxPosY(e.pageY);
                                                        setGridRowContextMenuVisible(true);
                                                    }
                                                }}
                                                key={`key-${index}`}
                                                tabIndex={-1}
                                                data-item-id={item.idGlobal}
                                                className={classNames(
                                                    styles.gridRow,
                                                    props.getStyleRow?.(item),
                                                    item.isSelected ? styles.gridRowSelected : null,
                                                    item.isDeleted ? styles.gridRowDeleted : null,
                                                )}
                                                onClick={(e) => {
                                                    if (props?.multipleSelect) {
                                                        if (props?.selectedItems?.length === 0) {
                                                            props.onMultipleSelect?.([item], item);
                                                        }
                                                        if (props?.selectedItems) {
                                                            props?.selectedItems?.find((x) => x.idGlobal === item.idGlobal) === undefined && props.onMultipleSelect?.([...props.selectedItems, item], item);
                                                        }
                                                        if (item.isSelected && props?.selectedItems) {
                                                            const newSelectedArray = props?.selectedItems?.filter((x) => x.idGlobal !== item.idGlobal);
                                                            props.onMultipleSelect?.([...newSelectedArray], item);
                                                        }
                                                    } else {
                                                        if (item.isSelected) {
                                                            let target = ((e.target as Element).className as unknown as SVGAnimatedString);
                                                            const isTargetSvgMini = target.baseVal?.includes("svgMini");
                                                            if (!isTargetSvgMini) {
                                                                setSelectedItem(undefined);
                                                                props.onMultipleSelect?.([]);
                                                                props.onSelect?.(undefined);
                                                                setGridRowContextMenuVisible(false);
                                                            }
                                                        } else {
                                                            setSelectedItem(item);
                                                            props.onSelect?.(item);
                                                            props.onMultipleSelect?.([item]);
                                                            
                                                        }
                                                    }
                                                }}
                                                onKeyDown={(eKeyPress) => {
                                                    switch (eKeyPress.key) {
                                                        case "ArrowDown":
                                                        case "Tab":
                                                            eKeyPress.preventDefault();
                                                            const ArrowDownRef = tableRef.current;
                                                            const ArrowDownRefBody = ArrowDownRef?.querySelector("tbody");
                                                            const ArrowDownRefChildren = ArrowDownRefBody?.children;

                                                            const scrollHeightDown = ArrowDownRef?.scrollHeight as number;
                                                            const clientHeightDown = ArrowDownRef?.clientHeight as number;
                                                            const scrollTopDown = ArrowDownRef?.scrollTop as number;

                                                            let lastRowArrowDown;
                                                            for (let i = 0; i < dataPaginator.length; i++) {
                                                                if (dataPaginator[i].idGlobal === selectedItem?.idGlobal) {
                                                                    if (i + 1 < dataPaginator.length) {
                                                                        setSelectedItem(dataPaginator[i + 1]);
                                                                        props.onSelect?.(dataPaginator[i + 1]);
                                                                    } else lastRowArrowDown = i + 1;
                                                                    if (scrollTopDown + clientHeightDown > scrollHeightDown - 25 && lastRowArrowDown !== undefined) {
                                                                        ArrowDownRef?.scrollBy({
                                                                            top: 100,
                                                                            left: 0,
                                                                            behavior: "smooth",
                                                                        });
                                                                    }
                                                                    break;
                                                                }
                                                            }

                                                            if (props.hiddenPagination?.hiddenCountRow && props.hiddenPagination?.hiddenNumberPage) {
                                                                let lastRow;
                                                                for (let i = 0; i < dataPaginator.length; i++) {
                                                                    if (dataPaginator[i].idGlobal === selectedItem?.idGlobal) {
                                                                        if (i + 1 < dataPaginator.length) {
                                                                            setSelectedItem(dataPaginator[i + 1]);
                                                                            props.onSelect?.(dataPaginator[i + 1]);
                                                                        } else lastRow = i + 1;
                                                                    }
                                                                }
                                                                if (props.hiddenPagination?.hiddenCountRow && props.hiddenPagination?.hiddenNumberPage) {
                                                                    if (lastRow === dataPaginator.length && props.totalCount / props.filter.numberPerPage > props.filter.pageNumber) {
                                                                        props.filter.pageNumber++;
                                                                        props.onPageNumberChange(props.filter.pageNumber);
                                                                    }
                                                                }
                                                                let nextElement = eKeyPress.currentTarget.nextElementSibling as HTMLElement;
                                                                if (nextElement != null) nextElement.focus();
                                                                break;
                                                            } else {
                                                                break;
                                                            }

                                                        case "ArrowUp":
                                                            eKeyPress.preventDefault();
                                                            const ArrowUpRef = tableRef.current;
                                                            const ArrowUpRefBody = ArrowUpRef?.querySelector("tbody");
                                                            const ArrowUpRefChildren = ArrowUpRefBody?.children;
                                                            const scrollTopUp = ArrowUpRef?.scrollTop as number;

                                                            let firstRow;
                                                            for (let i = data.length - 1; i >= 0; i--) {
                                                                if (dataPaginator[i].idGlobal === selectedItem?.idGlobal) {
                                                                    if (i - 1 >= 0) {
                                                                        setSelectedItem(dataPaginator[i - 1]);
                                                                        props.onSelect?.(dataPaginator[i - 1]);

                                                                        ArrowUpRefChildren &&
                                                                            ArrowUpRefChildren[i - 2]?.scrollIntoView({
                                                                                behavior: "smooth",
                                                                                block: "nearest",
                                                                                inline: "start",
                                                                            });
                                                                    } else firstRow = i - 1;
                                                                    if ((scrollTopUp as number) < 45) {
                                                                        ArrowUpRef?.scrollBy({
                                                                            top: -100,
                                                                            left: 0,
                                                                            behavior: "smooth",
                                                                        });
                                                                    }
                                                                    break;
                                                                }
                                                            }

                                                            if (props.hiddenPagination?.hiddenCountRow && props.hiddenPagination?.hiddenNumberPage) {
                                                                let firstRow;
                                                                for (let i = data.length - 1; i >= 0; i--) {
                                                                    if (dataPaginator[i].idGlobal === selectedItem?.idGlobal) {
                                                                        if (i - 1 >= 0) {
                                                                            setSelectedItem(dataPaginator[i - 1]);
                                                                            props.onSelect?.(dataPaginator[i - 1]);
                                                                        } else firstRow = i - 1;
                                                                    }
                                                                }
                                                                if (props.hiddenPagination?.hiddenCountRow && props.hiddenPagination?.hiddenNumberPage) {
                                                                    props.filter.pageNumber--;
                                                                    props.onPageNumberChange(props.filter.pageNumber);
                                                                }
                                                                break;
                                                            } else {
                                                                break;
                                                            }

                                                        case "ArrowRight":
                                                            if (props.hiddenPagination?.hiddenCountRow && props.hiddenPagination?.hiddenNumberPage) {
                                                                if (props.totalCount / props.filter.numberPerPage > props.filter.pageNumber && props.hiddenPagination?.hiddenNumberPage) {
                                                                    props.filter.pageNumber++;
                                                                    props.onPageNumberChange(props.filter.pageNumber);
                                                                }
                                                                break;
                                                            } else {
                                                                break;
                                                            }

                                                        case "ArrowLeft":
                                                            if (props.hiddenPagination?.hiddenCountRow && props.hiddenPagination?.hiddenNumberPage) {
                                                                if (props.filter.pageNumber > 1 && props.hiddenPagination?.hiddenNumberPage) {
                                                                    props.filter.pageNumber--;
                                                                    props.onPageNumberChange(props.filter.pageNumber);
                                                                }
                                                                break;
                                                            } else {
                                                                break;
                                                            }

                                                        case "Enter":
                                                            setViewState("edit");
                                                            if (props.onEnter) {
                                                                selectedItem && item && props.onEnter(item);
                                                            }
                                                            if(selectedItem &&  props.openWithEnterForEdit?.selectedDocumentState &&  props.openWithEnterForEdit.pluginTabContext) {
                                                                handleEnterKeyForEdit();
                                                            }
                                                            break;

                                                        case "Escape":
                                                            if (selectedItem) {
                                                                setSelectedItem(undefined);
                                                                props.onMultipleSelect?.([]);
                                                                props.onSelect?.(undefined);
                                                            }
                                                            break

                                                        case "Delete":
                                                            props.actionGridRow?.delete?.();
                                                            break;
                                                        default:
                                                            break;
                                                    }
                                                }}
                                                onDoubleClick={() => {
                                                    !props.multipleSelect && props.onDoubleClick?.(item);
                                                }}
                                            >
                                                {props.multipleSelect && (
                                                    <td className={styles.gridRowCell} style={getStyleTd("45px","45px")}>
                                                        <input type="checkbox" checked={item.isSelected} readOnly />
                                                    </td>
                                                )}
                                                {props.documentStatus && (
                                                    <td className={styles.gridRowCell} style={getStyleTd("55px","55px")}>
                                                        {returnStatusIcon(item.cells.find((x) => x["propertyName"] === "documentState")?.value)}
                                                    </td>
                                                )}
                                                {props.openKizEdit && props.openKizEdit.open && props.openKizEdit.action && (
                                                    <td className={styles.gridRowCell} style={getStyleTd("55px","55px")}>
                                                        {returnKizEditIcon(cellValueIsKiz(item.cells), () => {
                                                            props.openKizEdit?.action(item);
                                                        })}
                                                    </td>
                                                )}
                                                {props.kizStatus && (
                                                    <td className={styles.gridRowCell} style={getStyleTd("55px","55px")}>
                                                        {returnKizStatusIcon(item.cells.find((x) => x["propertyName"] === "kizState")?.value)}
                                                    </td>
                                                )}
                                                {props.boxStatus && (
                                                    <td className={styles.gridRowCell} style={getStyleTd("55px","55px")}>
                                                        {returnKizStatusIcon(item.cells.find((x) => x["propertyName"] === "kizState")?.value)}
                                                    </td>
                                                )}
                                                {props.numbering && (
                                                    <td className={styles.gridRowCell} style={getStyleTd("45px","45px")}>
                                                        {index + 1}
                                                    </td>
                                                )}
                                                {props.isEditKizs && (
                                                    <td className={styles.gridRowCell} style={getStyleTd("75px","75px")}>
                                                        {item.cells.find((x) => x["propertyName"] === "idError")?.value}
                                                    </td>
                                                )}
                                                {orderedCells.map((item, index) => {
                                                    return (
                                                        item.visibility && (
                                                            <MainViewTd
                                                                item={item}
                                                                index={index}
                                                                getStyleCell={props.getStyleCell}
                                                                orderedCells={orderedCells}
                                                                rowHeight={pluginSettings.rowHeight}
                                                            />
                                                        )
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        {sendRequest && (
                            <div className={styles.spin}>
                                {" "}
                                <Spinner xl />
                            </div>
                        )}
                        {props?.separator && <div onMouseDown={onMouseHoldDown} className={classNames(defaultStyles.separator, defaultStyles.separator_horizontal)}></div>}
                        {props.hiddenPagination && (
                            <GridPaginator
                                nearPage={2}
                                pageNumber={props.filter.pageNumber}
                                totalCount={props.totalCount}
                                numberPerPage={props.filter.numberPerPage}
                                onPageNumberChange={props.onPageNumberChange}
                                onNumberPerPageChange={props.onNumberPerPageChange}
                                hiddenNumberPage={props.hiddenPagination?.["hiddenNumberPage"]}
                                hiddenCountRow={props.hiddenPagination?.["hiddenCountRow"]}
                            />
                        )}
                        {gridHeaderContextMenuVisible && (
                            <ContextMenu
                                id={getContextId("header", props.gridId)}
                                xPos={headerCtxPosX}
                                yPos={headerCtxPosY}
                                items={[
                                    {
                                        name: "Настройки таблицы",
                                        onClick: () => setShowGridHeaderSettings(true),
                                    },
                                ]}
                            />
                        )}
                        {gridRowContextMenuVisible && <ContextMenu id={getContextId("row", props.gridId)} xPos={headerCtxPosX - 300} yPos={headerCtxPosY - 140} items={props.contextMenuItems ?? []} />}
                        {showGridHeaderSettings && (
                            <GridUISettings
                                plugin={pluginSettings}
                                defaultPlugin={defaultPlugin}
                                save={(e) => {
                                    if (e.columns) {
                                        setGridColumnsToSave({ columns: e.columns, save: true });
                                    }
                                    setPluginSettings({...pluginSettings, rowHeight: e.rowHeight})
                                    setGridHeaderContextMenuVisible(false);
                                    setShowGridHeaderSettings(false);
                                }}
                                cancel={() => {
                                    setGridHeaderContextMenuVisible(false);
                                    setShowGridHeaderSettings(false);
                                }}
                            ></GridUISettings>
                        )}

                        {gridRowContextMenu}
                    </div>
                </>
            )}
        </>
    );
};

interface IMainViewTdProps {
    item: IGridCellWithWidth;
    index: number;
    getStyleCell?: (processedCell: IGridCell, cells: IGridCell[]) => string | null;
    orderedCells: IGridCellWithWidth[]
    rowHeight?: number
}

const MainViewTd: FC<IMainViewTdProps> = ({ item, index, getStyleCell, orderedCells, rowHeight }) => {
    const [spanWidth, setSpanWidth] = useState<boolean>(false);
    const tdMainRef = useRef<HTMLTableCellElement>(null)
    const [formatTooltip, setFormatTooltip] = useState<string>('');

    const [copyVisibility, setCopyVisibility] = useState<boolean>(false);

    const CopyLowOpacity = 0.4;
    const CopyHighOpacity = 0.85;
    const [copyOpacity, setCopyOpacity] = useState<number>(CopyLowOpacity);

    const actionForRef = (ref: RefObject<HTMLTableCellElement>) => {
        if (ref && ref.current) {
            const tdElement = ref.current;
            const tdElementCss = getComputedStyle(tdElement);
            const tdWidth = tdElement.offsetWidth - (parseFloat(tdElementCss.paddingLeft) + parseFloat(tdElementCss.paddingRight)) - 5
            
            const span = tdElement.querySelector('span');
            const spanWidth = span?.scrollWidth;

            spanWidth as number > tdWidth ? setSpanWidth(true) : setSpanWidth(false);
        }
    };

    return (
        <td
            onMouseEnter={() => { 
                actionForRef(tdMainRef);
                setCopyVisibility(true);
            }}
            onMouseLeave={() => {
                setCopyVisibility(false);
            }}
            ref={tdMainRef}
            key={index}
            className={classNames(styles.gridRowCell, getStyleCell?.(item, orderedCells))}
            style={{ width: item.width, height: rowHeight, cursor: "default" }}
        >
            <Tooltip className={styles.gridRowCellText} title={spanWidth && formatTooltip}>
                <span style={{ width: '100%' }}>
                    <FormattedValueView newValue={item} setFormatTooltip={setFormatTooltip} />
                </span>
            </Tooltip>
            <span
                style={{
                    opacity: copyVisibility ? copyOpacity : 0,
                    transition: "opacity 0.3s ease-in",
                    marginRight: -10,
                    paddingLeft: 1,
                    width: 16,
                    height: 16,
                    cursor: "pointer",
                    backgroundColor: "white",
                    borderRadius: 3
                }}
                onClick={async () => {
                    await copyToClipboard(getFormattedCellValue(item));
                }}
            >
                <ActionIcons.CopyMiniIcon 
                    onMouseEnter={() => setCopyOpacity(CopyHighOpacity) }
                    onMouseLeave={() => setCopyOpacity(CopyLowOpacity) }
                />
            </span>
        </td>
    );
};
