import {DateTime} from 'luxon';
import {ILotByIdDTO, ILotsByIdDTO} from '../../libs/core-api-requests/accounting/lotRequest';
import {
  IAnalysisLotResponse,
  IAnalysisLotViewDTO,
  ILotByGoodsViewDTO,
  ILotDTO,
  ILotGtdNumberDto,
  ILotViewDTO,
  IOnlineReserveDTO,
} from '../../libs/coreapi-dto/accounting/lot';
import CoreApiService from '../../system/api/coreApiService';
import {DictionaryDataProvider} from './Common/DictionaryDataProvider';
import { CoreApiResponse } from '../../libs/core-api-requests/common';
import { IdTableVariant } from '../../@types/enumsGlobal';

export class LotDataProvider extends DictionaryDataProvider<ILotViewDTO, ILotDTO> {
  private _coreApiService: CoreApiService;

  constructor(coreApiService: CoreApiService) {
    super();
    this._coreApiService = coreApiService;
  }

  getDataRequest(): Promise<IDictionaryRequest<ILotViewDTO, ILotDTO>> {
    return this._coreApiService.lotRequest;
  }

  getView(filter: IViewBody, callback?: (data: ILotViewDTO[], totalCount: number) => void): void {
    this._coreApiService.lotRequest.then((r) =>
      r.getView(filter, (e) => {
        if (e.respType === 'isCompleted') {
          callback?.(e.data.entities, e.data.totalCount);
        } else {
          throw new Error(e.message.title);
        }
      })
    );
  }

  getViewAnalysis(filter: IViewBody, callback?: (data: IAnalysisLotViewDTO[], totalCount: number) => void): void {
    this._coreApiService.lotRequest.then((r) =>
      r.getViewAnalysis(filter, (e) => {
        if (e.respType === 'isCompleted') {
          callback?.(e.data.entities, e.data.totalCount);
        } else {
          throw new Error(e.message.title);
        }
      })
    );
  }

  createDocument(idTableVariant: IdTableVariant, filter: IViewBody, callback?: (response: IAnalysisLotResponse) => void): void {
    this._coreApiService.lotRequest.then((r) =>
      r.createDocument(idTableVariant,filter, (e) => {
        if (e.respType === 'isCompleted') {
          callback?.(e.data);
        } else {
          throw new Error(e.message.title);
        }
      })
    );
  }

  getViewByGoods(filter: IViewBody, callback?: (data: ILotByGoodsViewDTO[], totalCount: number) => void) {
    this._coreApiService.lotRequest.then((r) =>
      r.getViewByGoods(filter, (e) => {
        if (e.respType === 'isCompleted') {
          callback?.(e.data.entities, e.data.totalCount);
        } else {
          throw new Error(e.message.title);
        }
      })
    );
  }

  getViewLotsByGoods(filter: IViewBody, callback?: (data: ILotViewDTO[], totalCount: number) => void) {
    this._coreApiService.lotRequest.then((r) =>
      r.getViewLotsByGoods(filter, (e) => {
        if (e.respType === 'isCompleted') {
          callback?.(e.data.entities, e.data.totalCount);
        } else {
          throw new Error(e.message.title);
        }
      })
    );
  }

  getLotById(idGlobal: string, callback: (data: ILotByIdDTO) => void) {
    this._coreApiService.lotRequest.then((r) =>
      r.getLotById(idGlobal, (e) => {
        if (e.respType === 'isCompleted') {
          callback(e.data);
        } else {
          throw new Error(e.message.title);
        }
      })
    );
  }
  getLotByKiz(kiz: string, callback: (data: ILotByIdDTO) => void) {
    this._coreApiService.lotRequest.then((r) =>
      r.getLotByKiz(kiz, (e) => {
        if (e.respType === 'isCompleted') {
          callback(e.data);
        } else {
          throw new Error(e.message.text);
        }
      })
    );
  }
  getLotByKizBox(kizBox: string, callback: (data: ILotByIdDTO) => void) {
    this._coreApiService.lotRequest.then((r) =>
      r.getLotByKizBox(kizBox, (e) => {
        if (e.respType === 'isCompleted') {
          callback(e.data);
        } else {
          throw new Error(e.message.text);
        }
      })
    );
  }

  getLotByGtinSgtin(gtinSgtin: string, idTable: number, callback: (data: ILotByIdDTO) => void) {
    this._coreApiService.lotRequest.then((r) =>
      r.getLotByGtinSgtin(gtinSgtin, idTable, (e) => {
        if (e.respType === 'isCompleted') {
          callback(e.data);
        } else {
          throw new Error(e.message.text);
        }
      })
    );
  }

  getLotByBarcode(barcode: string, callback: (data: ILotByIdDTO) => void) {
    this._coreApiService.lotRequest.then((r) =>
      r.getLotByBarcode(barcode, (e) => {
        if (e.respType === 'isCompleted') {
          callback(e.data);
        } else {
          throw new Error(e.message.text);
        }
      })
    );
  }
  getLotByGoodsCode(code: string, callback: (data: ILotDTO[]) => void) {
    this._coreApiService.lotRequest.then((r) =>
      r.getLotByGoodsCode(code, (e) => {
        if (e.respType === 'isCompleted') {
          callback(e.data);
        } else {
          throw new Error(e.message.text);
        }
      })
    );
  }
  getLotByInternalBarcode(internalBarcode: string, callback: (data: ILotByIdDTO) => void) {
    this._coreApiService.lotRequest.then((r) =>
      r.getLotByInternalBarcode(internalBarcode, (e) => {
        if (e.respType === 'isCompleted') {
          callback(e.data);
        } else {
          throw new Error(e.message.text);
        }
      })
    );
  }

  remain(idLotGlobal: string, idDocumentGlobal: string, callback: (remain: number) => void) {
    this._coreApiService.lotRequest.then((r) =>
      r.remain(idLotGlobal, idDocumentGlobal, (e) => {
        if (e.respType === 'isCompleted') {
          callback(e.data);
        } else {
          throw new Error(e.message.text);
        }
      })
    );
  }

  onlineReserve(idLotGlobal: string, body: IOnlineReserveDTO, callback: () => void) {
    this._coreApiService.lotRequest.then((r) =>
      r.onlineReserve(idLotGlobal, body, (e) => {
        if (e.respType === 'isCompleted') {
          callback();
        } else {
          throw new Error(e.message.title);
        }
      })
    );
  }

  createGtdNumber(idLotGlobal: string, body: ILotGtdNumberDto, callback: (e: CoreApiResponse<null>) => void) {
    this._coreApiService.lotRequest.then((r) =>
      r.createGtdNumber(idLotGlobal, body, (e) => {
        if (e.respType === 'isCompleted') {
          callback(e);
        } else {
          callback(e)
          throw new Error(e.message.title);
        }
      })
    );
  }

  deleteOnlineItemReserve(idLotGlobal: string, idTable: number, idDocument: string, idDocumentItem: string , callback: () => void) {
 this._coreApiService.lotRequest
      .then(r => r.deleteOnlineItemReserve(idLotGlobal, idTable, idDocument,  idDocumentItem, (e) => { if (e.respType === 'isCompleted') {
          callback();
        } else {
          throw new Error(e.message.title);
        }
      })
    );
  }

  deleteOnlineReserve(idDocument: string, callback: () => void) {
    this._coreApiService.lotRequest
         .then(r => r.deleteOnlineReserve(idDocument, (e) => { if (e.respType === 'isCompleted') {
             callback();
           } else {
             throw new Error(e.message.title);
           }
         })
       );
     }

  getByDocument(idDocGlobal: string, callback: (e: ILotDTO[]) => void) {
    this._coreApiService.lotRequest.then((r) => {
      r.getByDocument(idDocGlobal, (e) => {
        if (e.respType === 'isCompleted') {
          callback(e.data);
        } else {
          throw new Error(e.message.title);
        }
      });
    });
  }

  updateSeries(idLotGlobal: string, idSeriesGlobal: string, callback: () => void) {
    this._coreApiService.lotRequest.then((r) =>
      r.updateSeries(idLotGlobal, idSeriesGlobal, (e) => {
        if (e.respType === 'isCompleted') {
          callback();
        } else {
          throw new Error(e.message.title);
        }
      })
    );
  }
}
