const SettingsAdditionalPluginSettings: IPluginSettings = {
    name: 'Дополнительные настройки',
    mnemocode: 'additional_settings_plugin',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 157
        }, {
            order: 1,
            propertyName: 'serialNumber',
            propertyType: 'number',
            displayName: '№',
            visibility: true,
            width: 157
        }, {
            order: 2,
            propertyName: 'setting',
            propertyType: 'string',
            displayName: 'Настройка',
            visibility: true,
            width: 257
        },
        {
            order: 3,
            propertyName: 'value',
            propertyType: 'boolean',
            displayName: 'Значение',
            visibility: true,
            width: 257
        },


    ]
}

export default SettingsAdditionalPluginSettings
