export const ProductGroupsGISMTPluginSettings: IPluginSettings = {
    name: 'Группы товаров для контроля ГИС МТ',
    mnemocode: 'product_groups_gismt_plugin',
    columns: [
      {
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'idGlobal',
        visibility: true,
        width: 100
      },
      {
        order: 1,
        propertyName: 'Guid',
        propertyType: 'string',
        displayName: 'Guid',
        visibility: true,
        width: 100
      },
    ],
  }