export const RegKeysListPluginSettings: IPluginSettings = {
    name: 'Рег. ключи для списка ЮЛ',
    mnemocode: 'reg_keys_list_plugin',
    columns: [
      {
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'idGlobal',
        visibility: true,
        width: 100
      },
      {
        order: 1,
        propertyName: 'INN',
        propertyType: 'string',
        displayName: 'Инн',
        visibility: true,
        width: 100
      },
      {
        order: 2,
        propertyName: 'Token',
        propertyType: 'string',
        displayName: 'Токен',
        visibility: true,
        width: 100
      },
  
    ],
  }